<template>
  <base-select
    v-bind="$attrs"
    v-on="$listeners"
    :name="$attrs.name || $t('Cost Center')"
    :placeholder="$attrs.placeholder || $t('Cost Center')"
    :label="$attrs.label"
    :id="$attrs.id || 'cost-center'"
    :options="options"
    clearable
  >

  </base-select>
</template>
<script>
import { allCostCenterOptions } from "@/components/ag-grid/columns/costCenterColumns";

export default {
  inheritAttrs: false,
  computed: {
    options() {
      return allCostCenterOptions
    }
  }
}
</script>
