import Vue from 'vue'
import axios from 'axios'
import get from 'lodash/get'
import i18n from "@/i18n";

const app = new Vue()

export async function downloadFileForEntity(entity, entityPath, data, fileName) {
  try {
    Vue.set(entity, 'downloading', true)
    data = data || {}
    const response = await axios.post(`/restify/${entityPath}/${entity.id}/action?action=download-pdf`, data, {
      responseType: 'blob',
    })
    downloadFileLocally(response, fileName || getFileName(entity))
  } catch (err) {
    app.$error(i18n.t('Could not download the file'))
  } finally {
    Vue.set(entity, 'downloading', false)
  }
}

export function getFileName(entity) {
  const paths = get(entity, 'attributes.pdf_paths')
  const filePath = get(Object.values(paths), '[0]')
  const parts = filePath.split('/')
  return parts[parts.length - 1] || 'file.txt'
}

export async function downloadFile(url, fileName = 'checks.pdf') {
  const data = await axios.get(url, {
    responseType: 'blob',
  })
  downloadFileLocally(data, fileName)
}

export function previewFileInNewTab(data, title) {
  const url = window.URL.createObjectURL(data)
  const newWindow = window.open(url)
  if (title) {
    setTimeout(() => {
      newWindow.document.title = title
    }, 10)
  }
}

export function downloadFileLocally(data, fileName) {
  const url = window.URL.createObjectURL(new window.Blob([data]))
  const link = document.createElement('a');
  link.href = url;

  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}

export async function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(blob);
    reader.onerror = reject
    reader.onabort = reject
  })
}
