<template>
  <div>
    <div class="col-span-3 w-full flex justify-end print:bg-transparent pb-4">
      <base-button
          @click="onCancelSelection"
          variant="danger-link"
          class="mr-2"
      >
        {{ $t('Reset selections') }}
      </base-button>

      <proof-listing-button
          :callback-action="onViewProofListing"
          :disabled="!validPayments.length"
          path=""
      />
    </div>
    <AgDataTable
        :columns="columns"
        :data="data"
        :get-empty-row="getEmptyRow"
        :show-cells-legend="true"
        :add-text="$t('Payment')"
        :masterDetail="true"
        :detailRowAutoHeight="true"
        :detailCellRenderer="detailCellRenderer"
        :default-filters="false"
        domLayout="autoHeight"
        actions="add"
        v-bind="editableTableProps"
        @add="onRowAdded"
        @update:data="tableData = $event"
        @grid-ready="grid = $event"
    >
      <template #header-info>
        <SummaryPill
            label="Amount"
            :value="totalAmounts.amount"
        />
        <SummaryPill
            label="Transfer Deposit"
            :value="totalAmounts.deposit"
        />
        <SummaryPill
            label="Discount"
            :value="totalAmounts.discount"
        />
      </template>
    </AgDataTable>

  </div>
</template>
<script lang="ts">
  import {defineComponent} from 'vue'
  import {editableTableProps} from '@/components/ag-grid/tableUtils'
  import {CustomerPayment, StorageKey} from '@/modules/accounts-receivable/pages/payments/customerPaymentTypes'
  import {cellEditors} from '@/components/ag-grid/cellEditors/cellEditors'
  import {cellClasses, requiredValueSetter} from '@/components/ag-grid/columnUtils'
  import CustomerPaymentDetailCellRenderer
    from '@/modules/accounts-receivable/pages/payments/CustomerPaymentDetailCellRenderer.vue'
  import {
    CellClassParams,
    GridReadyEvent,
    ICellEditorParams,
    ICellRendererParams,
    RowNode,
    ValueFormatterParams,
    ValueGetterParams,
    ValueSetterParams,
  } from '@ag-grid-community/core'
  import {Column} from '@/components/ag-grid/tableTypes'
  import {Customer} from '@/modules/common/types/models'
  import {useStorage} from '@vueuse/core'
  import {dateTypes, formatDate} from '@/plugins/dateFormatPlugin'
  import {$modules, BankUsedInTypes} from '@/enum/enums'
  import {getSetting} from '@/plugins/settingsAndEnumsPlugin'
  import {getPaymentCashOnDeposit} from '@/modules/accounts-receivable/pages/payments/customerPaymentUtils'
  import {globalResources} from '@/components/form/util'
  import i18n from '@/i18n'
  import sumBy from 'lodash/sumBy'
  import {getDeleteColumn} from '@/components/ag-grid/columns/deleteColumns'
  import cloneDeep from 'lodash/cloneDeep'
  import Data = API.Data

  function getEmptyRow(): CustomerPayment {
    return {
      _localId: crypto.randomUUID(),
      adjustments: false,
      deposit: false,
      bank_id: getSetting($modules.AR, 'default_bank_id') as string,
      customer_id: null,
      date: formatDate(new Date(), dateTypes.IsoDate),
      amount: 0,
      apply_to_billings_amount: 0,
      number: '',
      reference_no: formatDate(new Date()),
      billings: [],
    }
  }

  export default defineComponent({
    components: {
      CustomerPaymentDetailCellRenderer,
    },
    setup() {
      let defaultData: CustomerPayment[] = []
      try {
        defaultData = []
      } catch (err) {
        console.log(err)
      }
      const data = useStorage<CustomerPayment[]>(StorageKey, defaultData)
      return {
        data,
      }
    },
    data() {
      return {
        tableData: [] as CustomerPayment[],
        grid: null as GridReadyEvent | null,
        editableTableProps,
        detailCellRenderer: 'CustomerPaymentDetailCellRenderer',
        selectedCustomers: [] as string[],
      }
    },
    computed: {
      validPayments() {
        return this.tableData.filter(this.isPaymentValid)
      },
      totalAmounts() {
        let amounts = {
          amount: 0,
          deposit: 0,
          discount: 0,
        }
        const tableData = this.tableData
        tableData.forEach(payment => {
          amounts.amount += +payment.amount

          payment.billings.forEach(billing => {
            amounts.deposit += +billing.from_cash_on_deposit_amount
            amounts.discount += +billing.discount_amount
          })
        })
        return amounts
      },
      columns(): Column[] {
        const vm = this
        return [
          {
            headerName: this.$t('Customer'),
            field: 'customer_id',
            component: 'CustomerLink',
            editable: true,
            cellEditor: cellEditors.GlobalResourceSelect,
            cellEditorParams: (params: ICellEditorParams) => {
              return {
                resourceName: globalResources.Customers,
                sortByFunction: (options: Customer[]) => {
                  return options.sort((a: Customer, b: Customer) => {
                    if (a.current_ar_amount === 0) return 1;
                    if (b.current_ar_amount === 0) return -1;
                    return b.current_ar_amount - a.current_ar_amount;
                  })
                },
                ownColumns: [
                  {
                    minWidth: 100,
                    maxWidth: 120,
                    name: i18n.t('Code'),
                    prop: 'code',
                  },
                  {
                    minWidth: 200,
                    maxWidth: 200,
                    name: i18n.t('Name'),
                    prop: 'name',
                  },
                  {
                    minWidth: 150,
                    maxWidth: 200,
                    name: this.$t('Open Amount'),
                    prop: 'current_ar_amount',
                    toFormat: this.$formatPrice,
                  },
                  {
                    minWidth: 140,
                    maxWidth: 200,
                    name: this.$t('Cash On Deposit'),
                    prop: 'current_unapplied_cash_on_deposit_amount',
                    toFormat: this.$formatPrice,
                  },
                ],
                filterMethod(option: any) {
                  return !vm.getSelectedCustomers(params.node.rowIndex as number).includes(option.id)
                },
                onValueChanged: (params: ICellEditorParams, value: any, fullValue: Data<Customer>) => {
                  // We need the customer to fill in the customer cash on deposit value
                  params.node.setDataValue('customer', fullValue)
                  params.node.setDataValue('billings', [])
                },
              }
            },
            minWidth: 100,
            valueSetter: (params: ValueSetterParams) => {
              this.expandRow(params?.node?.rowIndex || 0)
              return requiredValueSetter(params)
            },
            cellClass: ({data}: CellClassParams) => data?.customer_id ? '' : cellClasses.Invalid,
          },
          {
            field: 'customer',
            hide: true,
          },
          {
            field: 'billings',
            hide: true,
          },
          {
            headerName: this.$t('Bank'),
            field: 'bank_id',
            component: 'BankLink',
            cellRendererParams: (params: ICellRendererParams) => {
              return {
                id: params.data.bank_id,
              }
            },
            editable: true,
            cellEditor: cellEditors.BankSelect,
            cellEditorParams: {
              usedFor: BankUsedInTypes.AccountsReceivable,
            },
            minWidth: 100,
            cellClass: ({data}: CellClassParams) => data?.bank_id ? '' : cellClasses.Invalid,
          },
          {
            headerName: this.$t('Payment Reference'),
            field: 'number',
            minWidth: 80,
            editable: true,
            valueSetter: requiredValueSetter,
            cellClass: ({data}: CellClassParams) => data?.number ? '' : cellClasses.Invalid,
          },
          {
            headerName: this.$t('Date'),
            field: 'date',
            minWidth: 100,
            editable: true,
            component: 'FormattedDate',
            cellEditor: cellEditors.DatePicker,
            cellClass: ({data}: CellClassParams) => data?.date ? '' : cellClasses.Invalid,
          },
          {
            headerName: this.$t('Prepaid Deposit'),
            field: 'deposit',
            minWidth: 100,
            editable: true,
            component: 'Status',
            cellEditor: cellEditors.Boolean,
            valueSetter: params => {
              if (params.newValue) {
                params.data.amount = 0
                params.data.apply_to_billings_amount = 0
              }
              return requiredValueSetter(params)
            },
          },
          {
            headerName: this.$t('Adjustments'),
            hide: true,
            field: 'adjustments',
            minWidth: 100,
            editable: params => {
              return !params.data.deposit
            },
            component: 'Status',
            cellEditor: cellEditors.Boolean,
            valueSetter: params => {
              if (params.newValue) {
                params.data.amount = 0
                params.data.apply_to_billings_amount = 0
              }
              return requiredValueSetter(params)
            },
          },
          {
            headerName: this.$t('Amount'),
            field: 'amount',
            minWidth: 100,
            editable: params => !params.data.adjustments,
            cellClass: ({data}: CellClassParams) => {
              return data.adjustments ? cellClasses.ReadOnly : ''
            },
            component: 'FormattedPrice',
            cellEditor: cellEditors.Numeric,
            valueSetter: (params: ValueSetterParams) => {
              params.newValue = +params.newValue
              if (params.newValue && !params.data.deposit) {
                params.data.apply_to_billings_amount = +params.newValue
              }
              return requiredValueSetter(params, 0)
            },
            suppressKeyboardEvent: params => {
              let isTabKey = params.event.key === 'Tab'
              if (isTabKey) {
                params.api.stopEditing()
              }
              return false
            },
          },
          {
            headerName: this.$t('Prepaid Deposit Amount'),
            field: 'cash_on_deposit',
            minWidth: 100,
            cellClass: cellClasses.ReadOnly,
            valueGetter: (params: ValueGetterParams) => {
              return getPaymentCashOnDeposit(params)
            },
            valueFormatter: (params: ValueFormatterParams) => {
              return this.$formatPrice(params.value)
            },
          },
          {
            field: 'order',
            headerName: ' ',
            cellRenderer: 'agGroupCellRenderer',
            minWidth: 40,
            maxWidth: 40,
            valueGetter: () => '',
          },
          {
            ...getDeleteColumn({
              url: '',
              title: this.$t('Delete Payment'),
              description: this.$t('Are you sure you want to delete this payment?'),
            }) as Column,
          },
        ]
      },
    },
    methods: {
      getEmptyRow,
      getSelectedCustomers(rowIndex: number): any[] {
        return this.tableData
            .map((d, index) => {
              return {
                customer_id: d.customer_id,
                index,
              }
            })
            .filter(c => c.customer_id && c.index !== rowIndex)
            .map(c => c.customer_id)
      },
      expandRow(index: number) {
        this.grid?.api?.getDisplayedRowAtIndex(index)?.setExpanded(true)
      },
      async onCancelSelection() {
        const result = await this.$deleteConfirm({
          title: this.$t('Do you really want to reset the selections ?'),
          description: this.$t('This action will reset all payment selections to the default state. You will have to process payments again.'),
          buttonText: this.$t('Reset Selections'),
        })
        if (!result) {
          return
        }
        this.clearSelections()
      },
      clearSelections() {
        this.data = []
      },
      isPaymentValid(payment: CustomerPayment) {
        let hasBillings = payment.billings.length > 0
        let hasDiscounts = payment.billings.some(b => +b.discount_amount !== 0)
        if (payment.deposit) {
          hasBillings = true
        }
        const hasAmounts = payment.amount >= 0 || payment.apply_to_billings_amount > 0 || hasDiscounts
        return payment.customer_id && payment.bank_id && payment.number && payment.date && hasAmounts && hasBillings
      },
      async onViewProofListing() {
        this.cleanupData()
        await this.checkForPaymentDifferences()
        this.$router.push('/accounts-receivable/payments/proof-listing')
      },
      async checkForPaymentDifferences() {
        let hasPaymentDifferences = false
        this.tableData.forEach(payment => {
          const billingsTotal = sumBy(payment.billings, 'amount')
          if (payment.amount > billingsTotal && !payment.deposit && !payment.adjustments) {
            hasPaymentDifferences = true
          }
        })
        if (!hasPaymentDifferences) {
          return
        }
        const confirmed = await this.$confirm({
          title: this.$t('Payment Differences'),
          description: this.$t('There are payments with differences greater than $0. The difference will be automatically added to the customer cash on deposit. Do you want to continue?'),
          buttonText: this.$t('Continue'),
        })
        if (!confirmed) {
          return false
        }
        this.tableData.forEach(payment => {
          const billingsTotal = sumBy(payment.billings, 'amount')
          if (payment.amount > billingsTotal && !payment.deposit && !payment.adjustments) {
            payment.apply_to_billings_amount = billingsTotal
          }
        })

        return confirmed
      },
      cleanupData() {
        this.data = cloneDeep(this.tableData)
        this.data = this.data.map(payment => {
          const billings = payment.billings.filter(b => b.amount > 0 || +b.discount_amount !== 0 || b.from_cash_on_deposit_amount > 0)
          return {
            ...payment,
            billings,
          }
        })
      },
      async onRowAdded(params: any, row: CustomerPayment) {
        await this.$nextTick()
        this.grid?.api.forEachNode(node => {
          node.setExpanded(false)
        })
        await this.$nextTick()
        const rowNode: RowNode | undefined = this.grid?.api.getRowNode(row._localId as string)
        const index: number = rowNode?.rowIndex || 0
        this.grid?.api.setFocusedCell(index, 'customer_id')
      },
    },
  })
</script>
