<template>
  <div class="-mt-6">
    <layout-tabs
      :items="filteredItems"
      class="mb-2"
    />
    <router-view/>
  </div>
</template>
<script>
  export default {
    computed: {
      currentJobId() {
        return this.$route.params.id
      },
      items() {
        return [
          {
            name: this.$t('List'),
            path: `/job-costing/jobs/${this.currentJobId}/line-items/list`,
            permission: 'line_items_show',
          },
          {
            name: this.$t('Phase Titles'),
            path: `/job-costing/jobs/${this.currentJobId}/line-items/phase-titles`,
            permission: 'line_items_show',
          },
          {
            name: this.$t('Linked Line Items'),
            path: `/job-costing/jobs/${this.currentJobId}/line-items/linked`,
            permission: 'line_items_show',
          },
        ]
      },
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
