<template>
  <div class="grid grid-cols-6 gap-4 import-preview">
    <el-upload
        ref="upload"
        class="col-span-6 md:col-span-2 mt-6"
        action="default"
        accept=".xlsx, .xls, .csv"
        :auto-upload="false"
        :on-change="parseFile"
        :on-remove="onFileRemove"
        :limit="1"
    >
      <base-button class="select-file-button focus:outline-none focus:ring-blue"
                   ref="uploadButton"
                   variant="white">
        {{ $t('Select CSV or Excel File') }}
      </base-button>
    </el-upload>

    <slot></slot>

    <div v-if="fileResults.data.length"
         class="col-span-6 flex flex-col">
      <base-alert :type="$promptType.Warning"
                  closable
                  class="mb-4 py-3"
      >
        {{
          $t('Once validated, the following data will be imported. Please make sure that all the data is correct because this action is irreversible')
        }}
        <br>
        {{ $t('Make sure that the selected file has headers, otherwise the first row will be skipped during import.') }}
        <br>
        <b>{{ $t('Header columns should not be numbers.') }}</b> {{$t('Unexpected results may occur if the header columns are numbers.')}}
      </base-alert>
      <div class="flex w-full justify-between items-center -mb-6">
        <div class="flex items-center">
          <base-input
              v-model.lazy="model.start_row"
              :label="$t('Start row')"
              :placeholder="$t('Start row')"
          />
          <div class="flex flex-col ml-4">
            <span class="font-medium text-sm text-gray-600 mb-2">
              {{ $t('Required Columns') }}
            </span>
            <div class="flex flex-wrap mb-8 space-x-2 ml-0">
              <base-tooltip
                  v-for="field in allRequiredFields"
                  :key="field.value"
                  :content="getFieldContent(field)"
              >
                <base-badge
                    :type="(isFieldMapped(field) || doesNotNeedMapping(field)) ? 'success' : 'danger'"
                >
                  {{ field.label }}
                </base-badge>
              </base-tooltip>
            </div>
          </div>
        </div>
        <div class="flex items-center space-x-4">
          <AddReportPreset
              :preset="selectedPreset"
              :type="reportType"
              :payload="presetPayload"
          />
          <base-button
              variant="green-link"
              @click="showSaveCsvDialog = true"
          >
            {{ $t('Save as CSV') }}
          </base-button>
        </div>
      </div>
      <div class="flex-1 min-h-[550px]">
        <AgDataTable
            class="mt-2"
            :columns="columns"
            :data="tableData"
            :pagination="false"
            :enableRangeSelection="true"
            :alwaysShowHorizontalScroll="true"
            :addRowOnTab="true"
            :suppressFieldDotNotation="true"
            :allowTableBulkDelete="true"
            :allowAddEmptyRows="true"
            :showCellsLegend="true"
            :enableFillHandle="true"
            rowSelection="multiple"
            @grid-ready="onGridReady"
        />
      </div>
    </div>
    <BaseFormDialog v-if="showSaveCsvDialog"
                    :title="$t('Save Data as CSV')"
                    :open.sync="showSaveCsvDialog"
    >
      <BaseForm @submit="saveAsCSV">
        <base-checkbox
            id="exclude_skipped_columns"
            :label="$t('Exclude skipped columns')"
            v-model="excludeSkippedColumns"
            class="col-span-6"
        />
      </BaseForm>
    </BaseFormDialog>
    <div v-if="fileResults.errors?.length"
         class="col-span-6 my-4 text-red-700">
      <div v-for="error in fileResults.errors" :key="error">
        {{ error }}
      </div>
    </div>
  </div>
</template>
<script>
  import { Upload } from 'element-ui'
  import AddReportPreset from '@/modules/common/components/reports/AddReportPreset';
  import { downloadFileLocally } from '@/modules/common/util/downloadFileLocally';
  import { convertJsonToCSVFile, parseExcelOrCsvFile, parseImportErrors } from '@/modules/common/util/csvUtils';
  import { getColMapping } from '@/modules/payroll/utils/timecardImportColumns';
  import WarningTip from '@/modules/payroll/components/WarningTip';
  import { extraColumns } from '@/modules/common/components/import/util'
  import { getTableData } from '@/components/ag-grid/tableUtils';
  import ImportColHeader from '@/modules/common/components/import/ImportColHeader';
  import AddColHeader from '@/modules/common/components/import/AddColHeader';
  import orderBy from 'lodash/orderBy';
  import uniq from "lodash/uniq";

  export default {
    components: {
      WarningTip,
      AddReportPreset,
      ImportColHeader,
      AddColHeader,
      [Upload.name]: Upload,
    },
    props: {
      initialMapping: {
        type: Object,
        default: () => ({}),
      },
      reportType: {
        type: String,
        required: true,
      },
      columnOptions: {
        type: Array,
        default: () => [],
      },
      columnMappings: {
        type: Object,
        default: () => ({}),
      },
      errors: {
        type: Array,
        default: () => [],
      },
      preset: {
        type: Object,
      },
      resource: {
        type: String,
        default: 'timecards',
      },
    },
    data() {
      return {
        fileResults: {
          data: [],
          errors: [],
        },
        model: {
          file: null,
          start_row: 1,
          mapping: {
            ...this.initialMapping,
          },
        },
        grid: null,
        colNames: [],
        selectedPreset: null,
        excludeSkippedColumns: true,
        showSaveCsvDialog: false,
        selectComponents: {},
        tableData: [],
      }
    },
    computed: {
      presetPayload() {
        return {
          start_row: +this.model.start_row,
          mapping: this.model.mapping,
          colNames: this.colNames,
        }
      },
      allRequiredFields() {
        return this.columnOptions.filter((col) => {
          return col.required !== undefined
        })
      },
      requiredFields() {
        return this.columnOptions.filter((col) => {
          if (typeof col.required === 'function') {
            return col.required(this.model.mapping)
          }
          return col.required
        })
      },
      areRequiredFieldsMapped() {
        return this.requiredFields.every(this.isFieldMapped)
      },
      columns() {
        let columns = this.colNames.map((col, index) => {
          let colName = col?.label
          let width = colName?.length * 10
          width = Math.max(width, 200)
          if (colName.includes('Address')) {
            width = 320
          }

          const headerName = col?.key
          const originalColMapping = this.findColMapping(col)

          const column = {
            headerName,
            field: col?.key,
            minWidth: width,
            editable: true,
            headerComponent: 'ImportColHeader',
            headerComponentParams: {
              colMapping: col?.mapping,
              mapping: this.model.mapping,
              options: this.columnOptions,
              custom: col?.custom,
              key: col?.key,
              deleteColumn: (colMapping) => this.deleteColumn(col?.key, colMapping),
              updateMapping: this.updateMapping,
            },
            cellClass: params => {
              const { data } = params
              const requiredFields = this.requiredFields.map(f => f.value)
              const field = this.getColKey(col.mapping, index + 1)
              const isRequired = requiredFields.includes(field)
              const isMapped = this.isFieldMapped({ value: field })
              if (isRequired && isMapped && !data[col?.key]) {
                return 'bg-red-50'
              }
              return
            },
            autoHeaderHeight: true,
            cellEditor: originalColMapping?.cellEditor,
            cellEditorParams: originalColMapping?.cellEditorParams,
            ...this.getExtraColumnOptions(col.mapping),
          }
          return column
        })

        columns = orderBy(columns, 'order')

        const indexColumn = {
          headerName: '1',
          maxWidth: 60,
          minWidth: 40,
          pinned: 'left',
          valueGetter: params => {
            return params.node.rowIndex + 2
          },
        }
        const addColumn = {
          headerName: '',
          field: '',
          minWidth: 60,
          maxWidth: 60,
          headerComponent: 'AddColHeader',
          headerComponentParams: {
            addColumn: this.addNewColumn,
          },
          headerClass: '!px-2',
          valueGetter: () => {
            return ''
          },
        }
        return [indexColumn, ...columns, addColumn]
      },
    },
    methods: {
      async setTableData() {
        await this.$nextTick()
        const startRow = +this.model.start_row
        let data = this.fileResults.data.slice(startRow).filter(r => r.join('').trim() !== '')
        this.tableData = data.map((rowData) => {
          let row = {}
          row._localId = crypto.randomUUID()
          // We do a normal for here because Array.forEach skips empty values
          for (let index = 0; index < rowData.length; index++) {
            const value = rowData[index] || null
            const key = this.colNames[index]?.key
            if (!key) {
              continue
            }
            row[key] = value
          }
          return row
        })
      },
      isFieldMapped(field) {
        return this.model.mapping[field.value] >= 0
      },
      doesNotNeedMapping(field) {
        if (typeof field.required === 'function') {
          return !field.required(this.model.mapping)
        }
        return false
      },
      getFieldContent(field) {
        if (this.isFieldMapped(field)) {
          return this.$t('This field is mapped')
        }
        if (field.required === true) {
          return this.$t('This field is required')
        }
        if (typeof field.required !== 'function') {
          return
        }
        const isRequired = field.required(this.model.mapping)
        if (isRequired) {
          return this.$t('This field is required')
        }
        return this.$t('This field is required if another field is not mapped')
      },
      onGridReady(grid) {
        this.grid = grid

        // There is a strange bug where the grid is not rendering the first row
        setTimeout(() => {
          this.grid.api.redrawRows()
        }, 50)
      },
      async applyPreset(preset) {
        this.selectedPreset = preset

        if (!preset || !preset.filters) {
          return
        }

        this.model.start_row = +preset.filters?.start_row
        await this.$nextTick()

        this.model.mapping = {
          ...(preset?.filters?.mapping || {}),
        }
      },
      async addNewColumn(data) {
        const column = getColMapping(data.name, this.columnMappings)
        column.custom = true
        this.colNames.push(column)
        this.fileResults.data.forEach(row => {
          row.push(null)
        })
        await this.$nextTick()
        this.grid.api.setColumnDefs(this.columns)
      },
      findColMapping(col) {
        return Object.values(this.columnMappings).find((colMapping) => {
          return colMapping.value === col?.mapping
        })
      },
      async deleteColumn(key, colMapping) {
        const colIndex = this.colNames.findIndex(c => c.key === key)
        if (colIndex === -1) {
          return
        }
        this.colNames.splice(colIndex, 1)
        for (let key in this.model.mapping) {
          const value = this.model.mapping[key]
          if (value > colIndex) {
            this.model.mapping[key]--
          }
        }
        if (colMapping && colMapping !== -1) {
          this.model.mapping[colMapping] = -1
        }
        this.fileResults.data.forEach(row => {
          row.splice(colIndex, 1)
        })
        await this.$nextTick()
        this.grid.api.setColumnDefs(this.columns)
      },
      async updateMapping(newMapping) {
        this.model.mapping = newMapping
      },
      async setColumnNames() {
        await this.$nextTick()
        const exceptions = ['dirty']
        const row = +this.model.start_row - 1
        let firstRow = this.get(this.fileResults, `data[${row}]`, [])
        let names = []
        for (let i = 0; i < firstRow.length; i++) {
          const value = firstRow[i] || ''
          names.push(value)
        }
        names = names.filter(col => !exceptions.includes(col?.toLowerCase()?.trim()))
        let duplicateErrors = []
        this.colNames = names.map((name, index) => {
          const namesToIndex = names.slice(0, index + 1)
          const columnNameCount = namesToIndex.filter(n => n === name).length
          const isDuplicate = columnNameCount > 1
          // We are checking for duplicates as there might be excel/csv files with columns that have the same name
          if (isDuplicate) {
            duplicateErrors.push(name)
            name = `${name} (${columnNameCount - 1})`
          }
          return getColMapping(name, this.columnMappings, index)
        })
        duplicateErrors = uniq(duplicateErrors)
        duplicateErrors.forEach(name => {
          this.$error(`Column name "${name}" is a duplicate. To avoid issues, ensure all column names are unique in your file.`)
        })

        if (this.selectedPreset) {
          return
        }

        this.colNames.forEach((column, index) => {
          if (column.mapping) {
            this.model.mapping[column.mapping] = index + 1
          }
        })
      },
      getExtraColumnOptions(colMapping) {
        let colName = colMapping?.toLowerCase()
        const colMappings = extraColumns

        for (let key in colMappings) {
          if (key === colName) {
            return colMappings[key]
          }
        }
        return {}
      },
      getColKey(colKey, colIndex) {
        for (let key in this.model.mapping) {
          const value = this.model.mapping[key]
          if (colIndex === value) {
            return key
          }
        }
        if (this.model.mapping[colKey] === -1) {
          return -1
        }
        return colKey || -1
      },
      clearErrors() {
        this.setErrors([])
      },
      setErrors(errors) {
        this.fileResults.errors = parseImportErrors(errors)
      },
      reset() {
        this.model = {
          file: null,
          start_row: 1,
          mapping: {
            ...this.initialMapping,
          },
        }
        this.fileResults = {
          data: [],
          errors: [],
        }
        this.clearErrors()
        this.$refs?.upload?.clearFiles()
      },
      async convertDataToCSV(excludeSkippedColumns = false) {
        let data = getTableData(this.grid.api)

        const mapping = this.model.mapping
        const colsToSkip = Object.keys(mapping).filter(key => mapping[key] === -1)

        data = data.map(row => {
          let newRow = { ...row }
          let colsToDelete = ['index', 'undefined', 'dirty', 'id', '_localId']
          if (excludeSkippedColumns) {
            let colsWithoutMappingOrSkipped = this.colNames
                .filter((c, index) => {
                  if (!c.mapping) {
                    return !Object.values(mapping).includes(index + 1)
                  }
                  return !c.mapping || colsToSkip.includes(c.mapping)
                }).map(c => c.key)
            colsToDelete.push(...colsWithoutMappingOrSkipped)
          }
          colsToDelete.forEach(col => {
            delete newRow[col]
          })
          return newRow
        })
        return convertJsonToCSVFile({ fileName: 'timecard.csv', data, addHeader: false })
      },
      async saveAsCSV() {
        let data = await this.convertDataToCSV(this.excludeSkippedColumns)
        const date = this.$formatDate(new Date(), 'MM/dd/yyyy')
        const fileName = `${this.resource}-${date}.csv`

        downloadFileLocally(data, fileName)
        this.showSaveCsvDialog = false
      },
      async parseFile(initialFile) {
        if (!initialFile) {
          this.$error('Please select a CSV / Excel file')
          return
        }
        try {
          this.clearErrors()
          this.fileResults.data = []
          let fileData = initialFile.raw

          const { results, file } = await parseExcelOrCsvFile(fileData)
          this.fileResults = results
          this.model.file = file
          await this.setColumnNames()
          await this.setTableData()
          if (this.preset) {
            this.applyPreset(this.preset)
          }

        } catch (err) {
          console.log(err)
          this.$error(`Could not parse the file. Please make sure it's a valid CSV / Excel and try again.`)
        }
      },
      async onFileRemove() {
        this.model.file = null
        this.fileResults.data = []
        this.clearErrors()
        await this.setColumnNames()
      },
      resetMapping() {
        this.model.mapping = {
          ...this.initialMapping,
        }
      },
    },
    watch: {
      errors: {
        immediate: true,
        handler(value) {
          this.setErrors(value)
        },
      },
      preset: {
        immediate: true,
        async handler(value) {
          this.applyPreset(value)
        },
      },
      'model.start_row'() {
        this.resetMapping()
        this.setColumnNames()
        this.setTableData()
      },
      areRequiredFieldsMapped: {
        immediate: true,
        handler(value) {
          this.$emit('valid-change', value)
        },
      },
    },
  }
</script>
<style lang="scss">
  .import-preview .th-select {
    @apply py-0 border-none rounded-sm mb-1;
  }
</style>
