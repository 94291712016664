<template>
  <base-button
      v-bind="$attrs"
      :variant="variant"
      class="print:hidden"
      @click="onClick">
    <div
        class="print-icon-wrapper"
        :class="{
         'p-2 bg-primary-50 rounded-md -ml-2': variant !== 'primary-link',
        }"
    >
      <PrinterIcon class="w-4 h-4 text-primary-500"/>
    </div>
    <slot>
      <span class="ml-2 font-semibold">{{ $t('Print') }}</span>
    </slot>
  </base-button>
</template>
<script>
  import { PrinterIcon } from 'vue-feather-icons'
  import { printAction } from '@/components/ag-grid/printUtils'

  export default {
    name: 'TableEmailButton',
    components: {
      PrinterIcon,
    },
    props: {
      variant: {
        type: String,
        default: 'primary-link'
      },
      hasCallback: {
        type: Boolean,
        default: false
      },
    },
    methods: {
      async onClick() {
        if (this.hasCallback) {
          return this.$emit('on-action-callback')
        }

        printAction()
      }
    }
  }
</script>
