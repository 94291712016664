<template>
  <billings-table
      :url-params="urlParams"
      :billing-status="resourceStatuses.All"
  />
</template>
<script>

  import { resourceStatuses } from "@/enum/enums";
  import BillingsTable from "@/modules/accounts-receivable/pages/billings/BillingsTable.vue";
  export default {
    components: {
      BillingsTable,
    },
    data() {
      return {
        resourceStatuses,
      }
    },
    computed: {
      urlParams() {
        return {
          equipment_id: this.$route.params.id,
        }
      },
    }
  }
</script>
