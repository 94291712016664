<template>
  <div>
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Aged By Customer'),
            path: '/accounts-receivable/reports/aged-by-customer',
            permission: 'accounts_receivable_reports',
          },
          {
            name: this.$t('Aged By Job'),
            path: '/accounts-receivable/reports/aged-by-job',
            permission: 'accounts_receivable_reports',
          },
          {
            name: this.$t('Customer History'),
            path: '/accounts-receivable/reports/customer-history',
            permission: 'accounts_receivable_reports',
          },
          {
            name: this.$t('Job Pre-Billing Worksheet'),
            path: '/accounts-receivable/reports/job-pre-billing-worksheet',
            permission: 'accounts_receivable_reports',
          },
          {
            name: this.$t('Statements'),
            path: '/accounts-receivable/reports/statements',
            permission: 'accounts_receivable_reports',
          },
          {
            name: this.$t('Sales Tax Report'),
            path: '/accounts-receivable/reports/sales-tax',
            permission: 'accounts_receivable_reports',
          },
          {
            name: this.$t('Waiver & Release'),
            path: '/accounts-receivable/reports/waiver-and-release',
            permission: 'accounts_receivable_reports',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
