<template>
  <div>
    <div class="mx-auto md:flex md:items-start md:justify-between md:space-x-5 px-4 mb-8">
      <div class="flex items-center space-x-5">
        <div>
          <h2>{{ $t('Payment Batch ') }} {{ journalName }}</h2>
          <div class="text-sm text-gray-700 mb-2">{{ $t('Posted on ') }} {{ $formatDate(paymentDate, '', true) }}</div>
          <BatchStats :batch="paymentsMeta"/>
        </div>
      </div>
      <div
          class="flex justify-stretch flex-row-reverse space-y-reverse md:flex-row overflow-x-auto sm:justify-end space-x-reverse space-x-3">
        <void-button :disabled="paymentsMeta.voided_count >= paymentsMeta.payments_count"
                     @click="showVoidDialog = true">
          {{ $t('Void') }}
        </void-button>

        <PreviewChecksButton
            v-if="!isARModule"
            :disabled="paymentsMeta.payments_count === 0 || journalId === undefined"
            :download-function="downloadChecks"
        >
          <PrinterIcon class="w-5 h-5 mr-2"></PrinterIcon>
          <span>{{ $t('Print Checks') }}</span>
        </PreviewChecksButton>
      </div>
    </div>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="px-4 mb-8">
      <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">

        <div class="bg-white overflow-hidden shadow rounded-lg">
          <div class="px-4 py-4 sm:px-6 sm:py-5">
            <dt class="text-sm font-medium text-gray-500 truncate">
              {{ $t('Total Discount Amount') }}
            </dt>
            <dd class="mt-1 text-2xl font-semibold text-gray-900">
              {{ $formatPrice(paymentsMeta.total_discount_amount) }}
            </dd>
          </div>
        </div>

        <div v-if="paymentsMeta.total_retention_amount"
             class="bg-white overflow-hidden shadow rounded-lg">
          <div class="px-4 py-4 sm:px-6 sm:py-5">
            <dt class="text-sm font-medium text-gray-500 truncate">
              {{ $t('Total Retention Amount') }}
            </dt>
            <dd class="mt-1 text-2xl font-semibold text-gray-900">
              {{ $formatPrice(paymentsMeta.total_retention_amount) }}
            </dd>
          </div>
        </div>
        <div class="bg-white overflow-hidden shadow rounded-lg">
          <div class="px-4 py-4 sm:px-6 sm:py-5">
            <dt class="text-sm font-medium text-gray-500 truncate">
              {{ $t('Total Amount') }}
            </dt>
            <dd class="mt-1 text-2xl font-semibold text-gray-900">
              {{ $formatPrice(paymentsMeta.total_net_amount) }}
            </dd>
          </div>
        </div>
      </dl>
    </div>
    <PaymentsTable
      ref="table"
      :url-params="paymentsTableUrlParams"
      :has-filters="false"
      :can-void="true"
      :enable-select-actions="true"
      :title="$t('Payments')"
      :for-billings="true"
      class="px-4"
      type="billing"
      @payments-meta="onPaymentsMeta"
      @data-fetch="payments = $event"
    />
    <base-back-link to="/accounts-receivable/payments/batches"/>
    <VoidPaymentsDialog
      v-if="showVoidDialog"
      :open.sync="showVoidDialog"
      :payments="payments.map(p => p.id)"
      :for-billings="true"
      @save="refreshData"
      @cancel="showVoidDialog = false">
    </VoidPaymentsDialog>
  </div>
</template>
<script>
  import PaymentsTable from "@/modules/accounts-payable/pages/payments/PaymentsTable";
  import BatchStats from "@/modules/accounts-payable/components/payment/BatchStats";
  import PreviewChecksButton from "@/components/common/PreviewChecksButton";
  import { PrinterIcon } from 'vue-feather-icons'
  import axios from "axios";
  import VoidPaymentsDialog from "@/modules/accounts-payable/components/checks/VoidPaymentsDialog.vue";
  import { $modules } from "@/enum/enums";

  export default {
    components: {
      VoidPaymentsDialog,
      PaymentsTable,
      BatchStats,
      PrinterIcon,
      PreviewChecksButton,
    },
    data() {
      return {
        downloadChecksLoading: false,
        payments: [],
        paymentsMeta: {
          total_net_amount: 0,
          total_retention_amount: 0,
          total_discount_amount: 0,
        },
        showVoidDialog: false,
        modules: $modules,
      }
    },
    computed: {
      isARModule() {
        return this.$route.path.includes('accounts-receivable')
      },
      paymentsTableUrlParams() {
        return !this.$route.params.journal ? {} : {
          journal_id: this.$route.params.journal,
        }
      },
      firstPayment() {
        return this.get(this.payments, '[0].attributes')
      },
      journal() {
        return this.get(this.payments, '[0].relationships.journal.attributes')
      },
      journalName() {
        if (!this.journal) {
          return ''
        }
        return this.journal?.name
      },
      paymentDate() {
        return this.firstPayment?.date
      },
      journalId() {
        return this.journal?.id
      }
    },
    methods: {
      onPaymentsMeta(meta) {
        this.paymentsMeta = meta
      },
      async downloadChecks() {
        const url = '/restify/billing-payments/action?action=print-billing-payment-batches'
        const data = { batches: [this.journalId] }
        const params = { responseType: 'blob' }
        return await axios.post(url, data, params)
      },
      refreshData() {
        this.$refs?.table?.refresh()
      }
    },
  }
</script>
