<template>
  <div>
    <base-tooltip :content="$t('Sync with latest data')" :tabindex="-1">
      <base-button
          :loading="loading"
          variant="gray-icon"
          size="xs"
          @click="$emit('click')">
        <RepeatIcon class="w-5 h-5 text-gray-500"/>
      </base-button>
    </base-tooltip>
  </div>
</template>
<script>
import { RepeatIcon } from 'vue-feather-icons'

export default {
  components: {
    RepeatIcon,
  },
  props: {
    loading: Boolean
  }
}
</script>
