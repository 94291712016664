<template>
  <div class="shadow print:shadow-none overflow-hidden sm:rounded-md form__inner bg-white px-2 bg-white sm:px-4 my-3">
    <RecordTypes
        v-if="journal.id"
        :journal="journal"
    />
  </div>
</template>
<script>
  import { formattedText } from '@/utils/utils'
  import RecordTypes from '@/modules/common/components/journal/RecordTypes'

  export default {
    components: {
      RecordTypes,
    },
    computed: {
      journal() {
        const journal = this.$store.state.generalLedger.currentJournal || {}
        return {
          ...journal?.attributes,
          ...journal?.relationships,
        }
      },
    },
    methods: {
      formattedText,
      getPeriodName(period) {
        return this.$store.getters['company/getPeriodName'](period)
      },
    },
  }
</script>
