import { AccrualMethodLabels } from "@/modules/payroll/utils/accrualMethods";
import pluralize from "pluralize";

export function getRuleText(rule, accrualMethod) {
  const { rate_unit, rate } = rule
  let accrualMethodLabel = AccrualMethodLabels[accrualMethod] || ''
  accrualMethodLabel = accrualMethodLabel.replace('Per ', '')
  let hoursLabel = pluralize('hour', +rate)
  return `${rate} ${hoursLabel} earned per ${rate_unit} ${accrualMethodLabel}`.toLowerCase()
}
