<template>
  <base-filter-range>
    <template #start>
      <component
        :is="config.component"
        :value="model[config.from_key]"
        :add-entity="false"
        :placeholder="`${$t(config.placeholder)} ${$t('From')}`"
        :hide-label="true"
        :rules="model[config.to_key] ? 'required' : ''"
        :id="model[config.from_key]"
        :name="model[config.from_key]"
        :value-key="valueKey"
        clearable
        class="w-1/2"
        @change="onChangeFromValue"
        @blur="onBlurStartRange"
      />
    </template>
    <template #end>
      <component
        :is="config.component"
        :value="model[config.to_key]"
        :add-entity="false"
        :placeholder="`${$t(config.placeholder)} ${$t('To')}`"
        :hide-label="true"
        :rules="model[config.from_key] ? 'required' : ''"
        :id="model[config.to_key]"
        :name="model[config.to_key]"
        :value-key="valueKey"
        clearable
        class="w-1/2"
        @change="onChangeToValue"
      />
    </template>
  </base-filter-range>
</template>
<script setup lang="ts">
import {computed, PropType} from 'vue'
import {RangeFilterType} from '@/components/range-filters'

const props = defineProps({
  config: {
    type: Object as PropType<RangeFilterType>,
    required: true,
  },
  model: {
    type: Object as PropType<Record<string, any>>,
    required: true,
  },
})

const valueKey = computed(() => {
  return props.config?.value_key || 'code'

})

const emit = defineEmits(['update:from', 'update:to'])

function onBlurStartRange() {
  const fromValue = props.model[props.config?.from_key]
  const toValue = props.model[props.config?.to_key]
  if (toValue) {
    return
  }
  onChangeToValue(fromValue)
}

function onChangeFromValue(value: string | number) {
  emit('update:from', value)
}

function onChangeToValue(value: string | number) {
  emit('update:to', value)
}
</script>
