<template>
  <base-data-table
      :url="url"
      :columns="columns"
      :is-expandable="true"
      :url-params="finalUrlParams"
      :group-by.sync="selectedGroupBy"
      :add-entity-in-new-tab="addEntityInNewTab"
      :addEntityUrlQuery="addEntityUrlQuery"
      :add-text="$t('New worksite')"
      :per-page="$pagination.list"
      ref="table"
      default-sort="number"
      permission="worksite"
      actions="search,refresh,print"
  >
    <template #extra-actions="{row}">
      <base-button variant="gray-link"
                   size="xs"
                   @click="tryAddWorkOrder(row.id)"
      >
        <IconAdd class="w-4 h-4 mr-1"/>
        {{ $t('Work Order') }}
      </base-button>
    </template>
    <template #header="{row}">
      <customer-link
          :data="row.header"
          showName
      />
    </template>
    <template #attributes.number="{row}">
      <worksite-link :data="row"/>
    </template>
    <template #address="{row}">
      <a :href="locationLink(row.attributes)" target="_blank">
        {{ formattedAddress(row.attributes) }}
      </a>
    </template>
    <template v-slot:expand-content="{row}">
      <WorksiteWorkOrders
          :work-site-id="row.id"
          :show-pagination="false"
      />
    </template>
  </base-data-table>
</template>
<script>
import { formattedAddress, locationLink } from '@/utils/utils'
import { groupByType } from '@/components/table/utils/groupByTypes'
import WorksiteWorkOrders from '@/modules/service-billing/pages/worksites/worksite-work-orders'
import { entityPreviewFields } from '@/modules/common/components/entity-preview/entities'

export default {
  components: {
    WorksiteWorkOrders,
  },
  props: {
    urlParams: {
      type: Object,
      default: () => ({}),
    },
    customerId: {
      type: String,
    }
  },
  data() {
    return {
      selectedGroupBy: groupByType.Customer,
      addEntityInNewTab: true,
      url: '/restify/work-sites',
    }
  },
  computed: {
    addEntityUrlQuery() {
      if (this.customerId) {
        return `/service-billing/worksites/add?customer_id=${this.customerId}`
      }
      return `/service-billing/worksites/add`
    },
    finalUrlParams() {
      return {
        related: entityPreviewFields.Customer,
        customer_id: this.customerId,
        ...(this.urlParams || {}),
      }
    },
    columns() {
      return [
        {
          label: 'Customer',
          prop: 'customers.code',
          hiddenValue: true,
          minWidth: 80,
          maxWidth: 80,
        },
        {
          label: this.$t('Site#'),
          prop: 'attributes.number',
        },
        {
          label: this.$t('Type'),
          prop: 'attributes.worksite_type',
          component: 'Status',
          minWidth: 80,
          maxWidth: 100,
        },
        {
          label: this.$t('Address'),
          prop: 'address',
          minWidth: 100,
          maxWidth: 150,
        },
        {
          label: this.$t('Phone'),
          prop: 'attributes.worksite_phone',
          component: 'PhoneLink',
          minWidth: 150,
          maxWidth: 200,
        },
        {
          label: this.$t('Contact'),
          prop: 'attributes.contact_name',
          minWidth: 150,
          maxWidth: 200,
        },
      ]
    },
  },
  methods: {
    formattedAddress,
    locationLink,
    tryAddWorkOrder(id) {
      this.$router.push(`/service-billing/work-orders/add?worksite=${id}`)
    },
  },
}
</script>
