<template>
  <div>
    <portal
        v-if="expandedSections.length === 0 && model.number"
        to="page-title"
    >
      {{ $t('Billing #') }}{{ model.number }},&nbsp;@
      <router-link :to="`/accounts-receivable/customers/${selectedCustomer.id}/view`">
        {{ selectedCustomer?.attributes?.code }}
        <span v-if="selectedCustomer?.attributes?.name">({{ selectedCustomer.attributes.name }})</span>
      </router-link>
    </portal>
    <base-go-to-edit-alert
        v-if="!!existingBillingId"
        :path="`/accounts-receivable/billings/unit-price/${existingBillingId}/edit`"
        message="Unit Price billing with the same billing number already exists. Please choose another billing number, job or customer."
    />
    <base-form
        :loading="loading"
        :show-back="showBack"
        :show-cancel="true"
        :save-text="$t('Create unit price billing')"
        :update-text="$t('Update unit price billing')"
        :submit-disabled="!!existingBillingId"
        :can-create-another-entity="!model.id"
        submit-button-type="button"
        layout="vertical"
        grid-classes="grid grid-cols-8 gap-x-4"
        @cancel="$router.push('/accounts-receivable/billings/unit-price')"
        @submit="onSubmit"
    >
      <el-collapse v-model="expandedSections"
                   class="col-span-8">
        <base-collapse-section key="form-header"
                               name="form-header"
                               class="-m-6"
                               has-background
        >
          <template slot="title">
            <div class="flex w-full items-center justify-between mx-2 form-header-summary">
              <BillingFormHeader :data="model"/>
              <portal-target name="billing-summary"/>
            </div>
          </template>
          <div class="grid grid-cols-8 gap-x-4 mx-2">
            <job-select
                v-model="model.job_id"
                :disabled="!!$route.params.id"
                :on-map-entry="onChangeJob"
                :add-entity="false"
                :edit-entity="!model.id"
                :url-params="jobUrlParams"
                rules="required"
                id="job_id"
                class="col-span-8 md:col-span-2"
                @change="validateSelection"
            />
            <customer-select
                v-model="model.customer_id"
                :disabled="!!$route.params.id || !!customerInputDisabled"
                :add-entity="false"
                rules="required"
                class="col-span-8 md:col-span-2"
                @change="validateSelection"
                @entity-change="onChangeCustomer"
            />

            <InvoiceNumberInput
              v-model="model.number"
              :model="model"
              class="col-span-8 md:col-span-2 xl:col-span-1"
              @change="onChangeInvoiceNumber"
              @blur="fillApplicationNumber"
            />

            <BillingDates
              :model="model"
              @date-change="validateSelection"
              @date-blur="fillApplicationDate"
            />
            <BillingTax
              :model="model"
              :show-retention="true"
              @sales-tax-change="shouldEntriesChange"
              @retention-change="shouldEntriesChange"
            />
            <div class="col-span-8"/>
            <base-input
                v-model="model.application_number"
                :label="$t('Application #')"
                :placeholder="$t('Application Number')"
                id="application_number"
                rules="max:12"
                class="col-span-8 md:col-span-2 xl:col-span-1"
            />
            <base-date-picker
                v-model="model.application_date"
                :label="$t('Application Date')"
                :placeholder="$t('Application Date')"
                :name="$t('Application Date')"
                id="application_date"
                class="col-span-8 md:col-span-2"
            />
            <base-date-picker
                v-model="model.period_to"
                :label="$t('Period To')"
                :placeholder="$t('Period To')"
                :name="$t('Period To')"
                id="period_to"
                class="col-span-8 md:col-span-2"
            />
            <base-input
                v-model="model.architect"
                :label="$t('Architect Project Number')"
                :placeholder="$t('Architect Project Number')"
                :name="$t('Architect Project Number')"
                id="architect"
                rules="max:12"
                class="col-span-8 md:col-span-2"
            />
            <div class="col-span-8 md:col-span-2 xl:col-span-1">
              <base-switch :value="model.status === resourceStatuses.NoPost"
                           :label-info="$t('No Post')"
                           :disable="noPostToggleDisabled"
                           @input="triggerNoPost"
              />
            </div>
          </div>
        </base-collapse-section>
      </el-collapse>
      <div class="col-span-8 mt-6">
        <UnitPriceBillingEntries
            v-bind="$attrs"
            :billing="model"
            :key="model.job_id"
            :get-billing-entries="getBillingEntries"
            ref="entriesTable"
        />
      </div>

      <FileAttachments
          ref="attachments"
          class="col-span-8 mt-4"
          entity="billings"
          :entity-id="entityId"
      />

    </base-form>
  </div>
</template>
<script>

  import axios from 'axios'
  import pick from 'lodash/pick'
  import { resourceStatuses } from '@/enum/enums'
  import BillingFormHeader from '@/modules/accounts-receivable/components/BillingFormHeader'
  import { billingTypeAbbr, getNextBillingNumber } from '@/modules/accounts-receivable/pages/billings/billings'
  import UnitPriceBillingEntries
    from '@/modules/accounts-receivable/components/unit-price-billings/UnitPriceBillingEntries'
  import { RestifyResources } from "@/components/form/util";
  import { JobTypeFor } from "@/modules/job-costing/enum/jobs";
  import { getTaxPercentage, shouldEntriesChange } from "@/modules/accounts-receivable/utils/billingUtils";
  import BillingDates from "@/modules/accounts-receivable/components/billings/BillingDates.vue";
  import BillingTax from "@/modules/accounts-receivable/components/billings/BillingTax.vue";
  import InvoiceNumberInput from "@/modules/accounts-payable/components/invoice/InvoiceNumberInput.vue";

  const meta = {
    application_number: '',
    application_date: '',
    period_to: '',
    architect: '',
    exempt_from_sales_tax: false,
  }

  export default {
    components: {
      InvoiceNumberInput,
      BillingTax,
      BillingDates,
      BillingFormHeader,
      UnitPriceBillingEntries,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        existingBillingId: false,
        selectedCustomer: {},
        resourceStatuses,
        expandedSections: ['form-header'],
        billingTypeAbbr,
        loading: false,
        showBack: true,
        entityId: null,
        model: {
          job_id: '',
          customer_id: '',
          number: '',
          gross_amount: 0,
          discount_percent: 0,
          sales_tax_percent: 0,
          retention_completed_percent: 0,
          retention_percent: 0,
          date: this.$now,
          due_date: null,
          invoice_date: null,
          type: billingTypeAbbr.UnitPrice,
          status: this.$settings(this.$modules.AR, 'default_unt_status') || resourceStatuses.Pending,
          ...meta,
        },
      }
    },
    computed: {
      jobUrlParams() {
        return {
          with_budget: JobTypeFor.Income.UnitPrice,
          related: 'customer[id|code|name]'
        }
      },
      noPostToggleDisabled() {
        return ![resourceStatuses.Pending, resourceStatuses.NoPost].includes(this.model.status)
      },
      customerInputDisabled() {
        return this.model.id || (this.model.job_id && this.model.customer_id)
      },
    },
    methods: {
      onChangeInvoiceNumber(value) {
        this.model.application_number = value
        this.validateSelection()
      },
      async validateSelection() {
        if (this.model.id) {
          return
        }
        this.existingBillingId = false

        const { data } = await axios.get('restify/billings', {
          params: {
            job_id: this.model.job_id,
            customer_id: this.model.customer_id,
            number: this.model.number,
            status: `${resourceStatuses.Pending},${resourceStatuses.NoPost}`,
            type: billingTypeAbbr.UnitPrice,
          },
        })

        this.existingBillingId = this.get(data, '[0].id', '')
      },
      fillApplicationNumber() {
        this.model.application_number = this.model.application_number || this.model.number
      },
      fillApplicationDate() {
        this.model.application_date = this.model.application_date || this.model.date
      },
      async onSubmit() {
        try {
          this.loading = true
          this.model.meta = pick(this.model, Object.keys(meta))

          if (this.model.id) {
            await axios.put(`/restify/billings/${this.model.id}`, this.model)
            await this.$refs.entriesTable.storeProgress(this.model.id)
            this.$success(this.$t('Job unit price billing updated'))
            await this.$addSystemGeneratedNote({
              resourceName: RestifyResources.Billings,
              resourceId: this.model.id,
              isEdit: true,
            })
            this.$emit('refresh')
          } else {
            const { data } = await axios.post('/restify/billings/', this.model)
            await this.$refs.entriesTable.storeProgress(data.id)
            this.$success(this.$t('Job unit price billing added'))
            await this.$addSystemGeneratedNote({
              resourceName: RestifyResources.Billings,
              resourceId: data.id,
            })
            this.entityId = data.id
            await this.$refs.attachments.triggerUpload()
            if (this.$createAnotherEntity) {
              return this.$emit('create-another')
            }
            this.$router.push('/accounts-receivable/billings/unit-price/pending')
          }

        } catch (err) {
          console.warn(err)
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.loading = false
        }
      },
      onChangeJob(job) {
        if (!job) {
          return
        }
        const { attributes } = job
        this.selectedCustomer = this.get(job, 'relationships.customer', {})

        this.model.retention_percent = attributes.billing_retention_percent
        this.model.customer_id = attributes.customer_id
        this.model.architect = attributes.project_number

        const invoiceNumber = getNextBillingNumber(billingTypeAbbr.UnitPrice, attributes)
        this.model.number = this.model.application_number = invoiceNumber
      },
      triggerNoPost(value) {
        value ? this.model.status = resourceStatuses.NoPost : this.model.status = resourceStatuses.Pending
      },
      onChangeCustomer(customer) {
        this.selectedCustomer = customer
      },
      async shouldEntriesChange() {
        await shouldEntriesChange(this.$refs.entriesTable)
      },
      async getBillingEntries() {
        try {
          const { data } = await axios.get('/restify/billing-entries', {
            params: {
              billing_id: this.model.id,
              perPage: 500,
              sort: 'order',
              related: 'addlSource',
            },
          })

          return data.map(entry => {
            return {
              ...entry.attributes,
              relationships: entry.relationships
            }
          })
        } catch (err) {
          console.warn(err)
        }
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return
          }

          this.expandedSections = []

          this.model = {
            ...this.model,
            ...value.attributes,
            ...value?.attributes.meta,
          }

          this.selectedCustomer = this.get(value, 'relationships.customer', {})
        },
      },
    },
  }
</script>
