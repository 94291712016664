import i18n from '@/i18n'
import { formattedText } from "@/utils/utils";
import pluralize from "pluralize";

const jobCosting = {
  Jobs: 'jobs',
  MasterLineItems: 'masterLineItems',
  JobTypes: 'jobTypes',
  JobCostTypes: 'jobCostTypes',
  JobIncomeTypes: 'jobIncomeTypes',
  LineItems: 'lineItems',
  CostLineItems: 'costLineItems',
  IncomeLineItems: 'incomeLineItems',
  PhaseTitles: 'phaseTitles',
  WTwoCodes: 'wTwoCodes',
}

const payroll = {
  CraftCodes: 'craftCodes',
  PayCodes: 'payCodes',
  BenefitCodes: 'benefitCodes',
  DeductionCodes: 'deductionCodes',
  DirectDepositCodes: 'directDepositCodes',
  DepartmentCodes: 'departmentCodes',
  GeneralCodes: 'generalCodes',
  RetirementPlans: 'retirementPlans',
  FlexibleBenefitPlans: 'flexibleBenefitPlans',
  TimeOffPolicies: 'timeOff',
  TimeOffStatuses: 'timeOffStatuses',
  TaxCodes: 'taxCodes',
  UnionCodes: 'unions',
  StateTaxes: 'stateTaxes',
  LocalTaxes: 'localTaxes',
  BillingRateTypes: 'billingRateTypes',
}

export const globalResources = {
  ...jobCosting,
  ...payroll,

  Vendors: 'vendors',
  Users: 'users',
  Equipments: 'equipment',
  EquipmentTypes: 'equipmentTypes',
  EquipmentMaintenances: 'equipmentMaintenances',
  Materials: 'materials',
  WorkOrders: 'workOrders',
  ServiceBillingCodes: 'serviceBillingCodes',
  ServiceBillingTypes: 'serviceBillingTypes',
  ServiceBillingCostTypes: 'serviceBillingCostTypes',
  ServiceBillingIncomeTypes: 'serviceBillingIncomeTypes',
  CraftCodes: 'craftCodes',
  SubTrades: 'subTrades',
  TransactionsForCostPlus: 'transactionsForCostPlus',
  PurchaseOrders: 'purchaseOrders',
  Employees: 'employees',
  SalesTaxDistricts: 'salesTaxDistricts',
  WaiverReleaseTemplates: 'waiverReleaseTemplates',
  Customers: 'customers',
  Locations: 'locations',
  PrintTemplates: 'printTemplates',
  AllCraftsAndLevels: 'craftCodes',
  CompanyAddresses: 'companyAddresses',
}

export const globalResourceEntityMap = {
  [globalResources.CostLineItems]: 'line_items',
  [globalResources.IncomeLineItems]: 'line_items',
  [globalResources.JobIncomeTypes]: 'job_types',
  [globalResources.JobCostTypes]: 'job_types',
  [globalResources.ServiceBillingIncomeTypes]: 'service_billing_types',
  [globalResources.ServiceBillingCostTypes]: 'service_billing_types',
  [globalResources.TimeOffPolicies]: 'time_off_policies',
  [globalResources.DepartmentCodes]: 'departments',
}

export const globalResourceLabels = {
  [globalResources.Jobs]: i18n.t('Job'),
  [globalResources.MasterLineItems]: i18n.t('Master Line Item'),
  [globalResources.JobTypes]: i18n.t('Job Type'),
  [globalResources.JobCostTypes]: i18n.t('Job Cost Type'),
  [globalResources.JobIncomeTypes]: i18n.t('Job Income Type'),
  [globalResources.LineItems]: i18n.t('Line Item'),
  [globalResources.CostLineItems]: i18n.t('Cost Line Item'),
  [globalResources.IncomeLineItems]: i18n.t('Income Line Item'),
  [globalResources.WTwoCodes]: i18n.t('W2 Code'),
  [globalResources.CraftCodes]: i18n.t('Craft Code'),
  [globalResources.PayCodes]: i18n.t('Pay Code'),
  [globalResources.BenefitCodes]: i18n.t('Benefit Code'),
  [globalResources.DeductionCodes]: i18n.t('Deduction Code'),
  [globalResources.DirectDepositCodes]: i18n.t('Direct Deposit Code'),
  [globalResources.DepartmentCodes]: i18n.t('Department Code'),
  [globalResources.GeneralCodes]: i18n.t('General Code'),
  [globalResources.RetirementPlans]: i18n.t('Retirement Plan'),
  [globalResources.FlexibleBenefitPlans]: i18n.t('Flexible Benefit Plan'),
  [globalResources.TimeOffPolicies]: i18n.t('Time Off Policy'),
  [globalResources.TaxCodes]: i18n.t('Tax Code'),
  [globalResources.UnionCodes]: i18n.t('Union Code'),
  [globalResources.StateTaxes]: i18n.t('State Tax'),
  [globalResources.LocalTaxes]: i18n.t('Local Tax'),
  [globalResources.BillingRateTypes]: i18n.t('Billing Rate Type'),
  [globalResources.Vendors]: i18n.t('Vendor'),
  [globalResources.Users]: i18n.t('User'),
  [globalResources.Equipments]: i18n.t('Equipment'),
  [globalResources.EquipmentTypes]: i18n.t('Equipment Type'),
  [globalResources.EquipmentMaintenances]: i18n.t('Equipment Maintenance'),
  [globalResources.Materials]: i18n.t('Material'),
  [globalResources.WorkOrders]: i18n.t('Work Order'),
  [globalResources.ServiceBillingCodes]: i18n.t('Service Billing Code'),
  [globalResources.ServiceBillingTypes]: i18n.t('Service Billing Type'),
  [globalResources.ServiceBillingCostTypes]: i18n.t('Service Billing Cost Type'),
  [globalResources.ServiceBillingIncomeTypes]: i18n.t('Service Billing Income Type'),
  [globalResources.CraftCodes]: i18n.t('Craft Code'),
  [globalResources.SubTrades]: i18n.t('Sub Trade'),
  [globalResources.TransactionsForCostPlus]: i18n.t('Transaction For Cost Plus'),
  [globalResources.PurchaseOrders]: i18n.t('Purchase Order'),
  [globalResources.Employees]: i18n.t('Employee'),
  [globalResources.SalesTaxDistricts]: i18n.t('Sales Tax District'),
  [globalResources.WaiverReleaseTemplates]: i18n.t('Waiver Release Template'),
  [globalResources.Customers]: i18n.t('Customer'),
  [globalResources.Locations]: i18n.t('Location'),
  [globalResources.PrintTemplates]: i18n.t('Print Template'),
  [globalResources.AllCraftsAndLevels]: i18n.t('All Crafts And Levels'),
  [globalResources.CompanyAddresses]: i18n.t('Company Address'),

}

export function getResourceLabelPlural(resource) {
  const resourceLabel = (globalResourceLabels[resource] || '')?.toLowerCase()
  return pluralize(resourceLabel, 2)
}

export const RestifyResources = {
  Vendors: 'vendors',
  Companies: 'companies',
  Customers: 'customers',
  Jobs: 'jobs',
  LineItems: 'line-items',
  Employees: 'employees',
  WorkSites: 'work-sites',
  WorkOrders: 'work-orders',
  Invoices: 'invoices',
  Billings: 'billings',
  Equipment: 'equipment',
  Material: 'materials',
  PurchaseOrders: 'purchase-orders',
  Banks: 'banks',
}

export const RestifyResourceLabels = {
  [RestifyResources.Vendors]: i18n.t('Vendor'),
  [RestifyResources.Customers]: i18n.t('Customer'),
  [RestifyResources.Jobs]: i18n.t('Job'),
  [RestifyResources.LineItems]: i18n.t('Line Item'),
  [RestifyResources.Employees]: i18n.t('Employee'),
  [RestifyResources.WorkSites]: i18n.t('Worksite'),
  [RestifyResources.WorkOrders]: i18n.t('Work Order'),
  [RestifyResources.Invoices]: i18n.t('Invoice'),
  [RestifyResources.Billings]: i18n.t('Billing'),
  [RestifyResources.PurchaseOrders]: i18n.t('Purchase Order'),
  [RestifyResources.Equipment]: i18n.t('Equipment'),
}


export const globalResourceRestifyMapping = {
  // job costing
  'jobs': globalResources.Jobs,
  'line-items': globalResources.LineItems,
  'job-types': globalResources.JobTypes,
  // payroll
  'craft-codes': globalResources.CraftCodes,
  'sub-trades': globalResources.SubTrades,
  'pay-codes': globalResources.PayCodes,
  'deduction-codes': globalResources.DeductionCodes,
  'direct-deposit-codes': globalResources.DirectDepositCodes,
  'department-codes': globalResources.DepartmentCodes,
  'benefit-codes': globalResources.BenefitCodes,
  'retirement-plans': globalResources.RetirementPlans,
  'flexible-benefit-plans': globalResources.FlexibleBenefitPlans,
  'time-off-policies': globalResources.TimeOffPolicies,
  'tax-codes': globalResources.TaxCodes,
  'unions': globalResources.UnionCodes,
  'state-taxes': globalResources.StateTaxes,
  'local-taxes': globalResources.LocalTaxes,
  'billing-rate-types': globalResources.BillingRateTypes,
  'w-two-codes': globalResources.WTwoCodes,

  'vendors': globalResources.Vendors,
  'equipment': globalResources.Equipments,
  'equipment-types': globalResources.EquipmentTypes,
  'materials': globalResources.Materials,
  'work-orders': globalResources.WorkOrders,
  'service-billing-codes': globalResources.ServiceBillingCodes,
  'districts': globalResources.SalesTaxDistricts,

  'customers': globalResources.Customers,
  'employees': globalResources.Employees,
  'purchase-orders': globalResources.PurchaseOrders,
}

export const storeMutations = {
  SetResource: 'set-resource',
  AddResource: 'addResource',
  RemoveResource: 'removeResource',
  UpdateResource: 'updateResource',
  SetCommonConfiguration: 'setCommonConfiguration',
  SetRefreshResourceLoading: 'setRefreshResourceLoading',
}

export const storeActions = {
  [globalResources.Jobs]: 'getJobs',
  [globalResources.Customers]: 'getCustomers',
  [globalResources.CostLineItems]: 'getLineItems',
  [globalResources.Materials]: 'getMaterials',
  [globalResources.Equipments]: 'getEquipments',
  [globalResources.CompanyAddresses]: 'getCompanyAddresses',
  refreshResource: 'refreshResource',
}

export const moduleAccountTypes = {
  Cost: 'cost',
  Income: 'income',
}

const commonColumns = [
  {
    minWidth: 100,
    maxWidth: 120,
    name: i18n.t('Code'),
    prop: 'code',
  },
  {
    minWidth: 200,
    maxWidth: 200,
    name: i18n.t('Description'),
    prop: 'description',
  },
]

const payrollCodeColumns = [
  ...commonColumns,
  {
    minWidth: 120,
    maxWidth: 120,
    name: i18n.t('Calculate By'),
    prop: 'calculate_by',
    toFormat: formattedText
  },
]

const moduleTypeColumns = [
  {
    minWidth: 80,
    maxWidth: 100,
    name: i18n.t('Abbr'),
    prop: 'abbr',
  },
  {
    minWidth: 150,
    maxWidth: 150,
    name: i18n.t('Name'),
    prop: 'name',
  },
]

const lineItemColumns = [
  {
    minWidth: 70,
    maxWidth: 70,
    name: i18n.t('Phase Code'),
    prop: 'phase_code',
  },
  {
    minWidth: 80,
    maxWidth: 80,
    name: i18n.t('Cost Code'),
    prop: 'cost_code',
  },
  {
    minWidth: 70,
    maxWidth: 70,
    name: i18n.t('Change Order'),
    prop: 'change_order',
  },
  {
    class: 'w-full',
    name: i18n.t('Description'),
    prop: 'description',
  },
  {
    minWidth: 105,
    maxWidth: 105,
    name: i18n.t('Budget'),
    prop: 'amount',
    formatPrice: true,
  },
  {
    minWidth: 105,
    maxWidth: 105,
    name: i18n.t('Actual'),
   prop: 'amount_to_date',
    formatPrice: true,
  },
]

export const tableColumns = {
  [globalResources.Jobs]: [
    {
      minWidth: 100,
      maxWidth: 120,
      name: i18n.t('Number'),
      prop: 'number',
    },
    {
      minWidth: 200,
      maxWidth: 200,
      name: i18n.t('Description'),
      prop: 'description',
    },
  ],
  [globalResources.JobCostTypes]: moduleTypeColumns,
  [globalResources.JobIncomeTypes]: moduleTypeColumns,
  [globalResources.Equipments]: commonColumns,
  [globalResources.EquipmentTypes]: moduleTypeColumns,
  [globalResources.EquipmentMaintenances]: commonColumns,
  [globalResources.ServiceBillingCodes]: commonColumns,
  [globalResources.Materials]: [
    ...commonColumns,
    {
      minWidth: 40,
      maxWidth: 40,
      name: i18n.t('UM'),
      prop: 'um',
    },
  ],
  [globalResources.WorkOrders]: [
    {
      minWidth: 100,
      maxWidth: 120,
      name: i18n.t('Number'),
      prop: 'number',
    },
    {
      minWidth: 200,
      maxWidth: 200,
      name: i18n.t('Description'),
      prop: 'description',
    },
  ],
  [globalResources.ServiceBillingCostTypes]: moduleTypeColumns,
  [globalResources.ServiceBillingIncomeTypes]: moduleTypeColumns,
  [globalResources.CraftCodes]: [
    {
      minWidth: 100,
      maxWidth: 120,
      name: i18n.t('Code'),
      prop: 'code',
    },
    {
      minWidth: 60,
      maxWidth: 80,
      name: i18n.t('Level'),
      prop: 'level',
    },
    {
      minWidth: 200,
      maxWidth: 200,
      name: i18n.t('Description'),
      prop: 'description',
    },
  ],
  [globalResources.JobCostTypes]: moduleTypeColumns,
  [globalResources.JobIncomeTypes]: moduleTypeColumns,
  [globalResources.Equipments]: commonColumns,
  [globalResources.EquipmentTypes]: moduleTypeColumns,
  [globalResources.EquipmentMaintenances]: commonColumns,
  [globalResources.ServiceBillingCodes]: commonColumns,
  [globalResources.Materials]: [
    ...commonColumns,
    {
      minWidth: 40,
      maxWidth: 40,
      name: i18n.t('UM'),
      prop: 'um',
    },
  ],
  [globalResources.WorkOrders]: [
    {
      minWidth: 100,
      maxWidth: 120,
      name: i18n.t('Number'),
      prop: 'number',
    },
    {
      minWidth: 200,
      maxWidth: 200,
      name: i18n.t('Description'),
      prop: 'description',
    },
  ],
  [globalResources.CostLineItems]: lineItemColumns,
  [globalResources.IncomeLineItems]: lineItemColumns,
  [globalResources.TransactionsForCostPlus]: lineItemColumns,
  [globalResources.Employees]: [
    {
      minWidth: 100,
      maxWidth: 120,
      name: i18n.t('Code'),
      prop: 'code',
    },
    {
      minWidth: 150,
      maxWidth: 150,
      name: i18n.t('Name'),
      prop: 'name',
    },
    {
      minWidth: 80,
      maxWidth: 80,
      name: i18n.t('Status'),
      prop: 'status',
      component: 'Status',
    },
  ],
  [globalResources.JobTypes]: [
    {
      minWidth: 30,
      maxWidth: 30,
      prop: 'type_abbr',
      name: i18n.t('T'),
    },
    ...moduleTypeColumns,
  ],
  [globalResources.ServiceBillingCostTypes]: moduleTypeColumns,
  [globalResources.PayCodes]: payrollCodeColumns,
  [globalResources.TaxCodes]: payrollCodeColumns,
  [globalResources.BenefitCodes]: payrollCodeColumns,
  [globalResources.FlexibleBenefitPlans]: payrollCodeColumns,
  [globalResources.TimeOffPolicies]: commonColumns,
  [globalResources.DeductionCodes]: payrollCodeColumns,
  [globalResources.GeneralCodes]: commonColumns,
  [globalResources.DirectDepositCodes]: commonColumns,
  [globalResources.DepartmentCodes]: commonColumns,
  [globalResources.RetirementPlans]: payrollCodeColumns,
  [globalResources.UnionCodes]: payrollCodeColumns,
  [globalResources.WTwoCodes]: commonColumns,
  [globalResources.StateTaxes]: commonColumns,
  [globalResources.LocalTaxes]: commonColumns,
  [globalResources.CostLineItems]: lineItemColumns,
  [globalResources.IncomeLineItems]: lineItemColumns,
  [globalResources.TransactionsForCostPlus]: lineItemColumns,
  [globalResources.Employees]: [
    {
      minWidth: 100,
      maxWidth: 120,
      name: i18n.t('Code'),
      prop: 'code',
    },
    {
      minWidth: 150,
      maxWidth: 150,
      name: i18n.t('Name'),
      prop: 'name',
    },
    {
      minWidth: 80,
      maxWidth: 80,
      name: i18n.t('Status'),
      prop: 'status',
      component: 'Status',
    },
  ],
  [globalResources.Vendors]: [
    {
      minWidth: 100,
      maxWidth: 120,
      name: i18n.t('Code'),
      prop: 'code',
    },
    {
      minWidth: 150,
      maxWidth: 150,
      name: i18n.t('Name'),
      prop: 'name',
    },
  ],
  [globalResources.SalesTaxDistricts]: [
    {
      minWidth: 60,
      maxWidth: 60,
      name: i18n.t('State'),
      prop: 'state',
    },
    {
      minWidth: 60,
      maxWidth: 60,
      name: i18n.t('Code'),
      prop: 'code',
    },
    {
      name: i18n.t('Description'),
      prop: 'description',
      minWidth: 220,
      maxWidth: 220,
    },
    {
      minWidth: 80,
      maxWidth: 120,
      name: i18n.t('Tax'),
      prop: 'sales_tax_percent',
      component: 'FormattedPercent',
    },
  ],
  [globalResources.Customers] :[
    {
      minWidth: 100,
      maxWidth: 120,
      name: i18n.t('Code'),
      prop: 'code',
    },
    {
      minWidth: 200,
      maxWidth: 200,
      name: i18n.t('Name'),
      prop: 'name',
    },
  ],
  [globalResources.SubTrades]: [
    {
      minWidth: 300,
      maxWidth: 300,
      name: i18n.t('Description'),
      prop: 'description',
    },
  ],
  default: [
    [
      {
        minWidth: 100,
        maxWidth: 120,
        name: i18n.t('Code'),
        prop: 'code',
      },
    ],
  ]
}

export function isInViewport(el) {
  if (!el) {
    return
  }
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
