<template>
  <div class="text-center no-data-row">
    <template v-if="!loading">
      <slot name="icon">
        <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             aria-hidden="true">
          <path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"></path>
        </svg>
      </slot>
      <h3 class="mt-2 text-sm font-medium text-gray-900 capitalize">
        <slot name="title">
          {{ noDataText }}
        </slot>
      </h3>
      <p v-if="showAction('search') && showDescription" class="mt-1 text-sm text-gray-500">
        <slot name="description">
          {{ $t('Amend your filter options above...') }}
        </slot>
      </p>
      <component
          v-if="showAction('add') && addText"
          :is="link && addEntityInNewTab ? 'router-link': 'span'"
          :to="link && addEntityInNewTab ? link : ''"
          class="mt-1 btn-link"
          @click="onAdd"
      >
        {{ $t(`Click here to create a ${addText}`) }}
      </component>
      <div class="my-2">
        <slot/>
      </div>
    </template>
    <div v-else
         v-loading="loading"
         element-loading-background="rgba(0, 0, 0, 0.05)"
    >

    </div>
  </div>
</template>
<script>
  import pluralize from "pluralize";

  export default {
    props: {
      loading: Boolean,
      entity: {
        type: String,
        default: null,
      },
      showAction: {
        type: Function,
        default: () => true,
      },
      showDescription: {
        type: Boolean,
        default: true,
      },
      addEntityInNewTab: {
        type: Boolean,
        default: false,
      },
      link: {
        type: String,
      },
      addText: {
        type: String,
        default: '',
      }
    },
    computed: {
      noDataText() {
        if (!this.entity) {
          return this.$t('No Data Found');
        }
        let entity = pluralize(this.entity)
        return this.$t(`No ${entity} Found`)
      }
    },
    methods: {
      onAdd(){
        this.$emit('add')
      }
    },
  }
</script>
<style scoped>
  .no-data-row {
    pointer-events: auto;
  }
</style>
