<template>
  <div class="flex items-center">
    <status-badge :status="getStatus"/>
    <span class="mx-3">
      {{ $t('Invoice') }}
    </span>
  </div>
</template>
<script>
  export default {
    props: {
      invoice: {
        type: Object,
        default: () => ({})
      },
    },
    computed: {
      getStatus() {
        return this.invoice.status || 'draft'
      },
    },
  }
</script>
