import store from '@/store'

export function formatPrice(value, options = {}) {
  if (value === null || value === undefined) {
    return ''
  }

  const {
    decimals: minimumFractionDigits = 2,
    decimals_point: decimalsPoint = '.',
    thousands_separator: thousandsSeparator = ',',
  } = store.getters['settings/getByKey']('currency_format')

  const formatOptions = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits,
  }
  if (options.maximumFractionDigits !== undefined) {
    formatOptions.maximumFractionDigits = options.maximumFractionDigits
    formatOptions.minimumFractionDigits = options.maximumFractionDigits
  }

  const formatter = new Intl.NumberFormat('en-US', formatOptions)

  const formatted = formatter.format(value)
  return formatted.replace(',', thousandsSeparator).replace('.', decimalsPoint)
}

export default {
  install(Vue) {
    Vue.prototype.$formatPrice = formatPrice
    Vue.prototype.$formatPriceRound = function format(value, options = { maximumFractionDigits: 0 }) {
      return formatPrice(value, options)
    }
  }
}
