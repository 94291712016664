<template>
  <BaseDetailCard
      :title="$t('Payroll Information')"
      :key="employee.id"
      :entity="employee"
      :can-expand="false"
      :expanded="true"
      entity-name="employee"
  >
    <BaseDetailCardRow :title="$t('Cost Center')" field="account">
      <div class="flex space-x-2">
        <span>{{ getOptionLabel(employeeCostCenters, employeeData.cost_center) }}</span>
        <SourceLink
            v-if="employeeData.source_id"
            :id="employeeData.source_id"
            :data="employeeData"
        />
        <TypeLink
            v-if="employeeData.type_id"
            :title="$t('Type')"
            :id="employeeData.type_id"
            :data="employeeData"
        />
        <AddlSourceLink
            v-if="employeeData.addl_source_id"
            :title="$t('Line Item')"
            :id="employeeData.addl_source_id"
            :data="employeeData"
        />

      </div>
    </BaseDetailCardRow>
    <BaseDetailCardRow :title="$t('Normal G/L Account')" field="account" stripe>
      <AccountLink :number="employeeData.account"/>
    </BaseDetailCardRow>
    <BaseDetailCardRow :title="$t(`Union`)">
      <UnionLink :id="employeeData.union_id"/>
    </BaseDetailCardRow>
    <BaseDetailCardRow :title="$t(`Craft Code`)" stripe>
      <CraftCodeLink :id="employeeData.craft_code_id"/>
    </BaseDetailCardRow>
    <BaseDetailCardRow :title="$t(`Department`)">
      <DepartmentCodeLink :id="employeeData.department_id"/>
    </BaseDetailCardRow>
    <BaseDetailCardRow :title="$t('Withholding State')" stripe>
      <StateTaxLink :id="employeeData.withholding_state_id"/>
    </BaseDetailCardRow>
    <BaseDetailCardRow :title="$t('State (SUI/SDI)')">
      <StateTaxLink :id="employeeData.sui_sdi_state_id"/>
    </BaseDetailCardRow>
    <BaseDetailCardRow :title="$t(`Worker's Comp Rate`)" stripe>
      <span>{{employeeData.workers_comp_rate_code}}</span>
    </BaseDetailCardRow>
    <BaseDetailCardRow :title="$t(`General Liability Rate`)">
      <span>{{employeeData.gen_liability_rate_code}}</span>
    </BaseDetailCardRow>
    <BaseDetailCardRow :title="$t('Pension Plan')" stripe>
      <status-badge :status="employeeData.has_pension_plan ? $t('Yes') : $t('No')"/>
    </BaseDetailCardRow>
  </BaseDetailCard>
</template>
<script lang="ts" setup>
  import { computed, PropType } from 'vue'
  import { Employee } from "@/modules/common/types/models";
  import StateTaxLink from "@/components/links/StateTaxLink.vue";
  import { employeeCostCenters } from "../../utils/employeeUtils";
  import SourceLink from "@/components/links/SourceLink.vue";
  import TypeLink from "@/components/links/TypeLink.vue";
  import AddlSourceLink from "@/components/links/AddlSourceLink.vue";
  import UnionLink from "@/components/links/UnionLink.vue";
  import CraftCodeLink from "@/components/links/CraftCodeLink.vue";
  import Data = API.Data;

  const props = defineProps({
    employee: {
      type: Object as PropType<Data<Employee>>,
      default: () => ({}),
      required: true,
    }
  })

  const employeeData = computed<Employee>(() => {
    return props.employee?.attributes || {}
  })


  const employeeUser = computed(() => {
    const { user } = props.employee?.relationships || {}
    return user
  })

</script>
