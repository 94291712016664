<template>
  <entity-select url="/restify/work-sites"
                 v-bind="$attrs"
                 v-on="$listeners"
                 :url-params="urlParams"
                 :add-entity="addEntity"
                 :edit-entity="editEntity"
                 :set-default-option="editEntity"
                 :name="$attrs.name || $t('Worksite')"
                 :placeholder="$attrs.placeholder || $t('Select Worksite')"
                 :label="$attrs.label !== undefined ? $attrs.label : $t('Worksite')"
                 :add-label="$t('Add new Worksite')"
                 :id="$attrs.id || 'work-site-select'"
                 :table-columns="tableColumns"
                 :label-format="formatLabel"
                 label-key="number"
  />
</template>
<script>
  import { formattedAddress } from "@/utils/utils";

  export default {
    name: 'WorkSiteSelect',
    inheritAttrs: false,
    props: {
      urlParams: {
        type: Object,
        default: () => ({
          sort: 'number',
        }),
      },
      addEntity: {
        type: Boolean,
        default: true,
      },
      editEntity: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        tableColumns: [
          {
            minWidth: 10,
            maxWidth: 40,
            name: this.$t('#'),
            prop: 'attributes.number',
          },
          {
            minWidth: 150,
            maxWidth: 150,
            name: this.$t('Name'),
            prop: 'attributes.name',
          },
          {
            minWidth: 120,
            maxWidth: 150,
            name: this.$t('Address'),
            prop: 'attributes',
            toFormat: (row) => {
              return formattedAddress(row)
            },
          },
        ],
      }
    },
    methods: {
      formatLabel(workSite) {
        const { number, name } = workSite.attributes
        if (!name) {
          return number
        }
        return `${number} (${name})`
      },
    },
  }
</script>
