<template>
  <span v-if="row" class="flex space-x-1">
    <BaseTooltip v-for="tag in filteredTags" :key="tag.type" :content="tag.tooltip">
      <component
        :is="tag.component"
        :class="tag.class"
        class="w-5 h-5 text-gray-600"
      />
    </BaseTooltip>
   </span>
</template>
<script>
import SignatureIcon from "./SignatureIcon.vue";
import MaterialIcon from "./MaterialIcon.vue";
import EquipmentIcon from "./EquipmentIcon.vue";
import PayCodeIcon from "./PayCodeIcon.vue";
import { questionTypes } from "@/modules/payroll/utils/payrollUtils";
import FileIcon from "@/modules/payroll/components/timesheets/FileIcon.vue";
import BreakIcon from "@/modules/payroll/components/timesheets/BreakIcon.vue";
import LunchIcon from "@/modules/payroll/components/timesheets/LunchIcon.vue";
import { FileTextIcon } from "vue-feather-icons";

const tagTypes = {
  Signature: 'signature',
  Material: 'material',
  Equipment: 'equipment',
  PayCode: 'pay-code',
  UrgentNote: 'urgent-note',
  File: 'file',
  Lunch: 'lunch',
  Break: 'break',
}

export default {
  components: {
    SignatureIcon,
    MaterialIcon,
    EquipmentIcon,
    PayCodeIcon,
    FileTextIcon
  },
  props: {
    row: Object
  },
  data() {
    return {
      tagTypes
    }
  },
  computed: {
    allTags() {
      return [
        {
          type: tagTypes.UrgentNote,
          component: FileTextIcon,
          tooltip: this.$t('This timesheet has an urgent note'),
          class: '!text-red-500',
        },
        {
          type: tagTypes.Signature,
          component: SignatureIcon,
          tooltip: this.$t('This timesheet has a signature'),
        },
        {
          type: tagTypes.PayCode,
          component: PayCodeIcon,
          tooltip: this.$t('This timesheet includes Pay Codes'),
        },
        {
          type: tagTypes.Material,
          component: MaterialIcon,
          tooltip: this.$t('This timesheet includes Materials'),
        },
        {
          type: tagTypes.Equipment,
          component: EquipmentIcon,
          tooltip: this.$t('This timesheet includes Equipment'),
        },
        {
          type: tagTypes.File,
          component: FileIcon,
          tooltip: this.$t('This timesheet includes images'),
        },
        {
          type: tagTypes.Break,
          component: BreakIcon,
          tooltip: this.$t('This timesheet includes breaks'),
        },
        {
          type: tagTypes.Lunch,
          component: LunchIcon,
          tooltip: this.$t('This timesheet includes lunch breaks'),
        }
      ]
    },
    filteredTags() {
      return this.allTags.filter(tag => this.hasTag(tag.type))
    }
  },
  methods: {
    hasComplianceAnswer(compliance, type) {
      const answer = Object.values(compliance).find(c => c.type === type)?.value
      return !!answer
    },
    hasTag(type) {
      const compliance = this.row?.attributes?.compliance || {}
      const meta = this.row?.attributes?.meta || {}
      if (type === tagTypes.Signature) {
        return this.hasComplianceAnswer(compliance, questionTypes.Signature)
      }
      if (type === tagTypes.UrgentNote) {
        return this.hasComplianceAnswer(compliance, questionTypes.UrgentText)
      }
      const tagMap = {
        [tagTypes.Material]: meta.has_materials,
        [tagTypes.Equipment]: meta.has_equipment,
        [tagTypes.PayCode]: meta.has_pay_codes,
        [tagTypes.File]: meta.has_files,
        [tagTypes.Lunch]: meta.has_lunch,
        [tagTypes.Break]: meta.has_break,
      }
      return tagMap[type]
    }

  }
}
</script>
