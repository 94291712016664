<template>
  <div>
    <div class="flex justify-between items-center w-full leading-4">
      <EmployeeLink :id="employeeId"/>
      <div>
        <TableEditButton @click="editTimesheet"/>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  computed: {
    children() {
      return this.params.node?.allLeafChildren
    },
    data() {
      return this.children[0]?.data || {}
    },
    employeeId() {
      return this.data?.attributes?.employee_id || {}
    },
  },
  methods: {
    editTimesheet() {
      this.$router.push({
        path: '/payroll/timesheets/review',
        query: {
          employee_id: this.employeeId,
          from: this.params.from,
          to: this.params.to,
        }
      })
    }
  }
}
</script>
