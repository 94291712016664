<template>
  <BaseReportForm
    :filters="model"
    :report-number="reportNumber"
    :hide-presets="true"
  >
    <template #filters>
      <BaseFilterRow
        :title="$t('Quarter')"
      >
        <BaseSelect
          v-model="model.quarter"
          :add-entity="false"
          :options="quarterOptions"
          class="col-span-3"
        />

      </BaseFilterRow>
      <BaseFilterRow
        :title="$t('State')"
      >
        <StateSelect
          v-model="model.state"
          :add-entity="false"
          :filter-method="filterStatesMethod"
          class="col-span-3"
        />
      </BaseFilterRow>
      <BaseFilterRow
        :title="$t('Report Format')"
      >
        <BaseSelect
          v-model="format"
          :add-entity="false"
          :options="formatOptions"
          class="col-span-3"
        />
      </BaseFilterRow>
    </template>

    <template #table="{data, loading}">
      <div v-if="isTextFileList">
        <TextFileList
          :data="[data.fileData]"
          :loading="loading"
          :file-name="data.fileName"
        />
      </div>
      <div v-else>
        Not implemented
      </div>
    </template>

  </BaseReportForm>
</template>
<script>
import { quarterOptions } from '@/enum/enums'

import TextFileList from "@/modules/payroll/components/reports/TextFileList.vue";

const formats = {
  CaliforniaDe9C: 'california_de9c',
}

export default {
  components: {
    TextFileList,
  },
  props: {
    employees: {
      type: Array,
      default: () => [],
    },
    preNoticeFormat: {
      type: Boolean,
      default: false,
    }
  },
  setup() {
    return {
      quarterOptions,
    }
  },
  data() {
    return {
      formats,
      format: formats.CaliforniaDe9C,
      model: {
       quarter: 1,
       state: 'CA'
      },
    }
  },
  computed: {
    reportNumber() {
      const reportMap = {
        [formats.CaliforniaDe9C]: '4878he',
        default: '4878'
      }
      return reportMap[this.format] || reportMap.default
    },
    formatOptions() {
      const stateFormatsMap = {
        CA: [
          {
            label: this.$t('California DE9/C'),
            value: formats.CaliforniaDe9C,
          },
        ],
      }

      return stateFormatsMap[this.model.state] || []
    },
    isTextFileList() {
      return [formats.CaliforniaDe9C].includes(this.format)
    },
  },
  methods: {
    filterStatesMethod(state) {
      const availableStates = [
        'CA'
      ]

      return availableStates.includes(state.value)
    },
  }
}
</script>
