import { minutesToHours } from "@/modules/payroll/utils/timeCardUtils";

export function getTotalMinutes(params) {
  const { regular_minutes, overtime_minutes, premium_minutes } = params.data?.attributes || {}
  return (+regular_minutes || 0) + (+overtime_minutes || 0) + (+premium_minutes || 0)
}

export function hoursFormatter(params) {
  const minutes = params.value || 0
  return minutesToHours(minutes)
}
