<template>
  <DetailLayout
      :loading="jobLoading"
      :current-resource="currentResource"
      :get-current-resource="getCurrentResource"
      :select-component="JobSelect"
      :items="items"
  >
    <template #page-title>
      {{ pageTitle }}
      <span class="mx-1 md:mx-2 flex items-center">
          <status-badge v-if="currentResource.id" :status="currentResource.attributes?.status"/>
        </span>
    </template>
    <template #entity-name>
      {{ currentResource?.attributes?.number }}
      <template v-if="currentResource?.attributes?.description">
        ({{ currentResource?.attributes?.description }})
      </template>
    </template>

  </DetailLayout>
</template>

<script>
import DetailLayout from "@/modules/common/components/DetailLayout.vue";
import JobSelect from "@/components/select/entities/JobSelect.vue";

export default {
  components: { DetailLayout },
  data() {
    return {
      JobSelect,
    }
  },
  computed: {
    items() {
      return [
        {
          name: this.$t('Details'),
          path: `/job-costing/jobs/${this.currentJobId}/view`,
          authorizedTo: 'authorizedToShow',
        },
        {
          name: this.$t('Edit'),
          path: `/job-costing/jobs/${this.currentJobId}/edit`,
          authorizedTo: 'authorizedToUpdate',
        },
        {
          name: this.$t('Line Items'),
          path: `/job-costing/jobs/${this.currentJobId}/line-items`,
          permission: 'line_items_show',
          authorizedTo: 'authorizedToShow',
        },
        {
          name: this.$t('Activity'),
          path: `/job-costing/jobs/${this.currentJobId}/activity`,
          permission: 'line_items_show',
          authorizedTo: 'authorizedToShow',
        },
        {
          name: this.$t('Documents'),
          path: `/job-costing/jobs/${this.currentJobId}/documents`,
          permission: 'jobs_show',
          authorizedTo: 'authorizedToShow',
        },
        {
          name: this.$t('Invoices'),
          path: `/job-costing/jobs/${this.currentJobId}/invoices`,
          permission: 'invoices_show',
          authorizedTo: 'authorizedToShow',
        },
        {
          name: this.$t('Billings'),
          path: `/job-costing/jobs/${this.currentJobId}/billings`,
          permission: 'billings_show',
          authorizedTo: 'authorizedToShow',
        },
        {
          name: this.$t('Purchase Orders'),
          path: `/job-costing/jobs/${this.currentJobId}/purchase-orders`,
          permission: 'invoices_show',
          authorizedTo: 'authorizedToShow',
        },
        {
          name: this.$t('Timesheets'),
          path: `/job-costing/jobs/${this.currentJobId}/timesheets`,
          permission: 'timesheets_approve',
          authorizedTo: 'authorizedToShow',
        },
        {
          name: this.$t('Rates'),
          path: `/job-costing/jobs/${this.currentJobId}/rates`,
          authorizedTo: 'authorizedToShow',
        },
      ]
    },
    currentResource() {
      return this.$store.state.jobCosting.currentJob || {}
    },
    jobLoading() {
      return this.$store.state.jobCosting.jobLoading
    },
    pageTitle() {
      const { number, description } = this.currentResource?.attributes || {}
      if (!number) {
        return ''
      }
      return `Job ${number} ${description ? description : ''}`
    },
    currentJobId() {
      return this.$route.params.id
    },
  },
  methods: {
    async getCurrentResource(id) {
      await this.$store.dispatch('jobCosting/getJob', id)
    },
  },
}
</script>
