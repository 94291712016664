<template>
  <AgDataTable
      v-bind="$attrs"
      :columns="columns"
      :url="url"
      :url-params="urlParams"
      :show-actions-column="false"
      default-sort="-posted_at"
      actions="search, refresh"
      importModel="journal-entries"
      class="h-full"
      ref="table">
    <template #attributes.type="{row}">
      <status-badge :status="getType(row)"/>
    </template>
    <template #period="{row}">
      {{ getPeriodName(row.attributes.period) }}
    </template>
    <template #attributes.name="{row}">
      <div @click="getJournal(row.id)">
        <router-link :to="`/${module}/journals/${row.id}/view`">
          {{ row.attributes.name }}
        </router-link>
      </div>
    </template>
  </AgDataTable>
</template>
<script lang="ts">
  import { moduleBasePatches } from '@/enum/enums'
  import { GeneralLedgerJournalCode } from '@/enum/journal'
  import { defineComponent } from 'vue'
  import { Column } from "@/components/ag-grid/tableTypes";
  import Data = API.Data;

  export default defineComponent({
    props: {
      moduleCode: {
        type: String,
        default: GeneralLedgerJournalCode,
      },
      module: {
        type: String,
        default: moduleBasePatches.JobCosting,
      }
    },
    data() {
      return {
        url: '/restify/journals',
      }
    },
    computed: {
      columns(): Column[] {
        return [
          {
            headerName: this.$t('Name'),
            prop: 'attributes.name',
            minWidth: 150,
          },
          {
            headerName: this.$t('Created By'),
            prop: 'attributes.created_by',
            minWidth: 120,
            maxWidth: 150,
            component: 'UserLink',
          },
          {
            headerName: this.$t('Type'),
            prop: 'attributes.type',
            maxWidth: 150,
          },
          {
            headerName: this.$t('Year'),
            prop: 'attributes.fiscal_year',
            minWidth: 80,
            maxWidth: 100,
          },
          {
            headerName: this.$t('Period'),
            prop: 'period',
            minWidth: 120,
            maxWidth: 200,
          },
          {
            headerName: this.$t('Posted At'),
            prop: 'attributes.posted_at',
            maxWidth: 180,
            component: 'FormattedDate',
            cellRendererParams: {
              format: 'datetime'
            }
          },
          {
            headerName: this.$t('Posted By'),
            prop: 'attributes.posted_by',
            minWidth: 150,
            component: 'UserLink',
          },
        ]
      },
      urlParams() {
        return {
          code: this.moduleCode,
          status: 'posted',
        }
      },
    },
    methods: {
      getJournal(id: string) {
        this.$store.dispatch('generalLedger/getJournal', id)
      },
      getType(row: Data<any>) {
        const { type } = row.attributes
        if (type === 'single_period') {
          return this.$t('Single Period')
        } else if (type === 'recurring') {
          return this.$t('Recurring')
        }
        return ''
      },
      getPeriodName(period: number) {
        return this.$store.getters['company/getPeriodName'](period)
      },
    },
  })
</script>
