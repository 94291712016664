import i18n from "@/i18n";

export const earningTypes = {
  StateEarnings: 'state',
  LocalEarnings: 'local',
}

// *
export const sortBy = {
  Employee: 'employee',
  Job: 'job',
  Summary: 'summary',
  Detail: 'detail',
}

export const historyByEmployeeFormats = {
  PeriodSummary: 'period-summary',
  RegisterRecap: 'register-recap',
}

export const certifiedPayloadFormats = {
  Job: 'job',
  WorkOrder: 'work_order',
}

export const dateSelections = {
  PeriodEndDate: 'period-end-date',
  PaymentDate: 'payment-date',
  CalendarQuarter: 'calendar-quarter',
  FiscalPeriods: 'fiscal-periods',
  AllPayPeriods: 'all-pay-periods',
  Batch: 'batch',
}

export const dateFields = {
  // * CalendarQuarter
  year_start: null,
  quarter_start: null,
  year_end: null,
  quarter_end: null,
  // * FiscalPeriods
  fiscal_year: null,
  period_start: null,
  period_end: null,
}

export const selectionDateFields = {
  select_by: dateSelections.PeriodEndDate,
  ...dateFields,
  // * PeriodEndDate, PaymentDate
  start_date: null,
  end_date: app.$now,
}

export const certifiedPayrollFilters = {
  is_certified_payroll: true,
}

export const workerCompSummarySortBy = {
  Employee: 'employee',
  Job: 'job',
  Summary: 'summary',
  Detail: 'detail',
  WorkersComp: 'workers_comp',
}

export const historyDistributionHistory = {
  // * TBD
  // All: 'all',
  Job: 'jobs',
  Equipment: 'equipment',
  WorkOrders: 'work_orders',
}

export const historyDistributionHistoryOptions = [
  {
    value: historyDistributionHistory.Job,
    label: 'Jobs'
  },
  {
    value: historyDistributionHistory.Equipment,
    label: 'Equipment'
  },
  {
    value: historyDistributionHistory.WorkOrders,
    label: 'Work Orders'
  },
]

export const historyDistributionSubtotalOn = {
  WorkOrder: 'work_order',
  Job: 'job',
  Account: 'account',
  LineItem: 'line_item',
  PeriodEndDate: 'period_end_date',
  Employee: 'employee',
}

export const historyDistributionSubtotalOnOptions = [
  {
    value: historyDistributionSubtotalOn.WorkOrder,
    label: 'Work Order'
  },
  {
    value: historyDistributionSubtotalOn.Job,
    label: 'Job'
  },
  {
    value: historyDistributionSubtotalOn.Account,
    label: 'Account'
  },
  {
    value: historyDistributionSubtotalOn.LineItem,
    label: 'Line Item'
  },
  {
    value: historyDistributionSubtotalOn.PeriodEndDate,
    label: 'Period End Date'
  },
  {
    value: historyDistributionSubtotalOn.Employee,
    label: 'Employee'
  },
]

export const SelectedHistorySortBy = {
  Code: 'code',
  Employee: 'employee',
  CodeJob: 'code_job',
  DebitAccount: 'debit_account',
  CreditAccount: 'credit_account',
  PeriodEndDate: 'period_end_date',
  PaymentDate: 'payment_date',
  JobCode: 'job_code',
  Journal: 'journal',
  PaymentNumber: 'payment_number',
};

export const SelectedHistoryPrintBy = {
  Detail: 'detail',
  SubTotalsOnly: 'sub_totals_only',
};

export const getSelectedHistoryOptions = () => {
  return [
    { label: i18n.t('Pay/Deduction'), value: SelectedHistorySortBy.Code },
    { label: i18n.t('Employee'), value: SelectedHistorySortBy.Employee },
    { label: i18n.t('Pay/Ded Job'), value: SelectedHistorySortBy.CodeJob },
    { label: i18n.t('G/L Debit Account'), value: SelectedHistorySortBy.DebitAccount },
    { label: i18n.t('G/L Credit Account'), value: SelectedHistorySortBy.CreditAccount },
    { label: i18n.t('Period End Date'), value: SelectedHistorySortBy.PeriodEndDate },
    { label: i18n.t('Payment Date'), value: SelectedHistorySortBy.PaymentDate },
    { label: i18n.t('Job Pay/Ded'), value: SelectedHistorySortBy.JobCode },
    { label: i18n.t('Journal #'), value: SelectedHistorySortBy.Journal },
    { label: i18n.t('Payment #'), value: SelectedHistorySortBy.PaymentNumber },
  ]
};

export const ToDateEarningsAndTaxesOptions = {
  SortBy: {
    Employee: 'employee',
    Code: 'code',
  },
  PrintBy: {
    Detail: 'detail',
    SubTotals: 'sub_totals',
    GrandTotals: 'grand_totals',
  },
  Include: {
    All: 'all',
    PayCodes: 'pay',
    TaxAndHours: 'tax_and_hours',
    DeductionCodes: 'deductions',
  }
};

export const EmployeeListingOptions = {
  SortBy: {
    Employee: 'employee_code',
    Union: 'union_code',
    State: 'state_code',
    Birthday: 'birthday',
    SSN: 'ssn',
    OriginalHireDate: 'original_hire_date',
    RehireDate: 'hire_date',
    TerminationDate: 'termination_date',
    OtherDate: 'other_date',
    LastCheckDate: 'last_check_date',
  },
  Include: {
    All: 'all',
    Active: 'active',
    Inactive: 'inactive',
    Hourly: 'hourly',
    Salary: 'salary',
  }
}

export const PayrollPaymentRegisterOptions = {
  SortBy: {
    PaymentNumber: 'payment',
    Employee: 'employee',
    FiscalPeriods: 'fiscal_periods',
  },
  SelectBy: {
    PeriodEndDate: 'period-end-date',
    PaymentDate: 'payment-date',
    CalendarQuarter: 'calendar-quarter',
    FiscalPeriods: 'fiscal-periods',
    AllPayPeriods: 'all-pay-periods',
    Batch: 'batch',
  }
}
