<template>
  <div>
    <base-go-to-edit-alert
        v-if="reconciliationAlreadyExists"
        :path="`/ledger/account-reconciliation/${pendingReconciliation.id}/edit`"
        message="Pending reconciliation for current selections already exists."
    />
    <base-form
        :loading="loading"
        :show-back="true"
        :save-text="$t('Create reconciliation')"
        :update-text="$t('Update reconciliation')"
        :submit-disabled="formSubmitDisabled"
        layout="vertical"
        grid-classes="grid grid-cols-1"
        @submit="onSubmit"
    >
      <el-collapse
          v-model="activeGroup"
          class="col-span-1"
      >
        <base-collapse-section
            key="summary"
            class="-m-5"
            name="summary"
        >
          <template #title>
            <div class="flex flex-1 items-center justify-between form-header-summary">
              <span class="mr-2">
                {{ $t('Summary') }}
              </span>
              <div class="summary">
                {{ $t('G/L Balance: ') }}
                <span>
                  {{ $formatPrice(accountBalance) }}
                </span>
              </div>
            </div>
          </template>
          <div class="grid grid-cols-3 gap-4">
            <div class="col-span-2 grid grid-cols-12 gap-x-4">
              <account-select
                  v-model="model.account"
                  :name="$t('Account')"
                  :label="$t('Account')"
                  :filter-function="filterAccounts"
                  :disabled="!!$route.params.id"
                  value-key="number"
                  :step="0.01"
                  id="account_id"
                  rules="required"
                  class="col-span-12 md:col-span-4"
                  @change="onAccountChange"
              />
              <period-select
                  v-model="model.period"
                  :reference-date="model.date"
                  :label="$t('Period')"
                  :disabled="!!$route.params.id"
                  class="col-span-12 md:col-span-3"
                  rules="required"
                  @change="validateSelection"
              />
              <fiscal-year-select
                  v-model="model.fiscal_year"
                  :label="$t('Year')"
                  :only-opened-years="false"
                  :disabled="!!$route.params.id"
                  class="col-span-12 md:col-span-3"
                  @change="validateSelection"
              />
              <base-date-picker
                  v-model="model.date"
                  :label="$t('Date')"
                  :disabled="!!$route.params.id"
                  class="col-span-12 md:col-span-3"
                  rules="required"
                  @change="validateSelection"
              />
              <base-input
                  v-model.number="model.bank_balance_amount"
                  :label="$t('Bank Statement Balance')"
                  id="bank-statement-balance"
                  :step="0.01"
                  type="number"
                  format="price"
                  class="col-span-12 md:col-span-3"
              />
              <!--to do: implement on be side-->
              <template v-if="false">
                <base-select
                    v-model="filters.additionalFilter"
                    :label="$t('Display')"
                    :options="additionalFilterOptions"
                    class="col-span-12 md:col-span-2"
                    rules="required"
                />
                <base-input v-if="filters.additionalFilter === 'exact_amount'"
                            v-model="filters.amount"
                            :label="$t('Exact Amount')"
                            :step="1"
                            id="exact_amount"
                            key="exact_amount"
                            format="price"
                            type="number"
                            class="number-input"
                            rules="required|excluded:0"
                />
                <template v-if="filters.additionalFilter === 'partial_amount'">
                  <base-input
                      v-model="filters.amount_from"
                      :label="$t('Min Amount')"
                      :step="1"
                      id="partial_amount_min"
                      type="number"
                      format="price"
                      rules="required|excluded:0"
                      class="col-span-12 md:col-span-2"
                  />
                  <base-input
                      v-model="filters.amount_to"
                      :label="$t('Max Amount')"
                      :rules="amountRules"
                      :step="1"
                      id="partial_amount_max"
                      type="number"
                      format="price"
                      class="col-span-12 md:col-span-2"
                  />
                </template>
              </template>
            </div>
            <div class="col-span-1 flex-1">
              <portal-target name="reconciliation-summary"/>
            </div>
          </div>
        </base-collapse-section>
      </el-collapse>
      <ReconciliationEntries
          v-if="showReconcileEntriesTable && !localLoading"
          :data="model"
          :url-params="getUrlParams"
          :account-balance="accountBalance"
          :details-page="false"
          class="mt-6"
          ref="entriesTable"
      />
      <file-attachments class="col-span-1 mt-8" entity="reconciliations"/>
    </base-form>
  </div>
</template>
<script>
  import axios from 'axios'
  import { resourceStatuses, transactionStatuses } from '@/enum/enums'
  import { getAccountByNumber, computedAccountBalance } from '@/utils/account'
  import ReconciliationEntries from '@/modules/ledger/components/ReconciliationEntries'
  import FileAttachments from '@/modules/accounts-payable/components/attachements/FileAttachments'

  const meta = {
    prior_and_current_outstanding: 0,
    future_items_reconciled: 0,
    adjusted_balance: 0,
    difference: 0,
  }

  export default {
    components: {
      FileAttachments,
      ReconciliationEntries,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      const firstPeriodValue = this.$store.getters['company/firstPeriodValue']

      return {
        localLoading: false,
        model: {
          account: '',
          bank_balance_amount: 0,
          date: this.$now,
          fiscal_year: this.$currentYear,
          period: firstPeriodValue,
          status: resourceStatuses.Pending,
          meta,
        },
        activeGroup: ['summary'],
        reconciliationAlreadyExists: false,
        isValidSelections: false,
        loading: false,
        urlQuery: null,
        filters: {
          additionalFilter: 'all_items',
          amount: 0,
          amount_from: 0,
          amount_to: 0,
        },
        additionalFilterOptions: [
          {
            label: this.$t('All Items'),
            value: 'all_items',
          },
          {
            label: this.$t('Exact Amount'),
            value: 'exact_amount',
          },
          {
            label: this.$t('Amount range'),
            value: 'partial_amount',
          },
          {
            label: this.$t('Outstanding only'),
            value: 'outstanding',
          },
          {
            label: this.$t('Reconciled only'),
            value: 'reconciled',
          },
          {
            label: this.$t('Variance only'),
            value: 'variance',
          },
        ],
        selectedAccount: {},
        pendingReconciliation: {},
      }
    },
    computed: {
      formSubmitDisabled() {
        if (this.model.id) {
          return false
        }

        return this.reconciliationAlreadyExists || !this.isValidSelections
      },
      showReconcileEntriesTable() {
        return this.isValidSelections || this.model.id
      },
      amountRules() {
        const amount_from = this.filters.amount_from
        return `required|min_value:${amount_from}`
      },
      periods() {
        return this.$store.getters['company/getAuthorizedToUsePeriods']
      },
      accountBalance() {
        return computedAccountBalance(this.selectedAccount, this.model.period)
      },
      getUrlParams() {
        return {
          account_number: this.selectedAccount.number,
        }
      },
      requiredFieldsFilled() {
        return this.model.account && this.model.period && this.model.fiscal_year && this.model.date
      },
    },
    methods: {
      async onAccountChange(accountNumber) {
        try {
          this.localLoading = true
          this.selectedAccount = await getAccountByNumber(accountNumber)
          await this.validateSelection()
        } catch (e) {
          console.warn(e)
        } finally {
          this.localLoading = false
        }
      },
      filterAccounts(account) {
        const bankAccounts = this.$store.state.company.activeCompany?.banks?.map(b => b?.attributes?.account) || []
        return bankAccounts.includes(account.number)
      },
      async validateSelection() {
        await this.$nextTick()

        if (this.model.id || !this.requiredFieldsFilled) {
          return
        }

        try {
          this.loading = true

          const { data } = await axios.get('/restify/reconciliations', {
            params: {
              account: this.model.account,
              period: this.model.period,
              fiscal_year: this.model.fiscal_year,
              date: this.model.date,
              status: resourceStatuses.Pending,
            },
          })

          this.reconciliationAlreadyExists = !!data.length
          this.pendingReconciliation = data[0] || {}
          this.isValidSelections = !data.length

          if (data.length) {
            return
          }

          await this.tryGetLastPostedReconciliation()

        } catch (e) {
          console.warn(e)
        } finally {
          this.loading = false
        }
      },
      async onSubmit() {
        try {
          this.loading = true
          const meta = this.$refs.entriesTable.getSummary() || {}
          this.model.prior_and_current_amount = meta.prior_and_current_outstanding
          this.model.future_amount = meta.future_items_reconciled
          this.model.account_balance_amount = meta.adjusted_balance

          if (this.model.id) {
            await axios.put(`/restify/reconciliations/${this.model.id}`, this.model)
            await this.$refs.entriesTable.storeProgress(this.model.id)
          } else {
            const { data } = await axios.post('/restify/reconciliations', this.model)
            await this.$refs.entriesTable.storeProgress(data.id, true)
            await this.$router.push('/ledger/account-reconciliation/pending')
          }
        } catch (err) {
          console.warn(err)
          if (err.handled) {
            return
          }

          this.$error(this.$t('Could not save account reconciliation'))
        } finally {
          this.loading = false
        }
      },
      async tryGetLastPostedReconciliation() {
        try {
          this.loading = true
          const { data } = await axios.get('/restify/reconciliations', {
            params: {
              account: this.model.account,
              fiscal_year: this.$currentYear,
              period: this.model.period,
              status: resourceStatuses.Posted,
              perPage: 1,
              sort: '-created_at',
            },
          })

          if (!data.length) {
            return
          }

          this.model.period = this.get(data, '0.attributes.period')

        } catch (e) {
          console.warn(e)
        } finally {
          this.loading = false
        }
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return
          }

          this.model = {
            ...this.model,
            ...value.attributes,
            meta: this.get(value, 'attributes.meta', meta),
          }

          this.onAccountChange(this.model.account)
        },
      },
    },
  }
</script>
