import { isProduction } from "@/isProduction";
import { RoleScopes } from "@/modules/auth/plugins/permissionsPlugin";

const ledger = [
  {
    name: 'Accounts ',
    path: '/ledger/accounts',
    permission: 'accounts_show',
  },
  {
    name: 'Sub Accounts',
    path: '/ledger/sub-accounts',
    permission: 'subaccounts_show',
  },
  {
    name: 'Budgets',
    path: '/ledger/budgets',
    permission: 'budgets_show',
  },
  {
    name: 'Journal Entries',
    path: '/ledger/journal',
    permission: 'budgets_show',
  },
  {
    name: 'Account Reconciliation',
    path: '/ledger/account-reconciliation',
    permission: 'cash_reconciliations_show',
  },
  {
    name: 'Account Balances',
    path: '/ledger/balances',
    permission: 'review_account_balances_show',
  },
  {
    name: 'Reports',
    path: '/ledger/reports',
    permission: 'general_ledger_reports',
  },
  {
    name: 'Settings',
    path: '/ledger/settings',
    permission: 'general_ledger_settings',
  },
]

const jobCosting = [
  {
    name: 'Jobs',
    path: '/job-costing/jobs',
    permission: 'jobs_show',
  },
  {
    name: 'Line Items',
    path: '/job-costing/cost-line-items',
    permission: 'line_items_show',
  },
  {
    name: 'Adjustments',
    path: '/job-costing/transactions/cost',
    permission: 'transaction_show',
  },
  {
    name: 'Progress Updating',
    path: '/job-costing/progress-updating',
    permission: 'jobs_show',
  },
  {
    name: 'Review Job Status',
    path: '/job-costing/review-job/summaries',
    permission: 'jobs_show',
  },
  {
    name: 'Reports',
    path: '/job-costing/reports',
    permission: 'job_costing_reports',
  },
  {
    name: 'Settings',
    path: '/job-costing/settings',
    permission: 'job_costing_settings',
  },
  {
    name: 'Journals',
    path: '/job-costing/journals',
    permission: 'jobs_show',
  },
]

const accountPayable = [
  {
    name: 'Dashboard',
    path: '/accounts-payable/dashboard',
    permission: 'invoices_show',
  },
  {
    name: 'Vendors',
    path: '/accounts-payable/vendors',
    permission: 'vendors_show',
  },
  //TODO: 30.30 ,.35,.38,.39
  // {
  //   name: 'Subcontract Invoices',
  //   path: '/accounts-payable/subcontract-invoices',
  //   permission: 'vendors_show',
  // },
  {
    name: 'Invoices',
    path: '/accounts-payable/invoices',
    permission: 'invoices_show',
  },
  {
    name: 'Subcontract Invoices',
    path: '/accounts-payable/subcontract-invoices',
    permission: 'accounts_payable_reports',
  },
  {
    name: 'Payments',
    path: '/accounts-payable/payments',
    permission: 'invoice_payments_show',
  },
  {
    name: 'Review Vendor History',
    path: '/accounts-payable/vendor-review-history',
    permission: 'accounts_payable_reports',
  },
  {
    name: 'Reports',
    path: '/accounts-payable/reports',
    permission: 'accounts_payable_reports',
  },
  {
    name: 'Settings',
    path: '/accounts-payable/settings',
    permission: 'accounts_payable_settings',
  },
  {
    name: 'Journals',
    path: '/accounts-payable/journals',
    permission: 'accounts_payable_reports',
  },
]

const accountReceivable = [
  {
    name: 'Dashboard',
    path: '/accounts-receivable/dashboard',
    permission: 'billings_show',
  },
  {
    name: 'Customers',
    path: '/accounts-receivable/customers',
    permission: 'customers_show',
    productionReady: true,
  },
  {
    name: 'Billings',
    path: '/accounts-receivable/billings',
    permission: 'billings_show',
  },
  {
    name: 'Payments',
    path: '/accounts-receivable/payments',
    permission: 'billing_payments_show',
  },
  {
    name: isProduction() ? 'Statements' : 'Statements (desktop)',
    path: '/accounts-receivable/statements',
    permission: 'customer_statements_show',
    productionReady: true,
    enabled: () => isProduction(),
  },
  {
    name: isProduction() ? 'Lump Sum Billings' : 'Lump Sum Billings (desktop)',
    path: '/accounts-receivable/lump-sum',
    permission: 'lump_sum_billings_show',
    productionReady: true,
    enabled: () => isProduction(),
  },
  {
    name: 'Review Customer History',
    path: '/accounts-receivable/customer-review-history',
    permission: 'customers_show',
  },
  {
    name: 'Reports',
    path: '/accounts-receivable/reports',
    permission: 'accounts_receivable_reports',
  },
  {
    name: 'Settings',
    path: '/accounts-receivable/settings',
    permission: 'accounts_receivable_settings',
  },
  {
    name: 'Journals',
    path: '/accounts-receivable/journals',
    permission: 'accounts_receivable_reports',
  },
]

const payroll = [
  {
    name: 'Employees',
    path: '/payroll/employees',
    permission: 'employees_show',
    productionReady: true,
  },
  {
    name: 'Timesheets',
    path: '/payroll/timesheets/list',
    permission: 'timesheets_approve',
  },
  {
    name: 'Timecards',
    path: '/payroll/timecard-batches',
    permission: 'timecards_show',
  },
  {
    name: 'Payroll Registers',
    path: '/payroll/batches',
    permission: 'payrolls_show',
  },
  {
    name: 'Checks',
    path: '/payroll/checks/batches',
    permission: 'payrolls_show',
  },
  {
    name: isProduction() ? 'Checks' : 'Checks (desktop)',
    path: '/payroll/check-batches',
    permission: 'employees_show',
    productionReady: true,
    enabled: () => isProduction(),
  },
  {
    name: isProduction() ? 'W2 Forms' : 'W2 Forms (desktop)',
    path: '/payroll/w2-forms-batches',
    permission: 'employees_show',
    productionReady: true,
    enabled: () => isProduction(),
  },
  {
    name: 'Codes',
    path: '/payroll/codes',
    permission: 'payroll_settings',
  },
  {
    name: 'Rates',
    path: '/payroll/rates',
    permission: 'payroll_settings',
  },
  {
    name: 'Reports',
    path: '/payroll/reports',
    permission: 'payroll_reports',
  },
  {
    name: 'Settings',
    path: '/payroll/settings',
    permission: 'payroll_settings',
  },
  {
    name: 'Journals',
    path: '/payroll/journals',
    permission: 'payroll_reports',
  },
  {
    name: 'My Checks',
    path: '/payroll/my-checks',
    permission: 'own_employee_checks_show',
    productionReady: true,
  },
  {
    name: 'My W2 Forms',
    path: '/payroll/my-w2-forms',
    permission: 'own_employee_w2_forms_show',
    productionReady: true,
  },
  {
    name: 'My Payroll Settings',
    path: '/payroll/my-settings',
    permission: 'own_employee_checks_show',
    productionReady: true,
  },
]

const estimating = [
  {
    name: 'Bids',
    path: '/estimates/bids',
    permission: 'bids_show',
  },
  {
    name: 'Bid Items',
    path: '/estimates/bid-items',
    permission: 'bid_items_show',
  },
  {
    name: 'Work Item Values',
    path: '/estimates/work-items',
    permission: 'work_items_show',
  },
  {
    name: 'Estimate Formulas',
    path: '/estimates/formulas',
    permission: 'estimate_formulas_show',
  },
  {
    name: 'Crew Labor Rates',
    path: '/estimates/crew-labor-rates',
    permission: 'crew_labor_rates_show',
  },
  {
    name: 'Material Codes',
    path: '/estimates/material-codes',
    permission: 'material_code_show',
  },
  {
    name: 'Phase Code / Cost Code',
    path: '/estimates/phase-cost',
    permission: 'estimate_codes_show',
  },
]

const serviceBilling = [
  {
    name: 'Worksites',
    path: '/service-billing/worksites',
    permission: 'worksite_show',
  },
  {
    name: 'Work Orders',
    path: '/service-billing/work-orders',
    permission: 'work_orders_show',
  },
  {
    name: 'Service Invoices',
    path: '/service-billing/service-invoices',
    permission: 'billings_show',
  },
  {
    name: 'Select Costs For Billing',
    path: '/service-billing/select-cost-for-billing',
    permission: 'billings_update',
  },
  {
    name: isProduction() ? 'Invoices' : 'Invoices (desktop)',
    path: '/service-billing/service-billing-invoice-batches',
    permission: 'service_billing_invoices_show',
    productionReady: true,
    enabled: () => isProduction(),
  },
  {
    name: 'Review Work Order Status',
    path: '/service-billing/work-order-review-status',
    permission: 'work_orders_show',
  },
  {
    name: 'Reports',
    path: '/service-billing/reports',
    permission: 'service_billing_reports',
  },
  {
    name: 'Settings',
    path: '/service-billing/settings',
    permission: 'service_billing_settings',
  },
  {
    name: 'Journals',
    path: '/service-billing/journals',
    permission: 'service_billing_reports',
  },
]

const equipmentManagement = [
  {
    name: 'Equipment',
    path: '/equipment/list',
    permission: 'equipment_show',
  },
  {
    name: 'Compute Depreciation',
    path: '/equipment/depreciation',
    permission: 'equipment_show',
  },
  {
    name: 'Adjustments',
    path: '/equipment/initialize-equipment',
    permission: 'equipment_adjustment_show',
  },
  {
    name: 'Maintenance Codes',
    path: '/equipment/maintenance-codes',
    permission: 'maintenance_codes_show',
  },
  {
    name: 'Reports',
    path: '/equipment/reports',
    permission: 'equipment_management_reports',
  },
  {
    name: 'Settings',
    path: '/equipment/settings',
    permission: 'equipment_management_settings',
  },
]

const purchasingAndInventory = [
  {
    name: 'Purchase Orders',
    path: '/purchasing-inventory/purchase-orders',
    permission: 'materials_show',
  },
  {
    name: 'Materials',
    path: '/purchasing-inventory/materials',
    permission: 'materials_show',
  },
  {
    name: 'Reports',
    path: '/purchasing-inventory/reports',
    permission: 'inventory_management_reports',
  },
  {
    name: 'Settings',
    path: '/purchasing-inventory/settings',
    permission: 'inventory_management_settings',
  },
]

const settings = [
  {
    name: 'Company Users',
    path: '/settings/company-users',
    permission: 'users_update',
    permissionScope: RoleScopes.Company,
    productionReady: true,
  },
  {
    name: 'Organization Users',
    path: '/settings/users',
    permission: 'users_update',
    permissionScope: RoleScopes.Organization,
    productionReady: true,
  },
  {
    name: 'Roles & Permissions',
    path: '/settings/roles',
    permission: 'company_roles_update',
    productionReady: true,
  },
  {
    name: 'Companies',
    path: '/settings/companies',
    permission: 'companies_update',
    productionReady: true,
  },
  {
    name: 'Banks',
    path: '/settings/banks',
    permission: 'banks_show',
  },
  {
    name: 'Email templates',
    path: '/settings/email-templates',
    permission: 'mail_templates_update',
    productionReady: true,
  },
  {
    name: 'App templates',
    path: '/settings/app-templates',
    permission: 'app_templates_update',
    productionReady: true,
  },
  {
    name: 'Company Settings',
    path: '/settings/company-settings',
    permission: 'company_settings',
    productionReady: true,
  },
  {
    name: 'Organization Settings',
    path: '/settings/organization-settings',
    permission: 'organization_update',
    productionReady: true,
  },
  {
    name: 'Organization Translations',
    path: '/settings/organization-translations',
    permission: 'organization_update',
  },
]

export {
  ledger,
  payroll,
  settings,
  jobCosting,
  estimating,
  accountPayable,
  accountReceivable,
  serviceBilling,
  equipmentManagement,
  purchasingAndInventory,
}
