<template>
  <div class="report-page">
    <base-report-form
        :filters="model"
        :report-number="reportNumber"
        :hide-presets="true"
    >
      <template #filters>

        <base-filter-row :title="$t('View')">
          <base-select
              v-model="model.sort_by"
              :add-entity="false"
              :options="sortByOptions"
              class="col-span-2"
              @change="onChangeSortType"
          />
        </base-filter-row>

        <job-range-filter
            v-if="sortByJob"
            v-model="model"
            :url-params="certifiedPayrollFilters"
        >
          <div class="col-span-2 flex items-baseline">
            <span class="text-label">{{ $t('Status') }}</span>
            <base-select
                v-model="model.job_statuses"
                :add-entity="false"
                :options="jobStatusOptions"
                class="flex-1"
                rules="required"
                collapse-tags
                multiple
            />

          </div>
        </job-range-filter>

        <base-filter-row
            :title="$t('Include')"
        >
          <div class="col-span-8 flex">
            <base-checkbox
                v-model="model.total_hours_worked"
                :label="$t('Total Weekly Hours Worked')"
                id="total_hours_worked"
            />
            <base-checkbox
                v-if="model.format === formats.CA_CPR_XML"
                v-model="model.include_non_performance"
                :label="$t('Include Non Performance')"
                id="include_non_performance"
            />
          </div>

        </base-filter-row>
        <base-filter-row :title="$t('Format')">

          <base-select
              v-model="model.format"
              :options="formatOptions"
              class="col-span-2"
          />

        </base-filter-row>

      </template>

      <template #table="{data, loading}">

        <certified-payroll-report-table
            v-if="model.format === formats.Detail"
            :data="data"
            :filters="model"
            :report-number="reportNumber"
            :data-loading="loading"
            :sort-by="model.sort_by"
            ref="reportTable"
        />

        <div v-else-if="[formats.CA_CPR_XML, formats.WA_PWC_PR].includes(model.format)">
          <XmlFileList :data="data" :loading="loading" :preview="isPayrollPending"/>
        </div>

      </template>

    </base-report-form>
  </div>
</template>
<script>
import { jobStatusOptions } from '@/modules/job-costing/enum/jobs'
import { JobRangeFilter, WorkOrderRangeFilter } from '@/components/range-filters'
import PayrollDatesFilter from '@/modules/payroll/components/reports/PayrollDatesFilter'
import { certifiedPayloadFormats, certifiedPayrollFilters, } from '@/modules/payroll/components/reports/util'
import CertifiedPayrollReportTable from '@/modules/payroll/components/reports/CertifiedPayrollReportTable'
import XmlFileList from "@/modules/payroll/components/reports/XmlFileList.vue";
import { resourceStatuses } from "@/enum/enums";

const formats = {
  Detail: 'detail',
  WA_PWC_PR: 'wa_pwc_pr',
  CA_CPR_XML: 'ca_cpr_xml',
}

export default {
  components: {
    XmlFileList,
    JobRangeFilter,
    PayrollDatesFilter,
    WorkOrderRangeFilter,
    CertifiedPayrollReportTable,
  },
  data() {
    return {
      certifiedPayrollFilters,
      jobStatusOptions,
      model: {
        sort_by: certifiedPayloadFormats.Job,
        include_pending: true,
        total_hours_worked: false,
        job_number_from: null,
        job_number_to: null,
        job_statuses: [this.$resourceStatuses.Open],

        work_order_number_from: null,
        work_order_number_to: null,
        include_non_performance: true,
        work_order_statuses: [this.$resourceStatuses.Open],

        format: formats.Detail,
        report_date: this.$now,
        batch_id: this.$route.params.id,
        select_by: 'batch',
      },
      formats,
      sortByOptions: [
        {
          label: this.$t('Jobs'),
          value: certifiedPayloadFormats.Job,
        },
        {
          label: this.$t('Work Orders'),
          value: certifiedPayloadFormats.WorkOrder,
        },
      ],
      workOrderStatusOptions: [
        {
          label: this.$t('Open'),
          value: this.$resourceStatuses.Open,
        },
        {
          label: this.$t('Closed'),
          value: this.$resourceStatuses.Closed,
        },
      ],
    }
  },
  computed: {
    payrollBatch() {
      return this.$store.state.payroll.currentPayrollBatch
    },
    isPayrollPending() {
      return this.payrollBatch?.attributes?.status === resourceStatuses.Pending
    },
    formatOptions() {
      return [
        {
          label: this.$t('Details'),
          value: formats.Detail,
        },
        {
          label: this.$t('CA CPR XML'),
          value: formats.CA_CPR_XML,
        },
        {
          label: this.$t('WA PWCPR'),
          value: formats.WA_PWC_PR,
        },
      ]
    },
    sortByJob() {
      return this.model.sort_by === certifiedPayloadFormats.Job
    },
    reportNumber() {
      const reportMap = {
        [formats.WA_PWC_PR]: '4886i',
        [formats.CA_CPR_XML]: '4886f',
        [formats.Detail]: '4886',
        default: '4886'
      }
      return reportMap[this.model.format] || reportMap.default
    },
  },
  methods: {
    onChangeSortType() {
      this.model.job_statuses = this.model.work_order_statuses = [this.$resourceStatuses.Open]
      this.model.job_number_from = this.model.job_number_to = null
      this.model.work_order_number_from = this.model.work_order_number_to = null
    },
  },
}
</script>
