import AccountSelectCell from "@/components/ag-grid/cellEditors/AccountSelectCell";
import AccountMultipleRangeSelectCell from "@/components/ag-grid/cellEditors/AccountMultipleRangeSelect.vue";
import CustomerSelectCell from "@/components/ag-grid/cellEditors/CustomerSelectCell.vue";
import BillingSelectCell from "@/components/ag-grid/cellEditors/BillingSelectCell.vue";
import VendorSelectCell from "@/components/ag-grid/cellEditors/VendorSelectCell.vue";
import BankSelectCell from "@/components/ag-grid/cellEditors/BankSelectCell.vue";
import CompanyRoleCell from "@/components/ag-grid/cellEditors/CompanyRoleCell";
import SubAccountSelectCell from "@/components/ag-grid/cellEditors/SubAccountSelectCell";
import CostCenterSelectCell from "@/components/ag-grid/cellEditors/CostCenterSelectCell";
import BaseSelectCell from "@/components/ag-grid/cellEditors/BaseSelectCell";
import FiscalYearSelectCell from "@/components/ag-grid/cellEditors/FiscalYearSelectCell.vue";
import GlobalResourceCell from "@/components/ag-grid/cellEditors/GlobalResourceCell";
import LineItemCell from "@/components/ag-grid/cellEditors/LineItemCell";
import NumericCell from "@/components/ag-grid/cellEditors/NumericCell";
import HtmlCell from "@/components/ag-grid/cellEditors/HtmlCell";
import SpecialCodeCell from "@/components/ag-grid/cellEditors/SpecialCodeCell";
import SpecialCodeTypeCell from "@/components/ag-grid/cellEditors/SpecialCodeTypeCell";
import DateCell from "@/components/ag-grid/cellEditors/DateCell";
import DatePickerCell from "@/components/ag-grid/cellEditors/DatePickerCell";
import BooleanCell from "@/components/ag-grid/cellEditors/BooleanCell";
import AddressCell from "@/components/ag-grid/cellEditors/AddressCell";
import AddressSelectCell from "@/components/ag-grid/cellEditors/AddressSelectCell";
import ServiceBillingCodeCell from "@/components/ag-grid/cellEditors/ServiceBillingCodeCell";
import ColorPickerCell from "@/components/ag-grid/cellEditors/ColorPickerCell";
import StatusCell from "@/components/ag-grid/cellEditors/StatusCell.vue";
import PeriodSelectCell from "@/components/ag-grid/cellEditors/PeriodSelectCell.vue";

export const cellEditorComponents = {
  LineItemCell,
  NumericCell,
  HtmlCell,
  BaseSelectCell,
  AccountSelectCell,
  AccountMultipleRangeSelectCell,
  CustomerSelectCell,
  VendorSelectCell,
  BillingSelectCell,
  BankSelectCell,
  CompanyRoleCell,
  SubAccountSelectCell,
  GlobalResourceCell,
  CostCenterSelectCell,
  SpecialCodeCell,
  SpecialCodeTypeCell,
  DateCell,
  DatePickerCell,
  BooleanCell,
  AddressCell,
  AddressSelectCell,
  ServiceBillingCodeCell,
  ColorPickerCell,
  StatusCell,
  FiscalYearSelectCell,
  PeriodSelectCell,
}

export const cellEditors = {
  AccountSelect: 'AccountSelectCell',
  AccountMultipleRangeSelect: 'AccountMultipleRangeSelectCell',
  CustomerSelect: 'CustomerSelectCell',
  VendorSelect: 'VendorSelectCell',
  BillingSelect: 'BillingSelectCell',
  BankSelect: 'BankSelectCell',
  CompanyRoleSelect: 'CompanyRoleCell',
  SubAccountSelect: 'SubAccountSelectCell',
  CostCenterSelect: 'CostCenterSelectCell',
  BaseSelect: 'BaseSelectCell',
  FiscalYearSelect: 'FiscalYearSelectCell',
  PeriodSelect: 'PeriodSelectCell',
  DatePicker: 'DatePickerCell',
  GlobalResourceSelect: 'GlobalResourceCell',
  LineItemSelect: 'LineItemCell',
  Numeric: 'NumericCell',
  Html: 'HtmlCell',
  SpecialCodeType: 'SpecialCodeTypeCell',
  SpecialCode: 'SpecialCodeCell',
  Date: 'DateCell',
  Boolean: 'BooleanCell',
  Address: 'AddressCell',
  AddressSelect: 'AddressSelectCell',
  ServiceBillingCodeSelect: 'ServiceBillingCodeCell',
  ColorPicker: 'ColorPickerCell',
  Status: 'StatusCell',
}
