<template>
  <div>
    <BaseFormDialog
      v-bind="$attrs"
      v-on="$listeners"
      size="2xl"
      :appendToBody="true"
    >
      <template #title>
        <div class="flex space-x-2 justify-center w-full mb-2">
          <EmployeeLink :id="timesheet.employee_id" link-class="text-lg font-medium"/>
          <span>{{ $t('Timesheet') }}</span>
          <span>{{ $formatDate(timesheet.date, 'dd MMM yyyy') }}</span>
          <TimesheetStatus :params="statusParams"/>
        </div>
      </template>
      <BaseForm layout="modal">
        <div
          :class="{
            'col-span-3': timesheet.clocked_out_at,
            'col-span-6': !timesheet.clocked_out_at,
          }"
          class="border border-gray-200 rounded-xl p-6"
        >
          <div class="flex space-x-4 items-center mb-2">
            <h4 class="pl-1 form-section-title">
              {{ $t('Timesheet Entries') }}
            </h4>
            <TimesheetTags :row="timesheet"/>
          </div>
          <TimesheetEntryList
            :entries="entries"
            :loading="loading"
            class="min-h-[500px]"
          />
        </div>
        <TimesheetClockOutDetails :timesheet="timesheet"/>
        <template #extra-buttons-left>
          <TimesheetStatus :params="statusParams" class="text-base !py-4 !px-5"/>
        </template>
        <template #extra-buttons-left>
          <div class="flex-1 flex space-x-4">
            <BaseButton
              v-if="showPrevButton"
              :disabled="!hasMoreTimesheets"
              variant="gray-light"
              @click="$emit('prev')">
              <ChevronLeftIcon class="w-4 h-4 ml-2"/>
              <span>{{ $t('Previous Timesheet') }}</span>
            </BaseButton>
            <BaseButton
              v-if="showNextButton"
              :disabled="!hasMoreTimesheets"
              variant="gray-light"
              @click="$emit('next')">
              <span>{{ $t('Next Timesheet') }}</span>
              <ChevronRightIcon class="w-4 h-4 ml-2"/>
            </BaseButton>
          </div>
        </template>
        <template #footer>
          <TimesheetActions :timesheet="timesheet"/>
        </template>
      </BaseForm>
    </BaseFormDialog>
  </div>
</template>
<script>
import axios from "axios";
import TimesheetEntry from "./TimesheetEntry.vue";
import TableOverlayLoading from "@/components/ag-grid/TableOverlayLoading.vue";
import sumBy from "lodash/sumBy";
import { minutesToHours } from "../../utils/timeCardUtils";
import TimesheetStatus from "@/components/table/cells/TimesheetStatus.vue";
import { CheckIcon, ChevronLeftIcon, ChevronRightIcon, XIcon } from "vue-feather-icons";
import { questionTypes } from "@/modules/payroll/utils/payrollUtils";
import orderBy from "lodash/orderBy";
import { dateTypes } from "@/plugins/dateFormatPlugin";
import parse from "date-fns/parse";
import TimesheetTags from "@/modules/payroll/components/timesheets/TimesheetTags.vue";
import TimesheetEntryList from "@/modules/payroll/components/timesheets/TimesheetEntryList.vue";
import TimesheetActions from "@/modules/payroll/components/timesheets/TimesheetActions.vue";
import TimesheetClockOutDetails from "@/modules/payroll/components/timesheets/TimesheetClockOutDetails.vue";

export default {
  components: {
    TimesheetClockOutDetails,
    TimesheetActions,
    TimesheetEntryList,
    TimesheetTags,
    TimesheetStatus,
    TableOverlayLoading,
    TimesheetEntry,
    CheckIcon,
    XIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  props: {
    timesheet: {
      type: Object,
      default: () => ({}),
    },
    hasMoreTimesheets: {
      type: Boolean,
      default: true,
    },
    showNextButton: {
      type: Boolean,
      default: false,
    },
    showPrevButton: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      loading: false,
      entries: [],
      questionTypes
    }
  },
  computed: {
    dateTypes() {
      return dateTypes
    },
    statusParams() {
      return {
        data: {
          attributes: this.timesheet
        }
      }
    },
  },
  methods: {
    minutesToHours,
    async getEntries() {
      try {
        if (!this.timesheet?.id) {
          return
        }
        this.loading = true
        const { data } = await axios.get(`/restify/timesheet-entries`, {
          params: {
            timesheet_id: this.timesheet?.id,
            related: 'addlSource',
          }
        })
        this.entries = orderBy(data, 'attributes.start_time', 'asc')
      } finally {
        this.loading = false
      }
    },
  },
  watch: {
    timesheet: {
      immediate: true,
      handler() {
        this.getEntries()
      }
    }
  }
}
</script>
