<template>
  <BaseForm
    :show-back="true"
    :show-cancel="true"
    :loading="loading"
    layout="vertical"
    grid-classes="grid grid-cols-12 gap-x-3"
    @cancel="$router.push('/settings/banks')"
    @submit="onSubmit"
  >
    <BaseSelect
      v-model="model.positive_pay_format"
      :options="positivePayFormats"
      :name="$t('Pay Format')"
      :placeholder="$t('Pay Format')"
      :label="$t('Format')"
      :rules="model.is_for_positive_pay ? 'required' : ''"
      id="positive_pay_format"
      class="col-span-12 md:col-span-3"
    />
    <BaseInput
      v-model="model.routing_number"
      :name="$t('Routing Number')"
      :placeholder="$t('Routing Number')"
      :label="$t('Routing Number')"
      :rules="model.is_for_positive_pay ? 'required|routingNumber' : ''"
      id="routing_number"
      class="col-span-12 md:col-span-3"
    />
    <BaseInput
      v-model="model.account_number"
      :name="$t('Account Number')"
      :placeholder="$t('Account Number')"
      :label="$t('Account Number')"
      :rules="model.is_for_positive_pay ? 'required' : ''"
      id="account_number"
      class="col-span-12 md:col-span-3"
    />
    <div class="col-span-12">
      <BaseCheckbox
        v-model="model.is_for_positive_pay"
        :tip="$t('Is this bank account for Positive Pay?')"
        :label="$t('Is this bank account for Positive Pay?')"
        @change="onPositiveStatePayChange"
        id="is_for_positive_pay"
      />
    </div>
    <div class="col-span-12">
      <BaseCheckbox
        v-model="model.is_for_micr"
        :tip="$t('Print the MICR on checks')"
        :label="$t('Print MICR on Checks')"
        :disabled="model.is_for_positive_pay"
        id="is_for_micr"
      />
    </div>
    <div class="col-span-12">
      <BaseCheckbox
        v-model="model.print_company_info"
        :tip="$t('Print company information in the check stubs')"
        :label="$t('Print Company Info')"
        id="print_company_info"
      />
    </div>
    <div class="col-span-12">
      <BaseCheckbox
        v-model="model.print_company_logo"
        :tip="$t('Print company logo in the check stubs')"
        :label="$t('Print Company Logo')"
        id="print_company_logo"
      />
    </div>
    <div class="col-span-12">
      <BaseCheckbox
        v-model="model.print_bank_info"
        :label="$t('Print Bank Info')"
        id="print_bank_info"
      />
    </div>
    <div class="col-span-12">
      <BaseCheckbox
        v-model="model.print_check_number"
        :tip="$t('Display check number in the check stub')"
        :label="$t('Print Check Number')"
        id="print_check_number"
      />
    </div>
  </BaseForm>
</template>
<script lang="ts">
import axios from 'axios'
import { defineComponent, PropType } from 'vue'
import { Bank, BankAccountType } from '@/modules/common/types/models'

export default defineComponent({
  props: {
    data: {
      type: Object as PropType<Bank>,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      statusOptions: [
        {
          label: this.$t('Active'),
          value: true,
        },
        {
          label: this.$t('Inactive'),
          value: false,
        },
      ],
      loading: false as boolean,
      positivePayFormats: [
        {
          label: this.$t('ARP'),
          value: 'arp',
        },
        {
          label: this.$t('Bank of America'),
          value: 'bofa',
        },
        {
          label: this.$t('CIT SIF'),
          value: 'citsif',
        },
        {
          label: this.$t('CRS'),
          value: 'crs',
        },
        {
          label: this.$t('CSV'),
          value: 'csv',
        },
        {
          label: this.$t('FFB'),
          value: 'ffb',
        },
        {
          label: this.$t('JP Morgan Chase'),
          value: 'jpm',
        },
        {
          label: this.$t('MICASH'),
          value: 'micash',
        },
        {
          label: this.$t('NACHA'),
          value: 'nacha',
        },
        {
          label: this.$t('NACHA2'),
          value: 'nacha2',
        },
        {
          label: this.$t('Silicon Valley Bank'),
          value: 'svb',
        },
        {
          label: this.$t('Washington'),
          value: 'wa',
        },
        {
          label: this.$t('Wells Fargo CSV'),
          value: 'wf',
        },
      ],
      model: {
        name: '',
        account: '',
        subaccount: '',
        phone: '',
        fax: '',
        email: '',
        last_ap_check_no: 0,
        last_ar_check_no: 0,
        last_pr_check_no: 0,
        last_pr_dd_no: 0,
        last_ap_dd_no: 0,
        account_type: 'checking' as BankAccountType,
        routing_number: null,
        account_number: null,
        is_for_dd: true,
        is_for_micr: false,
        is_for_positive_pay: false,
        print_bank_info: false,
        print_check_number: false,
        print_company_info: false,
        print_company_logo: false,
        positive_pay_format: 'csv',
      } as Bank,
    }
  },
  methods: {
    async onSubmit() {
      try {
        this.loading = true
        const data = {
          ...this.model,
          routing_number: this.model.routing_number || undefined,
          account_number: this.model.account_number || undefined,
        }
        if (this.model.id) {
          await axios.patch(`/restify/banks/${ this.model.id }`, data)
        } else {
          await axios.post('/restify/banks', this.model)
        }
        this.$success(this.$t('Bank Positive Pay updated'))
        await this.$store.dispatch('settings/getBank', this.data.id)
      } catch (err: any) {
        if (err.handled) {
          return
        }
        console.warn(err)
        this.$error(this.$t('Could not setup the Bank Positive Pay'))
      } finally {
        this.loading = false
      }
    },
    onPositiveStatePayChange(state: any) {
      this.model.is_for_positive_pay = !!state
      if (!state) {
        return
      }
      this.model.is_for_micr = state
    }
  },
  watch: {
    'data.attributes': {
      immediate: true,
      handler(value: Bank) {
        if (!value?.id) {
          return
        }

        this.model = {
          ...value,
        }
      },
    },
  },
})
</script>
