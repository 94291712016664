<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      :has-summary="false"
      :data-loading="dataLoading"
      :skipRowRenderBeforeHtmlRow="true"
      :row-classes="rowClasses"
      class="no-borders certified-payroll"
      @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template
        v-if="!dataLoading"
        #thead-infos
    >
      <th colspan="2"/>
      <th colspan="7" align="center"
          class="text-center bg-gray-50 text-xs leading-4 font-semibold text-gray-600 tracking-wider">
        <span class="flex w-full justify-center border-b-2 border-dashed pb-2">
          {{ $t('Hours Worked Each Day') }}
        </span>
      </th>
      <th colspan="6"/>
    </template>

    <template #header="{row}">
      <div class="flex">
        <JobLink
            v-if="sortByJob"
            :data="row.header"
            :show-preview="false"
        />
        <WorkOrderLink
            v-else
            :data="row.header"
            :show-preview="false"
        />
        <span class="ml-20">
          {{ $t('Period End:') }}
          {{ $formatDate(row.header.period_end_date) }}
        </span>
      </div>
    </template>

    <template #craft_code.code="{row}">
      <div class="truncate">
        <router-link
            v-if="row.craft_code?.id"
            :to="`/payroll/codes/craft-codes?id=${row.craft_code.id}`"
            class="truncate"
        >
          {{ row.craft_code.description }}
        </router-link>
        <span
          v-else-if="row.missing_craft_code || !row.craft_code?.id && !row.other_pay"
          class="text-red-500 ml-2">
        {{ $t('Missing Craft Code') }}
       </span>
        <div
            v-if="row.sub_trade"
            :title="row.sub_trade?.description"
            class="truncate !text-xs font-medium">
          {{ row.sub_trade.description }}
        </div>
      </div>
    </template>

    <template #html-row="{ htmlData }">
      <tr>
        <td colspan="15">
          <CertifiedPayrollReportRow :data="htmlData"/>
        </td>
      </tr>
    </template>

    <template #subtotal="{subtotal}">
      <td class="column-name">{{ getTotalLabel }}</td>

      <td>
        <dl>
          <dt class="h-12 mb-2">&nbsp;</dt>
          <dt v-for="{pay_code} in subtotal.pay_codes">
            {{ pay_code.abbr }}
          </dt>
          <dt>&nbsp;</dt>
          <dt>&nbsp;</dt>
        </dl>
      </td>

      <td v-for="i in 7" align="center">
        <dl>
          <dt v-html="getDayColumnLabel(i - 1, subtotal.days)"
              class="text-center column-name mb-4"
          />
          <dt v-for="{hours} in subtotal.pay_codes">
            {{ hours[i - 1] }}
          </dt>
          <dt class="mb-2">&nbsp;&nbsp;</dt>
          <dt class="pt-3 column-name" v-if="i === 7">{{ $t('Totals:') }}</dt>
          <dt class="pt-3" v-else>&nbsp;</dt>
        </dl>
      </td>

      <td align="right">
        <dl>
          <dt v-html="$t('Total') + '<br>' + $t('Hours')"
              class="text-right column-name mb-4"/>
          <dt v-for="{total_hours} in subtotal.pay_codes">
            {{ total_hours }}
          </dt>
          <dt class="mb-2">&nbsp;</dt>
          <dt class="top-border pt-3">
            {{ subtotal.total_hours }}
          </dt>
        </dl>
      </td>

      <td colspan="2"/>

      <td align="right">
        <dl>
          <dt v-for="i in (subtotal.pay_codes?.length + 2)">&nbsp;</dt>
          <dt class="mt-3 column-name">{{ $t('Other') }}</dt>
        </dl>
      </td>

      <td align="right">
        <dl>
          <dt v-html="$t('Total Pay') + '<br>' + $t('Base / Other')"
              class="text-right column-name mb-4"/>
          <dt v-for="{total_amount} in subtotal.pay_codes">
            {{ $formatPrice(total_amount) }}
          </dt>
          <dt class="mb-2">&nbsp;{{ $formatPrice(subtotal.other_direct_pay) }}</dt>
          <dt class="top-border pt-3">
            {{ $formatPrice(subtotal.total_amount) }}
          </dt>
        </dl>
      </td>

      <td align="right">
        <dl>
          <dt v-html="$t('Total Pay') + '<br>' + $t('Fringe')"
              class="text-right column-name mb-4"/>
          <dt v-for="{fringe_amount} in subtotal.pay_codes">
            {{ $formatPrice(fringe_amount) }}
          </dt>
          <dt class="mb-2">&nbsp;{{ $formatPrice(0) }}</dt>
          <dt class="top-border pt-3">
            {{ $formatPrice(subtotal.fringe_paid_to_plans) }}
          </dt>
        </dl>
      </td>
    </template>

  </base-data-table>
</template>
<script>
import { certifiedPayloadFormats } from '@/modules/payroll/components/reports/util'
import CertifiedPayrollReportRow from '@/modules/payroll/components/reports/CertifiedPayrollReportRow'
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'

export default {
  components: {
    CertifiedPayrollReportRow,
  },
  extends: ReportTableWrapper,
  props: {
    sortBy: {
      required: true,
      type: String,
      default: certifiedPayloadFormats.Job,
    },
    dataLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sortByJob() {
      return this.sortBy === certifiedPayloadFormats.Job
    },
    columns() {
      return [
        {
          label: this.sortByJob ? this.$t('Job Classification <br> Employee Data') : this.$t('Work Order Classification <br> Employee Data'),
          prop: 'craft_code.code',
          minWidth: 160,
          maxWidth: 200,
        },
        {
          prop: 'pay_code.abbr',
          component: 'EntityLink',
          entityKey: 'pay_code.id',
          redirectTo: '/payroll/codes/pay-codes?id={ID}',
          minWidth: 60,
          maxWidth: 60,
        },
        ...this.dayColumns,
        {
          label: this.$t('Total <br> Hours'),
          prop: 'total_hours',
          align: 'right',
          minWidth: 80,
          maxWidth: 100,
        },
        {
          label: this.$t('Base <br> Rate'),
          prop: 'base_rate',
          align: 'center',
          minWidth: 90,
          maxWidth: 120,
        },
        {
          label: this.$t('Fringe <br> Rate'),
          prop: 'fringe_rate',
          align: 'center',
          minWidth: 80,
          maxWidth: 100,
        },
        {
          label: this.$t('Total Hourly <br> Rate'),
          prop: 'total_rate',
          align: 'center',
          minWidth: 80,
          maxWidth: 100,
        },
        {
          label: this.$t('Total Pay <br> Base / Other'),
          prop: 'total_amount',
          component: 'FormattedPrice',
          align: 'right',
          minWidth: 80,
          maxWidth: 100,
        },
        {
          label: this.$t('Total Pay <br> Fringe'),
          prop: 'fringe_amount',
          component: 'FormattedPrice',
          align: 'right',
          minWidth: 80,
          maxWidth: 100,
        },
      ]
    },
    dayColumns() {
      const days = this.get(this.data, 'items[0].days', [])

      return days.map((day, index) => {
        return {
          label: `${day.name} <br> ${day.day_number}`,
          prop: `hours[${index}]`,
          align: 'center',
          minWidth: 60,
          maxWidth: 80,
        }
      })
    },
    getTotalLabel() {
      return this.sortByJob ? this.$t('Job Totals') : this.$t('Work Order Totals')
    },
  },
  methods: {
    rowClasses(row) {
      if (row?.pay_code) {
        return 'has-background'
      }
      return ''
    },
    composeRows(data) {

      data.forEach(group => {

        this.rows.push({
          header: group.source,
        })

        group.employees?.forEach(_group => {

          _group.craft_codes?.forEach(craftCode => {

            craftCode.pay_codes?.forEach((payCode) => {
              this.rows.push({
                craft_code: craftCode.craft_code,
                sub_trade: craftCode.sub_trade,
                ...payCode,
              })
              if (craftCode?.other_pay?.amount) {
                this.rows.push({
                  total_rate: this.$t('Other'),
                  total_amount: craftCode?.other_pay?.amount,
                  fringe_amount: craftCode?.other_pay?.fringe_amount,
                  other_pay: true,
                })
              }
            })

            this.rows.push({
              htmlData: {
                ..._group,
                craft_code: craftCode,
              },
            })

          })
          if (_group.craft_codes?.length === 0) {
            this.rows.push({
              craft_code: null,
              sub_trade: null,
              missing_craft_code: true,
            })
            this.rows.push({
              htmlData: {
                ..._group,
                craft_code: null,
              },
            })

          }


        })

        this.rows.push({
          subtotal: {
            ...group.totals,
            days: group.days,
          },
        })
      })

      return this.rows
    },
    getDayColumnLabel(index, days) {
      return `${days[index]?.name + '<br>' + days[index]?.day_number}`
    },
  },
}
</script>
<style lang="scss">
.column-name {
  @apply leading-tight tracking-wide font-medium text-gray-700;
}

.certified-payroll {
  .subtotal-row {
    vertical-align: baseline !important;
  }
}

</style>
