<template>
  <BaseFormDialog v-bind="$attrs"
                  v-on="$listeners"
                  :title="$t(`Preview checks for payroll ${payrollEndDate}`)"
                  :appendToBody="true"
  >
    <base-form :save-text="$t('Preview Checks')"
               :loading="loading"
               @cancel="$emit('cancel')"
               layout="modal"
               @submit="previewChecks"
    >
      <div class="col-span-6 md:col-span-3">
        <base-date-picker v-model="model.payment_date"
                          :label="$t('Payment Date')"
                          :name="$t('Payment Date')"
                          id="date"
                          rules="required"
        />
      </div>
      <div class="col-span-6 md:col-span-3">
        <bank-select v-model="model.bank_id"
                     :used-for="BankUsedInTypes.Payroll"
                     :label="$t('Bank')"
                     :name="$t('Bank')"
                     id="bank"
                     rules="required"
                     @entity-change="onBankChange"
        />
      </div>
      <div class="col-span-6 md:col-span-3">
        <base-input v-model="model.payment_start_number"
                    :min="0"
                    :label="$t('Starting Check Number')"
                    :tip="$t('When previewed check(s) will start with the provided number below')"
                    type="number"
                    id="payment_start_number"
                    rules="required"
        >
        </base-input>
      </div>
      <div class="col-span-6 md:col-span-3">
        <base-select v-model="model.template"
                     :label="$t('Template')"
                     :options="templateOptions"
                     id="template"
        />
      </div>
      <div class="col-span-6">
        <base-checkbox
            id="print_checks"
            v-model="model.print_checks"
            :label="$t('Print Checks')"
        />
      </div>
      <div class="col-span-6">
        <base-checkbox
            id="print_dds"
            v-model="model.print_dds"
            :label="$t('Print Direct Deposits')"
        >
          <template #label>
            {{$t('Print Direct Deposits')}}
            <template v-if="model.print_dds">
              {{ $t(' (these will be printed after the checks.)') }}
            </template>
          </template>
        </base-checkbox>
      </div>
      <div class="col-span-6">
        <BaseCheckbox
          id="include_signature"
          v-model="model.include_signature"
        >
          <template #label>
            {{ $t('Print Signature') }}
            <template v-if="model.include_signature">
              {{ $t(' (if the bank has a signature, the signature will be included on each check)') }}
            </template>
          </template>
        </BaseCheckbox>
      </div>
      <StimulsoftPrintButton
        ref="stimulsoftPrint"
        :url="`/restify/payroll-batches/${batch.id}/stimulsoft`"
        :url-params="printParams"
        :hide-button="true"
        :transform-data="transformStimulsoftData"
        entity="payroll-batch"
      />
    </base-form>
  </BaseFormDialog>
</template>
<script>
  import { previewFileInNewTab } from "@/modules/common/util/downloadFileLocally";
  import axios from "axios";
  import { BankUsedInTypes } from "@/enum/enums";
  import { filterPayrollsForPrint } from "@/modules/payroll/utils/payrollUtils";
  const CheckTemplates = {
    Box: 'Box',
    Detailed: 'Detailed',
    Summary: 'Summary',
  }

  export default {
    props: {
      batch: {
        type: Object,
        default: () => ({}),
      }
    },
    data() {
      return {
        loading: false,
        model: {
          bank_id: null,
          payment_date: this.$now,
          payment_start_number: null,
          template: CheckTemplates.Detailed,
          print_checks: true,
          print_dds: true,
          include_signature: false,
        },
        templateOptions: [
          {
            label: 'Summary',
            value: CheckTemplates.Summary
          },
          {
            label: 'Detailed',
            value: CheckTemplates.Detailed
          },
          {
            label: 'Box (old)',
            value: CheckTemplates.Box
          },
        ]
      }
    },
    computed: {
      BankUsedInTypes() {
        return BankUsedInTypes
      },
      payrollEndDate() {
        return this.$formatDate(this.batch?.attributes?.period_end_date)
      },
      printParams() {
        return {
          print_checks: Number(this.model.print_checks),
          print_dds: Number(this.model.print_dds),
          include_signature: Number(this.model.include_signature),
          hide_payroll_items: Number(this.model.template === CheckTemplates.Summary),
        }
      },
    },
    methods: {
      transformStimulsoftData(data) {
        if (this.model.template === CheckTemplates.Summary) {
          data.template_settings.hide_payroll_items = true
        } else if (this.model.template === CheckTemplates.Detailed) {
          data.template_settings.hide_payroll_items = false
        }
        return filterPayrollsForPrint(data, this.model)
      },
      onBankChange(bank) {
        this.model.payment_start_number = bank.attributes.last_pr_check_no + 1
      },
      async previewDetailedChecks() {
        if (!this.$refs.stimulsoftPrint) {
          this.$info(this.$t('The PDF preview module is still initializing... Please wait a few more seconds and try again.'))
          return
        }
        try {
          this.loading = true
          await this.$refs.stimulsoftPrint?.triggerPreview()
        } finally {
          this.loading = false
        }
      },
      async previewChecks() {
        if (this.model.template !== CheckTemplates.Box) {
          await this.previewDetailedChecks()
          return
        }
        try {
          this.$info('Generating preview... Please wait as this may take a while.')
          this.loading = true
          const url = `/restify/payroll-batches/${this.batch.id}/actions?action=generate-checks-preview`
          const data = {
            payment_date: this.model.payment_date,
            payment_start_number: this.model.payment_start_number?.toString(),
            print_checks: this.model.print_checks,
            print_dds: this.model.print_dds,
            include_signature: this.model.include_signature,
          }
          const params = {
            responseType: 'blob'
          }
          const response = await axios.post(url, data, params)

          previewFileInNewTab(response)
          this.$success(this.$t('PDF preview generated successfully'))
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('An error occurred while generating the PDF preview'))
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
