<template>
  <div>
    <AgDataTable
        v-bind="editableTableProps"
        :columns="columns"
        :url="url"
        :url-params="urlParams"
        :add-text="$t('New entry')"
        :transform-data="mapData"
        :get-empty-row="getEmptyEntry"
        :default-filters="false"
        :readOnly="readOnly"
        :show-cells-legend="!readOnly"
        :groupIncludeTotalFooter="true"
        :no-borders="readOnly"
        :actions="tableActions"
        suppressColumnReordering
        ref="gridTable"
        id="gridTable"
        @cell-focused="onCellFocused"
        @grid-ready="grid = $event"
    >
      <template #header-info>
        <div v-if="showMaterialSummary"
             class="form-header-summary flex">
          <div class="summary">
            {{ $t('Last P.O. unit rate:') }}
            <span>
            {{ $formatPrice(selectedMaterial.last_po_unit_rate) }}
        </span>
          </div>
          <div class="summary">
            {{ $t('Last invoice unit rate:') }}
            <span>
            {{ $formatPrice(selectedMaterial.last_invoice_unit_rate) }}
        </span>
          </div>
          <div class="summary">
            {{ $t('Standard unit rate:') }}
            <span>
            {{ $formatPrice(selectedMaterial.standard_unit_rate) }}
        </span>
          </div>
        </div>
      </template>
    </AgDataTable>
  </div>
</template>
<script>
  import i18n from '@/i18n'
  import { laborHourTypes } from '@/enum/enums'
  import { globalResources } from '@/components/form/util'
  import { getCellClasses, requiredValueSetter } from '@/components/ag-grid/columnUtils'
  import { editableTableProps, getRowData, getTableData, getTableNodes } from '@/components/ag-grid/tableUtils'
  import { costCenterTypes, sourceTypeMap, typeTypes } from '@/components/grid-table/utils/cost-center'
  import {
    additionalSourceTypeMap, laborHourTypeOptions,
    sbServiceCodeMorphKey,
    sourceTypeAbbrMap,
    sourceTypes
  } from '@/modules/service-billing/util/service-billing'
  import {
    additionalSourceCol,
    descriptionCol,
    updateColumnHeader,
  } from '@/components/ag-grid/columns/costCenterColumns'
  import axios from 'axios'
  import { getDeleteColumn } from "@/components/ag-grid/columns/deleteColumns";
  import { computeBillingEntrySalesTax } from "@/modules/accounts-receivable/utils/billingUtils";

  const additionalSourceMap = {
    [sourceTypes.Optional]: ' ',
    [sourceTypes.Equipment]: globalResources.Equipments,
    [sourceTypes.Labor]: globalResources.CraftCodes,
    [sourceTypes.Material]: globalResources.Materials,
    default: ' ',
  }

  const sourceCellTitles = {
    [sourceTypes.Optional]: '',
    [sourceTypes.Equipment]: 'Equipment',
    [sourceTypes.Labor]: 'Craft Code',
    [sourceTypes.Material]: 'Material',
    default: '',
  }

  const ResourceEndpoint = '/restify/billing-entries'

  export default {
    name: 'ServiceInvoiceEntries',
    props: {
      invoice: {
        type: Object,
        default: () => ({}),
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      workOrderServiceBillingCodeIds: Array,
      customerBillingRateTypeId: String,
    },
    data() {
      return {
        editableTableProps,
        grid: null,
        selectedRow: null,
        defaultEquipmentUM: this.$t('Hour'),
      }
    },
    computed: {
      tableActions() {
        return this.readOnly ? '' : 'add'
      },
      columns() {
        return [
          {
            headerName: this.$t('Service Code'),
            field: 'special_source_id',
            component: 'ServiceCodeLink',
            cellRendererParams: {
              showDescription: !this.readOnly,
            },
            cellEditor: this.$cellEditors.ServiceBillingCodeSelect,
            cellEditorParams: {
              target: '_blank',
              serviceBillingCodeIds: this.workOrderServiceBillingCodeIds,
            },
            editable: true,
            minWidth: this.readOnly ? 80 : 150,
            maxWidth: this.readOnly ? 100 : 250,
          },
          {
            headerName: this.$t('Type'),
            field: 'type_id',
            cellEditor: this.$cellEditors.GlobalResourceSelect,
            cellEditorParams: {
              resourceName: this.$globalResources.ServiceBillingIncomeTypes,
            },
            valueFormatter: (params) => {
              const op = this.serviceBillingIncomeTypes.find(o => o.id === params.value)
              return op ? (op.name || op.abbr) : ''
            },
            valueSetter: params => {
              if (!params.newValue) {
                return false
              }

              this.onChangeType(params)
              return true
            },
            cellClass: getCellClasses,
            editable: true,
            minWidth: 80,
            maxWidth: 120,
          },
          {
            headerName: ' ',
            field: 'meta.labor_type',
            component: undefined,
            cellEditor: this.$cellEditors.BaseSelect,
            cellEditorParams: {
              options: laborHourTypeOptions,
            },
            valueFormatter: (params) => {
              if (this.isLaborType(params)) {
                return params.value
              }

              const op = laborHourTypeOptions.find(o => o.value === params.value)
              if (this.readOnly && !op?.value) {
                return ''
              }
              return op ? op.label : ''
            },
            cellClass: params => {
              if (params.node.footer || this.readOnly) {
                return 'ag-cell-value'
              }

              return this.isNotLaborType(params) ? 'readonly-ag-cell' : ''
            },
            editable: params => !this.isNotLaborType(params),
            suppressNavigable: params => this.isNotLaborType(params),
            minWidth: 60,
            maxWidth: 100,
          },
          {
            ...additionalSourceCol(),
            headerName: ' ',
            component: undefined,
            cellClass: params => {
              if (params.node.footer || this.readOnly) {
                return ''
              }

              return this.hasAdditionalSource(params) ? 'readonly-ag-cell' : ''
            },
            editable: params => !this.hasAdditionalSource(params),
            suppressNavigable: params => this.hasAdditionalSource(params),
            cellEditorParams: params => {
              const { addl_source_type } = params.data
              const resourceName = additionalSourceMap[addl_source_type]

              return {
                resourceName,
                unauthorizedToAdd: false,
              }
            },
            valueFormatter: params => {
              if (!params?.data) {
                return
              }
              const resource = this.findResource(params.data)

              const { code, description } = resource
              if (!code) {
                return ''
              }
              let text = code
              if (description) {
                text = `${code} (${description})`
              }
              return text
            },
            valueSetter: params => {
              if (!params.newValue) {
                return false
              }

              params.data.addl_source_id = params.newValue
              this.onChangeAdditionalSource(params)
              return true
            },
            minWidth: 200,
            maxWidth: 250,
          },
          {
            headerName: this.$t('UM'),
            field: 'um',
            editable: true,
            minWidth: 80,
            maxWidth: 100,
          },
          {
            ...descriptionCol,
          },
          {
            headerName: this.$t('Qty'),
            field: 'quantity',
            cellEditor: this.$cellEditors.Numeric,
            valueSetter: params => {
              const isValid = requiredValueSetter(params, 0, 0)

              if (!isValid) {
                return false
              }

              this.computeGrossAmount(params)
              return true
            },
            editable: true,
            minWidth: 50,
            maxWidth: 60,
          },
          {
            headerName: this.$t('Unit Price'),
            field: 'unit_rate',
            component: 'FormattedPrice',
            cellEditor: this.$cellEditors.Numeric,
            valueSetter: params => {
              const isValid = requiredValueSetter(params, 0, 0)

              if (!isValid) {
                return false
              }

              this.computeGrossAmount(params)
              this.computeSalesTaxAmount(params)
              return true
            },
            editable: true,
            minWidth: 80,
            maxWidth: 150,
          },
          {
            headerName: this.$t('Taxable'),
            field: 'meta.subject_to_tax',
            component: 'Status',
            cellEditor: this.$cellEditors.Boolean,
            cellEditorParams: {
              plain: true,
            },
            align: 'center',
            valueSetter: params => {
              params.data.meta.subject_to_tax = params.newValue
              this.computeSalesTaxAmount(params)
              return true
            },
            editable: true,
            minWidth: 60,
            maxWidth: 90,
          },
          {
            headerName: this.$t('Extended Amount'),
            field: 'gross_amount',
            cellEditor: this.$cellEditors.Numeric,
            component: 'FormattedPrice',
            valueSetter: params => {
              const isValid = requiredValueSetter(params, 0, 0)

              if (!isValid) {
                return false
              }

              this.computeUnitRate(params)
              this.computeSalesTaxAmount(params)
              return true
            },
            suppressKeyboardEvent: params => {
              let isTabKey = params.event.key === 'Tab'
              if (isTabKey) {
                params.api.stopEditing()
              }
            },
            editable: true,
            minWidth: 90,
            maxWidth: 140,
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Sales Tax'),
            field: 'sales_tax_amount',
            cellEditor: this.$cellEditors.Numeric,
            component: 'FormattedPrice',
            cellClass: 'readonly-ag-cell',
            aggFunc: 'sum',
            minWidth: 90,
            maxWidth: 140,
          },
          {
            ...getDeleteColumn({
              url: '/restify/billing-entries',
              hide: this.readOnly,
            }),
          },
        ]
      },
      url() {
        return !this.invoice.id ? '' : ResourceEndpoint
      },
      urlParams() {
        return !this.invoice.id ? {} : {
          sort: 'order',
          perPage: 500,
          billing_id: this.invoice.id,
        }
      },
      showMaterialSummary() {
        // TODO handle this separately based on INV settings. We should display it only if should_reduce_for_service_billing_non_history is true
        return false
        const { addl_source_type, addl_source_id } = this.selectedRow || {}
        return addl_source_type === sourceTypes.Material && addl_source_id
      },
      selectedMaterial() {
        const { addl_source_id } = this.selectedRow
        return this.$store.getters['globalLists/getResourceById'](this.$globalResources.Materials, addl_source_id)
      },
      getLaborTypeIdByModule() {
        return this.$store.getters['globalLists/getLaborTypeIdByModule'](this.$globalResources.ServiceBillingIncomeTypes)
      },
      serviceBillingIncomeTypes() {
        return this.$store.getters['globalLists/getResourceOptions'](this.$globalResources.ServiceBillingIncomeTypes)
      },
    },
    methods: {
      hasAdditionalSource(params) {
        const { line_type } = this.serviceBillingIncomeTypes.find(item => item.id === params.data?.type_id) || { line_type: false }
        return !line_type
      },
      isNotLaborType(params) {
        return params.data?.addl_source_type !== sourceTypes.Labor
      },
      onCellFocused(params) {
        const row = getRowData(params)
        const sourceCol = params.columnApi.getColumn('addl_source_id')?.colDef
        const laborTypeCol = params.columnApi.getColumn('meta.labor_type')?.colDef
        const addl_source_type = row?.addl_source_type

        const laborColTitle = addl_source_type === sourceTypes.Labor ? this.$t('Labor Type') : this.$t('')
        const title = sourceCellTitles[addl_source_type] || sourceCellTitles.default

        updateColumnHeader(sourceCol, title)
        updateColumnHeader(laborTypeCol, laborColTitle)
        params.api.refreshHeader()

        this.selectedRow = row
      },
      findResource({ addl_source_id, addl_source_type }) {
        if (addl_source_type === sourceTypes.Optional) {
          return {}
        }
        const resourceName = additionalSourceMap[addl_source_type]
        if (!resourceName) {
          return {}
        }
        const resourceList = this.$store.getters['globalLists/getResourceList'](resourceName)
        return resourceList.find(resource => resource.id === addl_source_id) || {}
      },
      getMaterialResource(id) {
        return this.$store.getters['globalLists/getResourceById'](this.$globalResources.Materials, id)
      },
      getEquipmentResource(id) {
        return this.$store.getters['globalLists/getResourceById'](this.$globalResources.Equipments, id)
      },
      getCraftCodeResource(id) {
        return this.$store.getters['globalLists/getResourceById'](this.$globalResources.CraftCodes, id)
      },
      getTableData() {
        const gridApi = this.grid?.api
        return getTableData(gridApi)
      },
      async storeProgress(billing_id) {

        const promises = []
        let entries = this.getTableData()

        entries = entries.map(entry => {
          entry.addl_source_type = entry.addl_source_id ? entry.addl_source_type : null
          entry.special_source_type = entry.special_source_id ? sbServiceCodeMorphKey : null
          return entry
        })

        const entriesToUpdate = entries.filter(entry => entry.id && entry.dirty)

        const entriesToStore = entries.filter(entry => !entry.id && entry.dirty).map(entry => {
          entry.billing_id = billing_id
          entry.source_id = this.invoice.work_order_id
          return entry
        })

        if (entriesToStore.length) {
          promises.push(axios.post('/restify/billing-entries/bulk', entriesToStore))
        }

        if (entriesToUpdate.length) {
          promises.push(axios.post('/restify/billing-entries/bulk/update', entriesToUpdate))
        }

        await Promise.all(promises)
        this.$refs.gridTable.refresh()
      },
      getEntryDefaultSettings(type_id) {
        return this.serviceBillingIncomeTypes.find(type => type.id === type_id)
      },
      onChangeType(params) {
        let currentEntry = params.data

        currentEntry.type_id = params.newValue
        currentEntry.addl_source_id = null
        currentEntry.um = currentEntry.meta.labor_type = null

        const {
          abbr,
          account,
          subaccount,
          is_taxable
        } = this.getEntryDefaultSettings(params.newValue)

        currentEntry.addl_source_type = sourceTypeAbbrMap[abbr]
        currentEntry.account = account
        currentEntry.subaccount = subaccount
        currentEntry.meta.subject_to_tax = is_taxable

        if ([sourceTypes.Equipment, sourceTypes.Labor].includes(currentEntry.addl_source_type)) {
          currentEntry.um = this.defaultEquipmentUM
        }

        if (currentEntry.addl_source_type === sourceTypes.Labor) {
          currentEntry.meta.labor_type = laborHourTypes.Regular
        }

        params.node.setData(currentEntry)
      },
      getTypeAbbrId(id) {
        return this.serviceBillingIncomeTypes.find(o => o.id === id)?.abbr
      },
      onChangeAdditionalSource(params) {
        let currentEntry = params.data

        if (currentEntry.addl_source_type === sourceTypes.Equipment) {
          const equipment = this.getEquipmentResource(currentEntry.addl_source_id)
          currentEntry.description = equipment?.description
          currentEntry.unit_rate = this.tryAssignRegularRate(equipment)
        }

        if (currentEntry.addl_source_type === sourceTypes.Labor) {
          const craftCode = this.getCraftCodeResource(currentEntry.addl_source_id)
          currentEntry.description = craftCode?.description
          currentEntry.unit_rate = this.tryAssignRegularRate(craftCode)
        }

        if (currentEntry.addl_source_type !== sourceTypes.Material) {
          return
        }

        const material = this.getMaterialResource(currentEntry.addl_source_id)
        currentEntry.um = material.um
        currentEntry.description = material.description

        if (!this.customerBillingRateTypeId || !material?.billing_rates?.length) {
          return
        }

        currentEntry.unit_rate = this.tryAssignRegularRate(material)

        params.node.setData(currentEntry)
      },
      // * Billing rate from material related with work order / service invoice billing rate type
      tryAssignRegularRate(source) {
        if (!source?.billing_rates?.length) {
          return 0
        }
        const { regular_rate } = source?.billing_rates.find(rate => rate.billing_rate_type_id === this.customerBillingRateTypeId) || { regular_rate: 0 }
        return regular_rate
      },
      getSourceTypeByTypeId(typeId) {
        const type = this.serviceBillingIncomeTypes.find(type => type.id === typeId)
        const lineType = this.get(type, 'line_type', false)

        return additionalSourceTypeMap[lineType] || additionalSourceTypeMap.default
      },
      mapData(data) {
        return data.map(entry => {
          const addl_source_type = this.getSourceTypeByTypeId(entry.attributes?.type_id)
          return {
            ...entry.attributes,
            addl_source_type,
          }
        })
      },
      getEmptyEntry() {
        this.tryCollapseFormHeader()

        const type_id = this.$settings(this.$modules.SB, 'income_type_id') || null
        let addl_source_type = sourceTypes.Optional

        let account = ''
        let subaccount = ''
        let is_taxable = false

        if (type_id) {
          const type = this.getEntryDefaultSettings(type_id)
          addl_source_type = sourceTypeAbbrMap[type?.abbr]


          is_taxable = type?.is_taxable
          account = type?.account
          subaccount = type?.subaccount
        }

        return {
          cost_center: costCenterTypes.WorkOrder,
          source_id: this.invoice.work_order_id,
          source_type: sourceTypeMap[costCenterTypes.WorkOrder],
          type_id,
          type_type: typeTypes[costCenterTypes.WorkOrder],
          special_source_id: null,
          special_source_type: sbServiceCodeMorphKey,
          addl_source_id: null,
          addl_source_type,
          um: '',
          quantity: 0,
          unit_rate: 0,
          meta: {
            subject_to_tax: is_taxable,
            district_id: this.invoice.district_id,
            labor_type: '',
            exempt_from_sales_tax: false,
          },
          gross_amount: 0,
          sales_tax_percent: this.invoice.sales_tax_percent,
          sales_tax_amount: 0,
          description: '',
          _localId: crypto.randomUUID(),
          account,
          subaccount,
        }
      },
      tryCollapseFormHeader() {
        this.$emit('on-collapse-form-header')
      },
      getEntries() {
        return getTableData(this.grid.api)
      },
      recomputeEntryAmounts() {
        const nodes = getTableNodes(this.grid.api)
        nodes.forEach(node => {
          node.data.dirty = true
          this.computeSalesTaxAmount({ node, data: node.data })
        })
      },
      computeGrossAmount(params) {
        const { quantity, unit_rate } = params.data
        const grossAmount = quantity * unit_rate

        params.data.gross_amount = grossAmount
        params.node.setData(params.data)
      },
      computeUnitRate(params) {
        const { gross_amount, quantity } = params.data

        if (!quantity) {
          params.data.unit_rate = 0
        } else {
          const unitRate = gross_amount / quantity
          params.data.unit_rate = unitRate.toFixed(2)
        }

        params.node.setData(params.data)
      },
      computeSalesTaxAmount(params) {
        const { amount, exempt_from_sales_tax } = computeBillingEntrySalesTax(params.data, this.invoice)
        if (params.data?.meta) {
          params.data.meta.exempt_from_sales_tax = exempt_from_sales_tax
        }
        params.data.sales_tax_amount = amount
        params.node.setData(params.data)
      },
      isLaborType(params) {
        const addl_source_id = this.get(params.data, 'addl_source_id')
        return addl_source_id ? addl_source_id === this.getLaborTypeIdByModule : false
      },
    },
  }
</script>
