<template>

  <base-filter-row :title="$t('Select By')">

    <base-select
      v-model="value.select_by"
      :options="selectByOptions"
      class="col-span-2"
      @change="onChangeSelectionType"
    />

    <date-range-filter
      v-if="showDateRangeFilter"
      v-model="value"
      class="col-span-4"
      startDateRequired
      endDateRequired
    />

    <div
      v-if="showFiscalPeriodsFilter"
      class="col-span-8 flex items-baseline"
    >
      <span class="text-label mr-2">{{ $t('Fiscal Year') }}</span>
      <base-input
        v-model="value.fiscal_year"
        :name="$t('Fiscal Year')"
        :rules="{
            'required': true,
            'min_value': $validator.minFiscalYear,
            'max_value': $validator.maxFiscalYear,
          }"
        class="w-32"
        type="number"
      />

      <span class="text-label ml-4 mr-2">{{ $t('Periods') }}</span>

      <base-filter-range
        :separator="$t('thru')"
        separatorClass="text-label mx-4"
      >

        <template #start>
          <base-input
            v-model.number="value.period_start"
            :name="$t('Period Start')"
            rules="required|min_value:0|max_value:13"
            type="number"
            class="w-24"
          />
        </template>

        <template #end>
          <base-input
            v-model.number="value.period_end"
            :name="$t('Period End')"
            :rules="`required|min_value:${value.period_start}|max_value:13`"
            type="number"
            class="w-24"
          />
        </template>

      </base-filter-range>

    </div>

    <div v-if="showCalendarQuarterFilter" class="col-span-8">

      <base-filter-range
        :separator="$t('thru')"
        separatorClass="text-label mx-4"
      >

        <template #start>
          <div class="flex items-baseline">
            <span class="text-label">{{ $t('Year') }}</span>
            <base-input
              v-model="value.year_start"
              :name="$t('Year Start')"
              :rules="{
                  'required': true,
                  'min_value': $validator.minFiscalYear,
                  'max_value': $validator.maxFiscalYear,
                }"
              class="w-32"
              type="number"
            />

            <span class="text-label mx-2">{{ $t('Qtr') }}</span>
            <base-input
              v-model.number="value.quarter_start"
              :name="$t('Quarter Start')"
              rules="required|min_value:1|max_value:4"
              type="number"
              class="w-24"
            />
          </div>
        </template>

        <template #end>
          <div class="flex items-baseline">
            <span class="text-label">{{ $t('Year') }}</span>
            <base-input
              v-model="value.year_end"
              :name="$t('Year End')"
              :rules="{
                  'required': true,
                  'min_value': value.year_start,
                  'max_value': $validator.maxFiscalYear,
                }"
              class="w-32"
              type="number"
            />

            <span class="text-label ml-4 mr-2">{{ $t('Qtr') }}</span>
            <base-input
              v-model.number="value.quarter_end"
              :name="$t('Quarter End')"
              :rules="`required|min_value:${value.quarter_start}|max_value:4`"
              type="number"
              class="w-24"
            />
          </div>
        </template>

      </base-filter-range>

    </div>

    <div v-if="showBatchFilter" class="col-span-2">
      <PayrollBatchSelect
        v-model="value.batch_id"
        :hide-label="true"
        :placeholder="$t('Select Batch')"
        rules="required"
      />
    </div>

  </base-filter-row>

</template>
<script>
import { DateRangeFilter } from '@/components/range-filters'
import PayrollBatchSelect from '@/components/select/entities/PayrollBatchSelect.vue';
import { dateFields, dateSelections, historyByEmployeeFormats } from '@/modules/payroll/components/reports/util'
import { getQuarter } from "date-fns";

export default {
  components: {
    DateRangeFilter,
  },
  props: {
    value: {
      required: true,
      type: Object,
      default: () => ({}),
    },
    reportFormat: {
      type: String,
      default: historyByEmployeeFormats.PeriodSummary,
    },
    reportNumber: {
      type: [String, Number],
      default: '',
    }
  },
  computed: {
    showDateRangeFilter() {
      return [dateSelections.PeriodEndDate, dateSelections.PaymentDate].includes(this.value.select_by)
    },
    showFiscalPeriodsFilter() {
      return this.value.select_by === dateSelections.FiscalPeriods
    },
    showCalendarQuarterFilter() {
      return this.value.select_by === dateSelections.CalendarQuarter
    },
    showBatchFilter() {
      return this.value.select_by === dateSelections.Batch
    },
    selectByOptions() {
      const options = [
        {
          label: this.$t('Period End Date'),
          value: dateSelections.PeriodEndDate,
        },
        {
          label: this.$t('Payment Date'),
          value: dateSelections.PaymentDate,
        },
        {
          label: this.$t('Calendar Quarter'),
          value: dateSelections.CalendarQuarter,
        },
        {
          label: this.$t('Fiscal Periods'),
          value: dateSelections.FiscalPeriods,
          renderIf: () => this.reportFormat === historyByEmployeeFormats.PeriodSummary,
        },
        {
          label: this.$t('Batch'),
          value: dateSelections.Batch,
          renderIf: () => this.value.hasOwnProperty('batch_id'),
        },
        {
          label: this.$t('All Pay Periods'),
          value: dateSelections.AllPayPeriods,
          renderIf: () => this.reportFormat === historyByEmployeeFormats.PeriodSummary,
        },
      ]

      return options.filter(option => option.renderIf ? option.renderIf() : true)
    },
  },
  methods: {
    async onChangeSelectionType(selectBy) {
      Object.keys(dateFields).forEach(key => {
        this.$set(this.value, key, null)
      })
      const currentFiscalYear = this.$currentFiscalYear
      const currentPeriod = this.$currentPeriod
      const calendarYear = this.$settings(this.$modules.PR, 'calendar_year')
      const currentQuarter = getQuarter(new Date())

      if (selectBy === dateSelections.FiscalPeriods) {
        this.$set(this.value, 'fiscal_year', currentFiscalYear)
        this.$set(this.value, 'period_start', currentPeriod)
        this.$set(this.value, 'period_end', currentPeriod)
      }
      if (selectBy === dateSelections.CalendarQuarter) {
        this.$set(this.value, 'year_start', calendarYear)
        this.$set(this.value, 'year_end', calendarYear)
        this.$set(this.value, 'quarter_start', currentQuarter)
        this.$set(this.value, 'quarter_end', currentQuarter)
      }
    },
  },
}
</script>
