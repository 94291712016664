<template>
  <div>
    <portal to="page-title">
      <div class="flex items-center">
        {{ $t('Journal ') }} #{{ currentResource.attributes.name }}
        <span v-if="currentResource.attributes.status"
              class="mx-1 md:mx-2 flex items-center">
        <status-badge :status="currentResource.attributes.status"/>
      </span>
      </div>
    </portal>
    <div class="flex justify-between items-center">
      <layout-tabs :items="filteredItems"/>
      <ProofListingButton
          v-if="$isAuthorized('authorizedToPost', currentResource)"
          :selected-rows="[currentResource]"
          path="/ledger/journal/proof-listing"
      />
    </div>
    <router-view/>
  </div>
</template>
<script>
  import layoutPermissions from '@/mixins/layoutPermissions'

  export default {
    mixins: [layoutPermissions],
    computed: {
      items() {
        return [
          {
            name: this.$t('Details'),
            path: `/ledger/journal/${this.$route.params.id}/view`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Edit'),
            path: `/ledger/journal/${this.$route.params.id}/edit`,
            authorizedTo: 'authorizedToUpdate',
          },
        ]
      },
      currentResource() {
        return this.$store.state.generalLedger.currentJournal || {}
      },
    },
    methods: {
      async getCurrentResource(id) {
        await this.$store.dispatch('generalLedger/getJournal', id)
      },
    },
  }
</script>
