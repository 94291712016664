<template>
  <entity-select url="/restify/vendors"
                 v-bind="$attrs"
                 v-on="$listeners"
                 :url-params="urlParams"
                 :filter-options="filterOptions"
                 :name="$attrs.name || $t('Vendor')"
                 :placeholder="$attrs.placeholder || $t('Select Vendor')"
                 :label="$attrs.label !== undefined ? $attrs.label : $t('Vendor')"
                 :add-label="$t('Add new Vendor')"
                 :add-entity="addEntity"
                 :edit-entity="editEntity"
                 :set-default-option="editEntity"
                 label-key="code"
                 class="w-full"
                 :id="$attrs.id || 'vendor-select'"
                 :label-format="formatVendorLabel"
                 :table-columns="vendorTableColumns"
  />
</template>
<script>
  export default {
    inheritAttrs: false,
    props: {
      urlParams: {
        type: Object,
        default: () => ({
          sort: 'code'
        })
      },
      excludeIds: {
        type: Array,
        default: () => []
      },
      addEntity: {
        type: Boolean,
        default: true
      },
      editEntity: {
        type: Boolean,
        default: false,
      },
      showOpenAmount: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        vendorTableColumns: [
          {
            minWidth: 100,
            maxWidth: 150,
            name: this.$t('Code'),
            prop: 'attributes.code'
          },
          {
            minWidth: 200,
            maxWidth: 200,
            name: this.$t('Name'),
            prop: 'attributes.name'
          },
          this.showOpenAmount && {
            minWidth: 100,
            maxWidth: 150,
            name: this.$t('Open Amount'),
            prop: 'attributes.current_ap_amount',
            toFormat: this.$formatPrice,
          }
        ],
      }
    },
    methods: {
      filterOptions(options) {
        if (this.excludeIds.length) {
          return options.filter(o => {
            return !this.excludeIds.includes(o.id)
          })
        }
        return options
      },
      formatVendorLabel(account) {
        const { code, name } = account?.attributes || {}
        let label = code
        if (name) {
          label += ` (${name})`
        }
        return label
      }
    },
  }
</script>
