import axios from 'axios'
import { purchaseOrderStatuses } from "@/enum/enums";

const types = {
  SET_CURRENT_PURCHASE_ORDER: 'SET_CURRENT_PURCHASE_ORDER',
  SET_CURRENT_PURCHASE_ORDER_LOADING: 'SET_CURRENT_PURCHASE_ORDER_LOADING',
  SET_CURRENT_MATERIAL: 'SET_CURRENT_MATERIAL',
  SET_CURRENT_MATERIAL_LOADING: 'SET_CURRENT_MATERIAL_LOADING',
}

const state = {
  currentPurchaseOrder: {
    attributes: {},
    relationships: {},
    meta: {},
  },
  poLoading: false,
  currentMaterial: {
    attributes: {},
    relationships: {},
    meta: {},
  },
  materialLoading: false,
}

const mutations = {
  [types.SET_CURRENT_PURCHASE_ORDER]: (state, purchaseOrder) => {
    state.currentPurchaseOrder = purchaseOrder
  },
  [types.SET_CURRENT_PURCHASE_ORDER_LOADING]: (state, value) => {
    state.poLoading = value
  },
  [types.SET_CURRENT_MATERIAL]: (state, material) => {
    state.currentMaterial = material
  },
  [types.SET_CURRENT_MATERIAL_LOADING]: (state, value) => {
    state.materialLoading = value
  },
}

const actions = {
  async getPurchaseOrder({ commit }, purchaseOrderId) {
    try {
      commit(types.SET_CURRENT_PURCHASE_ORDER_LOADING, true)
      const { data } = await axios.get(`/restify/purchase-orders/${ purchaseOrderId }`, {
        params: {
          related: 'vendor,addresses,company,entries,vendor.contacts',
          relatablePerPage: 200,
        },
      })
      commit(types.SET_CURRENT_PURCHASE_ORDER, data)
    } finally {
      commit(types.SET_CURRENT_PURCHASE_ORDER_LOADING, false)
    }
  },
  async closePurchaseOrder({ commit, dispatch }, purchaseOrderId) {
    await axios.post(`/restify/purchase-orders/${ purchaseOrderId }/actions?action=close-purchase-order`)
    await dispatch('getPurchaseOrder', purchaseOrderId)
  },
  async openPurchaseOrder({ commit, dispatch }, purchaseOrderId) {
    await axios.post(`/restify/purchase-orders/${ purchaseOrderId }/actions?action=open-purchase-order`)
    await dispatch('getPurchaseOrder', purchaseOrderId)
  },
  async getMaterial({ commit }, materialId) {
    try {
      commit(types.SET_CURRENT_MATERIAL_LOADING, true)
      const related = 'class[id|code],lastInvoicedVendor[id|code|name],primaryVendor[id|code|name],alternateVendor[id|code|name],address,purchaseOrder[id|number]'
      const { data } = await axios.get(`/restify/materials/${ materialId }`, {
        params: {
          related,
        },
      })
      commit(types.SET_CURRENT_MATERIAL, data)
    } finally {
      commit(types.SET_CURRENT_MATERIAL_LOADING, false)
    }
  },
  async patchMaterial({}, data = {}) {
    await axios.patch(`/restify/materials/${data.id}`, data)
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
