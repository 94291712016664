<template>
  <div class="invoice-batches">
    <div>
      <BaseDataTable :columns="columns"
                     :data-loading="loading"
                     :delete-action="deleteAction"
                     :on-delete-confirm-message="$t('Are you sure you want to delete this Payment Batch? This action cannot be undone.')"
                     :enable-all-select="false"
                     :can-manage-columns="false"
                     actions="refresh,search"
                     url="/restify/invoice-payments/batches"
                     ref="table"
                     @data-fetch="batches = $event">
        <template v-slot:dropdown-actions>
          <TableActionItem>
            <void-button :disabled="selectedNonVoidCount === 0"
                         @click="showVoidDialog = true"
            >
              {{ $t('Void Batch(es)') }}
            </void-button>
          </TableActionItem>
          <TableActionItem>
            <base-button variant="gray-link"
                         :disabled="selectedDirectDepositCount === 0"
                         @click="$error('Coming soon...')"
            >
              <MailIcon class="w-5 h-5 mr-2"></MailIcon>
              <span>{{ $t('Email Direct Deposit(s)') }}</span>
            </base-button>
          </TableActionItem>
          <TableActionItem>
            <base-button variant="gray-link"
                         :disabled="selectedCheckCount === 0"
                         @click="showPositiveVerificationDialog = true"
            >
              <EyeIcon class="w-5 h-5 mr-2"/>
              <span>{{ $t('Positive Verification') }}</span>
            </base-button>
          </TableActionItem>
          <TableActionItem>
            <PreviewChecksButton
                variant="success-link"
                :disabled="selectedCheckCount === 0"
                :download-function="downloadChecks"
            >
              <PrinterIcon class="w-5 h-5 mr-2"></PrinterIcon>
              <span>{{ $t('Print Check(s)') }}</span>
            </PreviewChecksButton>
          </TableActionItem>
        </template>
        <template v-slot:select="{row, index}">
          <base-checkbox v-model="batches[index].selected">
          </base-checkbox>
        </template>
        <template v-slot:journal_number="{row}">
          <router-link :to="`/accounts-payable/payments/batches/${row.journal_id}/view`">
            {{ row.journal_code }}-{{ row.journal_number }}
          </router-link>
        </template>
        <template v-slot:count="{row}">
          <status-badge :status="row.count"/>
        </template>
        <template v-slot:voided_count="{row}">
          <status-badge :status="row.voided_count"/>
        </template>
        <template v-slot:checks_count="{row}">
          <batch-stats :batch="row"></batch-stats>
        </template>
        <template v-slot:printed_count="{row}">
          <base-tooltip :content="$t(`Paper Checks (out of ${row.check_count})`)">
            <status-badge :status="row.printed_count"></status-badge>
          </base-tooltip>
        </template>
        <template v-slot:send_count="{row}">
          <base-tooltip :content="$t(`No. of payments from the batch that have been sent at lease once`)">
            <status-badge :status="row.send_count"></status-badge>
          </base-tooltip>
        </template>
        <template v-slot:sent_times="{row}">
          <base-tooltip :content="$t(`No. of times payments were sent via email (sending a payment via email 3 times, will increment this count by 3)`)">
            <status-badge :status="row.sent_times"></status-badge>
          </base-tooltip>
        </template>
        <template v-slot:download_count="{row}">
          <base-tooltip :content="$t(`No. of payments from the batch that have been downloaded from the sent emails at lease once`)">
            <status-badge :status="row.download_count"></status-badge>
          </base-tooltip>
        </template>
        <template v-slot:extra-actions="{row}">
          <table-view-button :key="`view-${row.id}`" :link="`${$route.path}/${row.journal_id}/view`"/>
        </template>
      </BaseDataTable>
      <SendEmailChecksDialog v-if="showEmailChecksDialog"
                             :open.sync="showEmailChecksDialog"
                             :batches="selectedBatches">
      </SendEmailChecksDialog>
      <PositiveVerificationDialog v-if="showPositiveVerificationDialog"
                                  action="restify/invoice-payments/action?action=generate-batch-positive-pay"
                                  :payload="{batches: selectedBatches.map(b => b.journal_id)}"
                                  :open.sync="showPositiveVerificationDialog">
      </PositiveVerificationDialog>
      <VoidPaymentsDialog v-if="showVoidDialog"
                          :open.sync="showVoidDialog"
                          :batches="selectedBatches.map(b => b.journal_id)"
                          @cancel="showVoidDialog = false">
      </VoidPaymentsDialog>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import { EyeIcon, MailIcon, PrinterIcon } from 'vue-feather-icons'
  import BatchStats from "@/modules/accounts-payable/components/payment/BatchStats";
  import PositiveVerificationDialog from '@/modules/accounts-payable/components/payment/PositiveVerificationDialog'
  import SendEmailChecksDialog from "@/modules/accounts-payable/components/checks/SendEmailChecksDialog";
  import VoidPaymentsDialog from "@/modules/accounts-payable/components/checks/VoidPaymentsDialog";
  import PreviewChecksButton from "@/components/common/PreviewChecksButton";

  export default {
    components: {
      PreviewChecksButton,
      MailIcon,
      EyeIcon,
      BatchStats,
      PrinterIcon,
      PositiveVerificationDialog,
      VoidPaymentsDialog,
      SendEmailChecksDialog,
    },
    data() {
      return {
        batches: [],
        loading: false,
        showVoidDialog: false,
        showPositiveVerificationDialog: false,
        showEmailChecksDialog: false,
        columns: [
          {
            label: '',
            prop: 'select',
            minWidth: 40,
            maxWidth: 40,
            smallCell: true,
          },
          {
            label: this.$t('Journal'),
            prop: 'journal_number',
            maxWidth: 150,
          },
          {
            label: this.$t('Date'),
            prop: 'date',
            maxWidth: 200,
            component: 'FormattedDate',
          },
          {
            label: this.$t('Payment Info'),
            prop: 'checks_count',
            maxWidth: 100,
            align: 'center',
          },
          {
            label: this.$t('Payment Count'),
            prop: 'count',
            maxWidth: 100,
            align: 'center',
            component: 'Status'
          },
          {
            label: this.$t('Void Count'),
            prop: 'voided_count',
            maxWidth: 100,
            align: 'center',
            component: 'Status'
          },
          {
            label: this.$t('Sent Count'),
            prop: 'send_count',
            maxWidth: 80,
            align: 'center',
            component: 'Count',
          },
          {
            label: this.$t('Sent Times'),
            prop: 'sent_times',
            maxWidth: 80,
            align: 'center',
            component: 'Count',
          },
          {
            label: this.$t('Downloaded'),
            prop: 'download_count',
            maxWidth: 80,
            align: 'center',
            component: 'Count',
          },
          // {
          //   label: this.$t('Printed Checks'),
          //   prop: 'printed_count',
          //   maxWidth: 100,
          //   align: 'center',
          // },
          // {
          //   label: this.$t('Sent Direct Deposits'),
          //   prop: 'sent_count',
          //   maxWidth: 100,
          //   align: 'center',
          // },
        ]
      }
    },
    computed: {
      selectedBatches() {
        return this.batches.filter(b => b.selected)
      },
      selectedCheckCount() {
        let count = 0
        this.selectedBatches.forEach(batch => {
          count += batch.check_count
        })
        return count
      },
      selectedDirectDepositCount() {
        let count = 0
        this.selectedBatches.forEach(batch => {
          count += batch.direct_deposit_count
        })
        return count
      },
      selectedNonVoidCount() {
        let count = 0
        this.selectedBatches.forEach(batch => {
          if (batch.voided_count < batch.count) {
            count++
          }
        })
        return count
      }
    },
    methods: {
      formatDate(date) {
        return this.$formatDate(date, 'MM-dd-yyyy')
      },
      seeMoreClick(row) {
        this.$router.push(`/accounts-payable/payments/${this.formatDate(row.date)}`)
      },
      sendDirectDeposits() {
        // TODO send batch emails for direct deposits
      },
      async downloadChecks() {
        const url = '/restify/invoice-payments/action?action=print-invoice-payment-batches'
        const batches = this.selectedBatches.map(b => b.journal_id)
        const data = { batches }
        const params = { responseType: 'blob' }

        return await axios.post(url, data, params)
      },
      async deleteAction(row, index) {
        const date = this.formatDate(row.date)
        try {
          this.loading = true
          await axios.delete(`/restify/invoice-payments/batches/${date}`)
          this.$success(`Payment batch for date #${date} deleted.`)
          this.$refs.table.tableData.splice(index, 1)
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(`Could not delete payment batch for date #${date}.`)
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
