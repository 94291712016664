<template>
  <div>
    <div class="mb-5 grid gap-5 grid-cols-4">
      <base-stats-card icon="IconBank" :title="$t('Account Type')">
        {{ formattedText(bank.attributes.account_type) }}
      </base-stats-card>
      <base-stats-card icon="IconOpenAmount" :title="$t('Positive Pay Format')">
        <span class="uppercase">
          {{ formattedText(bank.attributes.positive_pay_format) }}
        </span>
      </base-stats-card>
      <base-stats-card icon="IconPayroll" :title="$t('Last Check')">
        <div class="flex flex-col mt-1">
          <span v-if="bank.attributes.last_ap_check_no">
            AP: {{ bank.attributes.last_ap_check_no }}
          </span>
          <span v-if="bank.attributes.last_pr_check_no">
            PR: {{ bank.attributes.last_pr_check_no }}
          </span>
        </div>
      </base-stats-card>
      <base-stats-card icon="IconCheckBatches" :title="$t('Last Direct Deposit')">
        {{ getLastDirectDepositNumber(bank) }}
      </base-stats-card>
    </div>
    <base-detail-card :title="$t('Bank Details')"
                      :key="bank.id"
                      :entity="bank"
                      :can-expand="false"
                      :expanded="true"
                      entity-name="bank"
    >
      <base-detail-card-row :title="$t('Name')"
                            field="name"
                            stripe>
        {{ bank.attributes.name }}
      </base-detail-card-row>

      <base-detail-card-row :title="$t('Account / Sub account')">
        <div class="flex items-center space-x-2">
        <AccountLink :number="bank.attributes.account"/>
        <template v-if="bank.attributes.subaccount">
          <span>/</span>
          <SubAccountLink :number="bank.attributes.subaccount"/>
        </template>
        </div>
      </base-detail-card-row>

      <base-detail-card-row :title="$t('Email')"
                            field="email"
                            stripe>
        <base-email-link :value="bank.attributes.email"/>
      </base-detail-card-row>

      <base-detail-card-row :title="$t('Fax')"
                            field="fax">
        <base-email-link :value="bank.attributes.fax"/>
      </base-detail-card-row>

      <base-detail-card-row :title="$t('Phone')"
                            field="phone"
                            stripe>
        <base-phone-link :value="bank.attributes.phone"/>
      </base-detail-card-row>

      <base-detail-card-row :title="$t('Address')">
        <base-address-link :value="bankAddress"/>
      </base-detail-card-row>

      <base-detail-card-row :title="$t('Direct Deposit')" stripe>
        <status-badge :status="bank.attributes.is_for_dd"/>
      </base-detail-card-row>

      <base-detail-card-row :title="$t('Print Checks')">
        <status-badge :status="bank.attributes.is_for_micr"/>
      </base-detail-card-row>

      <base-detail-card-row :title="$t('Positive Pay')"
                            stripe>
        <status-badge :status="bank.attributes.is_for_positive_pay"/>
      </base-detail-card-row>

    </base-detail-card>
    <base-back-link to="/settings/banks"/>
  </div>
</template>
<script>
  import { formattedText } from '@/utils/utils'
  import { getLastCheckNumber, getLastDirectDepositNumber } from "@/modules/settings/utils/bankUtils";

  export default {
    components: {},

    computed: {
      bank() {
        return this.$store.state.settings.currentBank
      },
      bankAddress() {
        return this.get(this.bank, 'relationships.addresses[0]', '')
      }
    },
    methods: {
      getLastDirectDepositNumber,
      getLastCheckNumber,
      formattedText,
    },
  }
</script>
