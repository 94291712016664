<template>
  <AgDataTable
      v-bind="attrs"
      :actions="readOnly ? '' : 'add'"
      :url="url"
      :url-params="urlParams"
      :columns="columns"
      :get-empty-row="getEmptyRow"
      :transform-data="transformData"
      :show-cells-legend="!readOnly"
      :read-only="readOnly"
      :groupIncludeTotalFooter="true"
      :authorizeToCopyLastRow="!data?.meta?.offset"
      domLayout="autoHeight"
      hide-actions="filters"
      ref="gridTable"
      suppressColumnReordering
      @grid-ready="grid = $event"
      @cell-focused="onCellFocused"
      @cell-value-changed="onCellValueChanged"
  />
</template>
<script lang="ts">

import axios from 'axios'
import { defineComponent } from 'vue'
import { resourceStatuses, transactionTypes } from '@/enum/enums'
import { costCenterTypes, costOrIncomeTypes } from '@/components/grid-table/utils/cost-center'
import { costCenterFields, getDefaultAccounts } from '@/modules/common/util/costCenterUtils'
import {addNewRow, editableTableProps, getTableData, storeBatchEntriesProgress} from '@/components/ag-grid/tableUtils'
import { getDeleteColumn } from '@/components/ag-grid/columns/deleteColumns';
import { Column } from '@/components/ag-grid/tableTypes'
import { globalResources } from '@/components/form/util'
import { cellEditors } from '@/components/ag-grid/cellEditors/cellEditors';
import {
  accountCol, descriptionCol,
  subAccountCol,
  updateCostCenterHeaderNames
} from '@/components/ag-grid/columns/costCenterColumns'
import {cellClasses, stopEditingOnTab} from '@/components/ag-grid/columnUtils'
import {
  CellClassParams, GridApi,
  GridReadyEvent,
  ICellEditorParams,
  ValueSetterParams
} from '@ag-grid-community/core'
import {JobTypeFor} from "@/modules/job-costing/enum/jobs";
import {now} from "@/plugins/dateFormatPlugin";


const jobSourceType = 'job'
const lineItemSourceType = 'line-item'
const jobTypeType = 'job-type'

const metaData = {
  quantity: 0,
  um: '',
  unit_price: 0,
}

const defaultUnitMeasure = {
  Hour: 'Hour',
  CubicYards: 'CY',
}

const ResourceEndpoint = '/restify/journal-entries'

export default defineComponent({
  props: {
    type: {
      type: String,
      default: transactionTypes.Cost,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      grid: null as GridReadyEvent | null,
      specialSourceTypeMap: {} as any,
    }
  },
  computed: {
    columns() {
      return [
        {
          headerName: this.$t('Job'),
          field: 'source_id',
          component: 'JobLink',
          cellEditor: cellEditors.GlobalResourceSelect,
          cellEditorParams: {
            resourceName: globalResources.Jobs,
          },
          valueSetter: (params: ValueSetterParams) => {
            params.data.source_id = params.newValue
            params.data.addl_source_id = null
            params.data.special_source_id = null
            return true
          },
          editable: true,
          minWidth: 140,
          maxWidth: 250,
          suppressNavigable: params => {
            return params.data?.is_offset
          },
        },
        {
          headerName: this.$t('Type'),
          field: 'type_id',
          cellEditor: cellEditors.GlobalResourceSelect,
          component: 'JobTypeLink',
          cellEditorParams: () => {
            return {
              resourceName: this.type === transactionTypes.Cost ? globalResources.JobCostTypes : globalResources.JobIncomeTypes,
              target: '_blank',
            }
          },
          editable: (params: ICellEditorParams) => {
            return !!(params.data?.source_id)
          },
          suppressNavigable: params => {
            return !params.data?.source_id
          },
          cellClass: (params: CellClassParams) => {
            if (params.node?.footer || this.readOnly) {
              return
            }
            if (!params.data?.source_id) {
              return cellClasses.ReadOnly
            }
            if (!params.data?.type_id) {
              return cellClasses.Invalid
            }
            return ''
          },
          minWidth: 60,
          maxWidth: 90,
        },
        {
          headerName: this.$t('Line Item'),
          field: 'addl_source_id',
          component: 'AddlSourceLink',
          cellEditor: cellEditors.LineItemSelect,
          cellEditorParams: (params: ICellEditorParams) => {
            const lineItemType = this.type ? globalResources.CostLineItems : globalResources.IncomeLineItems
            return {
              lineItemType,
              source_id: params.data.job_id,
              type_id: params.data.job_type_id,
              target: '_blank',
              showDescription: false,
            }
          },
          editable: (params) => {
            return !!(params.data?.source_id)
          },
          suppressNavigable: params => {
            return !params.data?.source_id
          },
          cellClass: (params) => {
            if (params.node?.footer || this.readOnly) {
              return
            }
            if (!params.data?.source_id) {
              return cellClasses.ReadOnly
            }
            if (!params.data?.addl_source_id) {
              return cellClasses.Invalid
            }
            return ''
          },
          minWidth: 120,
          maxWidth: 200,
        },
        {
          headerName: 'Special Code',
          field: 'special_source_id',
          minWidth: 120,
          maxWidth: 180,
          suppressNavigable: params => {
            const isGenericSourceType = this.isGenericSourceType(params)
            return !params.data?.special_source_type || isGenericSourceType
          },
          component: 'SpecialSourceLink',
          cellRendererParams: {
            target: '_blank',
          },
          editable: params => {
            return !this.isGenericSourceType(params)
          },
          cellEditor: cellEditors.SpecialCode,
          cellClass: (params: CellClassParams) => {
            if (params.node?.footer || this.readOnly) {
              return
            }
            const hasValue = params.data?.special_source_id
            const isGenericSourceType = this.isGenericSourceType(params)
            if (isGenericSourceType) {
              return cellClasses.ReadOnly
            }
            if (!hasValue && params.data?.special_source_type) {
              return cellClasses.Invalid
            }
            if (!params.data?.special_source_type) {
              return cellClasses.ReadOnly
            }
            return ''
          },
          hide: this.type === transactionTypes.Income,
        },
        {
          ...descriptionCol,
        },
        {
          headerName: this.$t('Post to G/L'),
          field: 'post_to_gl',
          component: 'Status',
          cellEditor: cellEditors.Boolean,
          cellEditorParams: {
            plain: true,
          },
          align: 'center',
          editable: true,
          hide: this.isPosted,
          minWidth: 80,
          maxWidth: 90,
        },
        {
          ...accountCol(this.readOnly),
        },
        {
          ...subAccountCol,
        },
        {
          headerName: this.$t('Ref #'),
          field: 'reference_no',
          editable: true,
          minWidth: 80,
          maxWidth: 110,
        },
        {
          headerName: this.$t('Unit'),
          field: 'meta.um',
          editable: true,
          align: 'right',
          minWidth: 60,
          maxWidth: 80,
        },
        {
          headerName: this.$t('Q/H'),
          field: 'meta.quantity',
          cellEditor: cellEditors.Numeric,
          editable: true,
          align: 'right',
          minWidth: 60,
          maxWidth: 80,
        },
        {
          headerName: this.$t('Unit Cost'),
          field: 'meta.unit_price',
          component: 'FormattedPrice',
          cellEditor: cellEditors.Numeric,
          editable: true,
          align: 'right',
          minWidth: 90,
          maxWidth: 120,
        },
        {
          headerName: this.$t('Amount'),
          field: 'amount',
          component: 'FormattedPrice',
          cellEditor: cellEditors.Numeric,
          valueGetter: (params: any) => {
            return +params.data.amount
          },
          aggFunc: 'sum',
          editable: true,
          align: 'right',
          minWidth: 140,
          maxWidth: 200,
        },
        {
          headerName: this.$t('Date'),
          field: 'reference_date',
          component: 'FormattedDate',
          cellEditor: cellEditors.Date,
          editable: true,
          align: 'right',
          minWidth: 90,
          maxWidth: 120,
          suppressKeyboardEvent: stopEditingOnTab,
        },
        {
          ...getDeleteColumn({
            url: ResourceEndpoint,
            hide: this.readOnly,
          }),
        },
      ] as Column[]
    },
    attrs() {
      return {
        ...editableTableProps,
        ...this.$attrs,
      }
    },
    isPosted() {
      const status = this.get(this.data, 'status', resourceStatuses.Pending)
      return status === resourceStatuses.Posted
    },
    url() {
      return this.data.id ? ResourceEndpoint : ''
    },
    urlParams() {
      if (!this.data.id) {
        return {}
      }
      return {
        sort: 'order',
        journal_id: this.data.id,
      }
    },
    typeOptions() {
      return this.$store.getters['globalLists/getResourceOptions'](globalResources.JobCostTypes) || []
    },
    laborTypeId() {
      return this.$store.getters['globalLists/getLaborTypeIdByModule'](globalResources.JobCostTypes)
    },
    emptyRow() {
      let referenceDate
      try {
        referenceDate = this.data?.reference_date
      } catch (err) {
        referenceDate = new Date(now())
      }

      return {
        _localId: crypto.randomUUID(),
        cost_center: costCenterTypes.Job,
        is_offset: false,
        source_id: null,
        source_type: null,
        type_id: null,
        type_type: null,
        addl_source_id: null,
        addl_source_type: null,
        special_source_id: null,
        special_source_type: null,
        post_to_gl: true,
        account: null,
        subaccount: null,
        reference_no: null,
        reference_date: referenceDate,
        description: null,
        transaction_type: this.type || transactionTypes.Cost,
        amount: 0,
        credit_amount: 0,
        debit_amount: 0,
        meta: {
          ...metaData,
        }
      }
    },
  },
  methods: {
    isGenericSourceType(params: any) {
      return params.data?.special_source_type === 'generic'
    },
    transformData(data: any[]) {
      return data.map((entry) => {
        const {credit_amount = 0, debit_amount = 0, transaction_type} = entry.attributes || {}
        let amount
        if (transaction_type === transactionTypes.Income) {
          amount = debit_amount ? -debit_amount : credit_amount
        } else {
          amount = credit_amount ? -credit_amount : debit_amount
        }
        return {
          ...entry.attributes,
          amount,
        }
      })
    },
    onCellFocused(params: ICellEditorParams) {
      updateCostCenterHeaderNames(params)
    },
    async onCellValueChanged(params: ICellEditorParams) {
      const field = params?.colDef?.field
      const entry = params.data

      if (field === 'type_id') {
        params.data = this.onChangeType(params.data)
        params.node.setData(params.data)
        return true
      }

      if (['meta.quantity', 'meta.unit_price' ].includes(field)) {
        params.data.amount = entry.meta.quantity * entry.meta.unit_price
        params.node.setData(params.data)
        return true
      }

      if (!costCenterFields.includes(field)) {
        return true
      }

      params.data = await getDefaultAccounts(entry)
      params.node.setData(params.data)
      // We need to explicitly set cell values here. Otherwise, cells won't update if in edit mode
      params.node.setDataValue('account', params.data.account)
      params.node.setDataValue('subaccount', params.data.subaccount)
    },
    getData() {
      const gridApi = this.grid?.api as GridApi
      return getTableData(gridApi) || []
    },
    async storeProgress(journal_id: string) {
      let entries = this.getData()
      entries = entries
          .filter(entry => entry.dirty || !entry.id)
          .map(entry => this.composeModel(entry, journal_id))

      const entriesToSave = entries.filter(entry => !entry.id)
      const entriesToUpdate = entries.filter(entry => entry.id)
      const promises = []

      if (entriesToSave.length) {
        const savePromise = axios.post(`${ ResourceEndpoint }/bulk`, entriesToSave)
        promises.push(savePromise)
      }
      if (entriesToUpdate.length) {
        const updatePromise = axios.post(`${ ResourceEndpoint }/bulk/update`, entriesToUpdate)
        promises.push(updatePromise)
      }
      await Promise.all(promises)
      this.refreshData()
    },
    composeModel(entry: any, journal_id: string) {
      if (entry.source_id) {
        entry.source_type = jobSourceType
        entry.addl_source_type = lineItemSourceType
        entry.type_type = jobTypeType
      }

      entry.special_source_type = entry.special_source_id ? this.specialSourceTypeMap[entry.type_id] : null
      const transactionType = entry.transaction_type
      if (transactionType === transactionTypes.Income) {
        if (+entry.amount > 0) {
          entry.debit_amount = 0
          entry.credit_amount = entry.amount
        } else {
          entry.debit_amount = Math.abs(entry.amount)
          entry.credit_amount = 0
        }
      } else if (transactionType === transactionTypes.Cost) {
        if (+entry.amount > 0) {
          entry.debit_amount = entry.amount
          entry.credit_amount = 0
        } else {
          entry.debit_amount = 0
          entry.credit_amount = Math.abs(entry.amount)
        }

      }
      entry.journal_id = journal_id

      return entry
    },
    refreshData() {
      // @ts-ignore
      this.$refs.gridTable?.refresh()
    },
    onChangeType(entry: any) {
      const {type_id} = entry
      const type = this.typeOptions.find((type: any) => type.id === type_id) || {}

      if ([ JobTypeFor.Cost.Labor, JobTypeFor.Cost.Equipment ].includes(type.for)) {
        entry.meta.um = this.$t(defaultUnitMeasure.Hour)
      } else {
        entry.meta.um = type.for === JobTypeFor.Cost.Subcontract ? this.$t(defaultUnitMeasure.CubicYards) : ''
      }
      entry.special_source_type = this.specialSourceTypeMap[type_id] || null
      entry.special_source_id = null

      return entry
    },
    getEmptyRowData() {
      const nextOrderValue = this.getData().length
      return {
        ...this.emptyRow,
        order: nextOrderValue,
        _localId: crypto.randomUUID(),
      }
    },
    addEmptyRowAfterOffset() {
      setTimeout(async () => {
        await addNewRow(this.grid as any, this.getEmptyRowData)
      }, 200)
    },
    shouldAddOffsetRow() {
      const previousRow = this.getData().at(-1)
      return previousRow && !previousRow.is_offset && this.data?.meta?.offset
    },
    getEmptyRow() {
      const emptyRow = this.getEmptyRowData()
      if (this.shouldAddOffsetRow()) {
        this.addEmptyRowAfterOffset()
        return this.getOffsetEntry()
      }
      return emptyRow
    },
    tryCollapseFormHeader() {
      this.$emit('on-collapse-form-header')
    },
    getOffsetEntry() {
      const {account, subaccount} = this.data.meta || {}
      const previousRow = this.getData().at(-1)
      if (!previousRow) {
        return this.getEmptyRowData()
      }
      const {amount, reference_no, reference_date, description} = previousRow

      return {
        ...this.getEmptyRowData(),
        account,
        subaccount,
        reference_no,
        reference_date,
        description,
        is_offset: true,
        amount: amount * -1,
        _localId: crypto.randomUUID(),
      }
    },
    composeSpecialSourceMappings() {
      if (this.type !== transactionTypes.Cost) {
        return
      }

      this.typeOptions.forEach((type: any) => {
        const typeMap = {
          [JobTypeFor.Cost.Labor]: 'craft-code',
          [JobTypeFor.Cost.Equipment]: 'equipment',
          [JobTypeFor.Cost.Subcontract]: 'vendor',
          [JobTypeFor.Cost.Material]: 'material',
          [JobTypeFor.Cost.Generic]: null,
        }
        this.specialSourceTypeMap[type.id] = typeMap[type.for] || typeMap[JobTypeFor.Cost.Generic]
      })
    },
  },
  created() {
    this.composeSpecialSourceMappings()
  },
})
</script>
