import i18n from '@/i18n'

export const accountTypes = [
  {
    label: i18n.t('Asset'),
    value: 'asset',
  },
  {
    label: i18n.t('Liability'),
    value: 'liability',
  },
  {
    label: i18n.t('Income'),
    value: 'income',
  },
  {
    label: i18n.t('Expense'),
    value: 'expense',
  },
]

export const accountRestrictions = {
  RESTRICTION_NO: 'no',
  RESTRICTION_SERVICE: 'service',
  RESTRICTION_NON_JOB: 'non_job',
  RESTRICTION_EQUIPMENT: 'equipment',
  RESTRICTION_INVENTORY: 'inventory',
  RESTRICTION_JOB_INCOME: 'job_income',
  RESTRICTION_JOB_EXPENSE: 'job_expense',
}

export const restrictionOptions = [
  {
    label: i18n.t('No restrictions'),
    value: accountRestrictions.RESTRICTION_NO,
  },
  {
    label: i18n.t('Use with NON-JOB Transactions only'),
    value: accountRestrictions.RESTRICTION_NON_JOB,
  },
  {
    label: i18n.t('Use only for JOB EXPENSE Transactions'),
    value: accountRestrictions.RESTRICTION_JOB_EXPENSE,
  },
  {
    label: i18n.t('Use only for JOB INCOME Transactions'),
    value: accountRestrictions.RESTRICTION_JOB_INCOME,
  },
  {
    label: i18n.t('Use only for INVENTORY Transactions'),
    value: accountRestrictions.RESTRICTION_INVENTORY,
  },
  {
    label: i18n.t('Use only for EQUIPMENT Transactions'),
    value: accountRestrictions.RESTRICTION_EQUIPMENT,
  },
  {
    label: i18n.t('Use only for SERVICE/BILLING Transactions'),
    value: accountRestrictions.RESTRICTION_SERVICE,
  },
]
