<template>
  <AgedByReportForm
   :report-number="reportNumbers.AgedByVendor"
   :print-format-options="printFormatOptions"
  />
</template>
<script setup>

  import AgedByReportForm from '@/modules/accounts-payable/components/reports/AgedByReportForm'
  import { agedByViewFormats, reportNumbers } from '@/modules/accounts-payable/components/reports/util'

  const printFormatOptions = [
    {
      label: app.$t('View Each Invoice'),
      value: agedByViewFormats.EachInvoice,
    },
    {
      label: app.$t('View Vendor Total Only'),
      value: agedByViewFormats.VendorTotal,
    },
  ]

</script>
