<template>
  <div
      :class="{
        'fixed bottom-0 bg-white border p-6 shadow-xl mx-auto left-[50%] ml-[-290px] w-[600px] rounded visible': fixed && data,
        'hidden': fixed && !data,
      }">
    <div v-if="fixed">
      <CloseButton class="absolute right-0 top-0"
                   @click="$emit('close')"/>
    </div>
    <div v-if="!readOnly && !data" class="tip mb-2 -mt-8">
      {{ $t('Select one of the rows to view employee information and rates.') }}
    </div>
    <template v-if="data">
      <div class="border border-gray-100 md:grid md:grid-cols-3 timecard-entry-summary">
        <template v-if="fixed">
          <OverrideSummaryField
              :label="$t('Employee')"
              class="pl-4">
            <EmployeeLink :data="employee" link-class="text-sm"/>
          </OverrideSummaryField>
          <OverrideSummaryField
              :label="$t('Day')">
            {{ formattedDay }}
          </OverrideSummaryField>
          <OverrideSummaryField
              :label="$t('Hours')">
            {{ hoursTotal?.toFixed(2) }}
          </OverrideSummaryField>
        </template>
        <OverrideSummaryField
            :label="$t('Union')"
            class="bg-gray-50 pl-4">
          {{ get(union, 'code', '') }}
        </OverrideSummaryField>

        <OverrideSummaryField
            :label="$t('Regular Rate')"
            :loading="loading"
            :is-override="hasOverride(data.regular_rate_from)"
            :class="{
              '!bg-red-50': noRegularRate,
            }"
            class="bg-gray-50"
        >
          {{ $formatPrice(data.regular_rate) }}
        </OverrideSummaryField>

        <OverrideSummaryField
            :class="{'bg-red-50': noAccount}"
            :is-override="hasOverride(data.account_from)"
            class="bg-gray-50"
        >
          <template v-slot:label>
            {{ $t(getAccountsTypePrefix) }} {{ $t('Expense Accounts') }}
          </template>
          <template>
            <AccountLink :data="data" target="_blank"/>
            <SubAccountLink v-if="data.subaccount" :data="data" target="_blank"/>
          </template>
        </OverrideSummaryField>

        <OverrideSummaryField
            :is-override="hasOverride(data.craft_code_from)"
            :label="$t('Craft')"
            class="pl-4">
          <template v-if="craftCode">
            {{ get(craftCode, 'code', '') }} / {{ get(craftCode, 'level', '') }}
          </template>
          <div v-if="subTrade" class="text-xs text-gray-500 whitespace-normal">
            {{ subTradeDescription }}
          </div>
        </OverrideSummaryField>

        <OverrideSummaryField
            :label="$t('Overtime Rate')"
            :loading="loading"
            :is-override="hasOverride(data.overtime_rate_from)"
            :class="{'!bg-red-50': noOverTimeRate}"
        >
          {{ $formatPrice(data.overtime_rate) }}
        </OverrideSummaryField>

        <OverrideSummaryField
            :is-override="hasOverride(data.workers_comp_rate_from)"
            :label="$t('W/Comp')">
          {{ workerCompRateCode }}
        </OverrideSummaryField>

        <OverrideSummaryField
            :is-override="hasOverride(data.withholding_state_from)"
            :label="$t('State/Local')"
            class="pl-4 bg-gray-50">
          <template v-if="stateLabel || localLabel">
            {{ `${stateLabel || ''} / ${localLabel || ''}` }}
          </template>
        </OverrideSummaryField>

        <OverrideSummaryField
            :is-override="hasOverride(data.premium_rate_from)"
            :label="$t('Premium Rate')"
            :loading="loading"
            :class="{
              '!bg-red-50': noPremiumRate,
            }"
            class="bg-gray-50"
        >
          {{ $formatPrice(data.premium_rate) }}
        </OverrideSummaryField>

        <OverrideSummaryField
            :is-override="hasOverride(data.gen_liability_rate_from)"
            :label="$t('G/Liab')" class="bg-gray-50">
          {{ get(data, 'gen_liability_rate_code', '') }}
        </OverrideSummaryField>

        <OverrideSummaryField
            :is-override="hasOverride(data.certified_payroll_exempt_from)"
            :label="$t('Certified P/R Exempt')"
            class="pl-4">
          <status-badge :status="data.is_certified_payroll_exempt"/>
        </OverrideSummaryField>

        <OverrideSummaryField
            :is-override="hasOverride(data.special_rate_from)"
            :label="$t('Special Rate')"
            :loading="loading"
            :class="{
              '!bg-red-50': noSpecialRate,
            }"
        >
          {{ $formatPrice(data.special_rate) }}
        </OverrideSummaryField>

        <OverrideSummaryField
            :is-override="hasOverride(data.special_account_from)"
            :label="$t('Special G/L Account')"
            :class="{'bg-red-50': noSpecialAccount}"
        >
          <AccountLink :number="data.special_account" target="_blank"/>
        </OverrideSummaryField>
      </div>
    </template>
  </div>
</template>
<script>
  import { costCenterTypes } from '@/components/grid-table/utils/cost-center'
  import OverrideSummaryField from '@/modules/payroll/components/timecard/OverrideSummaryField.vue';
  import { defineComponent } from 'vue';
  import { RateFrom } from "@/modules/payroll/utils/timeCardUtils";
  import CloseButton from "@/components/common/CloseButton.vue";

  const prefixesByType = {
    [costCenterTypes.Job]: 'Job',
    [costCenterTypes.GeneralAndAdministrative]: 'G/L',
    [costCenterTypes.Equipment]: 'EQP',
    [costCenterTypes.Inventory]: 'S/B',
    default: 'G/L',
  }

  export default defineComponent({
    components: {
      CloseButton,
      OverrideSummaryField,
    },
    props: {
      data: {
        type: [Object, null],
        default: () => ({}),
        required: true,
      },
      employee: {
        type: Object,
        default: () => ({}),
      },
      loading: {
        type: Boolean,
        default: false,
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      fixed: {
        type: Boolean,
        default: false,
      },
      days: {
        type: Array,
        default: () => [],
      }
    },
    computed: {
      formattedDay() {
        return this.days.find(day => day.value.startsWith(this.data.date))?.label
      },
      workerCompRateCode() {
        return this.data?.workers_comp_rate_code
      },
      getAccountsTypePrefix() {
        return prefixesByType[this.data.source_type] || 'G/L'
      },
      useMultipleLocalTaxes() {
        return this.$store.getters['company/getCompanyUseMultipleLocalTaxes']
      },
      useMultipleStateTaxes() {
        return this.$store.getters['company/getCompanyUseMultipleStateTaxes']
      },
      stateLabel() {
        const stateCode = this.$store.state.globalLists.stateTaxes.find(t => t.id === this.data.withholding_state_id)?.code
        if (!this.useMultipleStateTaxes || !this.data.sui_sdi_state_id) {
          return stateCode
        }
        const state2Code = this.$store.state.globalLists.stateTaxes.find(t => t.id === this.data.sui_sdi_state_id)?.code
        return `${stateCode},${state2Code}`
      },
      localLabel() {
        const localCode = this.$store.state.globalLists.localTaxes.find(t => t.id === this.data.withholding_local_id)?.code
        if (!this.useMultipleLocalTaxes || !this.data.withholding_local_2_id) {
          return localCode
        }
        const local2Code = this.$store.state.globalLists.localTaxes.find(t => t.id === this.data.withholding_local_2_id)?.code
        return `${localCode},${local2Code}`
      },
      craftCode() {
        return this.$store.state.globalLists.craftCodes.find(t => t.id === this.data.craft_code_id)
      },
      subTrade() {
        return this.$store.state.globalLists.subTrades.find(t => t.id === this.data.sub_trade_id)
      },
      subTradeDescription() {
        if (!this.subTrade) {
          return ''
        }
        const { trade, classification, description } = this.subTrade || {}
        if (trade && classification) {
          return `${trade}: ${classification}`
        }
        return description
      },
      union() {
        return this.$store.state.globalLists.unions.find(t => t.id === this.data.union_id)
      },
      noSpecialAccount() {
        return !this.data.special_account && this.data.special_source_id && !this.readOnly
      },
      hasRegularHours() {
        return +this.data.regular_hours > 0
      },
      hasOvertimeHours() {
        return +this.data.overtime_hours > 0
      },
      hasPremiumHours() {
        return +this.data.premium_hours > 0
      },
      hasSpecialUnits() {
        return +this.data.units > 0
      },
      noRegularRate() {
        return this.data.regular_rate === 0 && this.hasRegularHours && !this.loading && !this.readOnly
      },
      noOverTimeRate() {
        return this.data.overtime_rate === 0 && this.hasOvertimeHours && !this.loading && !this.readOnly
      },
      noPremiumRate() {
        return this.data.premium_rate === 0 && this.hasPremiumHours && !this.loading && !this.readOnly
      },
      noSpecialRate() {
        const specialRate = this.data.special_rate
        return (specialRate === null || specialRate === undefined) && this.hasSpecialUnits && !this.loading && !this.readOnly
      },
      noAccount() {
        return !this.data.account && !this.readOnly
      },
      hoursTotal() {
        const { regular_hours, overtime_hours, premium_hours } = this.data
        return +regular_hours + +overtime_hours + +premium_hours
      }
    },
    methods: {
      hasOverride(field) {
        return field === RateFrom.UserDefined
      }
    },
    mounted() {
      if (this.fixed) {
        document.body.appendChild(this.$el)
      }
    },
    beforeDestroy() {
      if (this.fixed) {
        document.body.removeChild(this.$el)
      }
    }
  })
</script>
<style lang="scss">
  .timecard-entry-summary {
    a {
      @apply text-gray-500 hover:underline;
    }
  }
</style>
