<template>
  <entity-preview
      :entity="equipmentData"
      :key="`preview-${equipmentData.id}`"
      :url="`/equipment/list/${equipmentData.id}/view`"
      :value-to-display="valueToDisplay"
      :parent-entity-id="equipmentData.id"
      :target="target"
      :is-link="!editableCell"
      :show-preview="showPreview"
      :link-class="linkClass"
      entity-name="equipment"
  />
</template>
<script>
  export default {
    name: 'EquipmentLink',
    props: {
      id: [String, Number],
      data: {
        type: Object,
        default: () => ({}),
      },
      showDescription: {
        type: Boolean,
        default: true,
      },
      showPreview: {
        type: Boolean,
        default: true,
      },
      target: String,
      linkClass: String,
      editableCell: Boolean,
    },
    computed: {
      allEquipments() {
        return this.$store.state.globalLists.equipment
      },
      equipmentData() {
        if (this.id) {
          return this.allEquipments.find(e => e.id === this.id)
        }
        return this.data?.attributes || this.data || {};
      },
      valueToDisplay() {
        if (!this.equipmentData?.id) {
          return
        }

        if (!this.showDescription || !this.equipmentData.description) {
          return this.equipmentData.code
        }

        return `${this.equipmentData.code} (${this.equipmentData.description})`
      },
    },
  }
</script>
