<template>
  <div>
    <layout-tabs
        v-if="!onViewResourcePage"
        :items="filteredItems"
    />
    <router-view/>
  </div>
</template>
<script>

  export default {
    computed: {
      items() {
        return [
          {
            name: this.$t('Pending'),
            tooltip: this.$t('Invoices that were not posted yet'),
            path: `/accounts-payable/invoices/pending`,
            permission: 'invoices_show',
          },
          {
            name: this.$t('Open'),
            tooltip: this.$t('Posted but not paid'),
            path: `/accounts-payable/invoices/open`,
            permission: 'invoices_show',
          },
          {
            name: this.$t('Partially Paid'),
            tooltip: this.$t('Partially paid invoices'),
            path: `/accounts-payable/invoices/partial-paid`,
            permission: 'invoices_show',
          },
          {
            name: this.$t('Paid'),
            tooltip: this.$t('Fully paid invoices'),
            path: `/accounts-payable/invoices/paid`,
            permission: 'invoices_show',
          },
          {
            name: this.$t('Hold'),
            tooltip: this.$t('Invoice put on hold'),
            path: `/accounts-payable/invoices/hold`,
            permission: 'invoices_show',
          },
          {
            name: this.$t('Voided'),
            tooltip: this.$t('Voided invoices'),
            path: `/accounts-payable/invoices/voided`,
            permission: 'invoices_show',
          },
          {
            name: this.$t('All Invoices'),
            tooltip: this.$t('All invoices'),
            path: `/accounts-payable/invoices/all`,
            permission: 'invoices_show',
          },
          {
            name: this.$t('Review Invoices'),
            tooltip: this.$t('Review pending invoices'),
            path: `/accounts-payable/invoices/review`,
            permission: 'invoices_approve',
          },
        ]
      },
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
