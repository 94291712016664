<template>
  <base-data-table
    v-bind="defaultAttributes"
    :filters="filters"
    :columns="columns"
    :data="getData"
    :total-rows="rowsLength"
    @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{row}">
      <div class="flex items-center space-x-1">
        <template v-if="row.header.vendor">
          <span>{{ $t('Vendor: ') }}</span>
          <VendorLink
            v-if="row.header?.vendor"
            :data="row.header?.vendor"
            :show-preview="false"
          />
        </template>
        <template v-else-if="row.header.job">
          <span>{{ $t('Job: ') }}</span>
          <JobLink
            :data="row.header?.job"
            :show-preview="false"
          />
        </template>
        <template v-else-if="row.header.source?.id">
          <div class="uppercase">
            {{ getSourceTypAbbr(headerSourceType) }}
          </div>
          <SourceLink
            v-if="row.header.source"
            :data="{ source: row.header.source, source_type: headerSourceType }"
            :show-description="false"
            :show-preview="false"
          />
        </template>
        <template v-else-if="row.header.buyer">
          <span>{{ $t('Buyer: ') }}</span>
          <span>{{ row.header.buyer }}</span>
        </template>
        <template v-else>
          <span>{{ row.header.fallback }}</span>
        </template>
      </div>
    </template>
    <template #purchase_order.number="{row}">
      <PurchaseOrderLink v-if="!row.isEntry" :data="row.purchase_order"/>
    </template>
    <template #source="{row}">
      <div v-if="!row.isEntry" class="flex space-x-1">
        <div v-if="row.source_type" class="uppercase">
          {{ getSourceTypAbbr(row.source_type) }}
        </div>
        <SourceLink
          v-if="row?.source"
          :data="{ source: row.source, source_type: row.source_type }"
          :show-description="false"
          :show-preview="false"
        />
      </div>
      <div v-if="row.isEntry">
        <div class="flex space-x-4">
          <div>
            <span :title="$t('Original Quantity')" class="mr-1">O</span> <span>{{ $formatHours(row?.entry?.quantity) }}</span>
          </div>
          <div>
            <span :title="$t('Invoiced')" class="mr-1">I</span> <span>{{ $formatHours(row?.entry?.quantity_invoiced) }}</span>
          </div>
          <div>
            <span :title="$t('Received')" class="mr-1">R</span> <span>{{ $formatHours(row?.entry?.quantity_received) }}</span>
          </div>
        </div>
      </div>
    </template>
    <template #purchase_order.description="{row}">
      <div>
        <div v-if="!row.isEntry">
          {{ row.purchase_order.description }}
        </div>
        <div v-else class="ml-8">
          {{ row.entry.description }}
        </div>
      </div>
    </template>
    <template #purchase_order.date="{row}">
      <div>
        <div v-if="!row.isEntry">
          {{$formatDate(row.purchase_order.date)}}
        </div>
        <div v-else class="ml-4">
          <template v-if="row.material">
            {{ $t('Material') }}
          </template>
        </div>
      </div>
    </template>
    <template #purchase_order.status="{row}">
      <StatusBadge v-if="row.purchase_order.status" :status="row.purchase_order.status"/>
    </template>
    <template #journal="{row}">
      <JournalLink
        v-if="row.journal"
        :data="row.journal"
      />
    </template>

    <template #subtotal="{row, subtotal}">
      <td colspan="6"/>
      <td colspan="2">
        <div class="flex justify-end">
          {{ row?.title }}
        </div>
      </td>
      <td class="text-right !pr-1">
        {{ $formatPrice(subtotal.sales_tax_amount) }}
      </td>
      <td class="text-right !pr-1">
        {{ $formatPrice(subtotal.freight_amount) }}
      </td>
      <td class="text-right !pr-1">
        {{ $formatPrice(subtotal.extended_amount) }}
      </td>
      <td class="text-right !pr-2">
        {{ $formatPrice(subtotal.invoiced_amount) }}
      </td>
    </template>

  </base-data-table>
</template>
<script>
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
import SourceLink from "@/components/links/SourceLink.vue";
import { costCenterTypes, sourceTypeAbbrMap, sourceTypeMap } from "@/components/grid-table/utils/cost-center";
import { SortBy } from "@/modules/purchasing-inventory/components/reports/purchaseListingOrderSortBy";
import PurchaseOrderLink from "@/components/links/PurchaseOrderLink.vue";

export default {
  components: { PurchaseOrderLink, SourceLink },
  extends: ReportTableWrapper,
  props: {
    format: {
      required: true,
      type: String,
      default: '',
    },
    filters: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {}
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('P.O. <br> No.'),
          prop: 'purchase_order.number',
          minWidth: 50,
          maxWidth: 70,
        },
        {
          label: this.$t('Date'),
          prop: 'purchase_order.date',
          minWidth: 80,
          maxWidth: 120,
          component: 'FormattedDate',
        },
        {
          label: this.$t('Description'),
          prop: 'purchase_order.description',
          minWidth: 150,
          maxWidth: 250,
        },
        {
          label: this.$t('Status'),
          prop: 'purchase_order.status',
          minWidth: 70,
          maxWidth: 100,
        },
        {
          label: this.$t('Job - W/O <br> Eqp - Inv'),
          prop: 'source',
          minWidth: 140,
          maxWidth: 180,
        },
        {
          label: this.$t('Journal'),
          prop: 'journal',
          minWidth: 40,
          maxWidth: 60,
        },
        {
          label: this.$t('Delivery <br> Date'),
          prop: 'purchase_order.delivery_date',
          minWidth: 80,
          maxWidth: 120,
          component: 'FormattedDate',
        },
        {
          label: this.$t('Dscnt %'),
          prop: 'purchase_order.discount_percent',
          minWidth: 40,
          maxWidth: 50,
          align: 'right',
          component: 'FormattedPercent',
        },
        {
          label: this.$t('Sales <br> Tax'),
          prop: 'purchase_order.sales_tax_amount',
          minWidth: 60,
          maxWidth: 100,
          align: 'right',
          component: 'FormattedPrice',
        },
        {
          label: this.$t('Freight'),
          prop: 'purchase_order.freight_amount',
          minWidth: 60,
          maxWidth: 100,
          align: 'right',
          component: 'FormattedPrice',
        },
        {
          label: this.$t('Total <br> P.O. Amount'),
          prop: 'purchase_order.extended_amount',
          minWidth: 60,
          maxWidth: 100,
          align: 'right',
          component: 'FormattedPrice',
        },
        {
          label: this.$t('Invoiced <br> To-Date'),
          prop: 'purchase_order.invoiced_amount',
          minWidth: 60,
          maxWidth: 100,
          align: 'right',
          component: 'FormattedPrice',
        },
      ]
    },
    headerSourceType() {
      const map = {
        [SortBy.WorkOrder]: [sourceTypeMap[costCenterTypes.WorkOrder]],
        [SortBy.Equipment]: [sourceTypeMap[costCenterTypes.Equipment]]
      }
      return map[this.filters?.sort_by]
    }
  },
  methods: {
    getSourceTypAbbr(sourceType) {
      return sourceTypeAbbrMap[sourceType] || sourceType
    },
    processVendors(vendors) {
      const rows = []
      vendors.forEach(row => {
        rows.push({
          header: {
            vendor: row.vendor,
            fallback: row.vendor.description,
          }
        })
        const purchaseOrders = row.purchase_orders || []
        purchaseOrders.forEach(purchaseOrder => {
          rows.push(purchaseOrder)
          rows.push(...this.processEntries(purchaseOrder.entries))
        })
        rows.push({
          title: this.$t('Vendor Totals:'),
          subtotal: row.totals
        })
      })
      return rows
    },
    processEntries(entries) {
      const rows = []
      if (!entries) {
        return []
      }
      entries.forEach(entry => {
        const mappedEntry = {
          ...entry,
          isEntry: true,
          purchase_order: {
            ...entry.entry,
            number: entry.entry.order,
          },
        }
        rows.push(mappedEntry)
      })
      return rows
    },
    composeRows(data) {
      const rows = []
      const subTitleMap = {
        [SortBy.Job]: this.$t('Job Totals:'),
        [SortBy.WorkOrder]: this.$t('Work Order Totals:'),
        [SortBy.Equipment]: this.$t('Equipment Totals:'),
        [SortBy.Buyer]: this.$t('Buyer Totals:'),
        default: this.$t('Totals:')
      }
      const otherSortOptions = [SortBy.Job, SortBy.WorkOrder, SortBy.Equipment, SortBy.Buyer]

      if (this.filters?.sort_by === SortBy.Vendor) {
        this.rows = this.processVendors(data)
      }
      if (otherSortOptions.includes(this.filters?.sort_by)) {
        data.forEach(row => {
          const source = row.job || row.source || row.buyer
          const subTotalTitle = subTitleMap[this.filters?.sort_by] || subTitleMap.default

          rows.push({
            header: {
              job: row.job,
              source: row.source,
              buyer: row.buyer,
              fallback: source.description,
            }
          })
          const vendorRows = this.processVendors(row.vendors || [])
          rows.push(...vendorRows)
          rows.push({
            title: subTotalTitle,
            subtotal: row.totals
          })
        })
        this.rows = rows
      }
      return this.rows
    },
  },
}
</script>
