<template>
  <base-select
    v-bind="$attrs"
    v-on="$listeners"
    :id="$attrs.id || 'employee-select'"
    :name="$attrs.name || $t('Employee')"
    :placeholder="$attrs.placeholder || $t('Select Employee')"
    :label="$attrs.label !== undefined ? $attrs.label : $t('Employee')"
    :add-entity="addEntity"
    :edit-entity="editEntity"
    :set-default-option="editEntity"
    :add-label="addLabel"
    :options="getOptions"
    :label-format="formatLabel"
    :table-columns="tableColumns"
    :resource-name="globalResources.Employees"
    :value-key="valueKey"
    :label-key="labelKey"
    :initial-value="initialValue"
  />
</template>
<script>
  import i18n from '@/i18n'
  import { globalResources } from "@/components/form/util";
  import { resourceStatuses } from "@/enum/enums";

  export default {
    name: 'EmployeeSelect',
    props: {
      addEntity: {
        type: Boolean,
        default: false,
      },
      editEntity: {
        type: Boolean,
        default: false,
      },
      displayPayColumn: {
        type: Boolean,
        default: false,
      },
      valueKey: {
        type: String,
        default: 'id',
      },
      labelKey: {
        type: String,
        default: 'code',
      },
      showInactive: {
        type: Boolean,
        default: false,
      },
      addLabel: {
        type: String,
        default: i18n.t('Add new Employee'),
      },
      initialValue: {
        type: [Object, Array],
      },
    },
    data() {
      return {
        globalResources
      }
    },
    computed: {
      getOptions() {
        const employees =  this.$store.state.globalLists.employees || []
        if (this.showInactive) {
          return employees
        }
        return employees.filter(e => e.status !== resourceStatuses.Inactive)
      },
      tableColumns() {
        let columns = [
          {
            minWidth: 80,
            maxWidth: 150,
            name: this.$t('Code'),
            prop: 'code',
          },
          {
            minWidth: 130,
            maxWidth: 130,
            name: this.$t('Name'),
            prop: 'name',
          },
          {
            minWidth: 100,
            maxWidth: 100,
            name: this.$t('Status'),
            prop: 'status',
            component: 'Status',
          },
        ]
        if (!this.displayPayColumn) {
          return columns
        }
        return [
          ...columns,
          {
            minWidth: 80,
            maxWidth: 100,
            name: this.$t('Pay'),
            prop: 'salary_amount',
            component: 'FormattedPrice',
          },
        ]
      },
    },
    methods: {
      formatLabel(employee) {
        const { code, name } = employee
        return !name ? code : `${code} (${name})`
      },
    },
  }
</script>
