<template>
  <DetailLayout
      :loading="accountLoading"
      :current-resource="currentResource"
      :get-current-resource="getCurrentResource"
      :select-component="AccountSelect"
      :select-class="{
        'mt-2': $route.query.debug
      }"
      :items="items"
  >
    <template #page-title>
      <div class="flex w-full justify-between">
        <div class="flex items-center justify-between md:justify-start">
        <span class="truncate">
          {{ currentResource.attributes.number }}
          <template v-if="currentResource.attributes.description">
            ({{ currentResource.attributes.description }})
          </template>
        </span>
          <div class="mx-1 md:mx-2 flex items-center">
            <status-badge :status="currentResource.attributes.type"/>
            <template v-if="!currentResource.attributes.active">
              &nbsp;&nbsp;
              <status-badge :status="$t('Inactive')"/>
            </template>
          </div>
        </div>
      </div>
      <AccountDebugActions :id="$route.params.id"/>
    </template>
  </DetailLayout>
</template>
<script>
  import DetailLayout from "@/modules/common/components/DetailLayout.vue";
  import AccountSelect from "@/modules/ledger/components/AccountSelect.vue";
  import AccountDebugActions from "@/modules/ledger/components/accounts/AccountDebugActions.vue";

  export default {
    components: {
      AccountDebugActions,
      DetailLayout
    },
    data() {
      return {
        AccountSelect,
      }
    },
    computed: {
      currentResource() {
        return this.$store.state.generalLedger.currentAccount || {}
      },
      accountLoading() {
        return this.$store.state.generalLedger.currentAccountLoading
      },
      items() {
        const { id } = this.$route.params
        return [
          {
            name: this.$t('History'),
            path: `/ledger/accounts/${id}/view`,
            permission: 'accounts_show',
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Summary'),
            path: `/ledger/accounts/${id}/summary`,
            permission: 'accounts_show',
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Edit'),
            path: `/ledger/accounts/${id}/edit`,
            permission: 'accounts_show',
            authorizedTo: 'authorizedToUpdate',
          },
        ]
      },
    },
    methods: {
      async getCurrentResource(id) {
        await this.$store.dispatch('generalLedger/getAccount', {
          id,
          params: {
            fiscal_year: this.activeFiscalYear,
          },
        })
      },
    },
  }
</script>
