import store from "@/store";
import {globalResources} from "@/components/form/util";

export function getPaymentCashOnDeposit(params: any) {
  const { data } = params
  const customer_id = data?.customer_id
  let customer = data?.customer
  if (!customer && customer_id) {
    customer = store.getters['globalLists/getResourceById'](globalResources.Customers, customer_id)
  }
  const customerCashOnDeposit = customer?.current_unapplied_cash_on_deposit_amount || 0
  return data.amount - data.apply_to_billings_amount + customerCashOnDeposit
}
