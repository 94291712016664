<template>
  <BaseFormDialog
    v-bind="$attrs"
    v-on="$listeners"
    size="2xl"
    :no-padding="true"
    :appendToBody="true"
  >
    <BaseForm
      layout="modal"
      no-padding
    >
      <slot/>
      <template #extra-buttons-left>
        <div class="flex-1 flex space-x-4">
          <BaseButton
            :disabled="!showPrevButton"
            variant="gray-light"
            @click="onPrev">
            <ChevronLeftIcon class="w-4 h-4 ml-2"/>
            <span class="capitalize">{{ $t('Previous') }} {{entityName}}</span>
          </BaseButton>
          <BaseButton
            :disabled="!showNextButton"
            variant="gray-light"
            @click="onNext">
            <span class="capitalize">{{ $t('Next') }} {{entityName}}</span>
            <ChevronRightIcon class="w-4 h-4 ml-2"/>
          </BaseButton>
        </div>
      </template>
      <template #footer>
        <EntityApproveActions
          :data="data"
          :base-path="basePath"
          :api-base-path="apiBasePath"
          :entity-name="entityName"
          @approve="navigateToNext"
          @reject="navigateToNext"
        />
      </template>
    </BaseForm>
  </BaseFormDialog>
</template>
<script>
import { ChevronLeftIcon, ChevronRightIcon, RotateCcwIcon, Edit2Icon } from "vue-feather-icons";
import EntityApproveActions from "@/modules/common/components/review/EntityApproveActions.vue";

export default {
  components: {
    RotateCcwIcon,
    EntityApproveActions,
    ChevronLeftIcon,
    ChevronRightIcon,
    Edit2Icon,
  },
  props: {
    data: {
      type: String,
      required: true,
    },
    showNextButton: {
      type: Boolean,
      default: false,
    },
    showPrevButton: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
      required: true,
    },
    apiBasePath: {
      type: String,
      required: true,
    },
    entityName: {
      type: String,
      required: true,
    }
  },
  methods: {
    onPrev() {
      this.$emit('prev')
    },
    onNext() {
      this.$emit('next')
    },
    navigateToNext() {
      setTimeout(() => {
        this.$emit('next')
      }, 500)
    }
  }
}
</script>
