export function generateCodeFromName(name = '') {
  const codeLength = 6
  const charsPerWord = 3
  let code = ''

  if (!name) {
    return
  }
  const nonEmptyWords = name
    .split(' ')
    .map(p => p.trim())
    .filter(p => p !== '')

  if (nonEmptyWords.length === 1) {
    return name.substring(0, codeLength).toUpperCase()
  }

  nonEmptyWords.forEach(part => {
    if (code.length >= codeLength) {
      return
    }
    let charsToAdd = Math.min(charsPerWord, codeLength - code.length)
    code += part.substring(0, charsToAdd).trim().toUpperCase()
  })

  return code
}
