<template>
  <base-form
      :loading="loading"
      :can-create-another-entity="!model.id"
      :submit-disabled="workSiteAlreadyExists"
      :show-back="true"
      :show-cancel="true"
      :save-text="$t('Create worksite')"
      :update-text="$t('Update worksite')"
      layout="vertical"
      grid-classes="grid grid-cols-8 gap-x-4"
      @submit="onSubmit"
      @cancel="$router.push('/service-billing/worksites')"
  >
    <base-input
        v-model="model.number"
        :tip="$t('The worksite number will be generated')"
        :label="$t('Worksite #')"
        :placeholder="$t('Worksite #')"
        :readonly="true"
        :name="$t('Worksite')"
        type="number"
        class="col-span-8 md:col-span-1"
    />
    <base-input
        v-model="model.city"
        v-uppercase
        :label="$t('City')"
        :name="$t('City')"
        :placeholder="$t('City')"
        id="city"
        class="col-span-8 md:col-span-2"
        rules="max:20"
    />
    <state-select
        v-model="model.state"
        v-uppercase
        :label="$t('State')"
        :placeholder="$t('State')"
        class="col-span-8 md:col-span-1"
        id="state"
        rules="required"
        clearable
    />
    <base-zip-code
        v-model="model.postal_code"
        v-uppercase
        :label="$t('Zip Code')"
        :placeholder="$t('Zip Code')"
        rules="required"
        class="col-span-8 md:col-span-2"
    />
    <div class="col-span-8 md:col-span-2"/>
    <base-input
        v-model="model.street_number"
        v-uppercase
        :label="$t('Street Number')"
        :placeholder="$t('Street Number')"
        class="col-span-8 md:col-span-1"
        rules="required|max:8"
    />
    <base-input
        v-model="model.street_name"
        v-uppercase
        :label="$t('Street')"
        :placeholder="$t('Street name')"
        class="col-span-8 md:col-span-3"
        rules="required|max:50"
    />
    <base-select
        v-model="model.unit_type"
        :options="unitTypeOptions"
        :label="$t('Unit Type')"
        :placeholder="$t('Unit Type')"
        class="col-span-8 md:col-span-1"
    />
    <base-input
        v-model="model.unit_number"
        :label="$t('Unit Number')"
        :placeholder="$t('Unit Number')"
        class="col-span-8 md:col-span-1"
        rules="max:20"
    />
    <customer-select
        v-model="model.customer_id"
        :initial-value="get(data, 'customer')"
        :label="$t('Billing Customer')"
        clearable
        class="col-span-8 md:col-span-2"
        rules="required"
    />
    <base-alert v-if="addressChanged || workSiteAlreadyExists"
                :type="workSiteAlreadyExists ? $promptType.Danger : $promptType.primary"
                class="col-span-8 mb-5">
      <div v-if="addressChanged"
           class="flex justify-between">
        <span>
          {{
            $t('Validate if the Worksite already exists.')
          }}
        </span>
        <base-button
            :loading="validateAddressLoading"
            size="xs"
            variant="gray-link"
            @click="onValidateAddress"
        >
          <SearchIcon class="w-4 h-4 mr-1"/>
          {{ $t('Validate') }}
        </base-button>
      </div>
      <p v-if="workSiteAlreadyExists">
        {{ $t('Worksite with this address exists, please add new address.') }}
      </p>
    </base-alert>
    <hr class="col-span-8 mb-5"/>
    <base-select
        v-model="model.worksite_type"
        :label="$t('Worksite Type')"
        :name="$t('Worksite Type')"
        :options="workSiteTypeOptions"
        class="col-span-8 md:col-span-1"
        id="worksite_type"
    />
    <base-input
        v-model="model.name"
        :label="$t('Worksite Name')"
        :placeholder="$t('Worksite Name')"
        :name="$t('Worksite Name')"
        class="col-span-8 md:col-span-3"
        id="name"
        rules="max:150"
    />
    <base-input
        v-model="model.contact_name"
        :label="$t('Contact')"
        :placeholder="$t('Contact Name')"
        :name="$t('Contact Name')"
        class="col-span-8 md:col-span-3"
        id="contact_name"
        rules="max:100"
    />
    <div class="col-span-8 md:col-span-1"/>
    <base-phone-input
        v-model="model.worksite_phone"
        :label="$t('Worksite Phone')"
        class="col-span-8 md:col-span-1"
        placeholder="(123) 456-7890"
        inline-errors
        id="worksite_phone"
    />
    <base-phone-input
        v-model="model.other_phone"
        :label="$t('Other Phone')"
        class="col-span-8 md:col-span-1"
        inline-errors
        id="other_phone"
    />
    <base-phone-input
        v-model="model.fax"
        :label="$t('Fax')"
        class="col-span-8 md:col-span-1"
        id="fax"
    />
    <base-input
        v-model="model.authorizing_person_name"
        :label="$t('Authorizing Person')"
        :placeholder="$t('Authorizing Person')"
        :name="$t('Authorizing Person')"
        class="col-span-8 md:col-span-2"
        id="authorizing_person_name"
        rules="max:100"
    />
    <div class="col-span-8 md:col-span-1">
      <base-checkbox
          v-model="useWorksiteData"
          :label="$t('Copy worksite address')"
          id="use_worksite_data"
          vertical
      />
    </div>
    <div class="col-span-8 md:col-span-4 grid grid-cols-8 gap-x-4">
      <base-input
          v-model="model.cross_streets"
          :label="$t('Directions (Cross Streets..)')"
          :placeholder="$t('Directions')"
          :name="$t('Directions')"
          class="col-span-8"
          id="cross_streets"
          rules="max:150"
      />
      <sales-tax-district-select
          v-model="model.district_id"
          :add-entity="true"
          :initial-value="get(data, 'district')"
          class="col-span-8 md:col-span-2"
          clearable
      />
      <employee-select-new
          v-model="model.sales_person_id"
          :label="$t('Commissioned Salesperson')"
          :placeholder="$t('Employee')"
          :name="$t('Employee')"
          class="col-span-8 md:col-span-3"
          clearable
      />
      <div class="col-span-8 md:col-span-3">
        <base-switch
          v-model="model.exempt_from_sales_tax"
          :label-info="$t('Exempt From Sales Tax')"
          id="exempt_from_sales_tax"
        />
      </div>
      <service-billing-category-select
          v-model="model.category_id"
          class="col-span-8 md:col-span-3"
      />
    </div>
    <div class="col-span-8 md:col-span-4 mb-4">
      <JobNotes v-model="model.user_notes"/>
    </div>
    <el-collapse class="col-span-8">
      <timecard-overrides
          v-model="model"
          :data="data"
      />
    </el-collapse>
  </base-form>
</template>
<script>
  import axios from 'axios'
  import pick from 'lodash/pick'
  import { addressTypes } from '@/enum/enums'
  import { SearchIcon } from 'vue-feather-icons'
  import TimecardOverrides from '@/components/common/TimecardOverrides'
  import { globalResources, RestifyResources } from "@/components/form/util";
  import { workSiteTypeLabels, workSiteTypes } from "@/modules/service-billing/util/service-billing";
  import JobNotes from "@/modules/job-costing/components/job/JobNotes.vue";

  const unitTypes = {
    Suite: 'suite',
    Unit: 'unit',
    Apartment: 'apartment',
  }

  const defaultAddress = {
    city: '',
    postal_code: '',
    state: '',
    street_name: '',
    street_number: '',
    unit_number: '',
    unit_type: unitTypes.Unit,
  }

  const addressFields = Object.keys(defaultAddress)

  export default {
    provide() {
      const defaultProps = {}
      Object.defineProperty(defaultProps, 'get', {
        enumerable: true,
        get: () => this.getCustomerDefaults,
      })
      return { defaultProps }
    },
    components: {
      JobNotes,
      SearchIcon,
      TimecardOverrides,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      redirectToListAfterStore: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        useWorksiteData: true,
        previewAddress: defaultAddress,
        validateAddressLoading: false,
        workSiteAlreadyExists: false,
        loading: false,
        unitTypeOptions: [
          {
            label: this.$t('Unit'),
            value: unitTypes.Unit,
          },
          {
            label: this.$t('Apartment'),
            value: unitTypes.Apartment,
          },
          {
            label: this.$t('Suite'),
            value: unitTypes.Suite,
          },
        ],
        model: {
          number: null,
          city: '',
          postal_code: '',
          state: '',
          street_name: '',
          street_number: '',
          unit_number: '',
          unit_type: unitTypes.Unit,
          type: '',
          unit: '',
          street_direction_code: '',
          cross_streets: '',
          user_notes: ['', '', '', ''],
          worksite_phone: '',
          other_phone: '',
          fax: '',
          worksite_type: workSiteTypes.Business,
          name: '',
          contact_name: '',
          customer_id: this.$route.query.customer_id || '',
          authorizing_person_name: '',
          district_id: '',
          category_id: '',
          exempt_from_sales_tax: false,
          withholding_state_id: '',
          withholding_local_id: '',
          withholding_local_2_id: '',
          sui_sdi_state_id: '',
        },
        workSiteTypeOptions: [
          {
            label: workSiteTypeLabels[workSiteTypes.Home],
            value: workSiteTypes.Home,
          },
          {
            label: workSiteTypeLabels[workSiteTypes.Apartment],
            value: workSiteTypes.Apartment,
          },
          {
            label: workSiteTypeLabels[workSiteTypes.Business],
            value: workSiteTypes.Business,
          },
        ],
      }
    },
    computed: {
      addressChanged() {
        const oldAddress = pick(this.previewAddress, addressFields)
        const currentAddress = pick(this.model, addressFields)
        return (JSON.stringify(currentAddress) !== JSON.stringify(oldAddress))
      },
      getCustomerDefaults() {
        return this.useWorksiteData ? {
          name: this.model.name,
          addresses: [
            {
              address_1: `${this.model.street_name}, ${this.model.street_number}, ${this.model.unit_number}`,
              city: this.model.city,
              state: this.model.state,
              type: addressTypes.Billing,
              primary: true,
            },
          ],
        } : {}
      },
    },
    methods: {
      async onValidateAddress() {
        try {
          const params = pick(this.model, addressFields)
          this.validateAddressLoading = true

          const { data } = await axios.get('/restify/work-sites', {
            params,
          })

          this.workSiteAlreadyExists = !!data.length

          if (!this.workSiteAlreadyExists) {
            this.$success(this.$t('Worksite address is valid, you can continue.'))
          } else {
            this.$error(this.$t('Worksite with this address exists, please add new address.'))
          }
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.validateAddressLoading = false
          this.previewAddress = {
            ...this.model,
          }
        }
      },
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/work-sites/${this.model.id}`, this.model)
            this.$success(this.$t('Worksite updated'))
            await this.$addSystemGeneratedNote({
              resourceName: RestifyResources.WorkSites,
              resourceId: this.model.id,
              isEdit: true
            })
            this.$emit('refresh', this.model.id)
          } else {
            const { data } = await axios.post('/restify/work-sites', this.model)
            await this.$addSystemGeneratedNote({
              resourceName: RestifyResources.WorkSites,
              resourceId: data.id,
            })
            this.$success(this.$t('Worksite created'))
            if (this.$createAnotherEntity && this.redirectToListAfterStore) {
              return this.$emit('create-another')
            }
            if (this.redirectToListAfterStore) {
              await this.$router.push('/service-billing/worksites')
            }
            this.$emit('save', this.model)
          }
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.loading = false
        }
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return
          }
          this.model = {
            ...this.model,
            ...value,
          }
          this.previewAddress = pick(value, addressFields)
        },
      },
    },
  }
</script>
