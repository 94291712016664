<template>
  <entity-select
      url="/restify/customers"
      v-bind="$attrs"
      v-on="$listeners"
      :url-params="allUrlParams"
      :name="$attrs.name || $t('Customer')"
      :placeholder="$attrs.placeholder || $t('Select Customer')"
      :label="$attrs.label !== undefined ? $attrs.label : $t('Customer')"
      :add-entity="addEntity"
      :edit-entity="editEntity"
      :set-default-option="editEntity"
      :add-label="$t('Add new Customer')"
      :on-map-entry="onChangeEntry"
      :label-format="formatCustomerLabel"
      :id="$attrs.id || 'customer-select'"
      :table-columns="customerTableColumns"
      :remote-search="true"
      :value-key="valueKey"
      :initial-value="initialValue"
      label-key="code"
      class="flex-1"
  />
</template>
<script>
  export default {
    inheritAttrs: false,
    props: {
      urlParams: {
        type: Object,
        default: () => ({
          sort: 'code',
        }),
      },
      addEntity: {
        type: Boolean,
        default: true,
      },
      editEntity: {
        type: Boolean,
        default: false,
      },
      showOpenAmount: {
        type: Boolean,
        default: false,
      },
      showCashOnDeposit: {
        type: Boolean,
        default: false,
      },
      valueKey: {
        type: String,
        default: 'id',
      },
      sortBy : {
        type: String,
        default: 'code',
      },
      initialValue: {
        type: [Object, Array],
      },
    },
    computed: {
      allUrlParams() {
        return {
          sort: this.sortBy,
          ...this.urlParams,
        }
      },
      customerTableColumns() {
        const columns =  [
          {
            minWidth: 100,
            maxWidth: 150,
            name: this.$t('Code'),
            prop: 'attributes.code',
          },
          {
            minWidth: 200,
            maxWidth: 200,
            name: this.$t('Name'),
            prop: 'attributes.name',
          },
        ]
        if (this.showOpenAmount) {
          columns.push({
            minWidth: 140,
            maxWidth: 200,
            name: this.$t('Open Amount'),
            prop: 'attributes.current_ar_amount',
            toFormat: this.$formatPrice,
          })
        }
        if (this.showCashOnDeposit) {
          columns.push({
            minWidth: 120,
            maxWidth: 200,
            name: this.$t('Cash On Deposit'),
            prop: 'attributes.current_unapplied_cash_on_deposit_amount',
            toFormat: this.$formatPrice,
          })

        }
        return columns
      }
    },
    methods: {
      formatCustomerLabel(customer) {
        const { code, name } = customer?.attributes || {}
        if (!name) {
          return code
        }
        return `${code} (${name})`
      },
      onChangeEntry(customer) {
        this.$emit('on-map-entry', customer)
      },
    },
  }
</script>

