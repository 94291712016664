<template>
  <base-form
    :loading="loading"
    :showCancel="showCancel"
    :showBack="showBack"
    :save-text="$t('Create bank')"
    :update-text="$t('Update bank')"
    :layout="layout"
    grid-classes="grid grid-cols-12 gap-x-3"
    @cancel="onCancel"
    @submit="onSubmit"
  >
    <base-input
      v-model="model.name"
      :label="$t('Name')"
      :placeholder="$t('Bank Name')"
      id="name"
      rules="required|max:150"
      class="col-span-12 md:col-span-4"
      @click.native.stop
    />
    <base-select
      v-model="model.account_type"
      :options="bankAccountTypes"
      :label="$t('Type')"
      :name="$t('Type')"
      id="account_type"
      class="col-span-12 md:col-span-2"
    />
    <div class="col-span-12 md:col-span-2">
      <base-switch
        v-model="model.active"
        :label-info="$t('Active')"
        id="active"
      />
    </div>
    <div class="col-span-12 md:col-span-2"/>
    <account-select
      v-model="model.account"
      :name="$t('Account')"
      :label="$t('Account')"
      rules="required"
      value-key="number"
      id="account"
      class="col-span-12 md:col-span-3"
    />
    <sub-account-select
      v-model="model.subaccount"
      :name="$t('Sub Account')"
      :label="$t('Sub Account')"
      value-key="number"
      id="subaccount"
      class="col-span-12 md:col-span-6 lg:col-span-2"
    />
    <base-select
      v-model="model.used_in"
      :options="BankUsedInOptions"
      :name="$t('Used For')"
      :label="$t('Used For')"
      multiple
      class="col-span-12 md:col-span-6 lg:col-span-3"
    />
    <div class="col-span-12">
      <el-collapse v-model="activeCollapseGroups">
        <base-collapse-section
          :title="$t('Contact & Addresses')"
          key="contact_infos"
          name="contact_infos"
        >
          <div class="grid grid-cols-2 gap-x-8">
            <div class="col-span-1 grid grid-cols-3 gap-x-3">
              <div class="col-span-3 md:col-span-1">
                <base-input
                  v-model="model.email"
                  :name="$t('Email')"
                  :label="$t('Email')"
                  :placeholder="$t('Email')"
                  id="email"
                />
              </div>
              <div class="col-span-3 md:col-span-1">
                <base-phone-input v-model="model.phone"/>
              </div>
              <div class="col-span-3 md:col-span-1">
                <base-phone-input
                  v-model="model.fax"
                  :label="$t('Fax')"
                  :name="$t('Fax')"
                  id="fax"
                />
              </div>
              <div class="col-span-3">
                <base-textarea
                  v-model="model.memo"
                  :label="$t('Memo')"
                  :placeholder="$t('Memo')"
                  id="memo"
                />
              </div>
            </div>

            <AddressList
              :data="model.addresses"
              :entity-id="model.id"
              :resource="model"
              ref="addressList"
              entity="banks"
              @refresh="$emit('refresh')"
              class="col-span-1"
            />
          </div>
        </base-collapse-section>

      </el-collapse>
      <div class="col-span-12 rounded-md bg-orange-50 shadow p-3 mb-4"
           v-if="!model.id">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg class="h-5 w-5 text-orange-400" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clip-rule="evenodd"/>
            </svg>
          </div>
          <div class="ml-3 flex-1 md:flex md:justify-between">
            <p class="text-sm leading-5 text-orange-700">
              <b>{{ $t('Bank Number') }}</b> {{ $t('will be assigned automatically.') }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12 mt-8">
      <div class="flex space-x-2 mb-4">
        <BaseButton
          variant="white"
          @click="showUploadSignature = true">
          {{ $t('Upload Signature') }}
        </BaseButton>
        <BaseButton
          v-if="hasSignature"
          variant="white"
          @click="removeSignature">
          {{ $t('Remove Signature') }}
        </BaseButton>
      </div>
      <img
        v-if="hasSignature"
        :src="signatureUrl"
        alt="signature"
        class="h-[100px] w-auto"
      >
      <SignatureUploadDialog
        v-if="showUploadSignature"
        :open.sync="showUploadSignature"
        :name="model.name"
        entity="banks"
        @save="onImageSave"
      />
    </div>
  </base-form>
</template>
<script>
import axios from 'axios'
import { BankAccountTypes, bankAccountTypes, BankUsedInOptions } from '@/enum/enums'
import AddressList from '@/modules/common/components/AddressList'
import SignatureUploadDialog from "@/modules/settings/components/SignatureUploadDialog.vue";

export default {
  components: {
    AddressList,
    SignatureUploadDialog,
    ImageCropper: () => import('@/components/common/files/ImageCropper'),
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    redirectToListAfterStore: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      attachmentsCount: 0,
      loading: false,
      showCancel: true,
      showBack: true,
      showUploadSignature: false,
      bankAccountTypes,
      BankUsedInOptions,
      activeCollapseGroups: ['contact_infos', 'check_signature'],
      signatureUrl: null,
      model: {
        name: '',
        account: undefined,
        subaccount: undefined,
        account_type: BankAccountTypes.Checking,
        used_in: [],
        routing_number: null,
        account_number: null,
        active: true,
        memo: '',
        email: '',
        phone: '',
        fax: '',
        is_for_dd: false,
        is_for_micr: false,
        is_for_positive_pay: false,
        positive_pay_format: 'csv',
        print_company_info: false,
        print_bank_info: false,
        print_check_number: false,
        print_company_logo: true,
        meta: {},
      },
    }
  },
  computed: {
    layout() {
      return this.redirectToListAfterStore ? 'vertical' : 'modal'
    },
    hasSignature() {
      return this.model.meta.signature_document_id
    },
  },
  methods: {
    onCancel() {
      if (this.redirectToListAfterStore) {
        return this.$router.push('/settings/banks')
      }
      this.$emit('cancel')
    },
    async onSubmit() {
      try {
        this.loading = true

        if (this.model.id) {
          const { data } = await axios.put(`/restify/banks/${this.model.id}`, this.model)
          this.$success(this.$t('Company bank updated'))
          this.$emit('refresh')
          this.$emit('save', data)
        } else {
          const { data } = await axios.post('/restify/banks', this.model)
          await this.$refs.addressList.attachTemporaryAddresses(data.id)
          this.$success(this.$t('Company bank added'))
          await this.$store.dispatch('globalLists/getGeneralConfiguration', true)
          if (this.redirectToListAfterStore) {
            await this.$router.push(`/settings/banks/${data.id}/edit`)
          }
          this.$emit('save', data)
        }
      } catch (err) {
        console.warn(err)
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not update the company bank'))
      } finally {
        this.loading = false
      }
    },
    async setSignatureId(id = undefined) {
      if (!this.model.meta) {
        this.model.meta = {}
      }
      const previousDocId = this.model.meta.signature_document_id
      this.model.meta = {
        ...this.model.meta,
        signature_document_id: id,
      }
      if (previousDocId) {
        await axios.delete(`/restify/documents/${previousDocId}`, {
          params: {
            ignore404: true,
          }
        })
      }
    },
    async removeSignature() {
      await this.setSignatureId(undefined)
      await this.onSubmit()
    },
    async onImageSave({ id }) {
      await this.setSignatureId(id)
      await this.onSubmit()
      this.showUploadSignature = false
    },
    async tryGetDocumentUrl() {
      if (!this.hasSignature) {
        return
      }
      const documentId = this.model.meta.signature_document_id
      const { data } = await axios.get(`/restify/documents/${documentId}`)
      this.signatureUrl = data?.attributes?.url
    }
  },
  watch: {
    data: {
      immediate: true,
      async handler(value) {
        if (!value?.id) {
          return
        }

        this.model = {
          ...this.model,
          ...value,
        }

        const { addresses } = value || []

        if (!addresses || !addresses.length) {
          return ''
        }

        this.address = addresses?.[0]
        await this.tryGetDocumentUrl()
      },
    },
  },
}
</script>
