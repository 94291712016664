<template>
  <entity-select
      v-bind="$attrs"
      v-on="$listeners"
      :filter-options="filterOptions"
      :url-params="urlParams"
      :name="$attrs.name || $t('Work Order')"
      :placeholder="$attrs.placeholder || $t('Select Work Order')"
      :label="$attrs.label !== undefined ? $attrs.label : $t('Work Order')"
      :add-label="$t('Add new Work Order')"
      :id="$attrs.id || 'work-orders-select'"
      :table-columns="tableColumns"
      :label-format="formatLabel"
      :hide-label="hideLabel"
      :value-key="valueKey"
      url="/restify/work-orders"
      label-key="number"
      class="w-full"
  />
</template>
<script>

  export default {
    name: 'WSelect',
    inheritAttrs: false,
    props: {
      urlParams: {
        type: Object,
        default: () => ({
          sort: 'number',
        }),
      },
      excludeIds: {
        type: Array,
        default: () => [],
      },
      hideLabel: {
        type: Boolean,
        default: false,
      },
      valueKey: {
        type: String,
        default: 'id',
      },
    },
    data() {
      return {
        tableColumns: [
          {
            minWidth: 100,
            maxWidth: 150,
            name: this.$t('Number'),
            prop: 'attributes.number',
          },
          {
            minWidth: 110,
            maxWidth: 110,
            name: this.$t('Date'),
            prop: 'attributes.date',
            component: 'FormattedDate',
          },
          {
            minWidth: 150,
            maxWidth: 150,
            name: this.$t('Description'),
            prop: 'attributes.description',
          },
        ],
      }
    },
    methods: {
      filterOptions(options) {
        if (!this.excludeIds.length) {
          return options
        }

        return options.filter(o => {
          return !this.excludeIds.includes(o.id)
        })
      },
      formatLabel(workOrder) {
        const { number, description } = workOrder.attributes
        return !description ? number : `${number} (${description})`
      },
    },
  }
</script>
