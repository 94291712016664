<template>
  <span
    v-if="resourceName || getData"
    @click="refreshResource"
    class="-ml-2 h-4">
    <BaseTooltip :content="$t('Refresh data')">
      <base-button
        :loading="dataLoading"
        variant="link"
        size="no-padding"
      >
        <RefreshCwIcon class="w-4 h-4"/>
      </base-button>
    </BaseTooltip>
  </span>
</template>
<script>
import { RefreshCwIcon } from "vue-feather-icons";

export default {
  components: {
    RefreshCwIcon
  },
  props: {
    resourceName: {
      type: String,
    },
    getData: {
      type: Function,
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    refreshResourceLoading() {
      return this.$store.state.globalLists.refreshResourceLoading
    },
    dataLoading() {
      return this.refreshResourceLoading || this.loading
    }
  },
  methods: {
    async refreshResource() {
      if (this.getData) {
        try {
          this.loading = true
          await this.getData()
        } finally {
          this.loading = false
        }
      }
      if (!this.resourceName) {
        return
      }
      await this.$store.dispatch('globalLists/refreshResource', this.resourceName)
    }
  }
}
</script>
<style>
</style>
