<template>
  <div>
    <div
      v-if="readOnly"
      class="flex w-full justify-end mb-2 pr-4"
    >
      <BaseSwitch
        v-if="showPhaseTotals"
        v-model="expandAll"
        :label-info="$t('Toggle Phase Details')"
        inline
        @change="toggleExpand"
        class="mr-2"
      />
      <BaseSwitch
        v-model="showPhaseTotals"
        :label-info="$t('Group By Phase')"
        inline
      />
    </div>
    <AgDataTable
      :key="showPhaseTotals"
      v-bind="attrs"
      :url="url"
      :url-params="urlParams"
      :entries="entries"
      :columns="columns"
      :per-page="perPage"
      :transform-data="transformData"
      :groupIncludeTotalFooter="true"
      :show-cells-legend="showCellsLegend"
      :enableBrowserTooltips="true"
      :read-only="readOnly"
      :groupDefaultExpanded="-1"
      :groupIncludeFooter="true"
      :groupRowRendererParams="groupRowRendererParams"
      :auto-group-column-def="autoGroupColumnDef"
      :groupDisplayType="showPhaseTotals ? 'singleColumn': 'groupRows'"
      suppressColumnReordering
      default-sort="relationships.addlSource.phase_code"
      ref="gridTable"
      id="gridTable"
      @grid-ready="grid = $event"
      @data-updated="onDataUpdated"
    >
      <template #cells-legend-after>
        <div class="flex space-x-2 items-center">
          <div class="w-4 h-4 rounded bg-orange-200"></div>
          <div>{{ $t('Exempt from sales tax') }}</div>
        </div>
        <div class="flex space-x-2 items-center">
          <div class="w-4 h-4 rounded bg-green-200"></div>
          <div>{{ $t('Entries with amounts') }}</div>
        </div>
      </template>
    </AgDataTable>
    <portal to="billing-summary">
      <div class="flex items-center">
        <div class="summary">
          {{ $t('Gross:') }}
          <span>
            {{ $formatPrice(getTotals.gross_amount) }}
          </span>
        </div>
        <div class="summary">
          {{ $t('Retention:') }}
          <span>
            {{ $formatPrice(getTotals.retention_amount) }}
          </span>
        </div>
        <div v-if="$settings($modules.AR, 'apply_tax_to_billings')"
             class="summary">
          {{ $t('Sales Tax:') }}
          <span>
            {{ $formatPrice(getTotals.sales_tax_amount) }}
          </span>
        </div>
        <div class="summary">
          {{ $t('Net:') }}
          <span>
            {{ $formatPrice(getTotals.net_amount) }}
          </span>
        </div>
      </div>
    </portal>
  </div>
</template>
<script>
  import axios from 'axios'
  import { resourceStatuses } from '@/enum/enums'
  import { editableTableProps, getTableData, getTableNodes, toggleRowExpand } from '@/components/ag-grid/tableUtils'
  import { composeEntryModels, composeLineItemModels } from '@/modules/accounts-receivable/pages/billings/billings'
  import { costCenterJobTypes } from '@/components/grid-table/utils/cost-center'
  import { cellEditors } from '@/components/ag-grid/cellEditors/cellEditors'
  import { cellClasses, requiredValueSetter } from '@/components/ag-grid/columnUtils'
  import BillingGroupRow from "@/modules/accounts-receivable/components/progress-bllings/BillingGroupRow.vue";
  import sumBy from "lodash/sumBy";
  import { getNumberValue } from "@/utils/utils";
  import { computeBillingEntrySalesTax } from "@/modules/accounts-receivable/utils/billingUtils";
  import { getPhaseTitle } from "@/modules/job-costing/utils/phaseUtils";

  const computedFields = {
    Quantity: 'quantity',
    GrossAmount: 'gross_amount',
    Retention: 'retention_amount',
    TaxAmount: 'sales_tax_amount',
    UnitRate: 'unit_rate',
    PriorRetention: 'prior_retention_amount',
  }

  export default {
    components: {
      BillingGroupRow
    },
    props: {
      billing: {
        type: Object,
        default: () => ({}),
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      getBillingEntries: Function,
      showCellsLegend: {
        type: Boolean,
        default: true,
      }
    },
    data() {
      return {
        editableTableProps,
        grid: null,
        entries: [],
        entriesToDelete: [],
        perPage: 100,
        expandAll: true,
        showPhaseTotals: false,
        autoGroupColumnDef: {
          headerName: this.$t('Group'),
          minWidth: 200,
          cellRendererParams: {
            suppressCount: true,
          }
        },
      }
    },
    computed: {
      attrs() {
        return {
          ...editableTableProps,
          ...this.$attrs,
        }
      },
      url() {
        if (!this.billing.job_id) {
          return ''
        }
        if (this.isBillingCompleted) {
          return '/restify/billing-entries'
        }

        return '/restify/line-items/unit-price'
      },
      urlParams() {
        if (!this.billing.job_id) {
          return {}
        }
        if (this.isBillingCompleted) {
          return {
            billing_id: this.billing.id,
            perPage: this.perPage,
            related: 'addlSource',
            sort: 'order',
          }
        }

        return {
          job_id: this.billing.job_id,
          perPage: this.perPage,
        }
      },
      groupRowRendererParams() {
        return {
          suppressCount: true,
          innerRenderer: 'BillingGroupRow',
          innerRendererParams: {
            jobId: this.billing.job_id,
          }
        }
      },
      isBillingCompleted() {
        return [resourceStatuses.Posted, resourceStatuses.Voided, resourceStatuses.Paid, resourceStatuses.PartialPaid].includes(this.billing.status)
      },
      hasChangeOrders() {
        return this.entries.some(entry => entry?.has_change_order)
      },
      getTotals() {
        const gross_amount = sumBy(this.entries, entry => getNumberValue(entry?.gross_amount))
        const retention_amount = sumBy(this.entries, entry => getNumberValue(entry?.retention_amount))
        const sales_tax_amount = sumBy(this.entries, entry => getNumberValue(entry?.sales_tax_amount))

        const net_amount = gross_amount - retention_amount + sales_tax_amount

        return {
          retention_amount,
          gross_amount,
          sales_tax_amount,
          net_amount,
        }
      },
      columns() {
        const columns = [
          {
            field: 'has_change_order',
            rowGroup: this.hasChangeOrders,
            hide: true,
            rowGroupIndex: 0,
            valueGetter: params => {
              const changeOrder = params.data?.relationships?.addlSource?.change_order
              return changeOrder > 0 ? this.$t('Change Orders') : this.$t('Base Contract')
            },
          },
          {
            headerName: this.$t('Contract'),
            children: [
              {
                headerName: this.$t('Line Item'),
                headerComponent: 'ActionsHeader',
                field: 'phase_cost_codes',
                component: 'AddlSourceLink',
                cellRendererParams: {
                  target: '_blank',
                },
                minWidth: 240,
                maxWidth: 320,
              },
              {
                headerName: this.$t('Qty'),
                field: this.isBillingCompleted ? 'attributes.meta.posted_quantity' : 'budget_quantity',
                valueFormatter: params => {
                  if (params.node.footer || params.node.group) {
                    return
                  }
                  let { um, budget_quantity } = params.data
                  if (this.isBillingCompleted) {
                    um = params.data?.attributes?.um
                    budget_quantity = params.data?.attributes?.meta?.posted_quantity
                  }
                  return `${budget_quantity || ''} ${um || ''}`
                },
                cellClass: params => {
                  if (params.node.footer || this.readOnly) {
                    return 'flex justify-end'
                  }
                  return `${cellClasses.ReadOnlyLight} flex justify-end`
                },
                minWidth: 60,
                maxWidth: 160,
              },
              {
                headerName: this.$t('Unit Rate'),
                field: this.isBillingCompleted ? 'attributes.meta.posted_unit_rate' : 'unit_rate',
                component: 'FormattedPrice',
                cellClass: params => {
                  if (params.node.footer || this.readOnly) {
                    return 'flex justify-end'
                  }
                  return `${cellClasses.ReadOnlyLight} flex justify-end`
                },
                minWidth: 100,
                maxWidth: 150,
              },
              {
                headerName: this.$t('Value'),
                field: this.isBillingCompleted ? 'attributes.meta.posted_amount' : 'amount',
                align: 'right',
                valueFormatter: params => {
                  if (params.node.footer || params.node.group) {
                    let amount = params.node.aggData[params.colDef.field]
                    if (!amount) {
                      return ''
                    }
                    return this.$formatPrice(amount)
                  }
                  let amount = params.data.amount
                  if (this.isBillingCompleted) {
                    amount = params.data?.attributes?.meta?.posted_amount
                  }
                  return this.$formatPrice(amount)
                },
                valueGetter: params => {
                  return +this.get(params.data, params.colDef.field)
                },
                cellClass: params => {
                  if (params.node.footer || this.readOnly) {
                    return 'flex justify-end'
                  }
                  return `${cellClasses.ReadOnlyLight} flex justify-end`
                },
                minWidth: 120,
                maxWidth: 160,
                aggFunc: 'sum',
              },
            ],
          },
          {
            headerName: 'Previous Completed',
            children: [
              {
                headerName: this.$t('Qty'),
                field: this.isBillingCompleted ? 'attributes.meta.posted_quantity_to_date' : 'quantity_to_date',
                cellClass: params => {
                  if (params.node.footer || this.readOnly) {
                    return 'flex justify-end'
                  }
                  return `${cellClasses.ReadOnlyLight} flex justify-end`
                },
                minWidth: 60,
                maxWidth: 100,
              },
              {
                headerName: this.$t('Amount'),
                field: this.isBillingCompleted ? 'attributes.meta.posted_amount_to_date' : 'amount_to_date',
                cellClass: params => {
                  if (params.node.footer || this.readOnly) {
                    return 'flex justify-end'
                  }
                  return `${cellClasses.ReadOnlyLight} flex justify-end`
                },
                component: 'FormattedPrice',
                minWidth: 120,
                maxWidth: 180,
                aggFunc: 'sum',
              },
            ],
          },
          {
            headerName: 'This Period',
            children: [
              {
                headerName: this.$t('Quantity'),
                field: this.isBillingCompleted ? 'attributes.quantity' : 'quantity',
                cellEditor: cellEditors.Numeric,
                editable: !this.readOnly,
                minWidth: this.readOnly ? 70 : 100,
                maxWidth: 140,
                valueSetter: params => {
                  const isValid = requiredValueSetter(params, 0, 0)

                  if (!isValid) {
                    return false
                  }

                  this.calculateAmounts(params, computedFields.Quantity)
                  return true
                },
                align: 'right',
              },
              {
                headerName: this.$t('Amount'),
                field: this.isBillingCompleted ? 'attributes.gross_amount' : 'gross_amount',
                component: 'FormattedPrice',
                cellEditor: cellEditors.Numeric,
                tooltipValueGetter(params) {
                  if (params.data?.exempt_from_sales_tax) {
                    return 'This line item is exempt from sales tax.'
                  }
                },
                cellClass: params => {
                  if (params.data?.exempt_from_sales_tax && params?.value) {
                    return 'warning-ag-cell flex justify-end'
                  }
                  if (params?.value && params.data) {
                    return `${cellClasses.Highlight} flex justify-end`
                  }
                  return 'flex justify-end'
                },
                valueSetter: params => {
                  const isValid = requiredValueSetter(params, 0, 0)

                  if (!isValid) {
                    return false
                  }

                  this.calculateAmounts(params, computedFields.GrossAmount)
                  return true
                },
                editable: !this.readOnly,
                minWidth: 140,
                align: 'right',
                aggFunc: 'sum',
              },
            ],
          },
          {
            headerName: 'Retention',
            children: [
              {
                headerName: this.$t('Prior'),
                field: this.isBillingCompleted ? 'attributes.meta.posted_retention_amount' : 'prior_retention_amount',
                cellClass: params => {
                  if (params.node.footer || this.readOnly) {
                    return 'flex justify-end'
                  }
                  return `${cellClasses.ReadOnlyLight} flex justify-end`
                },
                component: 'FormattedPrice',
                cellEditor: cellEditors.Numeric,
                aggFunc: 'sum',
                minWidth: 100,
              },
              {
                headerName: this.$t('Current'),
                field: this.isBillingCompleted ? 'attributes.retention_amount' : 'retention_amount',
                component: 'FormattedPrice',
                cellEditor: cellEditors.Numeric,
                align: 'right',
                aggFunc: 'sum',
                editable: !this.readOnly,
                minWidth: 100,
              },
            ],
          },
          {
            headerName: this.$t('Sales Tax'),
            field: this.isBillingCompleted ? 'attributes.sales_tax_amount' : 'sales_tax_amount',
            component: 'FormattedPrice',
            cellEditor: cellEditors.Numeric,
            cellClass: params => {
              if (params.data?.exempt_from_sales_tax && params?.value) {
                return `${cellClasses.Warning} flex justify-end`
              }
              if (params?.value && params.data) {
                return `${cellClasses.Highlight} flex justify-end`
              }
              return 'flex justify-end'
            },
            valueSetter: params => {
              const isValid = requiredValueSetter(params, 0, 0)
              if (!isValid) {
                return false
              }
              params.data.sales_tax_amount = +params.newValue
              if (params.data.meta?.length === 0) {
                params.data.meta = {}
              }
              params.data.meta.custom_sales_tax = true
              return true
            },
            editable: !this.readOnly,
            minWidth: 140,
            maxWidth: 200,
            align: 'right',
            aggFunc: 'sum',
            hide: true,
          },
        ]
        if (this.showPhaseTotals && this.readOnly) {
          columns.splice(1, 0, {
            field: 'relationships.addlSource.phase_code',
            hide: true,
            rowGroupIndex: 1,
            valueGetter: params => {
              const phase = params.data?.relationships?.addlSource?.phase_code || params.data?.phase_code
              return getPhaseTitle(phase, this.billing.job_id)
            }
          })
        }
        return columns
      },
    },
    methods: {
      onDataUpdated(entries) {
        this.entries = entries.filter((entry) => entry !== undefined)
      },
      async transformData(data) {
        if (!this.billing.id) {
          this.entries = composeLineItemModels(data, this)
        } else {
          const billingEntries = await this.getBillingEntries()
          this.entries = composeEntryModels(data, billingEntries, this)
          this.entriesToDelete = billingEntries.filter(entry => entry.id && !entry.relationships?.addlSource)
        }
        return this.entries
      },
      computeSalesTax(entry) {
        return computeBillingEntrySalesTax(entry, this.billing)
      },
      computeRetention(entry) {
        return this.billing.retention_percent ? (+entry[computedFields.GrossAmount] * this.billing.retention_percent / 100) : 0
      },
      computeRetentionAndTax(entry) {
        entry[computedFields.Retention] = this.computeRetention(entry)
        const salesTax = this.computeSalesTax(entry)
        entry[computedFields.TaxAmount] = salesTax.amount
        entry.exempt_from_sales_tax = salesTax.exempt_from_sales_tax
      },
      getEntries() {
        return getTableData(this.grid.api)
      },
      recomputeEntryAmounts() {
        const nodes = getTableNodes(this.grid.api)
        nodes.forEach(node => {
          const entry = node.data
          entry.dirty = true
          this.computeRetentionAndTax(entry)
          node.setData(entry)
        })
      },
      getEntriesToUpdate() {
        let entries = this.entries.filter(entry => entry.dirty && entry.id || (entry.description && entry?.addl_source?.description && entry.id))
        entries = entries.map(entry => {
          const salesTax = this.computeSalesTax(entry)
          return {
            account: entry.account,
            addl_source_id: entry.addl_source_id,
            addl_source_type: entry.addl_source_type,
            billing_id: entry.billing_id,
            cost_center: entry.cost_center,
            discount_amount: entry.discount_amount,
            gross_amount: entry.gross_amount,
            id: entry.id,
            material_stored_amount: entry.material_stored_amount,
            meta: entry.meta,
            net_amount: entry.net_amount,
            order: entry.order,
            quantity: entry.quantity,
            retention_amount: entry.retention_amount,
            retention_percent: entry.retention_percent,
            sales_tax_percent: entry.sales_tax_percent,
            source_id: entry.source_id,
            source_type: entry.source_type,
            special_source_id: entry.special_source_id,
            special_source_type: entry.special_source_type,
            subaccount: entry.subaccount,
            type_id: entry.type_id,
            type_type: entry.type_type,
            um: entry.um,
            unit_rate: entry.unit_rate,
            // extra fields
            description: entry?.addl_source?.description || entry.description,
            sales_tax_amount: salesTax.amount,
            exempt_from_sales_tax: salesTax.exempt_from_sales_tax,
          }
        })
        return entries
      },
      async getEntriesToSave(billing_id) {
        const entries = this.entries.filter(entry => !entry.id)

        const result = []

        for (let i = 0; i < entries.length; i++) {
          const entry = entries[i]
          const salesTax = this.computeSalesTax(entry)
          const model = {
            ...entry,
            billing_id,
            sales_tax_amount: salesTax.amount,
            exempt_from_sales_tax: salesTax.exempt_from_sales_tax,
            source_id: this.billing.job_id,
            source_type: costCenterJobTypes.Job,
            type_id: entry.job_type_id,
            type_type: costCenterJobTypes.Type,
            addl_source_id: entry.line_item_id,
            addl_source_type: costCenterJobTypes.LineItem,
            relationships: undefined,
          }

          result.push(model)
        }

        return result
      },
      async storeProgress(billingID) {
        const entriesToUpdate = this.getEntriesToUpdate()
        const entriesToSave = await this.getEntriesToSave(billingID)

        if (this.entriesToDelete.length) {
          await axios.post('/restify/billing-entries/actions?action=bulk-delete-quietly', {
            billing_id: billingID,
            repositories: this.entriesToDelete.map(entry => entry.id),
          })
        }

        let promises = []
        if (entriesToUpdate.length) {
          promises.push(axios.post('/restify/billing-entries/bulk/update', entriesToUpdate))
        }

        if (entriesToSave.length) {
          promises.push(
              axios.post(`/restify/billings/${billingID}/actions?action=create-unit-price-billing`, {
                calc_retention: this.billing.retention_basis,
                entries: entriesToSave,
              }),
          )
        }

        await Promise.all(promises)
        this.refreshData()
      },
      calculateAmounts(params, prop) {
        params.data[prop] = params.newValue
        let currentEntry = params.data

        if (prop === computedFields.Quantity) {
          currentEntry[computedFields.GrossAmount] = +currentEntry[computedFields.Quantity] * +currentEntry[computedFields.UnitRate]
        }

        if (params.data.meta.custom_sales_tax) {
          params.data.meta.custom_sales_tax = false
        }

        currentEntry[computedFields.Retention] = this.computeRetention(currentEntry)
        const salesTax = this.computeSalesTax(currentEntry)
        currentEntry[computedFields.TaxAmount] = salesTax.amount
        currentEntry.exempt_from_sales_tax = salesTax.exempt_from_sales_tax

        const budget = currentEntry.amount
        const grossAmount = currentEntry[computedFields.GrossAmount]

        const totalQuantity = +currentEntry[computedFields.Quantity] + +currentEntry.quantity_to_date
        const budgetQuantity = currentEntry.budget_quantity

        if (grossAmount > budget) {
          let formattedBudget = this.$formatPrice(budget)
          let formattedGrossAmount = this.$formatPrice(grossAmount)
          this.$warning(this.$t(`Entry amount exceeds contract value. </br> Contract Value: ${formattedBudget} </br> Entry: ${formattedGrossAmount}`))
        }

        if (totalQuantity > budgetQuantity && grossAmount <= budget) {
          this.$warning(this.$t(`Qty to date exceeds contract quantity </br> Scheduled: ${budgetQuantity} </br> Entry: ${totalQuantity}`))
        }

        params.node.setData(currentEntry)
      },
      refreshData() {
        this.$refs.gridTable.refresh()
      },
      toggleExpand(value) {
        const level = this.hasChangeOrders ? 1 : 0
        toggleRowExpand(this.grid?.api, value, level)
      }
    },
  }
</script>
