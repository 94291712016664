<template>
  <div class="payroll-batch-view">
    <div class="flex justify-between print:hidden">
      <div>
        <BatchStats :batch="batchSummary.attributes"
                    class="mt-2"
        />
      </div>
      <div class="flex items-center space-x-2">
        <VoidPayrollBatch
          v-if="$isAuthorized('authorizedToVoid', batchSummary)"
          :batch-summary="batchSummary"
          :payroll-data="payrollData"
          @submit="loadBatchData"
        />
        <base-button variant="primary-link"
                     :disabled="!batchSummary.id"
                     @click="goToTimeCard">
          <ClockIcon class="w-4 h-4 mr-1"/>
          {{ $t('View Timecards') }}
        </base-button>

        <base-button
            v-if="!isPosted"
            variant="primary-link"
            :disabled="!batchSummary.id"
            @click="showGenerateChecksDialog = true">
          <FileTextIcon class="w-4 h-4 mr-1"/>
          {{ $t('Preview Checks') }}
        </base-button>
        <ReportPrintAction/>

        <DownloadChecksDialog
            ref="downloadChecksDialog"
            v-if="isPosted"
            :batch="batchSummary"
        />

        <PayrollBatchActions
                :batch="batchSummary"
                class="mx-3"
                @on-action-callback="loadBatchData"
        />
      </div>
    </div>
    <div class="print:hidden">
      <dl class="mt-4 grid grid-cols-1 gap-4 md:grid-cols-3 xl:grid-cols-5">
        <base-stats-card :title="$t('Gross Pay')">
          {{ $formatPrice(get(batchSummary, 'attributes.totals.gross_pay')) }}
        </base-stats-card>
        <base-stats-card :title="$t('Hours')">
          {{ get(batchSummary, 'attributes.totals.hours') }}
        </base-stats-card>
        <base-stats-card :title="$t('Tax Deductions')">
          {{ $formatPrice(get(batchSummary, 'attributes.totals.employee_taxes')) }}
        </base-stats-card>
        <base-stats-card :title="$t('Other Deductions')">
          {{ $formatPrice(get(batchSummary, 'attributes.totals.deductions')) }}
        </base-stats-card>
        <base-stats-card :title="$t('Net Pay')">
          {{ $formatPrice(get(batchSummary, 'attributes.totals.net_pay')) }}
        </base-stats-card>
      </dl>
    </div>
    <el-collapse class="print:hidden">
      <base-collapse-section key="work-permit"
                             :title="$t('Employer & Other Expenses')"
                             transparent
                             name="employer-taxes">
        <dl class="mb-6 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
          <base-stats-card :title="$t('Employer Taxes')">
            {{ $formatPrice(get(batchSummary, 'attributes.totals.employer_taxes')) }}
          </base-stats-card>
          <base-stats-card :title="$t('Benefits')">
            {{ $formatPrice(get(batchSummary, 'attributes.totals.benefits')) }}
          </base-stats-card>
          <base-stats-card :title="$t('Worker Comp / Gen Liability')">
            {{ $formatPrice(get(batchSummary, 'attributes.totals.insurance')) }}
          </base-stats-card>
          <base-stats-card :title="$t('Non Payroll Expense')">
            {{ $formatPrice(get(batchSummary, 'attributes.totals.non_payroll')) }}
          </base-stats-card>
        </dl>
      </base-collapse-section>
    </el-collapse>
    <el-collapse class="print:hidden">
      <base-collapse-section
          key="summary"
          :title="$t('Summary')"
          transparent
          name="summary">
        <PayrollSummary />
      </base-collapse-section>
    </el-collapse>
    <PayrollBatchEmployeeSummary
      ref="table"
      @data-loaded="onDataLoaded" />

    <PayrollPreviewChecksDialog
        v-if="showGenerateChecksDialog"
        :open.sync="showGenerateChecksDialog"
        :batch="batchSummary"
    />

    <base-back-link to="/payroll/batches"/>
  </div>
</template>
<script>
  import { ClockIcon, FileTextIcon } from 'vue-feather-icons'

  import BatchStats from '@/modules/payroll/components/payroll/BatchStats'
  import TableViewButton from '@/components/table/actions/TableViewButton'
  import PayrollBatchActions from '@/modules/payroll/components/payroll/PayrollBatchActions'
  import PayrollPreviewChecksDialog from '@/modules/payroll/components/payroll/PayrollPreviewChecksDialog'
  import { resourceStatuses } from "@/enum/enums";
  import PayrollSummary from "@/modules/payroll/components/PayrollSummary";
  import PayrollBatchEmployeeSummary from "@/modules/payroll/components/payroll/PayrollBatchEmployeeSummary.vue";
  import ReportPrintAction from "@/components/table/ReportPrintAction.vue";
  import DownloadChecksDialog from "@/modules/payroll/components/payroll/DownloadChecksDialog.vue";
  import VoidPayrollBatch from "@/modules/payroll/components/payroll/VoidPayrollBatch.vue";

  export default {
    name: 'PayrollBatchView',
    components: {
      VoidPayrollBatch,
      DownloadChecksDialog,
      ReportPrintAction,
      PayrollBatchEmployeeSummary,
      ClockIcon,
      FileTextIcon,
      BatchStats,
      PayrollSummary,
      TableViewButton,
      PayrollBatchActions,
      PayrollPreviewChecksDialog,
    },
    data() {
      return {
        showGenerateChecksDialog: false,
        loading: false,
        payrollData: [],
      }
    },
    computed: {
      batchSummary() {
        return this.$store.state.payroll.currentPayrollBatch
      },
      isPosted() {
        return this.batchSummary?.attributes?.status === resourceStatuses.Posted
      },
      periodEndDate() {
        return this.batchSummary?.attributes?.period_end_date
      },
    },
    methods: {
      onDataLoaded(data) {
        this.payrollData = data
      },
      async loadBatchData() {
        await this.$store.dispatch('payroll/getPayrollBatch', this.$route.params.id)
        await this.$nextTick()

        if (this.isPosted) {
          this.toggleTableExpand = false
        }

        if (this.$route.query.printChecks) {
          await this.$refs.downloadChecksDialog?.toggleDialog()
        }
        if (this.$refs.table) {
          this.$refs.table.refresh()
        }
      },
      async goToTimeCard() {
        const timeCardBatchId = this.get(this.batchSummary, 'attributes.timecard_batch_id')
        if (!timeCardBatchId) {
          this.$error(this.$t('No timecard batch found for this payroll'))
          return
        }
        await this.$router.push(`/payroll/timecard-batches/${timeCardBatchId}/view`)
      },
    },
  }
</script>
