<template>
  <div v-if="actions.length"
       class="bg-gray-50 border border-gray-200 rounded pt-3 pb-2 px-4 mt-2 print:hidden">
    <h4 class="font-medium text-base print:text-print mt-2">{{ $t('History') }}</h4>
    <div class="flex flex-col divide-y divide-gray-200">
      <div v-for="action in actions" :key="action.timestamp" class="py-1">
        <h5 class="font-medium text-sm print:text-print">
          {{ action.title}}
        </h5>
        <div class="flex flex-wrap space-x-1 text-gray-500 text-sm">
          <template v-if="action.user_id">
            <UserLink :id="action.user_id" class="user-link"/>
            <span>{{$t('on')}}</span>
          </template>
          <span>{{ formatDate(action.timestamp) }}</span>
          <span>{{$t('at')}}</span>
          <span>{{ formatHours(action.timestamp) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import { computed } from 'vue'
  import orderBy from 'lodash/orderBy'
  import i18n from "@/i18n";
  import { ContactModel } from "@/modules/common/types/common";
  import { billingTypes } from "@/modules/accounts-receivable/pages/billings/billings";
  import {formatDate, formatHours} from "@/plugins/dateFormatPlugin";

  const props = defineProps({
    data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    entity: {
      type: String,
      default: 'Invoice'
    }
  })

  const actions = computed(() => {
    const {
      paid_at,
      paid_by,
      voided_at,
      voided_by,
      posted_at,
      posted_by,
      created_at,
      created_by,
      updated_at,
      updated_by,
      approved_at,
      approved_by,
      rejected_at,
      rejected_by,
    } = props.data
    let initialData = [
      {
        title: `${props.entity} approved`,
        timestamp: approved_at,
        user_id: approved_by,
      },
      {
        title: `${props.entity} rejected`,
        timestamp: rejected_at,
        user_id: rejected_by,
      },
      {
        title: `${props.entity} created`,
        timestamp: created_at,
        user_id: created_by,
      },
      {
        title: `${props.entity} updated`,
        timestamp: updated_at,
        user_id: updated_by,
      },
      {
        title: `${props.entity} posted`,
        timestamp: posted_at,
        user_id: posted_by,
      },
      {
        title: `${props.entity} voided`,
        timestamp: voided_at,
        user_id: voided_by,
      },
      {
        title: `${props.entity} paid`,
        timestamp: paid_at,
        user_id: paid_by,
      },
    ]
    if (props.data?.telemetry?.actions) {
      const sendData = props.data?.telemetry.actions.map((action: any) => {
        const contactsArray = action.contacts || []
        const contacts = contactsArray?.map((contact: ContactModel) => `${contact.name} <${contact.email}>`).join(', ')
        const isBilling = [billingTypes.Progress, billingTypes.UnitPrice, billingTypes.LumpSum, billingTypes.CostPlus].includes(props.data.type)
        const invoiceTitle = isBilling ? i18n.t('Billing') : props.entity

        let title = i18n.t(`Sent ${invoiceTitle} to ${contacts}`)
        if (action.action === 'download') {
          const contactName = `${action.name} <${action.email}>`
          title = i18n.t(`${invoiceTitle} Downloaded ${contactName}`)
        }
        return {
          title,
          timestamp: action.timestamp,
          user_id: action.user?.id,
        }
      })
      initialData = initialData.concat(sendData)
    }
    return orderBy(initialData.filter(item => item.timestamp), 'timestamp', 'desc')
  })
</script>
<style scoped>
  .user-link :deep(a) {
    @apply text-gray-900;
  }
</style>
