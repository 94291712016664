<template>
  <base-badge :type="badgeType" class="status-badge">
    <slot>
      {{ formattedStatus }}
    </slot>
  </base-badge>
</template>
<script>
  import startCase from 'lodash/startCase'
  import BaseBadge from './BaseBadge';
  import i18n from "@/i18n";

  const successStates = ['sent', 'notice', 'success', 'new', 'yes', 'enabled', 'paid', true, 1, '1', 'approved', 'has_user']
  const dangerStates = ['blocked', 'inactive', 'rejected', 'emergency', 'critical', 'error', 'rejected', 'no', 'disabled', 'voided', 'void', 'closed', 'N/A', false, 0, '0']
  const infoStates = ['posted', 'invited', 'payroll submitted', 'submitted', 'invited']
  const grayStatuses = ['recurring', 'pending', 'open', 'active', 'with-timecard']

  export default {
    name: 'status-badge',
    components: {
      BaseBadge
    },
    props: {
      status: {
        required: true
      },
      plain: {
        type: Boolean,
        default: false
      },
      type: {
        type: String,
      },
      statusText: {
        type: String,
      }
    },
    computed: {
      badgeType() {
        if (this.type) {
          return this.type
        }
        if (typeof this.status !== 'string' || this.plain) {
          return 'default'
        }
        if (successStates.includes(this.status.toLowerCase())) {
          return 'success'
        }
        if (dangerStates.includes(this.status.toLowerCase())) {
          return 'danger'
        }
        if (infoStates.includes(this.status.toLowerCase())) {
          return 'info'
        }

        if (grayStatuses.includes(this.status.toLowerCase())) {
          return 'default'
        }
        return 'default'
      },
      formattedStatus() {
        if (this.statusText) {
          return this.statusText
        }
        if (this.status === true) {
          return i18n.t('yes')
        } else if (this.status === false) {
          return i18n.t('no')
        }
        if (this.status === undefined || this.status === null || this.status === '') {
          return i18n.t('N/A')
        }
        return startCase(this.status.toString())
      }
    }
  }
</script>
<style>
  .status-badge {
    min-width: 55px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @apply capitalize;
  }
</style>
