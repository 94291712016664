<template>
  <div class="my-4 px-6 w-full group">
    <div class="flex items-center w-full space-x-2 text-sm print:text-print tracking-wide">
      <span class="font-medium leading-normal text-gray-400 mr-2">{{ $t('Description:') }}</span>
      <div class="min-w-[350px]">
        <template v-if="!isEditing">
          <span class="truncate text-gray-900">{{ invoice.description }}</span>
        </template>
        <template v-else>
          <base-input
            v-model="description"
            ref="input"
            :placeholder="$t('Description')"
            :inline-errors="true"
            id="description"
            rules="max:150"
            @keydown.enter="onSave"
          />
        </template>
      </div>
      <div
        v-if="canEdit"
        class="opacity-0 group-hover:opacity-100"
      >
        <TableEditButton v-if="!isEditing" @click="toggleEdit"/>
        <TableSaveButton v-else @click="onSave"/>
      </div>
    </div>
  </div>
</template>
<script setup>
import { nextTick, ref, set, watch } from "vue";
import axios from "axios";
import { error } from "@/components/common/NotificationPlugin";
import i18n from "@/i18n";
import { $confirm } from "@/components/common/modal/modalPlugin";
import { RestifyResources } from "@/components/form/util";
import store from "@/store";

const props = defineProps({
  invoice: {
    type: Object,
    default: () => ({}),
  },
  entity: {
    type: String,
    default: 'invoices'
  },
  canEdit: {
    type: Boolean,
    default: true,
  }
})

const isEditing = ref(false)
const description = ref(props.invoice.description)

function toggleEdit() {
  isEditing.value = !isEditing.value
  focusOnInput()
}

const input = ref(null)

async function focusOnInput() {
  await nextTick()
  input.value?.focus()
}

async function onSave() {
  try {
    isEditing.value = false
    const confirm = await $confirm({
      title: i18n.t('Change invoice description'),
      description: i18n.t('Are you sure you want to change the invoice description? This will also change the description when printing the corresponding checks.'),
    })
    if (!confirm) {
      return
    }
    const invoiceId = props.invoice.id
    await axios.post(`/restify/${props.entity}/${invoiceId}/actions?action=update-description`, {
      description: description.value,
    })
    const resourceName = props.entity === 'invoices' ? RestifyResources.Invoices : RestifyResources.Billings
    const entityName = props.entity === 'invoices' ? 'Invoice' : 'Billing'
    await store.dispatch('notes/addSystemGeneratedNote', {
      resourceName,
      resourceId: invoiceId,
      customTitle: entityName + i18n.t(' description changed.'),
    })
    if (resourceName === RestifyResources.Billings) {
      await store.dispatch('accountsReceivable/getBilling', invoiceId)
    } else {
      await store.dispatch('accountsPayable/getInvoice', invoiceId)
    }
  } catch (err) {
    console.error(err)
    error(i18n.t('Failed to update the invoice description'))
    isEditing.value = true
  }
}

watch(() => props.invoice?.description, (newValue) => {
  description.value = newValue
})
</script>
