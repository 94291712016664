import EmptyLayout from "@/components/common/EmptyLayout";
import OrganizationSettings from "@/modules/settings/pages/organization-settings";
import OrganizationTranslations from "@/modules/settings/pages/organization-translations";
import OrganizationUsers from "@/modules/settings/pages/organization-users.vue";
import CompanyUsers from "@/modules/settings/pages/company-users.vue";
import UserDetailsLayout from "@/modules/settings/layout/UserDetailsLayout";
import UserView from "@/modules/settings/pages/user-view";
import UserDetails from "@/modules/settings/pages/user-details";
import CompaniesList from "@/modules/settings/pages/company/companies-list";
import CompanySettingsLayout from "@/modules/settings/layout/CompanySettingsLayout";
import CompanySettings from "@/modules/settings/pages/company/company-settings";
import CompanyAddressesList from "@/modules/settings/pages/company/company-addresses-list";
import CompanyLayout from "@/modules/settings/layout/CompanyLayout";
import CompanyEdit from "@/modules/settings/pages/company/company-edit";
import CompanyDetails from "@/modules/settings/pages/company/company-details";
import BankList from "@/modules/settings/pages/bank/bank-list";
import BankAdd from "@/modules/settings/pages/bank/bank-add";
import BankLayout from "@/modules/settings/layout/BankLayout";
import BankEdit from "@/modules/settings/pages/bank/bank-edit";
import BankDetails from "@/modules/settings/pages/bank/bank-details";
import RolesList from "@/modules/settings/pages/roles-list";
import EmailTemplateList from "@/modules/settings/pages/EmailTemplateList";
import EmailTemplate from "@/modules/settings/pages/EmailTemplate";
import AppTemplateList from "@/modules/settings/pages/AppTemplateList";
import AppTemplateDetails from "@/modules/settings/pages/AppTemplateDetails";
import BankDirectDeposit from "@/modules/settings/pages/bank/bank-direct-deposit";
import BankPositivePay from "@/modules/settings/pages/bank/bank-positive-pay";
import DocumentTypes from "@/modules/settings/pages/company/document-types.vue";
import CompanyTaxJurisdictions from "@/modules/settings/pages/company/company-tax-jurisdictions.vue";
import { RestifyResources } from "@/components/form/util";
import DocumentsTable from "@/modules/common/components/documents/DocumentsTable.vue";
import CompanyDocuments from "@/modules/settings/pages/company/company-documents.vue";

export default [
  {
    path: '/settings',
    name: 'Settings',
    component: EmptyLayout,
    redirect: '/settings/users',
    meta: {
      permissions: ['settings_show'],
      productionReady: true,
      isModule: true,
    },
    children: [
      {
        path: 'organization-settings',
        name: 'Organization Settings',
        title: 'Organization Settings',
        description: 'Manage your organization settings and information',
        meta: {
          permissions: ['organization_show', 'organization_update'],
          productionReady: true,
        },
        component: OrganizationSettings
      },
      {
        path: 'organization-translations',
        name: 'Organization translations',
        title: 'Organization translations',
        description: 'Manage your organization custom translations',
        meta: {
          permissions: ['organization_show', 'settings_show'],
          productionReady: true,
        },
        component: OrganizationTranslations
      },
      {
        path: 'users',
        name: 'Organization Users',
        title: 'Organization Users',
        description: 'Manage your organization users and update their information',
        meta: {
          permissions: ['users_show', 'settings_show'],
          productionReady: true,
        },
        component: OrganizationUsers
      },
      {
        path: 'company-users',
        name: 'Company Users',
        title: 'Company Users',
        description: 'Manage your company users and update access to the company',
        meta: {
          permissions: ['users_show', 'settings_show'],
          productionReady: true,
        },
        component: CompanyUsers
      },
      {
        path: 'users/:id',
        name: 'Users',
        redirect: 'users',
        meta: {
          permissions: ['users_show', 'settings_show'],
          productionReady: true,
        },
        component: UserDetailsLayout,
        children: [
          {
            path: 'view',
            name: 'User Details',
            component: UserView,
          },
          {
            path: 'edit',
            name: 'User Edit',
            component: UserDetails,
          },
        ]
      },
      {
        path: 'companies',
        name: 'Companies',
        title: 'Companies',
        description: 'Manage your organization companies and their users',
        meta: {
            permissions: ['companies_update'],
            productionReady: true,
        },
        component: CompaniesList
      },
      {
        path: '/',
        title: 'Company Settings',
        redirect: '/settings/company-settings',
        meta: {
          permissions: ['companies_update'],
          productionReady: true,
        },
        component: CompanySettingsLayout,
        children: [
          {
            path: 'company-settings',
            name: 'Company Settings',
            title: 'Company Settings',
            description: 'Company specific settings that apply globally at the company level.',
            meta: {
              permissions: ['company_settings', 'companies_update'],
              productionReady: true,
            },
            component: CompanySettings
          },
          {
            path: 'company-addresses',
            name: 'Company Addresses',
            title: 'Company Addresses',
            meta: {
              permissions: ['company_settings', 'companies_update'],
            },
            component: CompanyAddressesList
          },
          {
            path: 'document-types',
            name: 'Document Types',
            title: 'Document Types',
            meta: {
              permissions: ['company_settings'],
            },
            component: DocumentTypes
          },
          {
            path: 'state-tax-jurisdictions',
            name: 'State Tax Jurisdictions Settings',
            title: 'State Tax Jurisdictions Settings',
            meta: {
              permissions: ['company_settings'],
            },
            component: CompanyTaxJurisdictions
          },
        ],
      },
      {
        path: 'companies/:id',
        name: 'Company Details Layout',
        redirect: '/settings/companies',
        meta: {
            permissions: ['companies_update'],
          productionReady: true,
        },
        component: CompanyLayout,
        children: [
          {
            path: 'edit',
            name: 'Company Edit',
            meta: {
                permissions: ['companies_update'],
                productionReady: true,
            },
            component: CompanyEdit
          },
          {
            path: 'view',
            name: 'Company Details',
            meta: {
                permissions: ['companies_update'],
                productionReady: true,
            },
            component: CompanyDetails
          },
          {
            path: 'settings',
            name: 'Company Settings Edit',
            meta: {
                permissions: ['companies_update'],
                productionReady: true,
            },
            component: CompanyDetails
          },
          {
            path: 'documents',
            name: 'Company Documents',
            meta: {
              permissions: ['companies_update'],
              resourceName: RestifyResources.Companies,
            },
            component: CompanyDocuments,
          },
          {
            path: 'state-tax-jurisdictions',
            name: 'Company State Tax Jurisdictions',
            meta: {
              permissions: ['companies_update'],
              productionReady: true,
            },
            component: CompanyTaxJurisdictions,
          },
        ],
      },
      {
        path: 'banks',
        name: 'Banks',
        title: 'Banks',
        description: 'Banks',
        meta: {
          permissions: ['banks_show', 'banks_update', 'settings_show'],
        },
        component: BankList,
      },
      {
        path: 'banks/add',
        name: 'Add Bank',
        title: 'Add Bank',
        meta: {
          permissions: ['banks_update'],
        },
        component: BankAdd,
      },
      {
        path: 'banks/:id',
        name: 'Bank Details ',
        redirect: '/settings/banks',
        meta: {
          permissions: ['banks_update'],
        },
        component: BankLayout,
        children: [
          {
            path: 'edit',
            name: 'Bank Edit',
            meta: {
              permissions: ['banks_update'],
              productionReady: true,
            },
            component: BankEdit
          },
          {
            path: 'view',
            name: 'Bank Details',
            meta: {
              permissions: ['banks_update'],
              productionReady: true,
            },
            component: BankDetails
          },
          {
            path: 'direct-deposit',
            name: 'Bank Direct Deposit',
            meta: {
              permissions: ['banks_update'],
            },
            component: BankDirectDeposit
          },
          {
            path: 'positive-pay',
            name: 'Bank Positive Pay',
            meta: {
              permissions: ['banks_update'],
            },
            component: BankPositivePay,
          },
          {
            path: 'documents',
            name: 'Bank Documents',
            meta: {
              permissions: ['banks_update'],
              resourceName: RestifyResources.Banks,
            },
            component: DocumentsTable,
          },
        ]
      },
      {
        path: 'roles',
        name: 'Roles',
        title: 'Roles',
        description: 'Manage your organization roles and their permissions',
        meta: {
          permissions: ['company_roles_show', 'settings_show'],
          productionReady: true,
        },
        component: RolesList
      },
      {
        path: 'email-templates',
        name: 'Email Templates',
        title: 'Email Templates',
        description: 'Setup Email Templates',
        meta: {
          permissions: ['mail_templates_show', 'mail_templates_update'],
          productionReady: true,
        },
        component: EmailTemplateList,
      },
      {
        path: 'email-templates/:id',
        name: 'Email Template Details',
        meta: {
          permissions: ['mail_templates_update'],
          productionReady: true,
        },
        component: EmailTemplate,
      },
      {
        path: 'app-templates',
        name: 'App Templates',
        title: 'App Templates',
        description: 'Setup App Templates',
        meta: {
          permissions: ['app_templates_update'],
          productionReady: true,
        },
        component: AppTemplateList,
      },
      {
        path: 'app-templates/:id',
        name: 'App Template Edit',
        meta: {
          permissions: ['app_templates_update'],
          productionReady: true,
        },
        component: AppTemplateDetails,
      }
    ]
  }
]
