import Vue from 'vue'
import axios from 'axios'
import orderBy from 'lodash/orderBy'
import { $modules, addressTypes } from '@/enum/enums'
import CompanyService from '@/modules/settings/services/CompanyService'
import Cache from '@/utils/Cache'
import { getCurrentPeriod } from "@/utils/dateUtils";
import { isPeriodDefined } from "@/modules/ledger/utils/periodUtils";

const app = new Vue({})

const types = {
  SET_COMPANY: 'SET_COMPANY',
  SET_CURRENT_FISCAL_YEAR: 'SET_CURRENT_FISCAL_YEAR',
  SET_CURRENT_FISCAL_YEAR_DATA: 'SET_CURRENT_FISCAL_YEAR_DATA',
  SET_PERIODS: 'SET_PERIODS',
  SET_FISCAL_YEARS: 'SET_FISCAL_YEARS',
  SET_ACCOUNTS: 'SET_ACCOUNTS',
  SET_SUB_ACCOUNTS: 'SET_SUB_ACCOUNTS',
  SET_USER_COMPANIES: 'SET_USER_COMPANIES',
  SET_BANKS: 'SET_BANKS',
  SET_ADMIN_VIEWING_COMPANY: 'SET_ADMIN_VIEWING_COMPANY',
  SET_SESSION_CURRENT_FISCAL_YEAR: 'SET_SESSION_CURRENT_FISCAL_YEAR',
  SET_COMPANY_SETTINGS_PER_MODULE: 'SET_COMPANY_SETTINGS_PER_MODULE',
  SET_CURRENT_COMPANY: 'SET_CURRENT_COMPANY',
  SET_CURRENT_USER_COMPANY: 'SET_CURRENT_USER_COMPANY',
  SET_CURRENT_COMPANY_LOADING: 'SET_CURRENT_COMPANY_LOADING',
  SET_USERS_LOADING: 'SET_USERS_LOADING',
  SET_USERS: 'SET_USERS',
  SET_CURRENT_REPORT_HEADER: 'SET_CURRENT_REPORT_HEADER',
}

function getDefaultPeriods() {
  return [
    { number: 1, name: 'January', is_open: true },
    { number: 2, name: 'February', is_open: true },
    { number: 3, name: 'March', is_open: true },
    { number: 4, name: 'April', is_open: true },
    { number: 5, name: 'May', is_open: true },
    { number: 6, name: 'June', is_open: true },
    { number: 7, name: 'July', is_open: true },
    { number: 8, name: 'August', is_open: true },
    { number: 9, name: 'September', is_open: true },
    { number: 10, name: 'October', is_open: true },
    { number: 11, name: 'November', is_open: true },
    { number: 12, name: 'December', is_open: true },
  ]
}

const state = {
  activeCompany: {},
  currentCompany: {
    attributes: {},
    relationships: {},
    meta: {},
  },
  currentUserCompany: {},
  currentCompanyLoading: false,
  adminViewingCompany: false,
  userCompanies: [],
  fiscalYears: [],
  currentFiscalYearData: {},
  currentFiscalYear: null,
  accounts: [],
  subAccounts: [],
  periods: [],
  banks: [],
  sessionCurrentFiscalYear: false,
  settings: {},
  usersLoading: false,
  users: [],
  currentReportHeader: {}
}

const mutations = {
  [types.SET_COMPANY]: (state, value) => {
    state.activeCompany = {
      ...value?.attributes,
      addresses: [],
      ...value?.relationships
    }
  },
  [types.SET_SESSION_CURRENT_FISCAL_YEAR]: (state, year) => {
    state.sessionCurrentFiscalYear = year
  },
  [types.SET_CURRENT_FISCAL_YEAR]: (state, value) => {
    state.currentFiscalYear = value
  },
  [types.SET_CURRENT_FISCAL_YEAR_DATA]: (state, value) => {
    state.currentFiscalYearData = value
  },
  [types.SET_ADMIN_VIEWING_COMPANY]: (state, value) => {
    state.adminViewingCompany = value
  },
  [types.SET_USER_COMPANIES]: (state, value) => {
    state.userCompanies = value
  },
  [types.SET_BANKS]: (state, value) => {
    state.banks = value
  },
  [types.SET_ACCOUNTS]: (state, value) => {
    state.accounts = value.map(acc => {
      return {
        ...acc,
        id: acc.id,
      }
    })
  },
  [types.SET_FISCAL_YEARS]: (state, value) => {
    state.fiscalYears = value
  },
  [types.SET_SUB_ACCOUNTS]: (state, value) => {
    state.subAccounts = value.map(acc => {
      return {
        ...acc,
        id: acc.id,
      }
    })
  },
  [types.SET_COMPANY_SETTINGS_PER_MODULE]: (state, value) => {
    state.settings = value
  },
  [types.SET_CURRENT_COMPANY]: (state, company) => {
    state.currentCompany = {
      ...state.currentCompany,
      ...company,
    }
  },
  [types.SET_CURRENT_COMPANY_LOADING]: (state, value) => {
    state.currentCompanyLoading = value
  },
  [types.SET_PERIODS]: (state, fiscalYear) => {
    let { periods } = fiscalYear?.attributes || []
    if (!periods) {
      periods = getDefaultPeriods()
    }
    periods = orderBy(periods, 'number')
    const options = periods.map((period) => {
      return {
        ...period,
        value: period.number,
        label: `${period.number} (${period.name})`,
        originalLabel: period.name
      }
    })
    state.periods = options
  },
  [types.SET_USERS_LOADING]: (state, value) => {
    state.usersLoading = value
  },
  [types.SET_USERS]: (state, value) => {
    state.users = value
  },
  [types.SET_CURRENT_REPORT_HEADER]: (state, value) => {
    state.currentReportHeader = value
  },
}

function activeFiscalYear(state) {
  const fiscalYear = state.sessionCurrentFiscalYear ? parseInt(state.sessionCurrentFiscalYear) : parseInt(state.currentFiscalYear)
  if (isNaN(fiscalYear)) {
    return state.currentFiscalYear || new Date().getFullYear()
  }
  return fiscalYear
}

const actions = {
  async makeCompanyActive({ commit }, company) {
    try {
      await CompanyService.markAsActive(company.id)
    } catch (err) {
      console.warn(err)
    }
    commit(types.SET_ADMIN_VIEWING_COMPANY, true)
    commit(types.SET_COMPANY, company)
  },
  setSessionActiveFiscalYear({ commit }, year) {
    commit(types.SET_SESSION_CURRENT_FISCAL_YEAR, year)
  },
  async markUserCompanyAsActive({ commit }, company) {
    try {
      await CompanyService.markAsActive(company.id)
      commit(types.SET_COMPANY, company)
    } catch (err) {
      console.warn(err)
    }
  },
  async getCurrentCompany({ commit }) {
    try {
      commit(types.SET_CURRENT_COMPANY_LOADING, true)
      const { data } = await axios.get('/restify/companies/current', {
        params: {
          related: 'fiscalYears,banks,companies.addresses'
        }
      })
      commit(types.SET_COMPANY, data)
    } catch (err) {
      console.warn(err)
    } finally {
      commit(types.SET_CURRENT_COMPANY_LOADING, false)
    }
  },
  async getCompanyAccounts({ commit, state }) {
    try {
      if (!app.$can('accounts_show')) {
        return
      }
      const { accounts } = await axios.get('/configuration/list?entities=accounts')
      commit(types.SET_ACCOUNTS, accounts)
    } catch (err) {
      console.warn(err)
    }
  },
  async getCompanyBanks({ commit }) {
    try {
      if (!app.$can('banks_show')) {
        return
      }

      let banks = await Cache.getRequest('/restify/banks/list')
      commit(types.SET_BANKS, banks)
    } catch (err) {
      console.warn(err)
    }
  },
  async getCompanySubAccounts({ commit, state }) {
    try {
      if (!app.$can('subaccounts_show')) {
        return
      }
      const fiscalYear = activeFiscalYear(state)
      const subaccounts = await axios.get('/restify/subaccounts/list', {
        params: {
          fiscal_year: fiscalYear,
        },
      })
      commit(types.SET_SUB_ACCOUNTS, subaccounts)
    } catch (err) {
      console.warn(err)
    }
  },
  updatePeriod({ commit, state }, period) {
    const periodIndex = state.periods.findIndex(p => p.number === period.number)
    if (periodIndex === -1) {
      return
    }
    const periods = state.periods
    periods.splice(periodIndex, 1, period)
    commit(types.SET_PERIODS, periods)
  },
  async getFiscalYears({ dispatch }) {
    try {
      if (!app.$can('fiscal_year_show')) {
        return
      }

      const { data } = await axios.get('/restify/fiscal-years?perPage=999')

      dispatch('setFiscalYears', data)
    } catch (err) {
      console.warn(err)
    }
  },
  setFiscalYears({ commit, rootGetters }, data) {
    let fiscalYears = data
    fiscalYears = orderBy(fiscalYears, ['attributes.fiscal_year'], 'desc')
      .map(year => {
        const { periods } = year.attributes
        let periodObj = periods.map((period, index) => {
          return {
            ...period,
            value: period.number,
            label: `${index} (${period.name})`,
            originalLabel: period.name
          }
        })
        return {
          ...year,
          attributes: {
            ...year.attributes,
            periods: periodObj,
          }
        }
      })

    let currentFiscalYearNumber = rootGetters['company/getSettingValue']($modules.GL, 'current_fiscal_year')
    let currentFiscalYear = fiscalYears.find(y => y.attributes.fiscal_year === currentFiscalYearNumber)
    const lastFiscalYear = fiscalYears[0]
    if (!currentFiscalYear) {
      currentFiscalYear = lastFiscalYear
    }

    if (!currentFiscalYear) {
      currentFiscalYearNumber = lastFiscalYear?.attributes?.fiscal_year
    }

    commit(types.SET_FISCAL_YEARS, fiscalYears)
    commit(types.SET_CURRENT_FISCAL_YEAR, currentFiscalYearNumber)
    commit(types.SET_CURRENT_FISCAL_YEAR_DATA, currentFiscalYear)

    commit(types.SET_PERIODS, currentFiscalYear)
  },

  async getFiscalYear({ commit }, id) {
    try {
      if (!app.$can('fiscal_year_show')) {
        return
      }

      const { data } = await axios.get(`/restify/fiscal-years/${id}`)
      let currentFiscalYear = data

      commit(types.SET_CURRENT_FISCAL_YEAR_DATA, currentFiscalYear)
      commit(types.SET_PERIODS, currentFiscalYear)
    } catch (err) {
      console.warn(err)
    }
  },

  async getCompanySettings({ dispatch }) {
    try {
      const { data } = await axios.get('/restify/company-settings')
      dispatch('setCompanySettings', data)
    } catch (err) {
      console.warn(err)
    }
  },
  setCompanySettings({ commit }, data) {
    if (!data.length) {
      return
    }
    const settingsObject = {}
    data.forEach(setting => {
      const module = setting?.attributes?.module
      settingsObject[module] = {
        id: setting?.id,
        module,
        ...setting?.attributes?.value,
      }
    })
    commit(types.SET_COMPANY_SETTINGS_PER_MODULE, settingsObject)
  },
  async getCompany({ commit }, companyId) {
    try {
      commit(types.SET_CURRENT_COMPANY_LOADING, true)
      const data = await CompanyService.getCompany(companyId)
      commit(types.SET_CURRENT_COMPANY, data)
    } finally {
      commit(types.SET_CURRENT_COMPANY_LOADING, false)
    }
  },
}

const getters = {
  getCompanyAccount: (state) => accountID => {
    const account = state.accounts.find(account => account.id?.toString() === accountID?.toString())
    return account || {}
  },
  getCompanyAccountByNumber: (state) => number => {
    const account = state.accounts.find(account => account.number?.toString() === number?.toString())
    return account || {}
  },
  getPeriodName: state => (period, propName = 'label') => {
    if (!isPeriodDefined(period)) {
      return ''
    }
    const periodObj = state.periods.find(p => p.number?.toString() === period.toString()) || { [propName]: '' }
    return periodObj[propName]
  },
  getPeriods: state => {
    return state.periods.filter(p => p.number > 0 && p.number < 13) || []
  },
  firstPeriodValue: state => {
    const period = state.periods.find(p => p.number > 0 && p.number < 12 && p.is_open) || { number: 1 }
    return period.number
  },
  getMonthlyPeriods: state => {
    return state.periods.filter(p => p.number > 0 && p.number < 13) || []
  },
  getBanks: state => {
    return state.banks
  },
  getBankById: state => id => {
    return state.banks.find(b => b.id?.toString() === id?.toString()) || {};
  },
  hasBanks: state => {
    return state.banks.length > 0
  },
  activeFiscalYears: state => {
    return state.fiscalYears
      .filter(fiscalYear => fiscalYear?.attributes?.closed_at === null)
      .map(fiscalYear => fiscalYear?.attributes?.fiscal_year)
      .sort((year1, year2) => year2 - year1)
  },
  getSessionActiveFiscalYear: state => {
    return activeFiscalYear(state)
  },
  isFiscalYearOpen: state => year => {
    const fiscalYear = state.fiscalYears.find(f => f.attributes.fiscal_year === year)
    return fiscalYear && fiscalYear?.attributes?.closed_at === null
  },
  lastFiscalYear: state => {
    return state.fiscalYears[0]?.attributes?.fiscal_year
  },
  getCurrentPeriodValue: state => {
    const periods = state.periods || []
    return getCurrentPeriod(periods)
  },
  getCurrentCompany: state => {
    return state.activeCompany
  },
  getCompanyUseMultipleLocalTaxes: state => {
    return state.activeCompany?.multiple_local_taxes || false
  },
  getCompanyUseMultipleStateTaxes: state => {
    return state.activeCompany?.multiple_state_taxes || false
  },
  getCurrentCompanyId: state => {
    return state.activeCompany?.id
  },
  getCompanySettingsPerModule: (state) => moduleName => {
    return state.settings[moduleName] || {}
  },
  getCompanySettingsIdPerModule: (state) => moduleName => {
    const settings = state.settings[moduleName] || { id: null }
    return settings.id
  },
  getSettingValue: (state) => (moduleName, key) => {
    const settings = state.settings[moduleName] || {}
    return settings[key]
  },
  isEmployeeConsentRequired: (state, getters) => {
    const generalSettings = getters.getCompanySettingsPerModule('general')
    return generalSettings?.employee_consent_required
  },
  getAuthorizedToUsePeriods: state => {
    const periods = state.periods.filter(p => p?.is_open) || []
    return orderBy(periods, 'number')
  },
  getFilteredAccountsByRestrictions: (state) => restrictions => {
    return state.accounts.filter(account => restrictions.includes(account.restrictions))
  },
  getAccountById: (state) => id => {
    return state.accounts.find(account => account.id === id)
  },
  getSubAccountById: (state) => id => {
    return state.subAccounts.find(subAccount => subAccount.id === id)
  },
  getActiveCompanyAddresses: (state) => {
    return state.activeCompany?.addresses || []
  },
  getWarehouseAddresses: (state) => {
    return state.activeCompany?.addresses.filter(address => address.attributes.type === addressTypes.Warehouse) || []
  },
  getPrimaryCompanyAddress: (state) => {
    const addresses = state.activeCompany?.addresses.filter(address => address.attributes.type === addressTypes.Warehouse) || [{}]
    return addresses.find(address => address.attributes.primary) || addresses[0]
  },
  getPrimaryCompanyState: (state, getters) => {
    return getters.getPrimaryCompanyAddress?.attributes?.state
  },
  getActiveCompanyPrimaryAddressId: (state, getters) => {
    return getters.getPrimaryCompanyAddress?.id
  },
  showReportsCode: (state, getters) => {
    const generalSettings = getters.getCompanySettingsPerModule('general')
    return generalSettings?.show_reports_code
  },
  getPrintReportOrientation: (state, getters) => {
    const generalSettings = getters.getCompanySettingsPerModule('general')
    return generalSettings?.print_report_orientation
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
