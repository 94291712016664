export const PaymentTypes = {
  Adjustment: 'adjustment',
  Check: 'check',
  CreditCard: 'credit-card',
  JointPayment: 'joint-payment',
  Manual: 'manual',
  DirectDeposit: 'direct-deposit',
} as const

export type PaymentType = typeof PaymentTypes[keyof typeof PaymentTypes]

export type CustomerPaymentBilling = {
  amount: number;
  discount_amount: number
  discount_account?: number | string
  discount_subaccount?: number | string
  from_cash_on_deposit_amount: number
  action?: string | null
  id: string
  type: PaymentType
}
export type CustomerPayment = {
  _localId?: string;
  adjustments: boolean;
  deposit: boolean;
  amount: number;
  apply_to_billings_amount: number;
  bank_id: string | null;
  customer_id: string | null;
  date: string;
  number: string;
  reference_no: string;
  billings: CustomerPaymentBilling[]
}

export const StorageKey = 'customer-payments-2'

export const PaymentActions = {
  None: null,
  FullPay: 'pay_remaining',
  PartialPay: 'partial',
  Discount: 'discount'
} as const
