import Data = API.Data;
import {Bank} from "@/modules/common/types/models";

export function getLastCheckNumber(bank: Data<Bank>) {
    const { last_ap_check_no, last_ar_check_no, last_pr_check_no } = bank.attributes;
    return last_ap_check_no || last_ar_check_no || last_pr_check_no || ''
}

export function getLastDirectDepositNumber(bank: Data<Bank>) {
    const { last_ap_dd_no, last_pr_dd_no } = bank.attributes;
    return last_ap_dd_no || last_pr_dd_no || ''
}
