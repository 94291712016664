import VueRouter from 'vue-router'
import authRoutes from '@/modules/auth/routes'
import publicRoutes from '@/modules/public/routes'
import reportPrintRoutes from '@/modules/print/routes'
import wikiRoutes from '@/modules/wiki/routes'
import dashboardRoutes from '@/modules/dashboard/routes'

const routes = [
  ...authRoutes,
  ...publicRoutes,
  ...reportPrintRoutes,
  ...wikiRoutes,
  ...dashboardRoutes
]

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: (to, from ,savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }

})

export default router
