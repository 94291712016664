export const agedByViewFormats = {
  EachInvoice: 'each-invoice',
  CustomerTotal: 'customer-total',
  JobTotal: 'job-total',
  PastInvoice: 'past-invoices',
}

export const reportNumbers = {
  AgedByCustomer: 5882,
  AgedByJob: 5883,
}

export const customerHistoryFormats = {
  ByCustomer: 'by-customer',
  ByJob: 'by-job',
  ByInvoice: 'by-invoice',
  Receipts: 'receipts',
  ContractInfo: 'contract-info',
}

export const salesTaxFormats = {
  ByCustomer: 'by-customer',
  CustomerDetails: 'customer-details',
}


export const preBillingViewFormats = {
  LineItemSummary: 'line-item-summary',
  History: 'history',
  Markups: 'markups',
  LineItemSummaryWithTitles: 'line-item-summary-w-titles',
  ActualCost: 'actual-cost',
  WorksheetDetails: 'worksheet-details',
  WorksheetSummary: 'worksheet-summary',
  JobSummary: 'job-summary',
  JobSummaryByCostType: 'job-summary-by-cost-type',
}
