import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/main.scss'
import i18n from './i18n'
import '@/modules/common/apiConfig'
import authMiddleware from "@/modules/auth/middleware/authMiddleware";
import GlobalComponents from "@/plugins/globalComponents";
import GlobalPlugins from "@/plugins/globalPlugins";
import globalMixins from "@/mixins/globalMixins";
import initProgress from "@/modules/common/progressbar";
import { isProduction, isDev } from "@/isProduction";
import { initGlobalErrorHandler } from "@/modules/common/globalErrorHandler";
import { flareVue } from "@flareapp/flare-vue";

Vue.config.productionTip = false

initGlobalErrorHandler()
initProgress(router);
authMiddleware(router)

Vue.use(VueRouter)
Vue.use(GlobalComponents)
Vue.use(GlobalPlugins)
Vue.use(globalMixins)
Vue.use(flareVue);

window.app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

Vue.mixin({
  data() {
    return {
      // change to local if you want to emulate how it looks in production
      isProduction: isProduction(),
      isDev: isDev()
    }
  }
})
