<template>
  <entity-select
    v-on="$listeners"
    :value="value || defaultBankId"
    :name="$attrs.name || $t('Bank')"
    :placeholder="$attrs.placeholder || $t('Select a Bank')"
    :label="$attrs.label !== undefined ? $attrs.label : $t('Bank')"
    :add-label="$t('Add new Bank')"
    :add-entity="addEntity"
    :edit-entity="editEntity"
    :disabled="$attrs.disabled"
    :set-default-option="editEntity"
    :label-format="formatBankLabel"
    :id="$attrs.id || 'bank-select'"
    :table-columns="bankTableColumns"
    :url-params="allUrlParams"
    :data="banks"
    :add-temporary-entity="true"
    :rules="rules"
    :clearable="$attrs.clearable"
    entityUrl="/restify/banks"
    class="w-full"
    @change="onChange"
  />
</template>
<script>

import { $modules } from "@/enum/enums";
import Cache from "@/utils/Cache";

export default {
  inheritAttrs: false,
  props: {
    urlParams: {
      type: Object,
      default: () => ({
        sort: 'name',
        related: 'addresses',
      }),
    },
    addEntity: {
      type: Boolean,
      default: true,
    },
    editEntity: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [String, Number, Object, Array],
    },
    usedFor: {
      type: String,
    },
    rules: {
      type: String,
    },
    useDefaultBank: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    allUrlParams() {
      return {
        ...this.urlParams,
        active: true,
      }
    },
    defaultBankId() {
      if (!this.useDefaultBank) {
        return null
      }
      return this.$settings(this.$currentModule, 'default_bank_id') || null
    },
    banks() {
      let banks = this.$store.state.company.banks || []
      if (this.usedFor) {
        banks = banks.filter(bank => {
          return bank?.used_in?.includes(this.usedFor)
        })
      }
      return banks
        .map(bank => {
          return {
            id: bank.id,
            attributes: {
              ...bank,
            }
          }
        })
    },
    bankTableColumns() {
      const showBankBalance = this.$settings($modules.GENERAL, 'show_bank_balance')
      const columns = [
        {
          minWidth: 100,
          maxWidth: 150,
          name: this.$t('Initials'),
          prop: 'attributes.initials',
        },
        {
          minWidth: 200,
          maxWidth: 200,
          name: this.$t('Name'),
          prop: 'attributes.name',
        },
        {
          minWidth: 100,
          maxWidth: 200,
          name: this.$t('Account'),
          prop: 'attributes.account',
        },
        {
          minWidth: 200,
          maxWidth: 200,
          name: this.$t('Balance'),
          prop: 'attributes.account',
          toFormat: (accountNumber) => {
            const account = this.$store.state.company.accounts.find(a => a.number === accountNumber)
            return this.$formatPrice(account?.total_amount || 0)
          },
          visible: showBankBalance,
        },
      ]
      return columns.filter(c => c.visible !== false)
    }
  },
  methods: {
    formatBankLabel(bank) {
      const { initials, name } = bank.attributes
      return !name ? `${initials}` : `${initials} (${name})`
    },
    onChange(value) {
      this.$emit('input', value)
    },
    tryAssignDefaultBank() {
      if (this.value) {
        return
      }

      const bank = this.banks.find(bank => bank.id === this.defaultBankId)
      if (bank) {
        this.$emit('entity-change', bank)
      }
      this.onChange(this.defaultBankId)
    }
  },
  async mounted() {
    Cache.removeForEntity('banks')
    await this.$store.dispatch('company/getCompanyBanks')
    this.tryAssignDefaultBank()
  }
}
</script>
