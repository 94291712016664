<template>
  <div class="p-4 mb-6">
    <div class="text-sm flex items-center mb-2">
      <span>{{ $t('Payment Difference:') }} &nbsp;</span>
      <b :class="{'text-red-600': amountDifference !== 0}">
        {{ $formatPrice(amountDifference) }}
      </b>
      <BaseTip
          :content="$t('When the payment difference is > $0, the difference will be added to the customer cash on deposit')"/>
      <BaseCheckbox
          v-model="selectAll"
          :label="$t('Select All (Pay In Full)')"
          :id="`${payment.customer_id}-select-all`"
          class="ml-4"
          @input="onSelectAll"
      />
    </div>
    <div v-if="!payment.customer_id" class="text-red-600 text-sm">
      {{ $t('No customer selected. Please select a customer in order to see their invoices.') }}
    </div>
    <AgDataTable
        v-else
        url="/restify/billings"
        :urlParams="urlParams"
        :columns="billingColumns"
        :transform-data="transformData"
        :groupIncludeTotalFooter="true"
        :suppressAggFuncInHeader="true"
        :detailCellRenderer="detailCellRenderer"
        :detailCellRendererParams="detailCellRendererParams"
        :masterDetail="true"
        :detailRowAutoHeight="true"
        :compact="true"
        domLayout="autoHeight"
        @grid-ready="grid = $event"
    />
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import {
    CustomerPayment,
    CustomerPaymentBilling,
    PaymentActions,
    StorageKey
  } from "@/modules/accounts-receivable/pages/payments/customerPaymentTypes";
  import { entityPreviewFields } from "@/modules/common/components/entity-preview/entities";
  import { Column } from "@/components/ag-grid/tableTypes";
  import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
  import { Billing } from "@/modules/common/types/models";
  import { GridReadyEvent, ValueFormatterParams, ValueSetterParams } from '@ag-grid-community/core';
  import { cellClasses, requiredValueSetter } from "@/components/ag-grid/columnUtils";
  import { getPaymentCashOnDeposit } from "@/modules/accounts-receivable/pages/payments/customerPaymentUtils";
  import BillingDetails from "@/modules/accounts-receivable/pages/billings/BillingDetails.vue";
  import { getBillingType } from "@/modules/accounts-receivable/pages/billings/billings";
  import { useStorage } from '@vueuse/core'
  import sumBy from 'lodash/sumBy';
  import Data = API.Data;

  export default defineComponent({
    components: {
      BillingDetails,
    },
    setup() {
      const allPayments = useStorage<CustomerPayment[]>(StorageKey, [])
      return {
        allPayments
      }
    },
    data() {
      return {
        grid: null as GridReadyEvent | null,
        detailCellRenderer: null as string | null,
        selectAll: false,
      }
    },
    computed: {
      discountAccount() {
        return this.$settings(this.$modules.AR, 'discounts_given_account')
      },
      discountSubAccount() {
        return this.$settings(this.$modules.AR, 'discounts_given_subaccount')
      },
      payment(): CustomerPayment {
        return this.params?.data
      },
      paymentBillings(): CustomerPaymentBilling[] {
        return this.params?.data.billings || []
      },
      entriesTotal() {
        return sumBy(this.paymentBillings, 'amount')
      },
      amountDifference() {
        return this.round(this.payment.amount - this.entriesTotal)
      },
      urlParams() {
        const payment = this.payment as CustomerPayment
        return {
          status: 'posted,partially_paid',
          customer_id: payment?.customer_id,
          related: entityPreviewFields.Customer,
          sort: 'customer.code',
        }
      },
      options() {
        return [
          {
            label: this.$t('?'),
            value: PaymentActions.None,
          },
          {
            label: this.$t('Pay Remaining'),
            value: PaymentActions.FullPay,
          },
          {
            label: this.$t('Partial/Transfer'),
            value: PaymentActions.PartialPay,
          },
        ]
      },
      billingColumns(): Column[] {
        return [
          {
            headerName: this.$t('Action'),
            field: 'action',
            minWidth: 80,
            maxWidth: 160,
            editable: true,
            cellEditor: cellEditors.BaseSelect,
            cellEditorParams: {
              options: this.options,
              delayNextCellNavigation: true,
            },
            valueFormatter: (params: ValueFormatterParams) => {
              if (params?.node?.footer) {
                return this.$t('Totals') as string
              }
              const action = params.data.action || PaymentActions.None
              return this.options.find(o => o.value === action)?.label?.toString() || ''
            },
            valueSetter: this.onPaymentActionSelect,
          },
          {
            headerName: this.$t('Type'),
            field: 'attributes.type',
            minWidth: 40,
            maxWidth: 80,
            cellClass: cellClasses.ReadOnlyLight,
          },
          {
            headerName: this.$t('Invoice'),
            field: 'attributes.number',
            component: 'EntityLink',
            redirectTo: '/accounts-receivable/billings/progress/{ID}/view',
            cellClass: cellClasses.ReadOnlyLight,
            minWidth: 80,
            maxWidth: 120,
          },
          {
            field: 'order',
            headerName: ' ',
            cellRenderer: 'agGroupCellRenderer',
            minWidth: 40,
            maxWidth: 40,
            cellClass: cellClasses.ReadOnlyLight,
            valueGetter: () => '',
          },
          {
            headerName: this.$t('Status'),
            field: 'attributes.status',
            component: 'Status',
            maxWidth: 120,
            cellClass: cellClasses.ReadOnlyLight,
          },
          {
            headerName: this.$t('Date'),
            field: 'attributes.date',
            component: 'FormattedDate',
            cellClass: cellClasses.ReadOnlyLight,
          },
          {
            headerName: this.$t('Open Amount'),
            field: 'attributes.open_amount',
            minWidth: 120,
            align: 'right',
            cellClass: cellClasses.ReadOnlyLight,
            valueGetter: params => {
              if (params?.node?.footer) {
                return
              }
              const otherPayments = this.getBillingsFromOtherPayments(params.data.id)
              const billingRemaining = params.data.attributes.open_amount
              return billingRemaining - otherPayments
            },
            valueFormatter: params => {
              if (params?.node?.footer) {
                return this.$formatPrice(params.node.aggData?.open_amount)
              }
              return this.$formatPrice(params.value)
            },
          },
          {
            headerName: this.$t('Payment'),
            field: 'amount',
            minWidth: 140,
            editable: true,
            align: 'right',
            cellEditor: cellEditors.Numeric,
            valueGetter: params => {
              if (params?.node?.footer) {
                return
              }
              return this.getBillingData(params.data, 'amount')
            },
            valueFormatter: params => {
              if (params?.node?.footer) {
                return this.$formatPrice(params.node.aggData?.amount)
              }
              return this.$formatPrice(params.value)
            },
            valueSetter: params => {
              const value = +params.newValue
              const open_amount = params.data?.attributes?.open_amount || 0

              this.setBillingData({
                originalBilling: params.data,
                field: 'amount',
                value: value,
              })

              if (value < open_amount) {
                params.data.action = PaymentActions.PartialPay
              }
              if (value >= open_amount) {
                params.data.action = PaymentActions.FullPay
              }
              if (!value) {
                params.data.action = PaymentActions.None
              }
              return true
            },
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Transfer Deposit'),
            field: 'from_cash_on_deposit_amount',
            minWidth: 100,
            valueGetter: params => {
              if (params?.node?.footer) {
                return
              }
              return +this.getBillingData(params.data, 'from_cash_on_deposit_amount')
            },
            valueFormatter: params => {
              if (params?.node?.footer) {
                return this.$formatPrice(params.node.aggData?.from_cash_on_deposit_amount)
              }
              return this.$formatPrice(params.value)
            },
            cellClass: () => {
              return getPaymentCashOnDeposit(this.params) > 0 ? '' : cellClasses.ReadOnlyLight
            },
            editable: () => {
              return getPaymentCashOnDeposit(this.params) > 0
            },
            valueSetter: (params) => {
              const value  = +params.newValue
              this.setBillingData({
                originalBilling: params.data,
                field: 'from_cash_on_deposit_amount',
                value: value,
              })
              return requiredValueSetter(params)
            },
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Discount'),
            field: 'discount_amount',
            minWidth: 100,
            editable: true,
            valueGetter: params => {
              if (params?.node?.footer) {
                return
              }
              return +this.getBillingData(params.data, 'discount_amount')
            },
            valueFormatter: params => {
              if (params?.node?.footer) {
                return this.$formatPrice(params.node.aggData?.discount_amount)
              }
              return this.$formatPrice(params.value)
            },
            valueSetter: params => {
              const value = +params.newValue
              this.setBillingData({
                originalBilling: params.data,
                field: 'discount_amount',
                value: value,
              })
              return true
            },
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Previously Paid'),
            field: 'attributes.paid_amount',
            minWidth: 120,
            align: 'right',
            component: 'FormattedPrice',
            cellClass: cellClasses.ReadOnlyLight,
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Remaining'),
            field: 'remaining',
            minWidth: 120,
            align: 'right',
            cellClass: cellClasses.ReadOnlyLight,
            valueGetter: params => {
              if (params?.node?.footer) {
                return
              }
              return this.getBillingRemaining(params.data)
            },
            valueFormatter: params => {
              if (params?.node?.footer) {
                return this.$formatPrice(params.node.aggData?.remaining)
              }
              return this.$formatPrice(params.value)
            },
            aggFunc: 'sum'
          },
        ]
      }
    },
    methods: {
      getBillingData(originalBilling: Data<Billing>, field: string) {
        let savedBilling: CustomerPaymentBilling | undefined = this.getSavedBilling(originalBilling)
        const mapping = {
          discount_amount: 'attributes.discount_amount',
        } as any
        const savedBillingFieldValue = this.get(savedBilling, field, 0)
        if (!savedBillingFieldValue) {
          const originalField = mapping[field] || field
          return this.get(originalBilling, originalField) || 0
        }
        return savedBillingFieldValue
      },
      getBillingsFromOtherPayments(billingId: number | string) {
        let total = 0
        this.allPayments.forEach(payment => {
          if (payment._localId === this.payment._localId) {
            return
          }
          const billing = payment.billings.forEach(billing => {
            if (billingId !== billing.id) {
              return
            }
            total += billing.amount + billing.discount_amount + billing.from_cash_on_deposit_amount
          })
        })
        return total
      },
      getTotalAppliedForBilling(originalBilling: Data<Billing>) {
        const amount = this.getBillingData(originalBilling, 'amount')
        const discount = this.getBillingData(originalBilling, 'discount_amount')
        const cashOnDeposit = this.getBillingData(originalBilling, 'from_cash_on_deposit_amount')
        return this.round(+amount + +discount + +cashOnDeposit)
      },
      getBillingRemaining(originalBilling: Data<Billing>) {
        const totalApplied = this.getTotalAppliedForBilling(originalBilling)
        const otherPayments = this.getBillingsFromOtherPayments(originalBilling.id)
        const previousRemaining = this.round(originalBilling.attributes.open_amount - otherPayments)
        return this.round(+previousRemaining - +totalApplied)
      },
      getSavedBilling(originalBilling: Data<Billing>) {
        return this.paymentBillings.find(b => b.id === originalBilling.id)
      },
      setBillingData({ originalBilling, field, value }: any) {
        let billing: CustomerPaymentBilling | undefined = this.getSavedBilling(originalBilling)
        if (!billing) {
          billing = {
            amount: 0,
            from_cash_on_deposit_amount: 0,
            discount_amount: originalBilling.attributes.discount_amount,
            discount_account: this.discountAccount,
            discount_subaccount: this.discountSubAccount || undefined,
            id: originalBilling.id,
            type: 'check',
          }
          this.params.data.billings.push(billing)
        }
        this.$set(billing, field, value)
      },
      transformData(billings: Data<Billing>[]) {
        return billings.map(billing => {
          const savedBilling = this.getSavedBilling(billing)
          return {
            ...billing,
            action: savedBilling?.action || null,
          }
        })
      },
      detailCellRendererParams(params: any) {
        return {
          billing: params.data,
          billingType: getBillingType(params.data.attributes.type),
        }
      },
      onPaymentActionSelect(params: ValueSetterParams, force = false) {
        const action = params.newValue
        if (params.data.action !== PaymentActions.None) {
          this.resetBillingData(params)
        }
        params.data.action = params.newValue
        let remainingAmount = this.getBillingRemaining(params.data) || 0
        if (remainingAmount > this.amountDifference && !force) {
          remainingAmount = this.amountDifference
          params.data.action = PaymentActions.PartialPay
        }

        if (action === PaymentActions.PartialPay) {
          this.setBillingData({ originalBilling: params.data, field: 'type', value: 'check' })
          this.setBillingData({ originalBilling: params.data, field: 'amount', value: remainingAmount })
        } else if (action === PaymentActions.FullPay) {
          this.setBillingData({ originalBilling: params.data, field: 'type', value: 'check' })
          this.setBillingData({ originalBilling: params.data, field: 'amount', value: remainingAmount })
          this.setBillingData({ originalBilling: params.data, field: 'from_cash_on_deposit_amount', value: 0 })
        } else if (action === PaymentActions.None) {
          this.resetBillingData(params)
        }

        this.setBillingData({ originalBilling: params.data, field: 'action', value: action })
        return true
      },
      resetBillingData(params: ValueSetterParams) {
        this.setBillingData({ originalBilling: params.data, field: 'amount', value: 0 })
        this.setBillingData({ originalBilling: params.data, field: 'from_cash_on_deposit_amount', value: 0 })
      },
      onSelectAll(value: boolean) {
        const action = value ? PaymentActions.FullPay : PaymentActions.None
        this.grid?.api?.forEachNode(node => {
          const params = {
            data: node.data,
            newValue: action,
            oldValue: node.data.action,
          } as ValueSetterParams
          this.onPaymentActionSelect(params, true)

          node.setData({
            ...node.data,
            action,
          })
        })
      }
    },
    created() {
      this.detailCellRenderer = 'BillingDetails'
    }
  })
</script>
