<template>
  <div :key="get(currentResource, 'id', 'purchase-order')">
    <portal to="page-title">
      {{ $t('PO ') }} #{{ currentResource.attributes.number }},&nbsp;@
      <router-link :to="`/accounts-payable/vendors/${vendor.id}/invoices`">
        {{ vendor.code }}
        <span v-if="vendor.name">({{ vendor.name }})</span>
      </router-link>

    </portal>

    <div class="flex justify-between items-center">
      <layout-tabs :items="filteredItems"/>
      <div class="flex items-center">
        <base-button
            v-if="isPosted && !isClosed"
            variant="danger-link"
            @click="closePurchaseOrder"
        >
          <XCircleIcon class="w-4 h-4 mr-1"/>
          <span>{{ $t('Close') }}</span>
        </base-button>
        <base-button
            v-if="!isPosted && isClosed"
            variant="danger-link"
            @click="openPurchaseOrder"
        >
          <UnlockIcon class="w-4 h-4 mr-1"/>
          <span>{{ $t('Reopen') }}</span>
        </base-button>
        <StimulsoftPrintButton
            :url="`/restify/purchase-orders/${currentResource.id}/stimulsoft`"
            :entity="StimulsoftPrintEntities.PurchaseOrder"
            :name="pageTitle"
        />
        <PostAction
            v-if="$isAuthorized('authorizedToPost', currentResource)"
            :id="currentResource.id"
            :is-accounting="false"
            entity-name="Purchase Order"
            resource-name="purchase-orders"
            @on-action-callback="getCurrentResource"
        />
        <VoidAction
            v-if="$isAuthorized('authorizedToVoid', currentResource)"
            :id="currentResource.id"
            :is-accounting="false"
            entity-name="Purchase Order"
            resource-name="purchase-orders"
            @on-action-callback="getCurrentResource"
        />
        <SendEmailPurchaseOrder
            v-if="$can('purchase_orders_update')"
            :purchase-order="currentResource"
            :entity="printEntity"
            @save="getCurrentResource(currentResource.id, true)"
        />
      </div>
    </div>

    <portal to="entity-name">
      {{ $t('Purchase Order ') }}{{ currentResource.attributes.number }}
      ({{ get(currentResource, 'relationships.vendor.attributes.name', '') }})
    </portal>
    <router-view/>
  </div>
</template>
<script>
import layoutPermissions from '@/mixins/layoutPermissions'
import { isBetaTestingOrganization } from "@/modules/common/config";
import { StimulsoftPrintEntities } from "@/enum/stimulsoft";
import SendEmailPurchaseOrder from "@/modules/purchasing-inventory/components/SendEmailPurchaseOrder.vue";
import { UnlockIcon, XCircleIcon } from "vue-feather-icons";
import { purchaseOrderStatuses } from "@/enum/enums";

export default {
  components: {
    XCircleIcon,
    UnlockIcon,
    SendEmailPurchaseOrder,
  },
  mixins: [layoutPermissions],
  data() {
    return {
      printEntity: StimulsoftPrintEntities.PurchaseOrder
    }
  },
  computed: {
    items() {
      return [
        {
          name: this.$t('Details'),
          path: `/purchasing-inventory/purchase-orders/${this.$route.params.id}/view`,
          authorizedTo: 'authorizedToShow',
        },
        {
          name: this.$t('Edit'),
          path: `/purchasing-inventory/purchase-orders/${this.$route.params.id}/edit`,
          authorizedTo: 'authorizedToUpdate',
        },
      ]
    },
    StimulsoftPrintEntities() {
      return StimulsoftPrintEntities
    },
    vendor() {
      return this.get(this.currentResource, 'relationships.vendor.attributes', {})
    },
    currentResource() {
      return this.$store.state.inventoryManagement.currentPurchaseOrder
    },
    isClosed() {
      return this.currentResource?.attributes?.status === purchaseOrderStatuses.Closed
    },
    isPosted() {
      return this.currentResource?.attributes?.posted_at !== null
    },
    pageTitle() {
      let baseTitle = `${this.$t('PO')} #${this.currentResource?.attributes?.number} @ ${this.vendor?.code || ''}`
      let vendorName = this.vendor?.name
      if (vendorName) {
        baseTitle += ` (${vendorName})`
      }
      return baseTitle
    }
  },
  methods: {
    isBetaTestingOrganization,
    async getCurrentResource(id) {
      await this.$store.dispatch('inventoryManagement/getPurchaseOrder', id)
    },
    async closePurchaseOrder() {
      const confirmed = await this.$deleteConfirm({
        title: this.$t('Close purchase order'),
        description: this.$t('Are you sure you want to close this purchase order?'),
        buttonText: this.$t('Close')
      })
      if (!confirmed) {
        return
      }
      await this.$store.dispatch('inventoryManagement/closePurchaseOrder', this.currentResource.id)
    },
    async openPurchaseOrder() {
      const confirmed = await this.$confirm({
        title: this.$t('Reopen purchase order'),
        description: this.$t('Are you sure you want to reopen this purchase order?'),
      })
      if (!confirmed) {
        return
      }
      await this.$store.dispatch('inventoryManagement/openPurchaseOrder', this.currentResource.id)
    }
  },
}
</script>
