<template>
  <base-select
      v-bind="$attrs"
      v-on="listeners"
      :name="$attrs.name || $t('Account Number')"
      :placeholder="$attrs.placeholder || $t('Select account')"
      :label="$attrs.label"
      :options="filteredAccounts"
      :table-columns="tableColumns"
      :id="$attrs.id || 'account-select'"
      :value-key="valueKey"
      :hide-label="hideLabel"
      :label-format="formatAccountName"
      label-key="number"
  />
</template>
<script>
  import { formatAccountName, isFieldRequired } from '@/modules/common/util/formattingUtils';
  import { isControlAccount } from "@/plugins/settingsAndEnumsPlugin";

  export default {
    inheritAttrs: false,
    props: {
      hideLabel: {
        type: Boolean,
        default: false,
      },
      valueKey: {
        type: String,
        default: 'id',
      },
      labelKey: {
        type: String,
        default: 'number',
      },
      restrictions: {
        type: Array,
        default: () => [],
      },
      filterFunction: {
        type: Function,
      },
      showInactive: {
        type: Boolean,
        default: true,
      },
      showLabelDescription: {
        type: Boolean,
        default: true,
      },
      warningOnChange: {
        type: Boolean,
        default: false,
      },
      canSelectControlAccounts: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        initialValue: '',
        tableColumns: [
          {
            minWidth: 100,
            maxWidth: 120,
            name: this.$t('Number'),
            prop: 'number',
          },
          {
            minWidth: 200,
            maxWidth: 200,
            name: this.$t('Description'),
            prop: 'description',
          },
        ],
      }
    },
    computed: {
      listeners() {
        return {
          ...this.$listeners,
          input: this.onChangeAccount,
        }
      },
      accounts() {
        const allAccounts = this.$store.state.company.accounts
        const hasRestrictions = this.restrictions.length > 0
        if (hasRestrictions) {
          return this.$store.getters['company/getFilteredAccountsByRestrictions'](this.restrictions)
        }
        if (!this.showInactive) {
          return allAccounts
        }
        return allAccounts.filter(account => account.active)
      },
      filteredAccounts() {
        let accounts = this.accounts
        if (this.filterFunction) {
          accounts = this.accounts.filter(this.filterFunction)
        }
        accounts = accounts.map(account => {
          return {
            ...account,
            disabled: isControlAccount(account?.number) && !this.canSelectControlAccounts,
          }
        })
        return accounts
      },
      showWarningOnChange() {
        return this.warningOnChange && isFieldRequired(this.$attrs)
      }
    },
    methods: {
      formatAccountName(account, asHtml = false) {
        return formatAccountName(account, asHtml, this.showLabelDescription)
      },
      async onChangeAccount(newValue) {
        const showControlWarning = isControlAccount(newValue) && !this.canSelectControlAccounts
        if (showControlWarning) {
          const confirmed = await this.$confirm({
            title: this.$t('Change account?'),
            description: this.$t(`The account you select is used as a control account in one of the settings screens. Changing it may have unintended consequences. Are you sure you want to change the account from <b>${this.$attrs.value}</b> to <b>${newValue}</b>?`),
            buttonText: this.$t('Confirm'),
          })
          if (!confirmed) {
            return
          }
        }
        if (!this.initialValue || !this.showWarningOnChange || newValue === this.initialValue) {
          this.$emit('input', newValue)
          return
        }

        const confirmed = await this.$confirm({
          title: this.$t('Change account?'),
          description: this.$t(`Are you sure you want to change the account from <b>${this.$attrs.value}</b> to <b>${newValue}</b>?`),
          buttonText: this.$t('Confirm'),
        })

        if (!confirmed) {
          return
        }

        this.$emit('input', newValue)
      },
    },
    created() {
      this.initialValue = this.$attrs.value
    },
  }
</script>
