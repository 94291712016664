<template>
  <AgedByReportForm
      :aged-by-job="agedByJob"
      :print-format-options="printFormatOptions"
      :report-number="reportNumbers.AgedByJob"
  />
</template>
<script setup>
  import AgedByReportForm from '@/modules/accounts-receivable/components/reports/AgedByReportForm'
  import { agedByViewFormats, reportNumbers } from '@/modules/accounts-receivable/components/reports/util'

  const agedByJob = true

  const printFormatOptions = [
    {
      label: app.$t('View Each Invoice'),
      value: agedByViewFormats.EachInvoice,
    },
    {
      label: app.$t('View Job Total Only'),
      value: agedByViewFormats.JobTotal,
    },
  ]
</script>
