<template>
  <entity-select
      v-bind="$attrs"
      v-on="$listeners"
      :url="url"
      :url-params="urlParams"
      :options="options"
      :name="$attrs.name || $t('Address')"
      :placeholder="$attrs.placeholder || $t('Select Address')"
      :label="$attrs.label !== undefined ? $attrs.label : $t('Address')"
      :add-entity="true"
      :id="$attrs.id || 'address-select'"
      :table-columns="tableColumns"
      :value-key="valueKey"
      :label-key="labelKey"
      :label-format="labelFormat"
      :extra-options="extraOptions"
      :form-params="{
        entity: formEntity,
        requireEntity: true,
        ...formParams,
      }"
      :addLabel="$t('Add Address')"
  />
</template>
<script>

  import { addressTypes } from "@/enum/enums";
  import { RestifyResources } from "@/components/form/util";

  export default {
    name: 'AddressSelect',
    props: {
      addressType: String,
      addressableId: String,
      valueKey: {
        type: String,
        default: 'id',
      },
      labelKey: {
        type: String,
        default: 'name',
      },
      includeDefaultAddress: {
        type: Boolean,
        default: false,
      },
      formParams: {
        type: Object,
        default: () => ({}),
      }
    },
    computed: {
      url() {
        return '/restify/addresses'
      },
      urlParams() {
        return {
          type: this.addressType,
          addressable_id: this.addressableId,
        }
      },
      tableColumns() {
        const columns = [
          {
            name: this.$t('Name'),
            prop: 'attributes.name',
            minWidth: 120,
            maxWidth: 120,
          },
          {
            name: this.$t('Type'),
            prop: 'attributes.type',
            component: this.$tableColumns.Status,
            minWidth: 120,
            maxWidth: 120,
            renderIf: () => !this.addressType,
          },
          {
            name: this.$t('Location'),
            component: this.$tableColumns.LocationLink,
            minWidth: 300,
            maxWidth: 300,
          },
        ]

        return columns.filter(column => column.renderIf ? column.renderIf() : true)
      },
      options() {
        let addresses = this.$store.getters['company/getActiveCompanyAddresses']

        if (!this.addressType) {
          return addresses
        }

        return addresses.filter(address => address.attributes.type === this.addressType)
      },
      formEntity() {
        const entityMap = {
          [addressTypes.Job]: RestifyResources.Jobs,
          [addressTypes.Warehouse]: RestifyResources.Companies,
          [addressTypes.Home]: RestifyResources.Employees,
        }
        return entityMap[this.addressType]
      },
      extraOptions() {
        if (!this.includeDefaultAddress || this.addressType === addressTypes.Warehouse) {
          return []
        }
        return this.$store.getters['company/getWarehouseAddresses']
      }
    },
    methods: {
      labelFormat(address) {
        const { name, type } = address.attributes
        return name || type
      },
    },
  }
</script>
