<template>
  <BaseDataTable
    v-bind="defaultAttributes"
    :columns="columns"
    :data="getData"
    :total-rows="rowsLength"
    :has-summary="false"
    class="billing-cost-report"
    @force-pagination="forcePagination"
  >
    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{row}">
      <JobLink
          :data="row.header"
          :show-preview="false"
      />
    </template>

    <template #thead-infos>

      <th colspan="5"/>
      <th colspan="3" align="center" class="thead-infos border-b-2 border-dashed pb-2">
        <div class="flex justify-between">
          <div>-A-</div>
          <div>-B-</div>
          <div>-C-</div>
        </div>
        <p class="mt-2 flex justify-center">{{ $t('Contract to date') }}</p>
      </th>
      <th colspan="3" align="center" class="thead-infos border-b-2 border-dashed pb-2">
        <div class="flex justify-between">
          <div>-D-</div>
          <div>-E-</div>
          <div>-F-</div>
        </div>
        <p class="mt-2 flex justify-center">{{ $t('Cost to date') }}</p>
      </th>
      <th colspan="3" align="center" class="thead-infos">
        <div class="flex justify-between">
          <div class="w-1/2 mx-6 text-center">
            -G- <br>Cost <br> Variance
          </div>
          <div class="w-1/2 flex justify-end text-center">
            -H- <br>Billing <br> Less Cost
          </div>
        </div>
      </th>

    </template>

    <template #description="{row}">
      <router-link :to="`/job-costing/${row.type}-line-items/${row.id}/view`">
        {{ row.description }}
      </router-link>
    </template>
    <template #cost_to_date="{row}">
      <router-link :to="`/job-costing/cost-line-items/${row.id}/transactions`">
        {{ $formatPrice(row.cost_to_date) }}
      </router-link>
    </template>

    <template #subtotal="{ subtotal }">
      <td colspan="4"/>
      <td align="right"> {{ subtotal.title }}</td>
      <td class="top-border">{{ $formatPrice(subtotal.income_amount) }}</td>
      <td align="center">{{ $formatPercent(subtotal.income_percent) }}</td>
      <td align="right" class="top-border">{{ $formatPrice(subtotal.income_to_date) }}</td>
      <td class="top-border">{{ $formatPrice(subtotal.cost_amount) }}</td>
      <td align="center">{{ $formatPercent(subtotal.cost_percent) }}</td>
      <td align="right" class="top-border">{{ $formatPrice(subtotal.cost_to_date) }}</td>
      <td align="right" class="top-border">{{ $formatPrice(subtotal.cost_variance) }}</td>
      <td align="right" class="top-border">{{ $formatPrice(subtotal.income_less_cost) }}</td>
    </template>

  </BaseDataTable>
</template>
<script>
  import { computeTotalBy } from '@/modules/job-costing/components/reports/util'
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
  import { costOrIncomeTypes } from "@/components/grid-table/utils/cost-center";
  import uniqBy from "lodash/uniqBy";

  export default {
    extends: ReportTableWrapper,
    props: {
      type: {
        required: true,
        type: String,
        default: computeTotalBy.PhaseAndCostCode,
      },
    },
    data() {
      return {
        grand_totals: {
          income_amount: 0,
          income_to_date: 0,
          income_percent: 0,
          cost_amount: 0,
          cost_to_date: 0,
          cost_percent: 0,
          cost_variance: 0,
          income_less_cost: 0
        }
      }
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Phase <br> Code'),
            prop: 'line_item.phase_code',
            minWidth: 60,
            maxWidth: 60,
            align: 'center',
            component: 'EntityLink',
            redirectTo: '/job-costing/cost-line-items/{ID}/view',
          },
          {
            label: this.$t('Cost <br> Code'),
            prop: 'line_item.cost_code',
            minWidth: 60,
            maxWidth: 60,
            align: 'center',
            component: 'EntityLink',
            redirectTo: '/job-costing/cost-line-items/{ID}/view',
          },
          {
            label: this.$t('CHG <br> Ord'),
            prop: 'line_item.change_order',
            minWidth: 40,
            maxWidth: 50,
            align: 'center',
            component: 'EntityLink',
            redirectTo: '/job-costing/cost-line-items/{ID}/view',
          },
          {
            label: this.$t('Description'),
            prop: 'line_item.description',
            minWidth: 80,
            maxWidth: 100,
          },
          {
            label: this.$t('Assigned <br> Vendor'),
            prop: 'vendor.code',
            minWidth: 60,
            maxWidth: 80,
            component: 'EntityLink',
            entityKey: 'vendor.id',
            redirectTo: '/accounts-payable/vendors/{ID}/view',
          },
          {
            label: this.$t('Value'),
            prop: 'income_amount',
            minWidth: 100,
            maxWidth: 110,
            component: 'FormattedPrice',
          },
          {
            label: this.$t('%'),
            prop: 'income_percent',
            minWidth: 50,
            maxWidth: 50,
            align: 'center',
            component: 'FormattedPercent',
          },
          {
            label: this.$t('Billed'),
            prop: 'income_to_date',
            minWidth: 100,
            maxWidth: 110,
            align: 'right',
            component: 'FormattedPrice',
          },
          {
            label: this.$t('Value'),
            prop: 'cost_amount',
            minWidth: 100,
            maxWidth: 110,
            component: 'FormattedPrice',
          },
          {
            label: this.$t('%'),
            prop: 'cost_percent',
            minWidth: 50,
            maxWidth: 50,
            align: 'center',
            component: 'FormattedPercent',
          },
          {
            label: this.$t('Actual'),
            prop: 'cost_to_date',
            minWidth: 100,
            maxWidth: 110,
            align: 'right',
            component: 'FormattedPrice',
          },
          {
            label: this.$t('(B x D - F)'),
            prop: 'cost_variance',
            minWidth: 60,
            maxWidth: 80,
            align: 'right',
            component: 'FormattedPrice',
          },
          {
            label: this.$t('(C - F)'),
            prop: 'income_less_cost',
            minWidth: 100,
            maxWidth: 110,
            align: 'right',
            component: 'FormattedPrice',
          },
        ]
      },
    },
    methods: {
      updateGrandTotals(totals, index) {
        this.grand_totals = {
          income_amount: this.grand_totals.income_amount + totals.income_amount,
          income_to_date: this.grand_totals.income_to_date + totals.income_to_date,
          cost_amount: this.grand_totals.cost_amount + totals.cost_amount,
          cost_to_date: this.grand_totals.cost_to_date + totals.cost_to_date,
          cost_variance: this.grand_totals.cost_variance + totals.cost_variance,
          income_less_cost: this.grand_totals.income_less_cost + totals.income_less_cost,
          income_percent: (this.grand_totals.income_percent * (index + 1) + totals.income_percent) / (index + 2),
          cost_percent: (this.grand_totals.cost_percent * (index + 1) + totals.cost_percent) / (index + 2),
        }
      },
      mapItems(items) {
        const finalItems = []
        items.forEach(item => {
          const matchingItem = this.findMatchingItem(items, item)
          let finalItem = {...item}
          if (!matchingItem) {
            finalItems.push(item)
            return
          }
          if (matchingItem.line_item.type === costOrIncomeTypes.Cost) {
            finalItem = {
              ...finalItem,
              cost_amount: matchingItem.cost_amount,
              cost_percent: matchingItem.cost_percent,
              cost_to_date: matchingItem.cost_to_date,
              cost_variance: matchingItem.cost_variance,
              income_less_cost: finalItem.income_to_date - matchingItem.cost_to_date,
            }
          } else if (matchingItem.line_item.type === costOrIncomeTypes.Income) {
            finalItem = {
              ...finalItem,
              income_amount: matchingItem.income_amount,
              income_percent: matchingItem.income_percent,
              income_to_date: matchingItem.income_to_date,
              income_less_cost: matchingItem.income_to_date - finalItem.cost_to_date,
            }
          }
          finalItem.line_item.id = matchingItem.line_item.id
          finalItems.push(finalItem)
        })
        return uniqBy(finalItems, 'line_item.id')
      },
      findMatchingItem(items, item) {
        const { type, cost_code, change_order, phase_code } = item.line_item
        return items.find(i => {
          const { type: i_type, cost_code: i_cost_code, change_order: i_change_order, phase_code: i_phase_code } = i.line_item
          return i_type !== type && i_cost_code === cost_code && i_phase_code === phase_code && i_change_order === change_order
        })
      },
      addJobLineItems(line_items) {
        line_items.forEach(lineItem => {
          let items = this.get(lineItem, 'items', [])
          items = this.mapItems(items)

          this.rows.push(...items)

          if (items?.length > 1) {
            const totals = this.get(lineItem, 'totals', {})

            const labels = {
              [computeTotalBy.Job]: this.$t('Job Totals'),
              [computeTotalBy.PhaseCode]: this.$t('Phase Totals'),
              [computeTotalBy.PhaseAndCostCode]: this.$t('Cost Code Totals'),
            }

            this.rows.push({
              subtotal: {
                ...totals,
                title: `${labels[this.type]} (${lineItem.key})`,
              },
            })
          }
        })
      },
      composeRows(data) {
        const lines = Array.isArray(data)
          ? data
          : Object.values(data)

        lines.forEach((group, index) => {
          const job = {
            id: group.id,
            status: group.status,
            number: group.number,
            description: group.description,
          }

          this.rows.push({ header: job })

          const { totals, line_items } = group

          if (this.type !== computeTotalBy.Job) {
            this.addJobLineItems(line_items)
          }

          this.rows.push({
            subtotal: {
              ...totals,
              title: this.$t('Job Totals')
            },
          })

          this.updateGrandTotals(totals, index)
        })

        this.rows.push({
          subtotal: {
            ...this.grand_totals,
            title: this.$t('Total All Selected Jobs'),
          },
        })
        
      },
    },
  }
</script>
<style lang="scss">
  .billing-cost-report .table-wrapper .content-row td {
    @apply border-none;
  }
</style>
