<template>

  <base-report-form
      :filters="model"
      :report-number="reportNumber"
  >

    <template #filters>

      <aged-thru-filter
          :today.sync="model.today"
          :period.sync="model.period"
          :fiscal_year.sync="model.fiscal_year"
      />

      <base-filter-row
          v-if="agedByJob"
          :title="$t('Sort & Subtotal By')"
      >
        <base-select
            v-model="model.group_by"
            :options="groupByOptions"
            :name="$t('Sort By')"
            rules="required"
            class="col-span-2"
            @change="onChangeGroupBy"
        />
      </base-filter-row>


      <base-filter-row :title="$t('Format')">
        <base-select
            v-model="model.format"
            :add-entity="false"
            :options="printFormatOptions"
            class="col-span-3"
            @change="onChangeFormat"
        />
        <base-checkbox
            v-if="model.format === agedByViewFormats.EachInvoice"
            v-model="model.actual_days"
            :label="$t('Actual Days')"
            class="col-span-3"
            id="actual_days"
        />
      </base-filter-row>

      <vendor-range-filter v-model="model"/>

      <work-order-range-filter
          v-if="groupByWorkOrder"
          v-model="model"
      />

      <job-and-additional-filters
          v-if="agedByJob && !groupByWorkOrder"
          v-model="model"
      />

      <base-filter-row :title="$t('Aged By')">
        <base-select
            v-model="model.age_by"
            :add-entity="false"
            :options="agedByOptions"
            class="col-span-2"
        />
        <div class="col-span-8 flex items-baseline">
          <span
              class="text-sm text-gray-600 font-medium whitespace-nowrap md:mr-3">
            {{ $t('Aging Categories (days)') }}
          </span>
          <div class="md:grid md:grid-cols-3">
            <base-input
                v-model="model.aging_days[0]"
                :name="$t('Days - 0')"
                type="number"
                class="col-span-1 md:mr-4"
            />
            <base-input
                v-model="model.aging_days[1]"
                :rules="`min_value:${model.aging_days[0]}`"
                :name="$t('Days - 1')"
                type="number"
                class="col-span-1 md:mr-4"
            />
            <base-input
                v-model="model.aging_days[2]"
                :rules="`min_value:${model.aging_days[1]}`"
                :name="$t('Days - 2')"
                type="number"
                class="col-span-1 md:mr-4"
            />
          </div>
          <span class="text-sm text-gray-600 font-medium whitespace-nowrap md:mr-3">{{ $t('As of') }}</span>
          <base-date-picker
              v-model="model.start_date"
              :name="$t('Start Date')"
              rules="required"
          />
        </div>
      </base-filter-row>

    </template>

    <template #table="{loading, data}">

      <component
          :is="getReportTableComponent"
          :data="data"
          :filters="model"
          :report-number="reportNumber"
          :data-loading="loading"
          :format="model.format"
          :days="model.aging_days"
          :group-by="model.group_by"
          :actual-days="model.actual_days"
          ref="reportTable"
      />

    </template>
  </base-report-form>

</template>
<script>
  import { agedByViewFormats } from '@/modules/accounts-payable/components/reports/util'
  import AgedByJobReportTable from '@/modules/accounts-payable/components/reports/AgedByJobReportTable'
  import AgedByVendorReportTable from '@/modules/accounts-payable/components/reports/AgedByVendorReportTable'
  import {
    agedByGrouping,
    AgedThruFilter,
    VendorRangeFilter,
    WorkOrderRangeFilter,
    JobAndAdditionalFilters,
  } from '@/components/range-filters'

  const agedBy = {
    InvoiceDate: 'date',
    DueDate: 'due_date',
  }

  export default {
    components: {
      AgedThruFilter,
      VendorRangeFilter,
      WorkOrderRangeFilter,
      AgedByJobReportTable,
      AgedByVendorReportTable,
      JobAndAdditionalFilters,
    },
    props: {
      printFormatOptions: {
        required: true,
        type: Array,
        default: () => [],
      },
      reportNumber: {
        required: true,
        type: [Number, String],
      },
      agedByJob: Boolean,
    },
    data() {
      return {
        agedByViewFormats,
        agedByOptions: [
          {
            label: this.$t('Invoice Date'),
            value: agedBy.InvoiceDate,
          },
          {
            label: this.$t('Due Date'),
            value: agedBy.DueDate,
          },
        ],
        model: {
          group_by: agedByGrouping.Job,
          today: true,
          period: this.$currentPeriod,
          fiscal_year: this.$currentYear,
          format: agedByViewFormats.EachInvoice,
          vendor_code_from: null,
          vendor_code_to: null,
          actual_days: true,

          job_number_from: null,
          job_number_to: null,
          job_ids: [],

          work_order_number_from: null,
          work_order_number_to: null,

          age_by: agedBy.InvoiceDate,
          aging_days: [30, 60, 90],
          start_date: this.$now,
        },
        groupByOptions: [
          {
            label: this.$t('Job'),
            value: agedByGrouping.Job,
          },
          {
            label: this.$t('Work Order'),
            value: agedByGrouping.WorkOrder,
          },
        ],
      }
    },
    computed: {
      getReportTableComponent() {
        return this.agedByJob ? 'AgedByJobReportTable' : 'AgedByVendorReportTable'
      },
      groupByWorkOrder() {
        return this.model.group_by === agedByGrouping.WorkOrder
      },
    },
    methods: {
      onChangeFormat() {
        this.model.actual_days = false
      },
      onChangeGroupBy() {
        this.model.job_ids = []
        this.model.job_number_from = this.model.job_number_to = null
        this.model.work_order_number_from = this.model.work_order_number_to = null
      },
    },
  }
</script>
