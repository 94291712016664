import { resourceStatuses } from '@/enum/enums';
import EmptyLayout from '@/components/common/EmptyLayout';

import EquipmentLayout from '@/modules/equipment/layout/EquipmentLayout';
import EquipmentList from '@/modules/equipment/pages/equipment-list.vue';
import EquipmentAdd from '@/modules/equipment/pages/equipment-add.vue';
import EquipmentEdit from '@/modules/equipment/pages/equipment-edit.vue';
import EquipmentDetails from '@/modules/equipment/pages/equipment-details.vue';

import EquipmentImport from '@/modules/equipment/pages/equipment-import';
import DepreciationLayout from '@/modules/equipment/layout/DepreciationLayout';
import DepreciationList from '@/modules/equipment/pages/depreciation-list';
import DepreciationCompute from '@/modules/equipment/pages/depreciation-compute';
import MaintenanceCodesList from '@/modules/equipment/pages/maintenance-codes-list';
import MaintenanceCodesImport from '@/modules/equipment/pages/maintenance-codes-import';
import SettingsLayout from '@/modules/equipment/layout/SettingsLayout';
import EquipmentSettings from '@/modules/equipment/pages/settings/equipment-settings';
import EquipmentClassesList from '@/modules/equipment/pages/settings/equipment-classes-list';
import EquipmentClassesImport from '@/modules/equipment/pages/settings/equipment-classes-import';
import EquipmentClassesAdd from '@/modules/equipment/pages/settings/equipment-class-add';
import EquipmentClassEdit from '@/modules/equipment/pages/settings/equipment-class-edit';
import SpecialtyCodesList from '@/modules/equipment/pages/settings/specialty-codes-list';
import SpecialtyCodesImport from '@/modules/equipment/pages/settings/specialty-codes-import';
import Reports from '@/modules/equipment/layout/Reports.vue';
import EquipmentHistoryListingReport from '@/modules/equipment/pages/reports/equipment-history-listing-report.vue';
import EquipmentExpenseListingReport from '@/modules/equipment/pages/reports/equipment-expense-listing-report.vue';
import EquipmentTransactions from "@/modules/equipment/pages/equipment-transactions.vue";
import EquipmentBillings from "@/modules/equipment/pages/equipment-billings.vue";
import EquipmentInvoices from "@/modules/equipment/pages/equipment-invoices.vue";
import EquipmentPurchaseOrders from "@/modules/equipment/pages/equipment-purchase-orders.vue";
import { RestifyResources } from "@/components/form/util";
import DocumentsTable from "@/modules/common/components/documents/DocumentsTable.vue";
import EquipmentAdjustmentList from "@/modules/equipment/pages/equipment-init/equipment-adjustment-list.vue";
import EquipmentAdjustmentAdd from "@/modules/equipment/pages/equipment-init/equipment-adjustment-add.vue";
import EquipmentAdjustmentLayout from "@/modules/equipment/layout/EquipmentAdjustmentLayout.vue";
import EquipmentAdjustmentEdit from "@/modules/equipment/pages/equipment-init/equipment-adjustment-edit.vue";
import EquipmentAdjustmentProofListing from "@/modules/equipment/pages/equipment-init/equipment-adjustment-proof-listing.vue";
import EquipmentTimesheets from "@/modules/equipment/pages/equipment-timesheets.vue";

export const reportRoutes = [
  {
    path: 'history-listing',
    name: 'Equipment History Listing',
    description: 'Preview Equipment History Listing',
    meta: {
      appScreen: 'Equipment History Listing: 68.71',
      permissions: ['equipment_management_reports'],
    },
    component: EquipmentHistoryListingReport,
  },
  {
    path: 'expense-listing',
    name: 'Equipment Expense Listing',
    description: 'Preview Equipment Expense Listing',
    meta: {
      appScreen: 'Equipment Expense Listing: 68.72',
      permissions: ['equipment_management_reports'],
    },
    component: EquipmentExpenseListingReport,
  },
]
export default [
  {
    path: '/equipment',
    name: 'Equipment Management',
    redirect: '/equipment/list',
    component: EmptyLayout,
    meta: {
      isModule: true,
    },
    children: [
      {
        path: 'list',
        name: 'Equipment',
        title: 'Equipment',
        description: 'Setup or change information for Equipment items',
        meta: {
          permissions: ['equipment_show'],
          appScreen: 'Setup Equipment: 60.10',
        },
        component: EquipmentList,
      },
      {
        path: 'list/add',
        name: 'Add Equipment',
        title: 'Add Equipment',
        description: 'Setup Equipment information',
        meta: {
          permissions: ['equipment_show'],
          appScreen: 'Setup Equipment: 60.10',
        },
        component: EquipmentAdd,
      },
      {
        path: 'list/:id',
        name: 'Equipment Details ',
        redirect: '/equipment/list',
        meta: {
          permissions: ['equipment_update'],
        },
        component: EquipmentLayout,
        children: [
          {
            path: 'edit',
            name: 'Equipment Edit',
            meta: {
              permissions: ['equipment_update'],
              appScreen: 'Setup Equipment: 60.10',
              resourceName: 'equipment',
            },
            component: EquipmentEdit,
          },
          {
            path: 'view',
            name: 'Equipment Details',
            meta: {
              permissions: ['equipment_update'],
              appScreen: 'Setup Equipment: 60.10',
              resourceName: 'equipment',
            },
            component: EquipmentDetails,
          },
          {
            path: 'documents',
            name: 'Equipment Documents',
            meta: {
              permissions: ['vendor_documents_show'],
              resourceName: RestifyResources.Equipment,
            },
            component: DocumentsTable,
          },
          {
            path: 'billings',
            name: 'Equipment Billings',
            meta: {
              permissions: ['billings_show'],
            },
            component: EquipmentBillings,
          },
          {
            path: 'invoices',
            name: 'Equipment Invoices',
            meta: {
              permissions: ['invoices_show'],
            },
            component: EquipmentInvoices,
          },
          {
            path: 'purchase-orders',
            name: 'Equipment Purchase Orders',
            meta: {
              permissions: ['purchase_orders_show'],
            },
            component: EquipmentPurchaseOrders,
          },
          {
            path: 'timesheets',
            name: 'Equipment timesheets',
            meta: {
              permissions: ['timesheets_approve'],
            },
            component: EquipmentTimesheets,
          },
          {
            path: 'transactions',
            name: 'Equipment Transactions',
            meta: {
              permissions: ['transaction_show'],
            },
            component: EquipmentTransactions,
          },
        ],
      },
      {
        path: 'import',
        name: 'Import Equipment',
        title: 'Import Equipment',
        description: 'Import AP Equipment from a CSV or Excel file',
        meta: {
          permissions: ['equipment_update'],
          appScreen: 'Setup Equipment: 60.10',
        },
        component: EquipmentImport,
      },
      {
        path: 'depreciation',
        name: 'Equipment Depreciations',
        redirect: '/equipment/depreciation/pending',
        meta: {
          hideBreadCrumb: true,
          permissions: ['equipment_show'],
        },
        component: DepreciationLayout,
        children: [
          {
            path: 'pending',
            name: 'Pending Depreciations',
            title: 'Pending Depreciations',
            meta: {
              permissions: ['equipment_show'],
              status: resourceStatuses.Pending,
            },
            component: DepreciationList,
          },
          {
            path: 'posted',
            name: 'Posted Depreciations',
            title: 'Posted Depreciations',
            meta: {
              permissions: ['equipment_show'],
              status: resourceStatuses.Posted,
            },
            component: DepreciationList,
          },
          {
            path: 'compute',
            name: 'Compute Depreciations',
            title: 'Compute Depreciations',
            description: 'Compute period depreciation for equipment and other fixed assets.',
            meta: {
              permissions: ['equipment_show'],
            },
            component: DepreciationCompute,
          },
        ]
      },
      {
        path: 'maintenance-codes',
        name: 'Maintenance Codes',
        title: 'Maintenance Codes',
        description: 'Record equipment preventive maintenance or repair activities.',
        meta: {
          permissions: ['maintenance_codes_show'],
          appScreen: 'Maintenance Codes: 60.50',
        },
        component: MaintenanceCodesList,
      },
      {
        path: 'maintenance-codes/import',
        name: 'Import Maintenance Codes',
        title: 'Import Maintenance Codes',
        description: 'Import Maintenance Codes from a CSV or Excel file',
        meta: {
          permissions: ['maintenance_codes_show'],
          appScreen: 'Maintenance Codes: 60.50',
        },
        component: MaintenanceCodesImport,
      },
      {
        path: 'initialize-equipment',
        name: 'Equipment Adjustments',
        title: 'Equipment Adjustments',
        meta: {
          permissions: ['equipment_adjustment_show'],
          appScreen: 'Initialize Equipment: 40.60',
          module: 'equipment',
        },
        component: EquipmentAdjustmentList,
      },
      {
        path: 'initialize-equipment/add',
        name: 'Add Equipment Adjustment',
        title: 'Add Equipment Adjustment',
        meta: {
          permissions: ['equipment_adjustment_store'],
          module: 'equipment',
        },
        component: EquipmentAdjustmentAdd,
      },
      {
        path: 'initialize-equipment/:id',
        meta: {
          permissions: ['equipment_adjustment_show'],
        },
        component: EquipmentAdjustmentLayout,
        children: [
          {
            path: 'edit',
            title: 'Edit Equipment Adjustment',
            meta: {
              permissions: ['equipment_adjustment_update'],
            },
            component: EquipmentAdjustmentEdit,
          },
          {
            path: 'proof-listing',
            title: 'Proof Listing',
            meta: {
              permissions: ['equipment_adjustment_show'],
            },
            component: EquipmentAdjustmentProofListing,
          },
        ],
      },
      {
        path: 'reports',
        name: 'EQP Reports',
        redirect: '/equipment/reports/history-listing',
        meta: {
          permissions: ['equipment_management_reports'],
        },
        component: Reports,
        children: reportRoutes,
      },
      {
        path: 'settings',
        name: 'Equipment Settings',
        redirect: '/equipment/settings/equipment-management-settings',
        meta: {
          hideBreadCrumb: true,
          permissions: ['equipment_management_settings'],
        },
        component: SettingsLayout,
        children: [
          {
            path: 'equipment-management-settings',
            name: 'Equipment Management Settings',
            title: 'Equipment Management Settings',
            description: 'Define Equipment Management Settings',
            meta: {
              permissions: ['equipment_management_settings'],
            },
            component: EquipmentSettings,
          },
          {
            path: 'classes',
            name: 'Equipment Classes',
            title: 'Equipment Classes',
            description: 'Setup Equipment Classes for summarizing categories of equipment costs on reports and posting to General Ledger.',
            meta: {
              permissions: ['equipment_class_show'],
              appScreen: 'Equipment Classes: 69.92',
            },
            component: EquipmentClassesList,
          },
          {
            path: 'classes/import',
            name: 'Import Equipment Classes',
            title: 'Import Equipment Classes',
            description: 'Import Equipment Classes from a CSV or Excel file',
            meta: {
              permissions: ['equipment_class_update'],
              appScreen: 'Equipment Classes: 69.92',
            },
            component: EquipmentClassesImport,
          },
          {
            path: 'classes/add',
            name: 'Add Equipment Class',
            title: 'Add Equipment Class',
            description: 'Add Equipment Class Code',
            meta: {
              permissions: ['equipment_class_store'],
            },
            component: EquipmentClassesAdd,
          },
          {
            path: 'classes/:id/edit',
            name: 'Equipment Class Edit',
            meta: {
              permissions: ['equipment_class_update'],
            },
            component: EquipmentClassEdit,
          },
          {
            path: 'classes/:id/view',
            name: 'Equipment Class Details',
            meta: {
              permissions: ['equipment_class_show'],
              appScreen: 'Equipment Classes: 69.92',
            },
            component: EquipmentClassEdit,
          },
          {
            path: 'specialty-codes',
            name: 'Specialty Codes',
            title: 'Specialty Codes',
            description: 'Setup Equipment Specialty Codes',
            meta: {
              permissions: ['equipment_specialties_show'],
            },
            component: SpecialtyCodesList,
          },
          {
            path: 'specialty-codes/import',
            name: 'Import Specialty Codes',
            title: 'Import Specialty Codes',
            description: 'Import AP Specialty Codes from a CSV or Excel file',
            meta: {
              permissions: ['equipment_specialties_update'],
              appScreen: 'Setup Specialty Codes: 69.93',
            },
            component: SpecialtyCodesImport,
          },
        ],
      },
    ],
  },
]
