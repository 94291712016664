<template>
  <div>
    <template v-if="!onViewResourcePage">
      <layout-tabs :items="filteredTypeItems"/>
      <layout-tabs :items="filteredItems"/>
    </template>
    <router-view/>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        typeItems: [
          {
            name: this.$t('Cost'),
            tooltip: this.$t('Job Cost Transactions'),
            path: '/job-costing/transactions/cost/pending',
            permission: 'transaction_show',
          },
          {
            name: this.$t('Income'),
            tooltip: this.$t('Job Income Transactions'),
            path: '/job-costing/transactions/income/pending',
            permission: 'transaction_show',
          },
        ],
      }
    },
    computed: {
      type() {
        return this.$route.path.includes('income') ? 'income' : 'cost'
      },
      items() {
        return [
          {
            name: this.$t('Pending'),
            tooltip: this.$t('Transactions that were not posted yet'),
            path: `/job-costing/transactions/${this.type}/pending`,
            permission: 'transaction_show',
          },
          {
            name: this.$t('Posted'),
            tooltip: this.$t('Posted transactions'),
            path: `/job-costing/transactions/${this.type}/posted`,
            permission: 'transaction_show',
          },
        ]
      },
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
      filteredTypeItems() {
        return this.typeItems.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
