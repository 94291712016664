<template>
  <base-filter-row :title="$t('Work Orders')">
    <base-filter-range :class="gridClass">
      <template #start>
        <work-order-select
            v-model="value.work_order_number_from"
            v-bind="$attrs"
            :add-entity="false"
            :placeholder="$t('Work Order From')"
            :hide-label="true"
            value-key="number"
            clearable
            @blur="onBlurStartRange"
        />
      </template>
      <template #end>
        <work-order-select
            v-model="value.work_order_number_to"
            v-bind="$attrs"
            :add-entity="false"
            :placeholder="$t('Work Order To')"
            :hide-label="true"
            :rules="value.work_order_number_from ? 'required' : ''"
            value-key="number"
            clearable
        />
      </template>
    </base-filter-range>

    <slot/>

  </base-filter-row>
</template>
<script>
  export default {
    props: {
      value: {
        type: Object,
        default: () => ({
          work_order_number_from: null,
          work_order_number_to: null,
        }),
      },
      gridClass: {
        type: String,
        default: 'col-span-4',
      },
    },
    methods: {
      onBlurStartRange() {
        this.value.work_order_number_to = this.value.work_order_number_to || this.value.work_order_number_from
      }
    }
  }
</script>
