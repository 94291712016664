<template>
  <AgDataTable
    v-bind="editableTableProps"
    :columns="columns"
    :data="joint_checks"
    :pagination="false"
    :get-empty-row="getEmptyRow"
    :groupIncludeTotalFooter="true"
    :add-text="$t('New joint check')"
    :actions="readonly ? '' : 'add'"
    domLayout="autoHeight"
    class="mt-4 col-span-6"
    ref="table"
    @grid-ready="grid = $event"
  >
    <template #additional-actions-before>
      <BaseCheckbox
        v-model="showAllCustomersAndBillings"
        :label="$t('Show All Customers and Billings')"
        id="show_all_customers_and_billings"
      />
    </template>
  </AgDataTable>
</template>
<script>
import BillingSelect from "@/components/select/entities/BillingSelect";
import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
import { getDeleteColumn } from "@/components/ag-grid/columns/deleteColumns";
import { cellClasses, requiredValueSetter, stopEditingOnTab } from "@/components/ag-grid/columnUtils";
import { editableTableProps, getTableData } from "@/components/ag-grid/tableUtils";
import { globalResources } from "@/components/form/util";
import isBefore from "date-fns/isBefore";
import { formatDate } from "@/plugins/dateFormatPlugin";

export default {
  components: {
    BillingSelect
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: Function,
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    let joint_checks = this.data?.length ? [...this.data] : [this.getEmptyRow()]
    return {
      showAllCustomersAndBillings: false,
      joint_checks,
      editableTableProps,
      grid: null,
    }
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('Customer'),
          prop: 'customer_id',
          minWidth: 140,
          maxWidth: 350,
          component: 'CustomerLink',
          editable: !this.readOnly,
          cellClass: this.requiredCellClass,
          cellEditor: cellEditors.CustomerSelect,
          cellEditorParams: params => {
            return {
              initialValue: params.data?.customer,
              onValueChanged(params, value, fullValue) {
                params.data.customer = fullValue
                params.data.billing_id = null
                params.data.payment_amount = 0
                params.node.setData(params.data)
              },
              showOpenAmount: true,
              urlParams: {
                with_open_billings: !this.showAllCustomersAndBillings,
              }
            }
          }
        },
        {
          label: this.$t('Check #'),
          prop: 'payment_number',
          minWidth: 100,
          maxWidth: 150,
          editable: !this.readOnly,
          cellClass: this.requiredCellClass,
        },
        {
          label: this.$t('Invoice'),
          prop: 'billing_id',
          minWidth: 200,
          maxWidth: 300,
          cellClass: this.requiredCellClass,
          editable: params => {
            return !this.readOnly && !!params.data.customer_id
          },
          valueSetter: params => {
            if (!params.newValue) {
              params.data.billing = null
              params.data.payment_amount = 0
            }
            params.data.billing_id = params.newValue
            return true
          },
          valueFormatter: params => {
            const billing = params.data?.billing?.attributes
            if (!billing) {
              return ''
            }
            return `#${billing?.number || ''} (${this.$formatPrice(billing?.open_amount || 0)})`
          },
          cellEditor: cellEditors.BillingSelect,
          cellEditorParams: params => {
            let status = 'posted,partially_paid'
            if (this.showAllCustomersAndBillings) {
              status = 'posted,partially_paid,paid'
            }
            return {
              initialValue: params.data?.billing,
              onValueChanged(params, value, fullValue) {
                params.data.billing = fullValue
              },
              urlParams: {
                customer_id: params.data?.customer_id,
                status,
              },
            }
          }
        },
        {
          label: this.$t('Check Date'),
          prop: 'payment_date',
          maxWidth: 150,
          editable: !this.readOnly,
          cellEditor: cellEditors.DatePicker,
          valueFormatter: params => {
            return params.value ? this.$formatDate(params.value) : ''
          },
          valueSetter: params => {
            params.data.payment_date = params.newValue
            const billingDate = params.data?.billing?.attributes?.date
            if (params.data.billing && isBefore(new Date(params.newValue), new Date(billingDate))) {
              const formattedDate = this.$formatDate(new Date(params.newValue))
              const formattedBillingDate = this.$formatDate(new Date(billingDate))
              this.$error(this.$t(`Check date ${formattedDate} is before invoice date ${formattedBillingDate}`))
            }
            return true
          }
        },
        {
          label: this.$t('Check Amount'),
          prop: 'payment_amount',
          maxWidth: 150,
          editable: params => {
            return !this.readOnly && !!params.data.billing_id
          },
          cellEditor: cellEditors.Numeric,
          valueFormatter: params => {
            return this.$formatPrice(params.value)
          },
          valueGetter: params => {
            if (params.node.footer || params.node.group) {
              return 0
            }
            return +params.data.payment_amount
          },
          valueSetter: params => {
            return requiredValueSetter(params, 0, (value) => {
              const maxValue = params.data?.billing?.attributes.open_amount
              return value <= maxValue
            })
          },
          cellClass: this.requiredCellClass,
          aggFunc: 'sum',
        },
        {
          label: this.$t('Joint Vendor'),
          prop: 'vendor_id',
          minWidth: 100,
          maxWidth: 200,
          component: 'VendorLink',
          cellClass: this.requiredCellClass,
          editable: !this.readOnly,
          cellEditor: cellEditors.GlobalResourceSelect,
          cellEditorParams: params => {
            return {
              resourceName: globalResources.Vendors,
              initialValue: params.data?.vendor,
              onValueChanged(params, value, fullValue) {
                params.data.vendor = fullValue
              },
            }
          }
        },
        {
          label: this.$t('Clearing Account'),
          prop: 'clear_account',
          minWidth: 100,
          maxWidth: 200,
          component: 'AccountLink',
          cellClass: this.requiredCellClass,
          cellRendererParams: params => {
            return {
              showDescription: false,
              id: params.data?.clear_account,
            }
          },
          editable: !this.readOnly,
          cellEditor: cellEditors.AccountSelect,
        },
        {
          label: this.$t('Sub Account'),
          prop: 'clear_subaccount',
          minWidth: 60,
          maxWidth: 100,
          component: 'SubAccountLink',
          cellRendererParams: params => {
            return {
              showDescription: false,
              id: params.data?.clear_subaccount,
              delayNextCellNavigation: true,
            }
          },
          editable: !this.readOnly,
          cellEditor: cellEditors.SubAccountSelect,
          suppressKeyboardEvent: stopEditingOnTab,
        },
        {
          ...getDeleteColumn({
            hide: this.readonly,
          }),
          minWidth: 70,
          maxWidth: 70,
        },
      ]
    }
  },
  methods: {
    requiredCellClass(params) {
      if (params.node.footer) {
        return ''
      }
      const hasValue = params.value
      if (!hasValue) {
        return cellClasses.Invalid
      }
      return ''
    },
    getEmptyRow() {
      return {
        _localId: crypto.randomUUID(),
        customer: null,
        vendor: null,
        billing: null,
        account: null,
        subaccount: null,
        customer_id: null,
        vendor_id: null,
        billing_id: null,
        payment_number: '',
        payment_date: this.$formatDate(new Date(), 'yyyy-MM-dd'),
        payment_amount: 0,
        clear_account: this.$settings(this.$modules.AR, 'joint_payments_clear_account'),
        clear_subaccount: this.$settings(this.$modules.AR, 'joint_payments_clear_subaccount'),
      }
    },
    getData() {
      return getTableData(this.grid?.api)
    }
  }
}
</script>
