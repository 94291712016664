export default {
  computed: {
    filteredItems() {
      return this.items.filter(({ authorizedTo, enabled, permission }) => {
        const isAuthorized = this.$isAuthorized(authorizedTo, this.currentResource)
        if (enabled) {
          return enabled() && isAuthorized
        }
        if (permission) {
          return this.$can(permission) && isAuthorized
        }
        return isAuthorized
      })
    },
    isDetailsPage() {
      const { path, name } = this.$route
      const nameExceptions = ['Job Line Items']
      return path.includes('/view') || nameExceptions.includes(name)
    },
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      async handler(id) {
        if (!id) {
          return
        }
        if (!this.getCurrentResource) {
          return
        }
        await this.getCurrentResource(id)
      }
    }
  },
}
