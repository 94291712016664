<template>
  <base-button
      v-bind="$attrs"
      :loading="downloadLoading"
      type="button"
      :variant="$attrs.variant || 'primary-light'"
      @click="previewChecks"
  >
    <slot>
      <IconFile class="w-4 h-4 mr-1"/>
      {{ $t('Preview Checks') }}
    </slot>
  </base-button>
</template>
<script>
  import { previewFileInNewTab } from "@/modules/common/util/downloadFileLocally";

  export default {
    name: 'PreviewChecksButton',
    inheritAttrs: false,
    props: {
      downloadFunction: {
        type: Function,
      }
    },
    data() {
      return {
        downloadLoading: false,
      }
    },
    methods: {
      async previewChecks() {
        this.$emit('click')
        try {
          if (!this.downloadFunction) {
            return
          }
          this.$info('Generating preview... Please wait as this may take a while.')
          this.downloadLoading = true
          const res = await this.downloadFunction()
          if (!res.size) {
            this.$warning(this.$t('There are no checks to preview. All the recorded payments are either credit card or manual.'))
            return
          }
          previewFileInNewTab(res)
          this.$success(this.$t('PDF preview generated successfully'))
        } catch (err) {
          console.log(err)
          this.$error(this.$t('An error occurred while generating the PDF preview'))
        } finally {
          this.downloadLoading = false
        }
      }
    }
  }
</script>
