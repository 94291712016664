<template>
  <div class="grid grid-cols-2 gap-x-3 job-summary">
    <el-collapse v-for="type in types"
                 v-model="activeGroups"
                 :key="type"
                 class="col-span-2 xl:col-span-1">
      <base-collapse-section :name="type"
                             wrapper-classes="p-2 print:p-0">
        <template #title>
          <div class="flex flex-1 items-center justify-between po-form-header">
            <div class="flex items-center">
              <span class="mx-2 print:mx-0 capitalize">
                {{ type }}
              </span>
            </div>
            <div v-show="isCollapse(type)"
                 class="flex items-center">
              <span class="summary">
                {{ $formatPrice(getDataByType(type).budget) }}
              </span>
              <span class="summary">
                {{ $formatPrice(getDataByType(type).actual) }}
              </span>
              <span class="summary">
                {{ $formatPercent(getDataByType(type).percent) }}
              </span>
            </div>
          </div>
        </template>
        <base-data-table
            v-bind="$attrs"
            :key="type"
            :columns="getColumns(type)"
            :data="getDataByType(type).types"
            :compact="true"
            :has-summary="true"
            :show-pagination="false"
        >
          <template #header="{row}">
            <div>
              <div class="text-sm print:text-print font-semibold flex justify-between items-center w-full">
                <span class="mr-3">{{ $t('Budget vs Actual') }}</span>
                <span>{{ $formatPrice(row.header.variance.est_variance_amount) }}</span>
              </div>
              <div class="text-sm print:text-print font-semibold flex justify-between items-center w-full">
                <span class="mr-3">{{ $t('Budget vs Estimated') }}</span>
                <span>{{ $formatPrice(row.header.variance.budget_variance_amount) }}</span>
              </div>
            </div>
          </template>
          <template #percent="{row}">
            {{ $formatPercent(row.percent) }}
          </template>
          <template #abbr="{row}">
            {{ getJobType(row.abbr)?.name || row.abbr }}
          </template>
          <template #abbr_summary>
            <span class="text-sm print:text-print font-semibold">
              {{ $t('Total') }}
            </span>
          </template>
          <template #budget_summary>
            <span class="text-sm print:text-print font-semibold">
              {{ $formatPrice(getDataByType(type).budget) }}
            </span>
          </template>
          <template #actual_summary>
            <span class="text-sm print:text-print font-semibold">
              {{ $formatPrice(getDataByType(type).actual) }}
            </span>
          </template>
          <template #percent_summary>
            <span class="text-sm print:text-print font-semibold">
              {{ $formatPercent(getDataByType(type).percent) }}
            </span>
          </template>
        </base-data-table>
      </base-collapse-section>
    </el-collapse>
  </div>
</template>
<script>
  import capitalize from 'lodash/capitalize'
  import { moduleAccountTypes } from '@/components/form/util'

  export default {
    props: {
      summary: {
        Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        activeGroups: [moduleAccountTypes.Income, moduleAccountTypes.Cost],
        moduleAccountTypes,
        columns: [
          {
            label: this.$t('Budget'),
            prop: 'budget',
            align: 'right',
            component: 'FormattedPrice',
            minWidth: 40,
            maxWidth: 100,
          },
          {
            label: this.$t('Actual'),
            prop: 'actual',
            align: 'right',
            component: 'FormattedPrice',
            minWidth: 40,
            maxWidth: 100,
          },
          {
            label: this.$t('Remaining'),
            prop: 'remaining',
            align: 'right',
            component: 'FormattedPrice',
            minWidth: 40,
            maxWidth: 100,
          },
          {
            label: this.$t(' '),
            prop: 'percent',
            align: 'right',
            minWidth: 40,
            maxWidth: 100,
          },
        ],
      }
    },
    computed: {
      types() {
        return Object.keys(this.summary).sort()
      },
      allJobTypes() {
        return this.$store.state.globalLists.jobTypes || []
      },
    },
    methods: {
      isCollapse(type) {
        return !this.activeGroups.includes(type)
      },
      getDataByType(type) {
        let { types, totals, variance } = this.summary[type]

        if (variance) {
          types = [
            { header: { variance } },
            ...types,
          ]
        }

        types = types.filter(type => {
          if (type.header) {
            return true
          }
          return type.budget > 0 || type.actual > 0
        })

        return {
          types,
          ...totals,
          budget: totals?.budget ?? 0,
          actual: totals?.actual ?? 0,
          percent: totals?.percent ?? 0,
        }
      },
      getColumns(type) {
        return [
          {
            label: capitalize(type),
            prop: 'abbr',
            align: 'left',
            minWidth: 100,
            maxWidth: 100,
          },
          ...this.columns,
        ]
      },
      getJobType(abbr) {
        return this.allJobTypes.find(j => j.abbr === abbr)
      },
    },
  }
</script>
<style lang="scss">
  .job-summary {
    .el-collapse {
      border-bottom: 0;
    }

    span.summary {
      @apply mx-1 text-sm font-semibold bg-gray-50 px-4 rounded-md;

      &:last-child {
        @apply bg-primary-50 font-bold;
      }
    }
  }
</style>
