<template>
  <div class="flex space-x-2 items-center">
    <BaseTooltip
      v-if="row.timesheet_entry_id"
      :content="$t('This entry was transferred from a timesheet and can only be partially edited')"
    >
      <ClockIcon class="w-4 h-4"/>
    </BaseTooltip>
    <span>
      {{ formattedDate }}
    </span>
  </div>
</template>
<script>
import { ClockIcon } from "vue-feather-icons";

export default {
  components: {
    ClockIcon
  },
  props: {
    days: {
      type: Array,
      default: () => [],
    },
    row: {
      type: Object,
      default: () => ({}),
    }
  },
  computed: {
    formattedDate() {
      const days = this.days
      return days.find(day => day.value.startsWith(this.row.date))?.label || date
    }
  },
}
</script>
