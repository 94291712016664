<template>
  <BaseSelect
    v-on="$listeners"
    v-bind="$attrs"
    :multiple="multiple"
    :label-format="formatLabel"
    :table-columns="tableColumns"
    :options="getOptions"
    :resource-name="globalResources.DeductionCodes"
    :value-key="valueKey"
    label-key="code"
  />
</template>
<script>
  import { globalResources } from "@/components/form/util";

  export default {
    props: {
      valueKey: {
        type: String,
        default: 'id',
      },
      multiple: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        globalResources,
        tableColumns: [
          {
            minWidth: 100,
            maxWidth: 150,
            name: this.$t('Code'),
            prop: 'code',
          },
          {
            minWidth: 150,
            maxWidth: 150,
            name: this.$t('Description'),
            prop: 'description',
          },
        ],
      }
    },
    computed: {
      getOptions() {
        return this.$store.getters['globalLists/getResourceList'](globalResources.DeductionCodes) || []
      }
    },
    methods: {
      formatLabel(option) {
        const { code, description } = option
        if (!description) {
          return code
        }
        return `${code} (${description})`
      },
    },
  }
</script>
