import { flare } from "@flareapp/flare-client";
// Only enable Flare in production, we don't want to waste your quota while you're developing:
export function initGlobalErrorHandler() {
    if (process.env.NODE_ENV === 'production') {
        flare.light();
    }
    window.addEventListener('error', (event) => {
        const errorMessage = event?.error?.toString() || ''
        if (errorMessage.includes('ResizeObserver loop limit exceeded')) {
            console.warn('Ignored: ResizeObserver loop limit exceeded');
            event.stopPropagation()
        }
    });
}
