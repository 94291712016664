<template>
  <div>
    <div
      v-if="readOnly"
      class="flex w-full justify-end mb-2 pr-4"
    >
      <BaseSwitch
        v-if="showPhaseTotals"
        v-model="expandAll"
        :label-info="$t('Toggle Phase Details')"
        inline
        @change="toggleExpand"
        class="mr-2"
      />
      <base-switch
        v-model="showPhaseTotals"
        :label-info="$t('Group By Phase')"
        inline
      />
    </div>
    <AgDataTable
        :key="showPhaseTotals"
        v-bind="extraProps"
        :url="url"
        :url-params="urlParams"
        :columns="columns"
        :transform-data="transformData"
        :groupIncludeTotalFooter="true"
        :enableBrowserTooltips="true"
        :read-only="readOnly"
        :no-borders="true"
        :show-cells-legend="showCellsLegend"
        :showPagination="false"
        :groupDefaultExpanded="-1"
        :groupIncludeFooter="true"
        :groupRowRendererParams="groupRowRendererParams"
        :auto-group-column-def="autoGroupColumnDef"
        :groupDisplayType="showPhaseTotals ? 'singleColumn': 'groupRows'"
        suppressColumnReordering
        dom-layout="autoHeight"
        ref="gridTable"
        id="gridTable"
        @data-updated="onDataUpdated"
        @grid-ready="grid = $event"
    />
    <portal to="billing-summary">
      <div class="flex items-center">
        <div class="summary">
          {{ $t('Gross:') }}
          <span>
            {{ formatPrice(getTotals.gross_amount) }}
          </span>
        </div>
        <div class="summary">
          {{ $t('Material Stored:') }}
          <span>
            {{ formatPrice(getTotals.material_stored_amount) }}
          </span>
        </div>
        <div class="summary">
          {{ $t('Retention:') }}
          <span>
            {{ formatPrice(getTotals.retention_amount) }}
          </span>
        </div>
        <div v-if="$settings($modules.AR, 'apply_tax_to_billings')"
             class="summary">
          {{ $t('Sales Tax:') }}
          <span>
            {{ formatPrice(getTotals.sales_tax_amount) }}
          </span>
        </div>
        <div class="summary">
          {{ $t('Net:') }}
          <span>
            {{ formatPrice(getTotals.net_amount) }}
          </span>
        </div>
      </div>
    </portal>

  </div>
</template>
<script lang="ts">
  import axios from 'axios'
  import { resourceStatuses } from '@/enum/enums'
  import {editableTableProps, getTableData, toggleRowExpand} from '@/components/ag-grid/tableUtils'
  import {cellClasses, percentageValidator, requiredValueSetter} from '@/components/ag-grid/columnUtils'
  import { cellEditors } from '@/components/ag-grid/cellEditors/cellEditors'
  import sumBy from "lodash/sumBy";
  import { getNumberValue } from "@/utils/utils";
  import { defineComponent } from 'vue'
  import { ProgressBillingEntry } from "@/modules/accounts-receivable/models/progressBillings";
  import {
    CellClassParams,
    EditableCallbackParams,
    GridApi,
    GridReadyEvent,
    ICellRendererParams,
    ValueGetterParams,
    ValueSetterParams,
  } from '@ag-grid-community/core'
  import { Column } from "@/components/ag-grid/tableTypes";
  import { formatPercent, getPercentage } from "@/plugins/formatPercent";
  import { formatPrice } from "@/plugins/formatPrice";
  import BillingGroupRow from "./BillingGroupRow.vue";
  import {computeBillingEntrySalesTax} from "@/modules/accounts-receivable/utils/billingUtils";
  import {round} from "lodash";
  import {getPhaseTitle} from "@/modules/job-costing/utils/phaseUtils";

  const computedFields = {
    CompletedPercentage: 'budget.completion',
    CurrentAmount: 'entry.gross_amount',
    MaterialStored: 'entry.material_stored_amount',
  }

  export default defineComponent({
    components: {
      BillingGroupRow,
    },
    props: {
      billing: {
        type: Object,
        default: () => ({}),
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      showCellsLegend: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        editableTableProps,
        entries: [] as ProgressBillingEntry[],
        grid: null as GridReadyEvent | null,
        showPhaseTotals: false,
        expandAll: true,
        autoGroupColumnDef: {
          headerName: this.$t('Group'),
          minWidth: 200,
          cellRendererParams: {
            suppressCount: true,
          }
        },
      }
    },
    computed: {
      extraProps() {
        return {
          ...editableTableProps,
          ...this.$attrs,
        }
      },
      isBillingCompleted() {
        return [resourceStatuses.Posted, resourceStatuses.Paid, resourceStatuses.PartialPaid, resourceStatuses.Voided].includes(this.billing.status)
      },
      url() {
        if (!this.billing?.job_id) {
          return
        }
        if (this.billing.id) {
          return `/restify/billing-entries/progress`
        }
        return `/restify/line-items/progress`
      },
      urlParams() {
        return {
          page: 1,
          billing_id: this.billing?.id,
          job_id: this.billing.job_id,
        }
      },
      groupRowRendererParams() {
        return {
          suppressCount: true,
          innerRenderer: 'BillingGroupRow',
          innerRendererParams: {
            jobId: this.billing.job_id,
          }
        }
      },
      hasChangeOrders() {
        return this.entries.some(entry => entry?.line_item?.change_order?.toString() !== '0')
      },
      getTotals() {
        const gross_amount = sumBy(this.entries, entry => getNumberValue(entry?.entry?.gross_amount))
        const retention_amount = sumBy(this.entries, entry => getNumberValue(entry?.entry?.retention_amount))
        const material_stored_amount = sumBy(this.entries, entry => getNumberValue(entry?.entry?.material_stored_amount))
        const sales_tax_amount = sumBy(this.entries, entry => getNumberValue(entry?.entry?.sales_tax_amount))

        const net_amount = gross_amount - retention_amount + material_stored_amount + sales_tax_amount

        return {
          material_stored_amount,
          retention_amount,
          gross_amount,
          sales_tax_amount,
          net_amount,
        }
      },
      columns(): Column<ProgressBillingEntry>[] {
        const columns: Column<ProgressBillingEntry>[] = [
          {
            field: 'line_item.has_change_order',
            rowGroup: this.hasChangeOrders,
            hide: true,
            rowGroupIndex: 0,
            valueGetter: params => {
              return params.data?.line_item?.change_order > 0 ? this.$t('Change Orders') : this.$t('Base Contract')
            },
          },
          {
            headerName: this.$t('Contract'),
            children: [
              {
                headerName: this.$t('Line Item'),
                field: 'line_item.id',
                component: 'AddlSourceLink',
                cellRendererParams: (params: ICellRendererParams<ProgressBillingEntry>) => {
                  return {
                    target: this.readOnly ? undefined : '_blank',
                    showDescription: !this.readOnly,
                    line_item: params.data?.line_item,
                  }
                },
                minWidth: this.readOnly ? 100 : 240,
                maxWidth: 400,
                flex: 1,
              },
              {
                headerName: this.$t('Description'),
                field: 'line_item.description',
                hide: !this.readOnly,
                minWidth: 200,
                maxWidth: 500,
              },
              {
                headerName: this.$t('Schedule Value'),
                field: 'budget.amount',
                component: 'FormattedPrice',
                minWidth: 120,
                maxWidth: 140,
                aggFunc: 'sum',
              },
            ]
          },
          {
            headerName: this.$t('Completed'),
            children: [
              {
                headerName: this.$t('Completed $'),
                field: 'budget.amount_to_date',
                component: 'FormattedPrice',
                cellClass: 'readonly-ag-cell flex justify-end',
                editable: !this.readOnly,
                minWidth: 120,
                maxWidth: 140,
                aggFunc: 'sum',
              },
              {
                headerName: this.$t('Completed %'),
                field: 'budget.completion',
                cellEditor: cellEditors.Numeric,
                minWidth: 100,
                maxWidth: 120,
                align: 'right',
                editable: !this.readOnly,
                valueGetter: (params: ValueGetterParams<ProgressBillingEntry>) => {
                  let { amount, amount_to_date } = params.data?.budget || {}
                  amount = amount || 0
                  amount_to_date = amount_to_date || 0
                  let { gross_amount } = params.data?.entry || {}
                  gross_amount = gross_amount || 0

                  const totalAmount = +gross_amount + +amount_to_date
                  let percentage = totalAmount / amount * 100
                  if (amount === 0) {
                    percentage = 100
                  }
                  if (amount === 0 && totalAmount === 0)  {
                    percentage = 0
                  }
                  if (params?.node?.group && !params?.node?.footer && params?.node?.expanded) {
                    return
                  }
                  return formatPercent(percentage, {
                    maximumFractionDigits: 2,
                  })
                },
                valueSetter: (params: ValueSetterParams<ProgressBillingEntry>) => {
                  const isValid = requiredValueSetter(params, 0, percentageValidator)

                  if (!isValid) {
                    return false
                  }

                  params.data.budget.completion = params.newValue
                  this.calculateAmounts(params, computedFields.CompletedPercentage)
                  return true
                },
                aggFunc: 'avg',
              },
              {
                headerName: this.$t('This Period'),
                field: 'entry.gross_amount',
                cellEditor: cellEditors.Numeric,
                editable: !this.readOnly,
                align: 'right',
                component: 'FormattedPrice',
                tooltipValueGetter(params) {
                  if (params.data?.line_item?.exempt_from_sales_tax) {
                    return 'This line item is exempt from sales tax.'
                  }
                },
                cellClass: params => {
                  if (params.data?.line_item?.exempt_from_sales_tax && params?.value) {
                    return 'warning-ag-cell flex justify-end'
                  }
                  if (params?.value && params.data) {
                    return cellClasses.Highlight
                  }
                  return 'flex justify-end'
                },
                valueSetter: (params: ValueSetterParams<ProgressBillingEntry>) => {
                  const { budget } = params.data
                  const maxVal = budget.amount - budget.amount_to_date
                  let isValid = params.newValue <= maxVal
                  // if budget is negative, we have a min value we cannot reach
                  if (budget.amount < 0) {
                    let minValue = budget.amount + budget.amount_to_date
                    isValid = params.newValue >=minValue
                  }

                  if (!isValid) {
                    const amount = formatPrice(maxVal)
                    this.$warning(this.$tc(`This Period amount cannot be greater than ${amount}.`))
                    return false
                  }

                  params.data.entry.gross_amount = +params.newValue
                  this.calculateAmounts(params, computedFields.CurrentAmount)
                  return true
                },
                aggFunc: 'sum',
                minWidth: 120,
                maxWidth: 200,
              },
            ]
          },
          {
            headerName: this.$t('Material Stored'),
            children: [
              {
                headerName: this.$t('Prior'),
                field: 'budget.material_stored_amount',
                component: 'FormattedPrice',
                cellClass: 'readonly-ag-cell',
                suppressNavigable: true,
                minWidth: 100,
                maxWidth: 120,
                align: 'right',
                aggFunc: 'sum',
              },
              {
                headerName: this.$t('Current'),
                field: 'entry.material_stored_amount',
                component: 'FormattedPrice',
                cellEditor: cellEditors.Numeric,
                valueSetter: (params: ValueSetterParams<ProgressBillingEntry>) => {
                  const isValid = this.validateMaterialStoredAmount(params)

                  if (!isValid) {
                    return false
                  }

                  const oldValue = +params.oldValue
                  const newValue = +params.newValue
                  const grossAmount = +params.data.entry.gross_amount

                  if (oldValue < 0) {
                    params.data.entry.gross_amount = grossAmount + oldValue
                    this.onGrossAmountChange(params.data)
                  }

                  if (newValue < 0) {
                    params.data.entry.gross_amount = +params.data.entry.gross_amount + Math.abs(newValue)
                    this.onGrossAmountChange(params.data)
                  }

                  params.data.entry.material_stored_amount = +params.newValue
                  this.calculateAmounts(params, computedFields.MaterialStored)
                  return true
                },
                editable: (params: EditableCallbackParams<ProgressBillingEntry>) => {
                  if (!params.data) {
                    return false
                  }
                  const { budget } = params.data
                  return budget.completion < 100
                },
                cellClass: (params: CellClassParams<ProgressBillingEntry>) => {
                  if (this.readOnly) {
                    return 'flex justify-end'
                  }
                  const completion = params.data?.budget.completion || 0
                  return completion === 100 ? 'readonly-ag-cell flex justify-end' : 'flex justify-end'
                },
                aggFunc: 'sum',
                minWidth: 120,
                maxWidth: 200,
              },
            ]
          },
          {
            headerName: 'Retention',
            children: [
              {
                headerName: this.$t('Prior'),
                field: 'budget.retention_amount',
                component: 'FormattedPrice',
                align: 'right',
                minWidth: 100,
                maxWidth: 120,
                cellClass: 'readonly-ag-cell',
                suppressNavigable: true,
                aggFunc: 'sum',
              },
              {
                headerName: this.$t('Current'),
                field: 'entry.retention_amount',
                component: 'FormattedPrice',
                align: 'right',
                cellEditor: cellEditors.Numeric,
                editable: !this.readOnly,
                aggFunc: 'sum',
                minWidth: 120,
                maxWidth: 200,
              },
            ]
          }
        ]
        if (this.showPhaseTotals && this.readOnly) {
          columns.splice(1, 0, {
            field: 'line_item.phase_code',
            rowGroup: true,
            enableRowGroup: true,
            hide: true,
            rowGroupIndex: 1,
            valueGetter: params => {
              return getPhaseTitle(params?.data?.line_item?.phase_code, this.billing.job_id)
            }
          })
        }
        return columns
      },
    },
    methods: {
      formatPrice,
      onDataUpdated(entries: any) {
        this.entries = entries.filter((entry: any) => entry !== undefined)
      },
      async transformData(entries: ProgressBillingEntry[]) {
        this.entries = entries.map(entry => {
          entry._localId = entry.entry.id || entry.line_item.id
          entry.line_item.has_change_order = entry.line_item.change_order !== 0
          return entry
        })
        return this.entries
      },
      calculateAmounts(params: ValueSetterParams<ProgressBillingEntry>, prop: string) {
        let currentEntry = params.data

        if (prop === computedFields.CompletedPercentage) {
          currentEntry = this.onCompletionChange(currentEntry)
        }

        if (prop === computedFields.CurrentAmount && currentEntry.entry.gross_amount >= 0) {
          currentEntry = this.onGrossAmountChange(currentEntry)
        }

        currentEntry = this.calculateSalesTax(currentEntry)
        currentEntry = this.calculateRetention(currentEntry)

        params.node?.setData(currentEntry)
      },
      onGrossAmountChange(currentEntry: ProgressBillingEntry) {
        const budget = +currentEntry.budget.amount
        const grossAmount = +currentEntry.entry.gross_amount
        const priorCompletion = +getPercentage(currentEntry.budget.amount, currentEntry.budget.amount_to_date, false)

        const percent = getPercentage(budget, grossAmount, false)
        currentEntry.budget.completion = priorCompletion + +percent
        currentEntry.entry.material_stored_amount = 0

        return currentEntry
      },
      onCompletionChange(currentEntry: ProgressBillingEntry) {
        const completion = +currentEntry.budget.completion
        const priorCompletion = +getPercentage(currentEntry.budget.amount, currentEntry.budget.amount_to_date, false)
        const budget = +currentEntry.budget.amount

        const percentDifference = completion - priorCompletion
        let grossAmount = budget * percentDifference / 100
        grossAmount = round(grossAmount, 2)
        if (completion === 100) {
          grossAmount = budget - currentEntry.budget.amount_to_date
        }
        currentEntry.entry.gross_amount = +grossAmount.toFixed(2)

        if (completion === 100) {
          currentEntry.entry.material_stored_amount = 0
        }

        return currentEntry
      },
      calculateSalesTax(currentEntry: ProgressBillingEntry) {
        const salesTax: any = computeBillingEntrySalesTax(currentEntry.entry, this.billing)
        currentEntry.entry.sales_tax_amount = salesTax.amount
        if (currentEntry.entry?.meta) {
          currentEntry.entry.meta.exempt_from_sales_tax = salesTax.exempt_from_sales_tax
        }
        return currentEntry
      },
      calculateRetention(currentEntry: ProgressBillingEntry) {
        let grossAmount = +currentEntry.entry.gross_amount
        const materialStoredAmount = +currentEntry.entry.material_stored_amount
        const billingRetentionPercent = +this.billing.retention_completed_percent
        const materialStoredRetentionPercent = +this.billing.retention_stored_percent

        let retentionAmount = 0
        let materialStoredRetention = 0

        if (billingRetentionPercent && grossAmount) {
          retentionAmount = grossAmount * billingRetentionPercent / 100
        }

        if (materialStoredRetentionPercent && materialStoredAmount) {
          materialStoredRetention = materialStoredAmount * materialStoredRetentionPercent / 100
        }

        currentEntry.entry.retention_amount = round(retentionAmount + materialStoredRetention, 2)

        return currentEntry
      },
      validateMaterialStoredAmount(params: ValueSetterParams<ProgressBillingEntry>) {
        const { budget, entry } = params.data
        const priorMaterialStored = +params.data.budget.material_stored_amount
        const newValue = +params.newValue

        const maxValue = (budget.amount - entry.gross_amount - budget.amount_to_date)
        const isValid = newValue >= -priorMaterialStored && newValue <= maxValue

        const amount = formatPrice(maxValue)
        const priorAmount = formatPrice(priorMaterialStored)
        if (isValid) {
          return true
        }
        if (priorMaterialStored > 0) {
          this.$warning(this.$tc(`Material Stored must be between -${priorAmount} and ${amount}.`))
        } else if (priorMaterialStored === 0) {
          this.$warning(this.$tc(`No material stored to release.`))
        }
        return isValid
      },
      mapLocalEntryToRequestEntry(entry: ProgressBillingEntry) {
        const salesTax: any = computeBillingEntrySalesTax(entry.entry, this.billing)
        return {
          id: entry.entry.id,
          billing_id: entry.entry.billing_id,
          description: entry.entry.description || entry.line_item.description,
          addl_source_id: entry.entry.addl_source_id,
          addl_source_type: entry.entry.addl_source_type,
          cost_center: entry.entry.cost_center,
          account: entry.entry.account,
          subaccount: entry.entry.subaccount,
          gross_amount: entry.entry.gross_amount,
          sales_tax_amount: salesTax?.amount || 0,
          retention_amount: entry.entry.retention_amount,
          prior_retention_amount: entry.budget.retention_amount,
          material_stored_amount: entry.entry.material_stored_amount,
          prior_material_stored_amount: entry.budget.material_stored_amount,
          sales_tax_percent: this.billing.sales_tax_percent || 0,
          retention_percent: this.billing.retention_percent || 0,
          meta: {
            district_id: entry.entry.meta?.district_id,
            exempt_from_sales_tax: salesTax?.exempt_from_sales_tax,
          }
        }
      },
      getEntries() {
        return this.entries
      },
      recomputeEntryAmounts() {
        this.entries.forEach(entry => {
          this.calculateRetention(entry)
          this.calculateSalesTax(entry)
        })
      },
      hasEntriesWithRetention() {
        return this.entries.some(entry => entry.entry.retention_amount > 0)
      },
      getEntriesToUpdate() {
        return this.entries
            .filter(entry => entry.entry?.id)
            .map(entry => this.mapLocalEntryToRequestEntry(entry))
      },
      async getEntriesToSave(billing_id: string) {
        const entries = this.entries.filter(entry => !entry?.entry.id)
        return entries.map(entry => {
          entry.entry.billing_id = billing_id
          return this.mapLocalEntryToRequestEntry(entry)
        })
      },
      async storeProgress(billingId: string) {
        const entriesToUpdate = this.getEntriesToUpdate()
        const entriesToSave = await this.getEntriesToSave(billingId)

        let promises = []

        if (entriesToUpdate.length) {
          promises.push(axios.post('/restify/billing-entries/bulk/update', entriesToUpdate))
        }

        if (entriesToSave.length) {
          promises.push(
              axios.post(`/restify/billings/${billingId}/actions?action=create-progress-billing`, {
                calc_retention: this.billing.retention_basis,
                entries: entriesToSave,
              }),
          )
        }

        await Promise.all(promises)
        this.refreshData()
      },
      refreshData() {
        const table = this.$refs?.gridTable as any
        table?.refresh()
      },
      getTableData() {
        return getTableData(this.grid?.api as GridApi)
      },
      toggleExpand(value: boolean) {
        const level = this.hasChangeOrders ? 1 : 0
        toggleRowExpand(this.grid?.api, value, level)
      },
    },
  })
</script>
