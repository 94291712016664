<template>
  <base-button
      :variant="variant"
      class="print:hidden post-button"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <div class="flex items-center">
      <SendIcon class="w-5 h-5 mr-2"/>
      <span>
        <slot>{{ $t('Post') }}</slot>
      </span>
    </div>
  </base-button>
</template>
<script>
  import { SendIcon } from 'vue-feather-icons'

  export default {
    name: 'post-button',
    components: {
      SendIcon,
    },
    props: {
      variant: {
        type: String,
        default: 'primary-link',
      },
    }
  }
</script>
<style>
  .post-button .btn{
    @apply px-0;
  }
</style>
