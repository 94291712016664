<template>
  <entity-select
    v-bind="$attrs"
    v-on="$listeners"
    :url="url"
    :url-params="finalUrlParams"
    :name="$attrs.name || $t('Employee')"
    :placeholder="$attrs.placeholder || $t('Select Employee')"
    :label="$attrs.label !== undefined ? $attrs.label : $t('Employee')"
    :add-entity="addEntity"
    :edit-entity="editEntity"
    :set-default-option="editEntity"
    :add-label="addLabel"
    :on-map-entry="onChangeEntry"
    :label-format="formatLabel"
    :id="$attrs.id || 'employee-select'"
    :table-columns="tableColumns"
    :label-key="labelKey"
    :initial-value="initialValue"
  />
</template>
<script>
  import i18n from '@/i18n'

  export default {
    name: 'EmployeeSelect',
    props: {
      url: {
        type: String,
        default: '/restify/employees',
      },
      urlParams: {
        type: Object,
        default: () => ({
          sort: 'code',
        }),
      },
      addEntity: {
        type: Boolean,
        default: false,
      },
      editEntity: {
        type: Boolean,
        default: false,
      },
      displayPayColumn: {
        type: Boolean,
        default: false,
      },
      labelKey: {
        type: String,
        default: 'code',
      },
      showInactive: {
        type: Boolean,
        default: false,
      },
      addLabel: {
        type: String,
        default: i18n.t('Add new Employee'),
      },
      initialValue: {
        type: [Object, Array],
      },
    },
    computed: {
      finalUrlParams() {
        const params = {
          ...this.urlParams,
        }
        if (!this.showInactive) {
          params.status = 'hourly,salary'
        }
        return params
      },
      tableColumns() {
        let columns = [
          {
            minWidth: 80,
            maxWidth: 150,
            name: this.$t('Code'),
            prop: 'attributes.code',
          },
          {
            minWidth: 130,
            maxWidth: 130,
            name: this.$t('Name'),
            prop: 'attributes.name',
          },
          {
            minWidth: 100,
            maxWidth: 100,
            name: this.$t('Status'),
            prop: 'attributes.status',
            component: 'Status',
          },
        ]
        if (!this.displayPayColumn) {
          return columns
        }
        return [
          ...columns,
          {
            minWidth: 80,
            maxWidth: 100,
            name: this.$t('Pay'),
            prop: 'attributes.salary_amount',
            component: 'FormattedPrice',
          },
        ]
      },
    },
    methods: {
      formatLabel(employee) {
        const { code, name } = employee.attributes
        return !name ? code : `${code} (${name})`
      },
      onChangeEntry(employee) {
        this.$emit('on-map-entry', employee)
      },
    },
  }
</script>
