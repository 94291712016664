import axios from 'axios'
import store from '@/store'
import get from 'lodash/get'

export async function getAccountByNumber(accountNumber = '') {
  if (!accountNumber) {
    return {}
  }

  const { id } = store.state.company.accounts.find(a => a.number === accountNumber)
  const { data } = await axios.get(`/restify/accounts/${id}`)

  return data.attributes
}

export function getAccountByNumberSync(accountNumber = '') {
  if (!accountNumber) {
    return {}
  }
  return store.state.company.accounts.find(a => a.number === accountNumber)
}

// * Compute account balance by account number
export async function getAccountBalance(accountNumber, period) {
  const account = await getAccountByNumber(accountNumber)

  return computedAccountBalance(account, period)
}

// * Compute account balance by account data
export function computedAccountBalance(account, period) {
  let sum = 0

  for (let i = 0; i <= period; i++) {
    sum += get(account, `p${i}_amount`, 0)
  }
  return sum
}
