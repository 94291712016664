<template>
  <BaseDataTable v-bind="$attrs"
                 :columns="columns"
                 :url="url"
                 :url-params="finalUrlParams"
                 :show-actions-column="false"
                 :is-expandable="true"
                 default-sort="-reference_date"
                 actions="search,refresh"
                 ref="historyTable"
  >
    <template #expand-content="{row}">
      <transaction-details :transaction="row.originalRow"/>
    </template>
    <template #attributes.journal="{row}">
      <JournalLink :data="{ id: row.attributes?.journal_id, name: row.attributes?.journal}"/>
    </template>
    <template #subaccount="{row}">
      <SubAccountLink
        v-if="get(row, 'relationships.subaccount')"
        :data="get(row, 'relationships.subaccount')"
      />
    </template>
    <template #business="{row}">
      <BusinessLink
        v-if="getBusinessData(row)"
        :data="getBusinessData(row)"
      />
    </template>
    <template #additional-actions
              v-if="showPeriodFilter">
      <PeriodFilter v-model="selectedPeriod"/>
    </template>
    <template v-slot:attributes.period="{row}">
      {{ getPeriodName(row.attributes.period) }}
    </template>
  </BaseDataTable>
</template>
<script>
import PeriodFilter from '../components/PeriodFilter'
import TransactionDetails from "@/modules/ledger/components/TransactionDetails";
import BusinessLink from "@/components/links/BusinessLink.vue";

export default {
  components: {
    BusinessLink,
    PeriodFilter,
    TransactionDetails,
  },
  props: {
    url: {
      type: String,
      default: '/restify/transactions',
    },
    urlParams: {
      type: Object,
      default: () => ({}),
    },
    showPeriodFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedPeriod: null,
    }
  },
  computed: {
    periodFilter() {
      return this.selectedPeriod || this.urlParams?.period || undefined
    },
    finalUrlParams() {
      return {
        ...this.urlParams,
        related: 'subaccount,source[id|number|description],business[id|code|name]',
        fiscal_year: this.activeFiscalYear,
        period: this.periodFilter
      }
    },
    columns() {
      const columns = [
        {
          label: this.$t('Journal'),
          prop: 'attributes.journal',
          maxWidth: 100,
        },
        {
          label: this.$t('Ref Date'),
          prop: 'attributes.reference_date',
          maxWidth: 140,
          component: 'FormattedDate',
        },
        {
          label: this.$t('Description'),
          prop: 'attributes.description',
          maxWidth: 180,
        },
        {
          label: this.$t('Sub'),
          prop: 'subaccount',
          maxWidth: 60,
        },
        {
          label: this.$t('Vendor <br> Customer'),
          prop: 'business',
          maxWidth: 100,
        },
        {
          label: this.$t('Ref #'),
          prop: 'attributes.reference_no',
          maxWidth: 100,
        },
        {
          label: this.$t('Period'),
          prop: 'attributes.period',
          maxWidth: 140,
          renderIf: () => !this.periodFilter,
        },
        {
          label: this.$t('Amount'),
          prop: 'attributes.amount',
          minWidth: 150,
          align: 'right',
          component: 'FormattedPrice',
        },
      ]

      return columns.filter(column => column.renderIf ? column.renderIf() : true)
    },
  },
  methods: {
    getPeriodName(period) {
      return this.$store.getters['company/getPeriodName'](period)
    },
    getBusinessData(row) {
      const business = this.get(row, 'relationships.business', {})
      return {
        business: {
          ...business,
          type: row.attributes?.business_type
        }
      }
    }
  },
}
</script>
