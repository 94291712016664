<template>
  <base-form :save-text="$t('Post')"
             :showCancel="showCancel"
             :loading="loading"
             layout="modal"
             @cancel="$emit('cancel')"
             @submit="onSubmit"
  >
    <template v-if="hasCustomMessage">
      <base-alert class="col-span-6"
                  :type="$promptType.Warning">
        <slot name="custom-message"/>
      </base-alert>
    </template>
    <template v-else>
      <div class="col-span-6 flex items-center justify-center text-md leading-5 text-cool-gray-500 font-medium pb-6">
        <ValidationWarning>
          {{
            $t(`This will post the ${resourceName} to the corresponding accounts. Note that this action is irreversible.`)
          }}
        </ValidationWarning>
      </div>
      <PostActionWarning
          class="col-span-6"
          v-if="showDateAheadWarning"
          :period="model.period"
          :fiscal-year="model.fiscal_year"
      />

      <template v-if="isAccounting">
        <div v-if="showYear" class="col-span-6 md:col-span-3">
          <base-input :label="$t('Fiscal Year')">
            <template v-slot:container>
              <fiscal-year-select v-model="model.fiscal_year"
                                  class="w-full"
                                  id="fiscal_year"
              />
            </template>
          </base-input>
        </div>
        <div v-if="showPeriod" class="col-span-6 md:col-span-3">
          <period-select
              v-model="model.period"
              :fiscal-year="model.fiscal_year"
              :name="$t('Period')"
              :label="$t('Period')"
              rules="required"
              id="period-select"
          />
        </div>
      </template>
      <div v-if="paymentDate"
           class="col-span-6 md:col-span-3">
        <base-date-picker v-model="model.payment_date"
                          :label="$t('Payment Date')"
                          :name="$t('Payment Date')"
                          id="date"
                          rules="required"
        />
      </div>
      <div v-if="showBank"
           class="col-span-6 md:col-span-3">
        <bank-select v-model="model.bank_id"
                     :label="$t('Bank')"
                     :name="$t('Bank')"
                     :used-for="usedFor"
                     id="bank"
                     rules="required"
                     @entity-change="onBankChange"
        />
      </div>
      <template v-if="showPaymentStartNumber">
        <div class="col-span-6 md:col-span-3">
          <base-input v-model="model.payment_start_number"
                      :min="0"
                      :label="$t('Starting Check Number')"
                      :tip="$t('When previewed check(s) will start with the provided number below')"
                      type="number"
                      id="payment_start_number"
                      rules="required"
          >
          </base-input>
        </div>
        <div class="col-span-6 md:col-span-3">
          <base-input
            v-if="!model.use_same_series_for_dd"
            v-model="model.dd_start_number"
            :min="0"
            :label="$t('Starting DD Number')"
            :tip="$t('When previewed DD(s) will start with the provided number below')"
            type="number"
            id="dd_start_number"
          >
          </base-input>
        </div>
        <div class="col-span-6 md:col-span-3 mb-2">
          <base-checkbox v-model="model.use_same_series_for_dd"
                         :label="$t('Use same series for DD')"
                         :tip="$t('Use same series for Direct Deposits')"
                         id="use_same_series_for_dd"
          >
          </base-checkbox>
        </div>
      </template>
      <template v-if="hasRecurringOption">
        <div class="col-span-6 md:col-span-3">
          <base-switch v-model="model.recurring"
                       label-info="Include Recurring Payable"
                       id="recurring"
          />
        </div>
        <div class="col-span-6 md:col-span-3">
          <base-input v-model="model.before_days"
                      :label="$t('Before Day')"
                      :name="$t('Before Day')"
                      :disabled="!model.recurring"
                      id="before_days"
                      type="number"
                      :step="1"
                      :min="0"
                      :max="31"
                      rules="min_value:0|max_value:31"
          />
        </div>
      </template>
      <div class="col-span-6 md:col-span-3">
        <base-checkbox
            id="redirect_to_journal"
            v-model="model.redirect_to_journal"
            :label="$t('Redirect to Journal')"
            :tip="$t('Redirect to journal after posting')"
        />
      </div>
    </template>

  </base-form>
</template>
<script>
  import PostActionWarning from '@/components/common/modal/PostActionWarning'
  import { getLastCheckNumber } from "@/modules/settings/utils/bankUtils";
  import { BankUsedInTypes } from "@/enum/enums";

  export default {
    name: 'PostDialog',
    components: {
      PostActionWarning,
    },
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      hasRecurringOption: {
        type: Boolean,
        default: false,
      },
      resourceName: {
        type: String,
        default: 'invoices',
      },
      paymentDate: {
        type: Boolean,
        default: false,
      },
      showPeriod: {
        type: Boolean,
        default: true,
      },
      showYear: {
        type: Boolean,
        default: true,
      },
      showBank: {
        type: Boolean,
        default: false,
      },
      showPaymentStartNumber: {
        type: Boolean,
        default: false,
      },
      hasCustomMessage: {
        type: Boolean,
        default: false,
      },
      isAccounting: {
        type: Boolean,
        default: true,
      }
    },
    data() {
      const currentYear = this.activeFiscalYear
      const currentPeriod = this.$store.getters['company/getCurrentPeriodValue']

      let redirect_to_journal = this.$settings(this.$modules.GENERAL, 'redirect_to_journal_after_post')
      const use_same_series_for_dd = this.$settings(this.$modules.PR, 'use_same_series_for_dd') || false
      if (redirect_to_journal === undefined) {
        redirect_to_journal = true
      }

      return {
        showCancel: true,
        model: {
          period: currentPeriod,
          recurring: false,
          before_days: 0,
          entered_by: 'all',
          fiscal_year: currentYear,
          payment_date: this.$now,
          bank_id: null,
          payment_start_number: null,
          dd_start_number: null,
          use_same_series_for_dd: use_same_series_for_dd,
          redirect_to_journal,
        },
      }
    },
    computed: {
      showDateAheadWarning() {
        return this.showYear && this.showPeriod
      },
      usedFor() {
        const APResources = ['invoices', 'invoice-payments', 'accounts-payable-init']
        const ARResources = ['billings', 'billing-payments', 'accounts-receivable-init']
        const PRResources = ['payroll-batch']
        if (APResources.includes(this.resourceName)) {
          return BankUsedInTypes.AccountsPayable
        } else if (ARResources.includes(this.resourceName)) {
          return BankUsedInTypes.AccountsReceivable
        } else if (PRResources.includes(this.resourceName)) {
          return BankUsedInTypes.Payroll
        }
      }
    },
    methods: {
      onBankChange(bank) {
        let check_no = 0
        const lastCheckNo = getLastCheckNumber(bank)
        if (lastCheckNo) {
          check_no = lastCheckNo
        }
        this.model.payment_start_number = check_no + 1
        if (this.model.use_same_series_for_dd) {
          this.model.dd_start_number = check_no + 1
        } else {
          const lastDDNumber = (bank?.attributes?.last_pr_dd_no || 0)
          this.model.dd_start_number = lastDDNumber + 1
        }
      },
      onSubmit() {
        this.$emit('save', this.model)
      },
    },
    watch: {
      activeFiscalYear: {
        immediate: true,
        handler(value) {
          if (!this.model.fiscal_year && value) {
            this.model.fiscal_year = value
          }
        }
      }
    }
  }
</script>
