<template>
  <div class="report-page">
    <portal to="report-common-filter">
      <CalendarYearSelect/>
    </portal>
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>
  import CalendarYearSelect from '@/components/select/entities/CalendarYearSelect'

  export default {
    components: {
      CalendarYearSelect,
    },
    data() {
      return {
        items: [
          {
            name: this.$t('Payroll History Distribution'),
            path: '/payroll/reports/payroll-history-distribution',
            permission: 'payroll_reports',
          },
          {
            name: this.$t('Payroll History - By Employee'),
            path: '/payroll/reports/payroll-history-by-employee',
            permission: 'payroll_reports',
          },
          {
            name: this.$t('Selected Payroll History'),
            path: '/payroll/reports/selected-payroll-history',
            permission: 'payroll_reports',
          },
          {
            name: this.$t('General Liability Summary'),
            path: '/payroll/reports/general-liability-summary',
            permission: 'payroll_reports',
          },
          {
            name: this.$t('Quarterly State Unemployment'),
            path: '/payroll/reports/quarterly-state-unemployment',
            permission: 'payroll_reports',
          },
          {
            name: this.$t('Payroll Payment Register'),
            path: '/payroll/reports/payroll-payment-register',
            permission: 'payroll_reports',
          },
          {
            name: this.$t('Quarterly Earnings - Federal'),
            path: '/payroll/reports/quarterly-earnings-federal',
            permission: 'payroll_reports',
          },
          {
            name: this.$t('Quarterly Earnings - State / Local'),
            path: '/payroll/reports/quarterly-earnings-state-local',
            permission: 'payroll_reports',
          },
          {
            name: this.$t('To-Date Earnings & Taxes'),
            path: '/payroll/reports/to-date-earnings-and-taxes',
            permission: 'payroll_reports',
          },
          {
            name: this.$t('Employee Listing'),
            path: '/payroll/reports/employee-listing',
            permission: 'payroll_reports',
          },
          {
            name: this.$t('Certified Payroll'),
            path: '/payroll/reports/certified-payroll',
            permission: 'payroll_reports',
          },
          {
            name: this.$t('Union Report'),
            path: '/payroll/reports/union-report',
            permission: 'payroll_reports',
          },
          {
            name: this.$t(`Worker's Comp Summary`),
            path: '/payroll/reports/workers-comp-summary',
            permission: 'payroll_reports',
          }
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
