<template>
  <BaseSelect
    v-on="$listeners"
    v-bind="$attrs"
    :multiple="multiple"
    :options="getOptions"
    :label-format="formatLabel"
    :table-columns="tableColumns"
    :value-key="valueKey"
    label-key="code"
  />
</template>
<script>
import { globalResources } from "@/components/form/util";

export default {
  props: {
    codeTypes: {
      type: Array,
      required: true,
    },
    filterMethod: {
      type: Function,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    valueKey: {
      type: String,
      default: 'id',
    },
  },
  data() {
    return {
      globalResources,
      tableColumns: [
        {
          minWidth: 100,
          maxWidth: 150,
          name: this.$t('Code'),
          prop: 'code',
        },
        {
          minWidth: 150,
          maxWidth: 150,
          name: this.$t('Description'),
          prop: 'description',
        },
      ],
    }
  },
  computed: {
    getOptions() {
      const options = []

      this.codeTypes.forEach(type => {
        const codes = (this.$store.getters['globalLists/getResourceList'](type) || [])
          .map(code => this.remapCode(code, type))

        options.push(...codes)
      })

      if (this.filterMethod) {
        return options.filter(this.filterMethod)
      }

      return options
    },
  },
  methods: {
    remapCode(code, type) {
      return {
        ...code,
        type,
      }
    },
    formatLabel(option) {
      const { code, description } = option
      if (!description) {
        return code
      }
      return `${code} (${description})`
    },
  },
}
</script>
