<template>
  <div class="flex justify-between items-center max-w-full group">
    <div class="flex flex-1 space-x-2 items-start">
      <div class="flex flex-1 flex-col space-y-1 items-start truncate">
        <div
          class="text-gray-800 truncate font-medium whitespace-break-spaces text-left"
        >
          {{ item.code }}
        </div>
        <span class="text-sm text-gray-500 truncate whitespace-break-spaces text-left">
            {{ item.description }}
            <span v-if="item.units" class="font-semibold">
              ({{ item.units }}
              <template v-if="item.type === codeTypes.EQUIPMENT">
                {{ $t('hours') }}
              </template>
              <template v-else>
                {{ item.um }}
              </template>)
            </span>
            <template v-else-if="item.um && !item.units">
              ({{ item.um }})
            </template>
          </span>
      </div>
    </div>
    <div class="hidden group-hover:flex space-x-2">
      <TableEditButton @click="showEditDialog = true"/>
      <TableDeleteButton @click="onDelete"/>
    </div>
    <BaseFormDialog
      v-if="showEditDialog"
      :open.sync="showEditDialog"
      :title="title"
    >
      <BaseForm @submit="onUpdate">
        <base-input
          :label="item.type === codeTypes.EQUIPMENT ? $t('Hours') : item.um || $t('Units')"
          v-model="model.units"
          type="number"
          class="col-span-6 md:col-span-3"
          step="0.01"
          :rules="validationRules"
        >
          <template #suffix>
            <template v-if="item.type === codeTypes.EQUIPMENT">
              {{ $t('hours') }}
            </template>
            <template v-else>
              {{ item.um }}
            </template>
          </template>
        </base-input>
      </BaseForm>
    </BaseFormDialog>
  </div>
</template>
<script>
import { codeTypes } from "@/modules/payroll/components/rates/util";

export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({})
    },
    duration: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      codeTypes,
      showEditDialog: false,
      model: {
        units: this.item.units,
      }
    }
  },
  computed: {
    title() {
      const type = this.item.type
      const resourceMap = {
        [codeTypes.EQUIPMENT]: this.$t('Equipment'),
        [codeTypes.MATERIAL]: this.$t('Material'),
        [codeTypes.PAY]: this.$t('Pay Code'),
      }
      return `${this.$t('Edit')} ${resourceMap[type]}`
    },
    validationRules() {
      const maxUnits = this.duration / 60
      return `max_value:${maxUnits}`
    }
  },
  methods: {
    async onDelete() {
      const confirmed = await this.$deleteConfirm({
        title: this.$t('Delete row'),
        description: this.$t('Are you sure you want to delete this row?'),
      })
      if (!confirmed) {
        return
      }
      this.$emit('delete')
    },
    onUpdate() {
      const newItem = {
        ...this.item,
        units: this.model.units
      }
      this.showEditDialog = false
      this.$emit('update', newItem)
    }
  }
}
</script>
