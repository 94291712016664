import axios from 'axios'
import { entityPreviewFields } from "@/modules/common/components/entity-preview/entities";

const types = {
  SET_CURRENT_INVOICE: 'SET_CURRENT_INVOICE',
  SET_CURRENT_VENDOR: 'SET_CURRENT_VENDOR',
  SET_CURRENT_VENDOR_LOADING: 'SET_CURRENT_VENDOR_LOADING',
  SET_CURRENT_INVOICE_LOADING: 'SET_CURRENT_INVOICE_LOADING',
  UPDATE_VENDOR: 'UPDATE_VENDOR',
};
const state = {
  currentInvoice: {
    attributes: {},
    relationships: {},
    meta: {}
  },
  currentVendor: {
    attributes: {},
    relationships: {},
    meta: {}
  },
  vendorLoading: false,
  invoiceLoading: false,
};

const mutations = {
  [types.SET_CURRENT_INVOICE]: (state, invoice) => {
    state.currentInvoice = invoice
  },
  [types.SET_CURRENT_VENDOR]: (state, vendor) => {
    state.currentVendor = vendor
  },
  [types.SET_CURRENT_VENDOR_LOADING]: (state, value) => {
    state.vendorLoading = value
  },
  [types.SET_CURRENT_INVOICE_LOADING]: (state, value) => {
    state.invoiceLoading = value
  },
  [types.UPDATE_VENDOR]: (state, vendor) => {
    state.currentVendor.attributes = vendor.attributes
  }
};

const actions = {
  async getInvoice({ commit }, invoiceId) {
    try {
      commit(types.SET_CURRENT_INVOICE_LOADING, true)
      const params = {
        related: `payments,${entityPreviewFields.Vendor},vendor.address,${entityPreviewFields.PurchaseOrder}`
      }
      const { data } = await axios.get(`/restify/invoices/${invoiceId}`, {
        params,
      })
      commit(types.SET_CURRENT_INVOICE, data)
    } finally {
      commit(types.SET_CURRENT_INVOICE_LOADING, false)
    }
  },
  async holdInvoice({ commit, dispatch }, invoiceId) {
    await axios.post(`/restify/invoices/${invoiceId}/actions?action=hold-invoice`)
    dispatch('getInvoice', invoiceId)
  },
  async unHoldInvoice({ commit, dispatch }, invoiceId) {
    await axios.post(`/restify/invoices/${invoiceId}/actions?action=unhold-invoice`)
    dispatch('getInvoice', invoiceId)
  },
  async getVendor({ commit }, invoiceId) {
    try {
      commit(types.SET_CURRENT_VENDOR_LOADING, true)
      const params = {
        related: 'addresses,contacts,activities'
      }
      const { data } = await axios.get(`/restify/vendors/${invoiceId}`, {
        params,
      })
      commit(types.SET_CURRENT_VENDOR, data)
    } finally {
      commit(types.SET_CURRENT_VENDOR_LOADING, false)
    }
  },
  async patchVendor({ state }, data = {}) {
    await axios.patch(`/restify/vendors/${data.id}`, data)
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
