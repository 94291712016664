<template>
  <div class="invoice-batches">
    <div class="mt-4">
      <BaseDataTable :columns="columns"
                     :data-loading="loading"
                     :can-manage-columns="false"
                     url="/restify/billing-payments/batches"
                     ref="table"
                     @data-fetch="batches = $event">
        <!-- TODO Add when voiding batches is supported -->
        <template v-if="false" v-slot:dropdown-actions>
          <TableActionItem>
            <void-button :disabled="selectedNonVoidCount === 0"
                         @click="showVoidDialog = true"
            >
              {{ $t('Void Batch(es)') }}
            </void-button>
          </TableActionItem>
        </template>
        <template v-if="false" v-slot:select="{row, index}">
          <base-checkbox v-model="batches[index].selected">
          </base-checkbox>
        </template>
        <template v-slot:journal_number="{row}">
          <router-link :to="`/accounts-receivable/payments/batches/${row.journal_id}/view`">
            {{ row.journal_code }}-{{ row.journal_number }}
          </router-link>
        </template>
        <template v-slot:count="{row}">
          <status-badge :status="row.count"/>
        </template>
        <template v-slot:voided_count="{row}">
          <status-badge :status="row.voided_count || '0'"/>
        </template>
        <template v-slot:checks_count="{row}">
          <batch-stats :batch="row"></batch-stats>
        </template>
        <template v-slot:extra-actions="{row}">
          <table-view-button :key="`view-${row.id}`" :link="`${$route.path}/${row.journal_id}/view`"/>
        </template>
      </BaseDataTable>
      <VoidPaymentsDialog
        v-if="showVoidDialog"
        :open.sync="showVoidDialog"
        :batches="selectedBatches.map(b => b.journal_id)"
        :for-billings="true"
        @cancel="showVoidDialog = false">
      </VoidPaymentsDialog>
    </div>
  </div>
</template>
<script>
  import { PrinterIcon } from 'vue-feather-icons'
  import BatchStats from "@/modules/accounts-payable/components/payment/BatchStats";
  import VoidPaymentsDialog from "@/modules/accounts-payable/components/checks/VoidPaymentsDialog";
  import PreviewChecksButton from "@/components/common/PreviewChecksButton";
  import axios from "axios";

  export default {
    components: {
      PreviewChecksButton,
      BatchStats,
      PrinterIcon,
      VoidPaymentsDialog,
    },
    data() {
      return {
        batches: [],
        loading: false,
        showVoidDialog: false,
        columns: [
          {
            label: this.$t('Journal'),
            prop: 'journal_number',
            maxWidth: 150,
          },
          {
            label: this.$t('Date'),
            prop: 'date',
            maxWidth: 200,
            component: 'FormattedDate',
          },
          {
            label: this.$t('Payment Info'),
            prop: 'checks_count',
            maxWidth: 100,
            align: 'center',
          },
          {
            label: this.$t('Payment Count'),
            prop: 'count',
            maxWidth: 100,
            align: 'center',
            component: 'Status'
          },
          {
            label: this.$t('Void Count'),
            prop: 'voided_count',
            maxWidth: 100,
            align: 'center',
            component: 'Status'
          },
        ]
      }
    },
    computed: {
      selectedBatches() {
        return this.batches.filter(b => b.selected)
      },
      selectedNonVoidCount() {
        let count = 0
        this.selectedBatches.forEach(batch => {
          if (batch.voided_count < batch.count) {
            count++
          }
        })
        return count
      },
      selectedCheckCount() {
        let count = 0
        this.selectedBatches.forEach(batch => {
          count+= batch.check_count
        })
        return count
      }
    },
    methods: {
      formatDate(date) {
        return this.$formatDate(date, 'MM-dd-yyyy')
      },
      seeMoreClick(row) {
        this.$router.push(`/accounts-payable/payments/${this.formatDate(row.date)}`)
      },
      async downloadChecks() {
        const url = '/restify/billing-payments/action?action=print-billing-payment-batches'
        const batches = this.selectedBatches.map(b => b.journal_id)
        const data = { batches }
        const params = { responseType: 'blob' }

        return await axios.post(url, data, params)
      }
    }
  }
</script>
