<template>
  <div class="print:hidden">
    <base-tooltip :content="$t('Compute Payroll')">
      <base-button :loading="loading"
                   variant="primary-light"
                   @click="showDialog = true"
      >
        <IconServiceInvoices class="w-5 h-5 mr-1"/>
        {{ $t('Compute') }}
      </base-button>
    </base-tooltip>
    <base-form-dialog
      v-if="showDialog"
      :open.sync="showDialog"
      :title="$t('Compute Payroll.')"
      @close="showDialog = false"
    >
      <ValidationObserver>
        <base-form :save-text="$t('Compute')"
                   :focus-on-first-input="false"
                   layout="modal"
                   submit-button-type="button"
                   @submit="onSubmit"
                   grid-classes="grid grid-cols-1"
        >
          <slot/>
          <div class="col-span-1 mt-2">
            <bank-select v-model="model.bank_id"
                         :used-for="BankUsedInTypes.Payroll"
                         :label="$t('Bank')"
                         :name="$t('Bank')"
                         id="bank"
                         rules="required"
            />
          </div>
        </base-form>
      </ValidationObserver>
    </base-form-dialog>
  </div>
</template>
<script>
  import { BankUsedInTypes } from "@/enum/enums";

  export default {
    computed: {
      BankUsedInTypes() {
        return BankUsedInTypes
      }
    },
    inheritAttrs: false,
    data() {
      return {
        loading: false,
        showDialog: false,
        model: {
          bank_id: undefined,
        },
      }
    },
    methods: {
      onSubmit() {
        this.loading = true
        this.showDialog = false
        this.$emit('on-compute', this.model, () => {
          this.loading = false
        })
      },
    },
  }
</script>
