<template>
  <base-filter-row :title="$t('Jobs')">

    <base-filter-range :class="gridClass">
      <template #start>
        <job-select
            v-model="value.job_number_from"
            v-bind="$attrs"
            :add-entity="false"
            :placeholder="$t('Job From')"
            :name="$t('Job From')"
            :hide-label="true"
            :allowCreateNoOptionsOnly="true"
            value-key="number"
            clearable
            allow-create
            @visible-change="onChangeStartRangeFilterVisibility"
        />
      </template>
      <template #end>
        <job-select
            v-model="value.job_number_to"
            v-bind="$attrs"
            :add-entity="false"
            :placeholder="$t('Job To')"
            :name="$t('Job To')"
            :hide-label="true"
            :rules="value.job_number_from ? 'required' : ''"
            :allowCreateNoOptionsOnly="true"
            value-key="number"
            clearable
            @blur="tryFillEndRangeValue"
        />
      </template>
    </base-filter-range>

    <slot/>

  </base-filter-row>
</template>
<script>
  export default {
    props: {
      value: {
        type: Object,
        default: () => ({
          job_number_from: null,
          job_number_to: null,
        }),
      },
      gridClass: {
        type: String,
        default: 'col-span-4',
      },
    },
    methods: {
      onChangeStartRangeFilterVisibility(opened) {
        if (opened) {
          return
        }
        this.tryFillEndRangeValue()
      },
      tryFillEndRangeValue() {
        this.value.job_number_to = this.value.job_number_to || this.value.job_number_from
      },
    }
  }
</script>
