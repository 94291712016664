<template>
  <BaseDataTable
    v-bind="defaultAttributes"
    :columns="columns"
    :data="getData"
    :total-rows="rowsLength"
    :has-summary="false"
    :compact="true"
    class="no-borders"
    :customizeLastSubtotalRow="false"
    @force-pagination="forcePagination"
  >
    <template #report-header>
      <BaseReportHeader :header="data.header" />
    </template>

    <template #header="{ row }">
      <div
        v-if="row?.header?.employee"
        class="flex items-inline-base"
      >
        <EmployeeLink
          :data="row.header?.employee"
          :show-preview="false"
          class="min-w-[400px]"
        />
        <BaseAddressLink
          :value="row.header?.address"
          class="mx-2"
        />

        <span>{{ $t('SS#') }}</span>
        <span class="mx-2">
          {{ row.header?.employee?.masked_ssn }}
        </span>
        <BasePhoneLink
          :value="row.header?.employee?.phone_1"
          :phoneMask="'(###)###-####'"
          :no-data-text="''"
        />
      </div>
      <div v-else>
        <div class="font-bold">
          <span v-if="row.header.departmentCode">
            {{ $t('Department Code') }}
            <span>{{ row.header.departmentCode }}</span>
          </span>
          <span v-else>
            {{ $t(`No Department`) }}
          </span>
        </div>
      </div>
    </template>
    <template #employee="{ row }">
      <dl class="w-full">
        <dt
          v-if="row.employee.driver_license_number"
          class="flex justify-between"
        >
          <span>{{ $t(`Driver License`) }}</span>
          <span>{{ row.employee.driver_license_number }}</span>
        </dt>
        <dt
          v-if="row.employee.minority"
          class="flex justify-between"
        >
          <span>{{ $t('Minority') }}</span>
          <span>{{ row.employee.minority }}</span>
        </dt>
        <dt class="flex justify-between">
          <span>{{ $t('M/F') }}</span>
          <span>{{ row.employee.sex  }}</span>
        </dt>

        <dt class="flex justify-between">
          <span>{{ $t('Status') }}</span>
          <span>{{ row.employee.statusLabel }}</span>
        </dt>
        <dt class="flex justify-between">
          <span :title="$t('Job Can Override Union')">{{ $t('Union (O/R)') }}</span>
          <UnionLink
            :id="row.union.id"
            :displayValue="row.union.text"
          />
        </dt>
        <dt class="flex justify-between">
          <span>{{ $t('Craft/Level') }}</span>
          <CraftCodeLink
            :id="row.craft?.id"
            :displayValue="row.craft?.text"
          />
        </dt>
        <dt class="flex justify-between">
          <span>{{ $t('On Certified Payroll') }}</span>
          <span>{{ row.employee?.on_certified_payroll }}</span>
        </dt>

        <dt
          v-if="false /* Hidden for now (client feedback) */"
          class="flex justify-between"
        >
          <span>{{ $t('Has Pension Plan') }}</span>
          <span>{{ row.employee?.has_pension_plan }}</span>
        </dt>

        <dt
          v-if="row.department?.code"
          class="flex justify-between"
        >
          <span>{{ $t('Department Code') }}</span>
          <span>{{ row.department?.code }}</span>
        </dt>

      </dl>
    </template>
    <template #job="{ row }">
      <dl class="w-full">
        <dt class="flex justify-between">
          <span>{{ $t('Job') }}</span>
          <span>{{ row.job?.number }}</span>
        </dt>
        <dt class="flex justify-between">
          <span>{{ $t('Phase Code') }}</span>
          <span>{{ row.line_item?.phase_code }}</span>
        </dt>
        <dt class="flex justify-between">
          <span>{{ $t('Cost Code') }}</span>
          <span>{{ row.line_item?.cost_code }}</span>
        </dt>
      </dl>
    </template>
    <template #payment="{ row }">
      <dl class="w-full">
        <dt class="flex justify-between">
          <span>{{ $t('Pay Frequency') }}</span>
          <span>{{ row.employee?.pay_frequency  }}</span>
        </dt>
        <dt
          v-if="row.employee.salary_amount"
          class="flex justify-between"
        >
          <span>{{ $t('Salary') }}</span>
          <span>{{ $formatPrice(row.employee.salary_amount) }}</span>
        </dt>
        <template v-else>
          <dt class="flex justify-between">
            <span>{{ $t('Reg Hour Rate') }}</span>
            <span>{{ row.payment?.REGPAY?.rate }}</span>
          </dt>
          <dt class="flex justify-between">
            <span>{{ $t('Ovt Hour Rate') }}</span>
            <span>{{ row.payment?.OVTPAY?.rate }}</span>
          </dt>
          <dt class="flex justify-between">
            <span>{{ $t('Prm Hour Rate') }}</span>
            <span>{{ row.payment?.PRMPAY?.rate }}</span>
          </dt>
        </template>
        <div class="border-b border-gray-500 w-full my-2" />
        <dt class="flex justify-between">
          <span>{{ $t('G/L Acc (Sub)') }}</span>
          <span>{{ row.employee.accountAndSubaccount }}</span>
        </dt>
        <dt class="flex justify-between">
          <span>{{ $t('W/HOLD') }}</span>
          <span>{{ row.state_tax_jurisdiction?.code }}</span>
        </dt>
        <dt class="flex justify-between">
          <span :title="$t(`Job Can Override Worker Compensation`)">{{ $t('W/COMP (O/R)') }}</span>
          <span>{{ row.employee.workersCompText }}</span>
        </dt>
        <dt class="flex justify-between">
          <span :title="$t(`Job Can Override General Liablity`)">{{ $t('G/LIAB (O/R)') }}</span>
          <span>{{ row.employee.generalLiabilityText }}</span>
        </dt>
      </dl>
    </template>
    <template #filingStatus="{ row }">
      <div class="font-bold">
        {{ $t('Federal') }}
      </div>
      <dl class="w-full">
        <dt class="flex justify-between">
          <span>{{ row.filingStatus.federal.status }}</span>
        </dt>
        <dt class="flex justify-between">
          <span>{{ $t('Allowances') }}</span>
          <span>{{ row.filingStatus.federal.allowances }}</span>
        </dt>
      </dl>
      <template v-for="state of row.filingStatus.state">
        <div class="font-bold mt-2">
          {{ state.description }}
        </div>
        <dl class="w-full">
          <dt class="flex justify-between">
            <span>{{ state.status }}</span>
          </dt>
          <dt class="flex justify-between">
            <span>{{ $t('Allowances') }}</span>
            <span>{{ state.allowances }}</span>
          </dt>
        </dl>
      </template>
    </template>
    <template #dates="{ row }">
      <dl class="w-full">
        <dt class="flex justify-between">
          <span>{{ $t('Birthday') }}</span>
          <span>{{ row.dates.birthday }}</span>
        </dt>
        <dt class="flex justify-between">
          <span>{{ $t('Orig Hire') }}</span>
          <span>{{ row.dates.initial_hire_date }}</span>
        </dt>
        
        <dt class="flex justify-between">
          <span>{{ $t('Rehire') }}</span>
          <span>{{ row.dates.hire_date }}</span>
        </dt>
        <dt class="flex justify-between">
          <span>{{ $t('Termination') }}</span>
          <span>{{ row.dates.term_date }}</span>
        </dt>
        <dt class="flex justify-between">
          <span>{{ $t('Other') }}</span>
          <span>{{ row.dates.other_date }}</span>
        </dt>
        <dt class="flex justify-between">
          <span>{{ $t('Last Check') }}</span>
          <span>{{ row.dates.last_check_date }}</span>
        </dt>
      </dl>
    </template>
    <template #html-row="{ htmlData }">
      <tr class="content-row">
        <td :colspan="columns.length">
          <div
            v-if="htmlData.deductionBenefitLines?.length"
            class="py-2"
          >
            <span class="font-bold">
              {{ htmlData.title }}
            </span>

            <EmployeeListingReportRateLine
              v-for="line of htmlData.deductionBenefitLines"
              :line="line"
              :key="line.sourceId"
            />
          </div>
        </td>
      </tr>
    </template>
    <template #subtotal="{ subtotal }">
      <td />
      <td />
      <td />
      <td align="right" style="vertical-align: top;">
        {{ $t('Total Employees:') }}
      </td>
      <td>
        <div
          v-for="status in subtotal.countsByStatus"
          :key="status.label"
          class="flex justify-between"
        >
          <span>{{ status.label }}</span>
          <span>{{ status.count }}</span>
        </div>
        <div class="border-t-2 border-gray-400 my-1" />
        <div class="flex justify-between ">
          <span></span>
          <span>{{ subtotal.total }}</span>
        </div>
      </td>
    </template>
  </BaseDataTable>
</template>
<script>
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
import EmployeeListingReportRateLine from '@/modules/payroll/components/reports/employee-listing/EmployeeListingReportRateLine.vue'
import UnionLink from "@/components/links/UnionLink.vue";
import CraftCodeLink from "@/components/links/CraftCodeLink.vue";
import { capitalize } from 'lodash'
import {
  employeeRatesTypes,
} from '@/modules/payroll/components/rates/util'
import { federalFilingStatusesDictionary } from '@/enum/enums'
import { statusOptions } from "@/modules/payroll/utils/employeeUtils";
import { globalResources } from "@/components/form/util";

export default {
  extends: ReportTableWrapper,
  components: {
    EmployeeListingReportRateLine,
    UnionLink,
    CraftCodeLink,
  },
  setup() {
    return {
      capitalize
    }
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('Employee'),
          prop: 'employee',
          align: 'left',
          verticalAlign: 'top',
          minWidth: 80,
          maxWidth: 200,
        },
        {
          label: this.$t('Job'),
          prop: 'job',
          align: 'left',
          verticalAlign: 'top',
          minWidth: 80,
          maxWidth: 200,
        },
        {
          label: this.$t('Filing<br>Status'),
          prop: 'filingStatus',
          align: 'left',
          verticalAlign: 'top',
          minWidth: 80,
          maxWidth: 200,
        },
        {
          label: this.$t('Dates'),
          prop: 'dates',
          align: 'left',
          verticalAlign: 'top',
          minWidth: 150,
          maxWidth: 150,
        },
        {
          label: this.$t('Payment'),
          prop: 'payment',
          align: 'left',
          verticalAlign: 'top',
          minWidth: 80,
          maxWidth: 200,
        },
      ]
    },
    groupByDepartment() {
      return [1, "1", true].includes(this.options.group_by_department)
    }
  },
  methods: {
    addEmployeeRow(employeeData) {
      this.rows.push({
        header: {
          employee: employeeData.employee,
          address: employeeData.address,
        }
      })

      const {
        account,
        subaccount
      } = employeeData.employee

      const accountAndSubaccount = (account || '') +
        (subaccount ? ` (${subaccount})` : '')

      const jobCanOverrideUnion = employeeData.employee.job_can_override_union
        ? this.$t('Yes')
        : this.$t('No')

      const jobCanOverrideWorkersComp = employeeData.employee.job_can_override_workers_comp
        ? this.$t('Yes')
        : this.$t('No')

      const jobCanOverrideGeneralLiability = employeeData.employee.job_can_override_gen_liability
        ? this.$t('Yes')
        : this.$t('No')

      const unionText = (employeeData.union?.code || '') + ` (${jobCanOverrideUnion})`
      const craftText = employeeData.craft?.code
        ? `${employeeData.craft?.code}/${employeeData.craft?.level}`
        : ''

      const workersCompText = (employeeData.employee.workers_comp_rate_code || '') + 
      ` (${jobCanOverrideWorkersComp})`

      const generalLiabilityText = (employeeData.employee.gen_liability_rate_code || '') + 
        `(${jobCanOverrideGeneralLiability})`

      let stateTaxes = []

      if (employeeData.state_tax) {
        stateTaxes = Array.isArray(employeeData.state_tax)
          ? employeeData.state_tax
          : [employeeData.state_tax]
      }

      const filingStatus = {
        federal: {
          status: federalFilingStatusesDictionary[employeeData.employee?.federal_status],
          allowances: employeeData.employee?.allowances,
        },
        state: stateTaxes.map(tax => {
          const description = tax?.description || this.getStateTaxDescription(tax?.jurisdiction_id)
          return {
            description,
            status: federalFilingStatusesDictionary[tax?.status],
            allowances: tax?.personal_tax_credit_allowance_count,
          }
        })
      }

      const driver_license_number =  (employeeData.employee.driver_licenses || []).map(dl => {
        return [
          dl?.state,
          dl?.number
        ].filter(Boolean).join(' ')
      }).join(', ')
      
      const employeeLine = {
        ...employeeData,
        union: {
          ...employeeData.union,
          text: unionText
        },
        craft: {
          ...employeeData.craft,
          text: craftText,
        },
        employee: {
          ...employeeData.employee,
          minority: !employeeData.employee.minority || employeeData.employee.minority === 'none'
            ? ''
            : capitalize(employeeData.employee.minority),
          driver_license_number,
          on_certified_payroll: employeeData.employee.on_certified_payroll ? this.$t('Yes') : this.$t('No'),
          has_pension_plan: employeeData.employee.has_pension_plan ? this.$t('Yes') : this.$t('No'),
          statusLabel: statusOptions.find(o => o.value === employeeData.employee.status)?.label || employeeData.employee.status,
          pay_frequency: capitalize(employeeData.employee.pay_frequency),
          accountAndSubaccount,
          workersCompText,
          generalLiabilityText,
        },
        payment: {
          ...employeeData.rates,
          REGPAY: {
            ...employeeData.rates?.REGPAY,
            rate: this.$formatPrice(employeeData.rates?.REGPAY?.rate || 0)
          },
          OVTPAY: {
            ...employeeData.rates?.OVTPAY,
            rate: this.$formatPrice(employeeData.rates?.OVTPAY?.rate || 0)
          },
          PRMPAY: {
            ...employeeData.rates?.PRMPAY,
            rate: this.$formatPrice(employeeData.rates?.PRMPAY?.rate || 0)
          }
        },
        filingStatus,
        dates: {
          birthday: this.$formatDate(employeeData.employee?.dob),
          initial_hire_date: this.$formatDate(employeeData.employee?.initial_hire_date),
          hire_date: this.$formatDate(employeeData.employee?.hire_date),
          term_date: this.$formatDate(employeeData.employee?.term_date),
          other_date: this.$formatDate(employeeData.employee?.other_date),
          last_check_date: this.$formatDate(employeeData.payroll?.payment_date),
        }
      }

      this.rows.push(employeeLine)

      const deductionBenefitLines = [
        ...this.mapDeductionOrBenefitLines(employeeData.benefits, employeeRatesTypes.BenefitRates),
        ...this.mapDeductionOrBenefitLines(employeeData.deductions, employeeRatesTypes.DeductionRates),
        ...this.mapDeductionOrBenefitLines(employeeData.retirements, employeeRatesTypes.RetirementRates),
      ]

      this.rows.push({
        htmlData: {
          title: this.$t('Benefits, deductions & retirements'),
          deductionBenefitLines,
        }
      })
    },
    getStateTaxDescription(jurisdictionId) {
      const stateTaxes = this.$store.getters['globalLists/getResourceList'](globalResources.StateTaxes)
      const stateTax = stateTaxes.find(tax => tax.id === jurisdictionId)

      return stateTax?.description || ''
    },
    mapDeductionOrBenefitLines(deductionsOrBenefits, rate_type) {
      if (!deductionsOrBenefits?.rates) {
        return []
      }

      const lines = []
      const rates = deductionsOrBenefits?.rates || {}
      const sources = deductionsOrBenefits?.sources || {}

      const sourceIds = Object.keys(sources)

      sourceIds.forEach(sourceId => {
        const source = sources[sourceId] || {}
        const rate = rates[sourceId] || {}

        lines.push({
          sourceId,
          description: source.description,
          rate: rate.rate,
          employee_id: rate.employee_id,
          rate_type,
          calculate_by: rate.calculate_by, // calculateByOptions
          frequency: rate.frequency, // rateTypeFrequencies
          accumulation_interval: rate.accumulation_interval,  // accumulationIntervalOptions
          accumulated_amount_to_date: rate.accumulated_amount_to_date,
          limit_on_accumulated_amount: rate.limit_on_accumulated_amount,
          period_limit_amount: rate.period_limit_amount,
        })
      })

      return lines
    },
    composeRows(data) {
      const allEmployees = []

      if (!this.groupByDepartment) {
        data.forEach(employeeData => {
          allEmployees.push(employeeData.employee)
        })

        data.forEach(this.addEmployeeRow)
      }
      else {
        const departmentCodes = Object.keys(data)

        departmentCodes.forEach(code => {
          this.rows.push({
            header: {
              departmentCode: code,
            }
          })

          const employeesData = data[code] || []
          employeesData.forEach(employeeData => {
            allEmployees.push(employeeData.employee)
          })

          employeesData.forEach(this.addEmployeeRow)
        })
      }
      
      // Count employees grouped by status
      const countsByStatus = allEmployees.reduce((acc, employee) => {
        const status = employee.status
        const label = statusOptions.find(o => o.value === status)?.label || status
        acc[status] = {
          label,
          count: (acc[status]?.count || 0) + 1
        }

        return acc
      }, {})

      this.rows.push({
        subtotal: {
          countsByStatus,
          total: allEmployees.length
        }
      })
      
      return this.rows
    },
  }
}
</script>
