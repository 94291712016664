<template>
  <div class="truncate">
    <template v-if="meta?.invoice_id">
      <router-link :to="`/accounts-payable/invoices/${meta.invoice_id}/view`">
        <template v-if="showDescription">
          {{ $t('Invoice #') }}
        </template>
        {{ reference }}
      </router-link>
    </template>

    <template v-else-if="meta?.invoice_payment_id">
      <router-link :to="`/accounts-payable/payments/batches/${journalId}/view`">
        <template v-if="showDescription">
          {{ $t('Invoice Payment #') }}
        </template>
        {{ reference }}
      </router-link>
    </template>

    <template v-else-if="meta.billing_payment_id">
      <router-link :to="`/accounts-receivable/payments/batches/${journalId}/view`">
        <template v-if="showDescription">
          {{ $t('Billing Payment #') }}
        </template>
        {{ reference }}
      </router-link>
    </template>

    <router-link
        v-else-if="isBillingType"
        :to="`/accounts-receivable/billings/${getBillingType(meta.billing_type)}/${meta.billing_id}/view`"
    >
      {{ reference }}
    </router-link>

    <template v-else>
      {{ reference }}
    </template>
  </div>
</template>
<script>
  import { getBillingType } from '@/modules/accounts-receivable/pages/billings/billings'

  export default {
    name: 'ReferenceLink',
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      showDescription: {
        type: Boolean,
        default: false,
      },
      referenceNo: {
        type: [String, Number],
        default: '',
      },
      id: {
        type: String,
        default: '',
      },
    },
    computed: {
      reference() {
        return this.data.attributes?.reference_no || this.referenceNo || ''
      },
      journalId() {
        return this.id || this.data.id
      },
      meta() {
        return this.data.attributes?.meta || this.data
      },
      isBillingType() {
        return this.meta.billing_id && this.meta.billing_type
      },
    },
    methods: {
      getBillingType,
    },
  }
</script>
