<template>
  <base-form
      layout="vertical"
      v-bind="$attrs"
      :loading="loading"
      :save-text="$t('Open Calendar Year')"
      @cancel="$emit('cancel')"
      @submit="onSubmit"
  >
    <base-input
        :disabled="true"
        :value="$settings($modules.PR, 'calendar_year')"
        :label="$t('Current Calendar Year')"
    />

    <base-input
        :disabled="true"
        :value="year"
        :label="$t('New Calendar Year')"
    />
  </base-form>
</template>
<script>

  import axios from "axios";

  export default {
    data() {
      return {
        loading: false,
        year: this.getNewCalendarYear()
      }
    },
    computed: {
    },
    methods: {
      getNewCalendarYear() {
        const now = new Date()
        const currentMonth = now.getMonth()
        const currentYear = now.getFullYear()

        const isDecember = currentMonth === 11
        const isNovember = currentMonth === 12
        if (isNovember || isDecember) {
          return currentYear + 1
        }
        return currentYear
      },
      async onSubmit() {
        try {
          this.loading = true
          await axios.post(`/restify/company-settings/actions?action=open-new-payroll-calendar-year`, {
            year: this.year,
          })
          await this.$store.dispatch('company/getCompanySettings')
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not open the new calendar year'))
        } finally {
          this.loading = false
        }
      }
    },
  }
</script>
