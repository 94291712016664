<template>
  <BaseReportForm
    :report-number="reportNumber"
    :filters="model"
    :transform-filters="transformFilters"
  >
    <template #filters>
      <BaseFilterRow>
        <JobRangeFilter v-model="model" class="col-span-12"/>
      </BaseFilterRow>
      <BaseFilterRow :title="$t('Distributions')">
        <BaseSelect
          v-model="model.distributions"
          :options="historyDistributionHistoryOptions"
          class="col-span-2"
        />
      </BaseFilterRow>
      <BaseFilterRow :title="$t('Subtotal On')">
        <BaseSelect
          v-model="model.subtotal_on"
          :options="historyDistributionSubtotalOnOptions"
          class="col-span-2"
          collapse-tags
          multiple
        />
      </BaseFilterRow>
      <PayrollDatesFilter
        v-model="model"
      />
    </template>
    <template #table="{data, loading}">
      <PayrollHistoryDistributionReportTable
        :data="data"
        :filters="model"
        :report-number="reportNumber"
        :data-loading="loading"
        ref="reportTable"
      />
    </template>
  </BaseReportForm>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import {
  historyDistributionHistory,
  historyDistributionHistoryOptions,
  historyDistributionSubtotalOn,
  historyDistributionSubtotalOnOptions, selectionDateFields
} from '@/modules/payroll/components/reports/util'
import JobRangeFilter from '@/components/range-filters/JobRangeFilter.vue'
import PayrollDatesFilter from '@/modules/payroll/components/reports/PayrollDatesFilter'
import PayrollHistoryDistributionReportTable
  from '@/modules/payroll/components/reports/PayrollHistoryDistributionReportTable.vue'

export default defineComponent({
  components: {
    JobRangeFilter,
    PayrollDatesFilter,
    PayrollHistoryDistributionReportTable,
  },
  data() {
    return {
      reportNumber: 4871,
      historyDistributionHistoryOptions,
      historyDistributionSubtotalOnOptions,
      model: {
        job_number_from: null,
        job_number_to: null,
        distributions: historyDistributionHistory.Job,
        subtotal_on: [ historyDistributionSubtotalOn.Job ],
        ...selectionDateFields,
      } as Payroll.History_Distribution,
    }
  },
  methods: {
    transformFilters(params: any) {
      const { subtotal_on } = params
      if (!Array.isArray(subtotal_on) && typeof subtotal_on === 'string') {
        params.subtotal_on = [subtotal_on]
        this.model.subtotal_on = [subtotal_on]
      }
      return params
    }
  }
})
</script>
