<template>
  <div class="report-header">
    <div class="flex justify-between w-full">

      <dl class="text-left w-1/3">
        <dt>{{ $t('Date:') }} {{ $formatDate(now) }}</dt>
        <dt>{{ $t('Time:') }} {{ $formatDate(now, 'HH:mm:ss') }}</dt>
        <dt v-if="header.company?.name">{{ $t('Company:') }} {{ header.company?.name }}</dt>
        <slot name="left"/>
      </dl>

      <dl class="w-1/3 text-center report-title">
        <dt v-if="header.titles"
            v-html="header.titles.join('<br>')"
        >

        </dt>
        <dt v-else>{{ header.title }}</dt>
        <dt v-if="header.format">
          {{ header.format }}
        </dt>
        <slot name="center"/>
      </dl>

      <dl class="text-right w-1/3">
        <template v-if="showReportsCode && header.code">
          <dt>{{ $t('Report Code:') }} {{ header.code }}</dt>
          <dt v-if="header.description">{{ header.description }}</dt>
        </template>
        <dt class="page-counter">
        </dt>
        <slot name="right"/>
      </dl>

    </div>

    <div v-if="hasFilters" class="flex">
      <span class="mr-1">{{ $t('View:') }}</span>
      <div class="flex flex-col items-start">
        <span
            v-for="(filter, index) of header.filters"
            v-html="filter"
            :key="`filter-${index}`"
        />
      </div>
    </div>

  </div>
</template>
<script>

  export default {
    props: {
      header: {
        required: true,
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        now: new Date(),
      }
    },
    computed: {
      hasFilters() {
        return this.header.filters && this.header.filters.length
      },
      isArrayTitle() {
        return Array.isArray(this.header.title)
      },
      showReportsCode() {
        return this.$store.getters['company/showReportsCode']
      },
    },
    watch: {
      header: {
        immediate: true,
        handler(value) {
          this.$store.commit('company/SET_CURRENT_REPORT_HEADER', value)
        }
      }
    },
    beforeDestroy() {
      this.$store.commit('company/SET_CURRENT_REPORT_HEADER', {})
    }
  }
</script>
